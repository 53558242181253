import React, { useState } from "react";
import { useOne } from "@pankod/refine-core";
import {
    useModalForm, Modal, Space, Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { ICompany, IOptionGroup, IState, InputValidatorRegex } from "../../../../interfaces/company";
import { getStateNameById } from "utility/companyUtils";

export const UpdateCompanyAddressModel: React.FC<{ refetchCompanyDetail: any, companyDetail: ICompany }> = ({ companyDetail, refetchCompanyDetail }) => {
    let [stateId, setStateId] = useState(0);
    let [cityList, setCityList] = useState<IOptionGroup[]>();
    let [cityListLength, setCityListLength] = useState(0);
    //get state list
    const { selectProps: stateSelectProps } = useSelect<IState>({
        dataProviderName: "grapghQl",
        resource: 'getAllState',
        optionLabel: "name",
        optionValue: "id"
    });
    stateSelectProps.showSearch = true
    const { refetch: fatchState } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCityByState',
        id: '',
        metaData: { stateId: stateId },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                setCityListLength(data.data.length)
                setCityList(data.data)
            },
        },
    });
    //Add Company address Model
    const { modalProps: companyAddressModel, formProps: companyAddressForm, show: companyAddressShow } = useModalForm<any>({
        resource: "addOrUpdateCompanyAddressAndGstNumber",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId: companyDetail.id,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });

    companyAddressModel.title = `Edit Company Address and GST`
    companyAddressModel.centered = true
    companyAddressModel.width = 800
    companyAddressModel.onOk = () => {
        companyAddressForm.form?.setFieldsValue({
            city: getStateNameById(companyAddressForm.form?.getFieldValue('city'), cityList),
            state: getStateNameById(companyAddressForm.form?.getFieldValue('state'), stateSelectProps.options)
        })
        companyAddressForm.form?.submit()
    }
    const { addressLine1, addressLine2, city, state, pincode } = companyDetail.companyData ?? {};
    return (
        <Space>
            <Button className="credit-btn" type={"link"} onClick={() => {
                companyAddressShow();
            }}>
                Edit Company Address
            </Button>

            <Modal {...companyAddressModel}>
                <Form {...companyAddressForm} initialValues={{
                    addressLine1: addressLine1 || '',
                    addressLine2: addressLine2 || '',
                    city: city || '',
                    state: state || '',
                    pincode: pincode || '',
                    gstNo: companyDetail?.gstNo || '',
                }} layout="vertical">
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item label="Address" name="addressLine1">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item label="Address Line 2" name="addressLine2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="State"
                                name="state"

                            >
                                <Select
                                    placeholder='Enter State'
                                    onSelect={(e: any) => {
                                        companyAddressForm.form?.setFieldsValue({
                                            city: "",
                                            state: getStateNameById(e, stateSelectProps.options)
                                        })
                                        setStateId(e);
                                    }}
                                    {...stateSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="City"
                                name="city"
                            >
                                <Select
                                    showSearch={true}
                                    placeholder='Enter City'
                                    onSearch={(e: any) => {
                                        companyAddressForm.form?.setFieldsValue({ city: e });
                                        if (cityList && cityList) {
                                            cityList[cityListLength] = {
                                                label: e,
                                                value: e
                                            }
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        companyAddressForm.form?.setFieldsValue({ city: getStateNameById(e, cityList) });
                                    }}
                                    options={cityList}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item rules={[
                                {
                                    message: "Enter Correct Pincode",
                                    pattern: InputValidatorRegex.PINCODE
                                },
                            ]} label="Pincode" name="pincode">
                                <Input onChange={(event: any) => {
                                    companyAddressForm.form?.setFieldsValue({ pincode: event?.target?.value.replace(/[^0-9]/g, '') ? event?.target?.value.replace(/[^0-9]/g, '') : '' });
                                }} maxLength={6} />
                            </Form.Item>
                        </Col>
                        <Col lg={24}>
                            <Form.Item label="GST Number" name="gstNo">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        </Space>
    );
};