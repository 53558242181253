import React from "react";
import {
    useModalForm, Modal, Space, Form,
    Row,
    Col,
    Button,
    Input,
} from "@pankod/refine-antd";
import { ICompany, InputValidatorRegex } from "../../../../interfaces/company";

export const UpdateCompanyDetailsModel: React.FC<{ refetchCompanyDetail: any, companyDetail: ICompany }> = ({ companyDetail, refetchCompanyDetail }) => {

    //update Company details Model
    const { modalProps: companyDetailsModel, formProps: companyDetailsForm, show: companyDetailsShow } = useModalForm<any>({
        resource: "updateCompanyDetail",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId: companyDetail.id,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });

    companyDetailsModel.title = `Edit Company Pan, Phone and Email`
    companyDetailsModel.centered = true
    companyDetailsModel.width = 800

    const { emailId, panNo, phoneNo } = companyDetail ?? {};
    return (
        <Space>
            <Button className="credit-btn" type={"link"} onClick={() => {
                companyDetailsShow();
            }}>
                Edit Company Details
            </Button>

            <Modal {...companyDetailsModel}>
                <Form {...companyDetailsForm} initialValues={{
                    emailId: emailId || '',
                    panNo: panNo || '',
                    phoneNo: phoneNo || '',
                }} layout="vertical">
                    <Row gutter={16}>
                        <Col lg={8}>
                            <Form.Item rules={[
                                {
                                    message: "Enter Correct Pan Number",
                                    pattern: InputValidatorRegex.PAN_NO
                                },
                            ]} label="Pan Number" name="panNo">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item rules={[
                                {
                                    message: "Enter Correct Phone Number",
                                    pattern: InputValidatorRegex.MOBILE_NO
                                },
                            ]} label="Phone Number" name="phoneNo">
                                <Input onChange={(event: any) => {
                                    companyDetailsForm.form?.setFieldsValue({ phoneNo: event?.target?.value.replace(/[^0-9]/g, '') ? event?.target?.value.replace(/[^0-9]/g, '') : '' });
                                }} maxLength={10} />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item rules={[
                                {
                                    message: "Enter Correct Email",
                                    pattern: InputValidatorRegex.EMAIL
                                },
                            ]} label="Email" name="emailId">
                                <Input type="email"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Space>
    );
};