import React, { useEffect, useState } from "react";
import { useOne } from "@pankod/refine-core";
import {
    useModalForm, Modal, Space, Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { IUser } from "../../../../interfaces";
import { IState, IUserAddress, InputValidatorRegex } from "../../../../interfaces/company";

export const AddEditUserAddressModel: React.FC<{ refetchUsers: any, refetchCompanyDetail: any, userItem: IUser, addressItem?: IUserAddress }> = ({ userItem, refetchCompanyDetail, addressItem, refetchUsers }) => {
    let [userState, setUserState] = useState(0);
    let [cityList, setCityList] = useState();
    //get state list
    const { selectProps: stateSelectProps } = useSelect<IState>({
        dataProviderName: "grapghQl",
        resource: 'getAllState',
        optionLabel: "name",
        optionValue: "id"
    });
    stateSelectProps.showSearch = false
    const { refetch: fatchState } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCityByState',
        id: '',
        metaData: { stateId: userState },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                setCityList(data.data)
            },
            onError: (error) => {
                // console.log('search error', error);
            },
            onSettled: (data, error) => {
                // console.log('search error', data, error);
            },
        },
    });
    let paymentSwitch = (id: any) => {
        setUserState(id)
    }
    useEffect(() => {
        if (userState) {
            fatchState();
        }

    }, [userState, addressItem?.state.id]);
    useEffect(() => {
        if (addressItem?.state.id) {
            setUserState(addressItem?.state.id)
        }
    }, [addressItem?.state.id]);
    //Add user Model
    const { modalProps: userAddressModel, formProps: userAddressForm, show: userAddressShow } = useModalForm<any>({
        resource: "addDeliveryAddress",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            id: addressItem?.id && addressItem?.id,
            user: userItem.id,
        },
        redirect: false,
        onMutationSuccess() {
            // refetchCompanyDetail(true)
            refetchUsers(true)
        }
    });

    userAddressModel.title = `Assign `
    userAddressModel.centered = true
    userAddressModel.width = 800

    return (
        <Space>
            <Button className="credit-btn" type={
                addressItem?.id ? 'default' : "primary"
            } onClick={() => {
                userAddressShow();
            }}>

                {
                    addressItem?.id ? 'Edit' : 'Add New'
                } Address
            </Button>

            <Modal {...userAddressModel}>
                <Form {...userAddressForm} initialValues={{
                    addressLine1: addressItem?.addressLine1,
                    addressLine2: addressItem?.addressLine2,
                    addressLine3: addressItem?.addressLine3,
                    cityId: addressItem?.city.id,
                    stateId: addressItem?.state.id,
                    pincode: addressItem?.pincode,
                }} layout="vertical">
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Address is required',
                                    required: true,
                                },
                            ]} label="Address" name="addressLine1">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Address is required',
                                    required: true,
                                },
                            ]} label="Address Line 2" name="addressLine2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="State"
                                name="stateId"
                                rules={[
                                    {
                                        message: 'State is required',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Enter Cardholder State'
                                    onChange={(e: any) => { userAddressForm.form?.setFieldsValue({ cityId: '' }); setUserState(e); paymentSwitch(e) }}
                                    {...stateSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                label="City"
                                name="cityId"
                                rules={[
                                    {
                                        message: 'City is required',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder='Enter Cardholder City'
                                    options={cityList}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item rules={[
                                {
                                    message: 'Pincode is required',
                                    required: true,
                                },
                                {
                                    message: "Enter Correct Pincode",
                                    pattern: InputValidatorRegex.PINCODE
                                },
                            ]} label="Pincode" name="pincode">
                                <Input onChange={(event: any) => {
                                    userAddressForm.form?.setFieldsValue({ pincode: event?.target?.value.replace(/[^0-9]/g, '') ? parseInt(event?.target?.value.replace(/[^0-9]/g, ''), 10) : '' });
                                }} maxLength={6} />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        </Space>
    );
};