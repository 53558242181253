import React from "react";
import {
    Form,
    useModalForm,
    Space,
    Button,
    Modal,
    Input,
} from "@pankod/refine-antd";
import { Typography } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;

export const DeleteFeeStatementModel: React.FC<{ refetchActiveFeeStatementsDetails: any, statementId: string }> = ({ refetchActiveFeeStatementsDetails, statementId }) => {

    let { modalProps: deleteFeeStatementModel, formProps: deleteFeeStatementForm, show: deleteFeeStatementShow } = useModalForm({
        resource: "deleteFeeStatement",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            statementId: statementId,
        },
        redirect: false,
        onMutationSuccess() {
            refetchActiveFeeStatementsDetails(true)
        }
    });
    deleteFeeStatementModel.title = 'Delete Settlement'
    deleteFeeStatementModel.centered = true
    deleteFeeStatementModel.okText = 'Delete Settlement'
    deleteFeeStatementModel.width = 500


    return (
        <Space >
            <Button className="credit-btn" danger={true} onClick={() => { deleteFeeStatementShow() }} type="default">
                Delete Settlement
            </Button>

            <Modal {...deleteFeeStatementModel}>
                <Text>Are you sure you want Delete Fee Settlement?</Text>

                <Form {...deleteFeeStatementForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="Delete Fee Settlement Note" name="notes">
                        <TextArea rows={4} placeholder={"Reasons To Delete"} />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};