import { Card, Col, NumberField, Row, Typography } from "@pankod/refine-antd";
import { Link } from "@pankod/refine-react-router-v6";
import React, { useState } from "react";
import { CompanyOverview } from "./components/company-overview";
import { BankMandateOverview } from "./components/bank-mandate-overview";
import { RefundOverview } from "./components/refund-overview";
import { AppIntegrationOverview } from "./components/app-integration-overview";
// import "./styles.css";
const { Text, Title } = Typography;
export const HomePage: React.FC = () => {
    let [companyCount, setCompanyCount] = useState(0);
    let [bankMandateCount, setBankMandateCount] = useState(0);
    // let [appIntegrationCount, setAppIntegrationCount] = useState(0);

    const totalCompanyCount = (count: number) => {
        setCompanyCount(count);
    };
    const totalBankMandateCount = (count: number) => {
        setBankMandateCount(count);
    };
    // const totalAppIntegrationCount = (count: number) => {
    //     setAppIntegrationCount(count);
    // };
    const count = [
        {
            kpiTitle: "Onboarded Company",
            value: companyCount,
        },
        {
            kpiTitle: "Active Bank Mandates",
            value: bankMandateCount,
        },
    ];


    return (
        <Row gutter={[16, 16]} className="dashboard">
            <Col md={24}>
                <Row className="rowgap-vbox" gutter={[11, 11]}>
                    {count.map((c, index) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                            <Card bordered={false} className="criclebox ">
                                <div className="number">
                                    <Row align="middle" gutter={[14, 0]}>
                                        <Col xs={24}>
                                            <span>{c.kpiTitle}</span>
                                            <Title level={3}>
                                                <NumberField
                                                    value={c.value || 0}
                                                    options={{
                                                        notation: "standard",
                                                    }}
                                                />
                                            </Title>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col md={12}>
                <Card
                    className="h-100"
                    title={
                        <Text strong>Latest Onboarded Company</Text>
                    }
                    extra={<Link to="/company">See All</Link>}
                    bodyStyle={{ padding: 5 }}
                >
                    <CompanyOverview totalCompanyCount={totalCompanyCount} />
                </Card>
            </Col>
            <Col md={12}>
                <Card
                    className="h-100"
                    title={
                        <Text strong>Bank Mandate</Text>
                    }
                    extra={<Link to="/bank-mandate">See All</Link>}
                >
                    <BankMandateOverview totalBankMandateCount={totalBankMandateCount}/>
                </Card>
            </Col>
            <Col md={24}>
                <Card
                    className="h-100"
                    title={
                        <Text strong>Refunds</Text>
                    }
                    extra={<Link to="/refund">See All</Link>}
                >
                    <RefundOverview />
                </Card>
            </Col>
            {/* <Col md={9}>
                <Card
                    className="h-100"
                    title={
                        <Text strong>App integrations</Text>
                    }
                    extra={<Link to="/app-integration">See All</Link>}
                >
                    <AppIntegrationOverview totalAppIntegrationCount={totalAppIntegrationCount} />
                </Card>
            </Col> */}
        </Row>
    );
};
