import React from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    DatePicker,
    Typography,
    Input,
} from "@pankod/refine-antd";
import { ReloadOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { RangePicker } = DatePicker;

export const CheckAndUpdateOcrMetricsButtonModel: React.FC<{}> = ({ }) => {

    const { modalProps: checkAndUpdateOcrMetricsModel, formProps: checkAndUpdateOcrMetricsForm, show: checkAndUpdateOcrMetricsShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "checkAndUpdateOcrMetrics",
        action: "create",
        metaData: {
        },
        redirect: false,
        onMutationSuccess() {
        }
    });
    checkAndUpdateOcrMetricsModel.title = 'Check Or Update'
    checkAndUpdateOcrMetricsModel.confirmLoading = true
    checkAndUpdateOcrMetricsModel.centered = true
    checkAndUpdateOcrMetricsModel.okText = 'Check Or Update'
    checkAndUpdateOcrMetricsModel.width = 500

    return (
        <Space >
            <Button onClick={() => { checkAndUpdateOcrMetricsShow() }} icon={<ReloadOutlined />} >Check Or Update</Button>

            <Modal {...checkAndUpdateOcrMetricsModel}>
                <Text>Do you want to check Or update ocr metrics?</Text>
                <Form {...checkAndUpdateOcrMetricsForm} layout="vertical">
                    <Form.Item hidden={true} label="Start Time" name="startTime">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden={true} label="End Time" name="endTime">
                        <Input />
                    </Form.Item>
                    
                    <Form.Item label="Select Range" name="createdAt">
                        <RangePicker showTime onChange={(event: any) => {
                            checkAndUpdateOcrMetricsForm.form?.setFieldsValue({ startTime: event[0].toISOString() })
                            checkAndUpdateOcrMetricsForm.form?.setFieldsValue({ endTime: event[1].toISOString() })
                        }} />
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};