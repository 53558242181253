import React from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Typography,
    Input
} from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;
const { TextArea } = Input;

export const CancelAutoDebitAuthButtonModel: React.FC<{ refetchCompanyDetail: any, companyId: string }> = ({ refetchCompanyDetail, companyId }) => {

    const { modalProps: cancelAutoDebitAuthModel, formProps: cancelAutoDebitAuthForm, show: cancelAutoDebitAuthShow } = useModalForm<any>({
        resource: "repayment-auto-debit-auth/internal-portal/cancel-link-to-be-sent-auth-single",
        action: "create",
        metaData: { companyId },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    cancelAutoDebitAuthModel.title = 'Cancel Auto Debit Auth'
    cancelAutoDebitAuthModel.confirmLoading = true
    cancelAutoDebitAuthModel.centered = true
    cancelAutoDebitAuthModel.okText = 'Continue'
    cancelAutoDebitAuthModel.width = 500

    return (
        <Space >
            <Button danger={true} onClick={() => { cancelAutoDebitAuthShow() }} icon={<DeleteOutlined />} > Cancel Auto Debit Auth</Button>

            <Modal {...cancelAutoDebitAuthModel}>
                <Title level={5}>Are you sure you want to Cancel Auto Debit Auth?</Title>
                <Form {...cancelAutoDebitAuthForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="Notes" name="notes">
                        <TextArea rows={4} placeholder={"Reasons To Cancel Auto Debit Auth"} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Password is required',
                            required: true,
                        }
                    ]} label="Password" name="passcode">
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};