import React, { useEffect } from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
    Select,
} from "@pankod/refine-antd";
import { ICompany, ICompanyPBI, InputValidatorRegex } from "../../../../interfaces/company";

export const OnboardCompanyModel: React.FC<{ refetchCompanyDetail: any, accountMangerSelectProps: any, productsBundleId: string, companyDetail: ICompany, productsBundleItem: ICompanyPBI }> = ({ productsBundleItem, productsBundleId, accountMangerSelectProps, companyDetail, refetchCompanyDetail }) => {

    useEffect(() => {

    });

    let forPrepaid: boolean
    if (productsBundleItem?.productsBundle) {
        forPrepaid = productsBundleItem?.productsBundle?.productEnabled?.prepaidCards ? true : false
    } else {
        forPrepaid = productsBundleItem?.productEnabled?.prepaidCards ? true : false
    }
    //kodo Payment Model
    const { modalProps: onboardModel, formProps: onboardForm, show: onboardShow, close: onboardClose } = useModalForm({
        resource: forPrepaid ? "onboardCompanyWithPrepaidProductsBundle" : "onboardCompanyWithNonPrepaidProductsBundle",
        dataProviderName: "grapghQl",
        redirect: false,
        action: "create",
        metaData: {
            productsBundleId: productsBundleId,
            companyId: companyDetail.id
        },
        onMutationSuccess() {
            // window.location.reload()
            refetchCompanyDetail(true)
        }
    });
    onboardModel.title = `Onboard with ${productsBundleItem?.productsBundle ? productsBundleItem?.productsBundle?.displayName : productsBundleItem?.displayName} `
    onboardModel.centered = true
    onboardModel.width = 400
    onboardModel.onCancel = () => {
        onboardForm.form?.resetFields();
        onboardClose();
    }

    return (
        <Space>
            <Button className="credit-btn" type="default" onClick={() => { refetchCompanyDetail(false); onboardShow() }}>
                Onboard with {productsBundleItem?.productsBundle ? productsBundleItem?.productsBundle?.displayName : productsBundleItem?.displayName}
            </Button>

            <Modal {...onboardModel}>
                <Form {...onboardForm} layout="vertical">

                    <Form.Item label="Name On Card"
                        rules={[
                            {
                                message: 'Name On Card is required',
                                required: true,
                            }
                        ]} initialValue={companyDetail?.brandName} name="nameOnCard">
                        <Input />
                    </Form.Item>
                    {
                        !forPrepaid && <Form.Item rules={[
                            {
                                message: 'Kodo Credit Limit is required',
                                required: true,
                            },
                            {
                                message: 'Enter Correct Amount',
                                pattern: InputValidatorRegex.AMOUNT_WT_DCIMAL,
                            },
                            {
                                max: 12,
                            },
                        ]}
                            label="Kodo Credit Limit" name="companyLimit">
                            <Input onChange={(event: any) => {
                                onboardForm.form?.setFieldsValue({ companyLimit: event?.target?.value.replace(/[^0-9.]/g, '') })
                            }} />
                        </Form.Item>
                    }
                    {
                        !forPrepaid && <Form.Item rules={[
                            {
                                message: 'Kodo Pay On Credit Limit is required',
                                required: true,
                            },
                            {
                                message: 'Enter Correct Amount',
                                pattern: InputValidatorRegex.AMOUNT_WT_DCIMAL,
                            },
                            {
                                max: 12,
                            },
                        ]}
                            label="Kodo Pay On Credit Limit" name="nonCardTxnCompanyLimit">
                            <Input onChange={(event: any) => {
                                onboardForm.form?.setFieldsValue({ nonCardTxnCompanyLimit: event?.target?.value.replace(/[^0-9.]/g, '') })
                            }} />
                        </Form.Item>
                    }
                    {
                        !forPrepaid && <Form.Item rules={[
                            {
                                message: 'Review After Months is required',
                                required: true,
                            },
                        ]}
                            initialValue={12}
                            label="Review After Months" name="monthsApprovedFor">
                            <Input type={'number'} maxLength={2} />
                        </Form.Item>
                    }
                    <Form.Item
                        label="Account Manager"
                        name={"accountManagerUserId"}
                        rules={[
                            {
                                message: 'Account Manager is required',
                                required: true,
                            },
                        ]}
                    >
                        <Select {...accountMangerSelectProps} />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};