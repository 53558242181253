import React, { useState } from "react";
import { useLogin } from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Form,
    Input,
    Button,
    Icons,
    Alert,
} from "@pankod/refine-antd";
import "./style.css"
import { kodoLogo } from "../../../style";
import { from, InMemoryCache, createHttpLink, ApolloClient } from '@apollo/client';
import { loginQueryGQL, otpValidationQueryGQL } from '../../../dataProvider/mutations/auth.mutation';
import { AppConfig } from "../../../app.config";
import { InputValidatorRegex } from "interfaces/company";


const { NumberOutlined } = Icons;
export interface ILoginForm {
    emailId: string;
    passcode: string;
    gsmNumber: string;
    code: string;
}


const API_URL = process.env.REACT_APP_URL;
const httpLink = createHttpLink(
    {
        uri: `${API_URL}/graphql`,
        credentials: 'same-origin'
    }
);

export const clients = new ApolloClient(
    {
        link: from([httpLink]),
        cache: new InMemoryCache()
    }
);

export enum AppNameForAuthContext {
    KODO_APP = 'KODO_APP',
    KODO_MANAGEMENT_PORTAL = 'KODO_MANAGEMENT_PORTAL',
    KODO_KONSOLE = 'KODO_KONSOLE',
}

export const LoginOtp: React.FC = () => {
    const [current, setCurrent] = useState<"gsmNumber" | "code">("gsmNumber");
    const [gsmNumber, setGsmNumber] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    const onGsmFormSubmit = async (values: Pick<ILoginForm, "gsmNumber">) => {
        setLoading(true)
        let val: any = values
        let loginData = {
            emailId: val.emailId,
            passcode: val.passcode,
            panelView: 'adminPanel',
            appName: AppNameForAuthContext.KODO_KONSOLE,
            deviceToken: sessionStorage.getItem('deviceToken') ? sessionStorage.getItem('deviceToken') : null,
        };

        let response: any = await clients.mutate({
            mutation: loginQueryGQL,
            variables: loginData,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
        });
        if (response?.errors?.length > 0) {
            setErrorMsg(response.errors[0].message)
            setShowError(true)
            setLoading(false);
            return null
        }
        if (response?.data?.userLogin?.success) {
            sessionStorage.setItem('userEmailId', response?.data?.userLogin?.email);
        }
        if (response?.data?.userLogin?.token) {
            sessionStorage.setItem(AppConfig.apiTokenKey, response.data.userLogin?.token);
            sessionStorage.setItem('deviceToken', response.data.userLogin?.deviceToken);
            sessionStorage.setItem(AppConfig.refreshTokenKey, response.data.userLogin?.refreshToken);

            sessionStorage.setItem('userName', response.data.userLogin?.user?.username);
            sessionStorage.setItem('fullname', response.data.userLogin?.user?.fullname);
            sessionStorage.setItem('emailId', response.data.userLogin?.user?.email);
            sessionStorage.setItem('assignedRole', btoa(JSON.stringify(response.data.userLogin?.user?.role)));

            // const roles = response.data.userLogin?.user?.role.sort();
            // sessionStorage.setItem('role', roles[0]);
            sessionStorage.setItem('roleName', btoa(JSON.stringify(response.data.userLogin?.user?.roleName)));
            sessionStorage.setItem('profilePicture', response.data.userLogin?.user?.profilePicture);
            setLoading(false);
            login(val);
        } else {
            setErrorMsg('')
            setShowError(false);
            setLoading(false);
            setGsmNumber(val.emailId);
            if (response?.data?.userLogin?.needsOtp)
                setCurrent("code")
        }
    };

    const onCodeFormSubmit = async (values: any) => {
        setLoading(true);

        let loginData = {
            emailId: gsmNumber,
            otp: parseInt(values.code)
        };

        let response: any = await clients.mutate({
            mutation: otpValidationQueryGQL,
            variables: loginData,
            errorPolicy: 'all',
            fetchPolicy: 'network-only',
        });
        if (response.errors && response.errors.length > 0) {
            setShowError(true)
            setErrorMsg(response.errors[0].message)
            setLoading(false);
            return Promise.reject({
                name: "Login Failed!",
                message: response.errors[0].message,
            });
        }
        if (response?.data?.otpValidation?.token) {
            sessionStorage.setItem(AppConfig.apiTokenKey, response.data.otpValidation?.token);
            sessionStorage.setItem('deviceToken', response.data.otpValidation?.deviceToken);
            sessionStorage.setItem(AppConfig.refreshTokenKey, response.data?.otpValidation?.refreshToken);

            sessionStorage.setItem('userName', response.data.otpValidation?.user?.username);
            sessionStorage.setItem('fullname', response.data.otpValidation?.user?.fullname);
            sessionStorage.setItem('emailId', response.data.otpValidation?.user?.email);
            sessionStorage.setItem('assignedRole', btoa(JSON.stringify(response.data.otpValidation?.user?.role)));

            // const roles = response.data.otpValidation?.user?.role.sort();
            // sessionStorage.setItem('role', roles[0]);
            sessionStorage.setItem('roleName', btoa(JSON.stringify(response.data.otpValidation?.user?.roleName)));
            sessionStorage.setItem('profilePicture', response.data.otpValidation?.user?.profilePicture);
        }

        setLoading(false);
        login(values);
    };

    const renderGSMForm = () => (
        <div>
            <div className="invalid-credentials-container">
                {
                    showError &&
                    <Alert type="error"
                        showIcon={false}
                        message="Login Failed"
                        description={errorMsg}
                        closable
                    />
                }
            </div>

            <Form layout="vertical" form={form} requiredMark={false}
                initialValues={{
                    remember: false,
                }}
                onFinish={onGsmFormSubmit}>
                <Form.Item
                    name="emailId"
                    label="Email ID"
                    rules={[{
                        message: 'Enter Correct Email',
                        pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                    }, {
                        message: 'Email is Required',
                        required: true
                    }]}

                >
                    <Input
                        size="large"
                        placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    name="passcode"
                    label="Password"
                    rules={[{
                        message: 'Enter Correct Password',
                        pattern: InputValidatorRegex.PASSCODE,
                    }, {
                        message: 'Password is Required',
                        required: true
                    }]}
                >
                    <Input
                        type="password"
                        placeholder="●●●●●●●●"
                        size="large"
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={loading}
                        block
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    const renderCodeForm = () => (
        <div>
            <div className="invalid-credentials-container">
                {
                    showError &&
                    <Alert type="error"
                        showIcon={false}
                        message="Login Failed"
                        description={errorMsg}
                        closable
                    />
                }
            </div>
            <Form
                layout="vertical"
                requiredMark={false}
                onFinish={onCodeFormSubmit}
            >
                <Form.Item
                    name="code"
                    label="OTP"
                    rules={[
                        {
                            required: true,
                            message: "OTP is required",
                        },
                    ]}
                >
                    <Input
                        type="password"
                        maxLength={6}
                        prefix={<NumberOutlined style={{ color: "#00000040" }} />}
                    />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        block
                        loading={isLoading}
                    >
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    return (
        <AntdLayout
            style={{
                background: `radial-gradient(50% 50% at 50% 50%, #63386A 0%, #310438 100%)`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    {[
                        <h2 style={kodoLogo}>KODO KONSOLE</h2>,
                        (current === 'gsmNumber') ? renderGSMForm() : renderCodeForm(),
                    ].map(it => <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "28px",
                    }}>{it}</div>)}
                </Col>
            </Row>
        </AntdLayout>
    );
};
