import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import {
    List,
    Table,
    Space,
    ShowButton,
    NumberField,
    Tag,
    Button,
} from "@pankod/refine-antd";

import { ActiveFeeStatements, SettlementBankAccountsList, SettlementEventType } from "../../interfaces/mmt";
import { formatDate } from "../../utility/dateFormat";
import { AddBankAccountModel } from "./components/add-bank-account.model";
import { GenerateSettlementModel } from "./components/generate-settlement.model";
import { DescriptionItem } from "components/text-layout/descriptionItem.text";
import { DeleteFeeStatementModel } from "./components/delete-settlement.model";
import { InitiateFundSettlementModel } from "./components/initiate-fund-settlement.model";
import { getActiveBankStatement } from "utility/companyUtils";
import { FetchStatusForPendingSettlementModel } from "./components/fetch-status-settlement.model";

export const VASettlementList: React.FC<IResourceComponentsProps> = () => {
    let [refreshActiveFeeStatement, setRefreshActiveFeeStatement] = useState(false);
    let [settlementAccountId, setSettlementAccountId] = useState('');
    let [refreshBankAccount, setRefreshBankAccount] = useState(false);


    let { data: bankAccountList, isLoading: bankAccountLoading, refetch: refetchBankAccount } = useOne<SettlementBankAccountsList[]>({
        dataProviderName: "grapghQl",
        resource: 'viewListOfSettlementBankAccounts',
        id: '',
        metaData: {
            isMutation: true,
        },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                data?.data && setSettlementAccountId(getActiveBankStatement(data?.data))
                setRefreshBankAccount(false);
            },
        },
    });

    //get active fee statement
    let { data: activeFeeStatementsList, isLoading: activeFeeStatementsLoading, refetch: refetchActiveFeeStatements } = useOne<ActiveFeeStatements[]>({
        dataProviderName: "grapghQl",
        resource: 'getActiveFeeStatements',
        id: '',
        metaData: {
            isMutation: true,
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshActiveFeeStatement(false);
            },
        },
    });

    //get all fee statement
    let { data: pastFeeStatementsList, isLoading: pastFeeStatementsLoading, refetch: refetchPastFeeStatements } = useOne<ActiveFeeStatements[]>({
        dataProviderName: "grapghQl",
        resource: 'getFeeStatementsWithinDateRange',
        id: '',
        metaData: {
            isMutation: true,
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshActiveFeeStatement(false);
            },
        },
    });

    useEffect(() => {
        if (refreshActiveFeeStatement) {
            refetchActiveFeeStatements();
            refetchPastFeeStatements();
        }
    }, [refreshActiveFeeStatement]);
    const refetchActiveFeeStatementsDetails = (message: boolean) => {
        setRefreshActiveFeeStatement(message);
    };


    return (
        <List title={"Settlements"} headerButtons={({ defaultButtons }) => (
            <>
                <GenerateSettlementModel refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails} />
                <FetchStatusForPendingSettlementModel eventType="" refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails} />
                <AddBankAccountModel />
            </>
        )}>
            <Table loading={activeFeeStatementsLoading} dataSource={activeFeeStatementsList?.data} rowKey="id">
                <Table.Column title="Event Type" dataIndex="eventType" />

                <Table.Column
                    dataIndex="createdAt"
                    title="Dates"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space direction="vertical">
                            {
                                (record?.startDate) && <DescriptionItem title="Start Date" content={formatDate(record?.startDate)} />
                            }
                            {
                                (record?.endDate) && <DescriptionItem title="End Date" content={formatDate(record?.endDate)} />
                            }
                        </Space>
                    )}
                />
                <Table.Column
                    dataIndex="statementAmount"
                    title="Statement Amount"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space direction="horizontal">
                            Rs.
                            <NumberField
                                value={record?.statementAmount || 0}
                                options={{
                                    notation: "standard",
                                }}
                            />
                        </Space>
                    )}
                />

                <Table.Column
                    dataIndex="referenceIdSharedWithBank"
                    title="URN"
                // title="Reference Id Shared With Bank"
                />
                <Table.Column
                    dataIndex="month"
                    title="month"
                />
                <Table.Column dataIndex="isActive" title="Status"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space align='baseline' direction="vertical">
                            {
                                record?.isActive && <Tag color="green">Active</Tag>
                            }
                            {
                                record?.isDeleted && <Tag color="red">Deleted</Tag>
                            }
                        </Space>
                    )} />
                <Table.Column<ActiveFeeStatements>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space direction="vertical">
                            {
                                <Button size="small" href={`/settlement/show/${record.id}?eventType=${record.eventType}`} >
                                    View Settlement Records
                                </Button>
                            }
                            {
                                (record?.isActive && (
                                    record?.eventType !== SettlementEventType.KODO_VA_FEE ||
                                    (record?.eventType === SettlementEventType.KODO_VA_FEE && settlementAccountId && record?.statementAmount > 0)
                                )) && <InitiateFundSettlementModel
                                    refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails}
                                    statementId={record.id}
                                    eventType={record.eventType}
                                    settlementAccountId={settlementAccountId || ''} />
                            }
                            {
                                (record?.isActive) && <DeleteFeeStatementModel
                                    refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails}
                                    statementId={record.id} />
                            }
                            {
                                (record?.eventType === SettlementEventType.KODO_VA_FEE) && <FetchStatusForPendingSettlementModel eventType={record.eventType} statementId={record.id} refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails} />
                            }

                        </Space>
                    )}
                />
            </Table>
            <Table loading={pastFeeStatementsLoading} dataSource={pastFeeStatementsList?.data} title={() => 'Past Fee Statements'} rowKey="id">
                <Table.Column title="Event Type" dataIndex="eventType" />

                <Table.Column
                    dataIndex="createdAt"
                    title="Dates"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space direction="vertical">
                            {
                                (record?.startDate) && <DescriptionItem title="Start Date" content={formatDate(record?.startDate)} />
                            }
                            {
                                (record?.endDate) && <DescriptionItem title="End Date" content={formatDate(record?.endDate)} />
                            }
                        </Space>
                    )}
                />
                <Table.Column
                    dataIndex="statementAmount"
                    title="Statement Amount"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space direction="horizontal">
                            Rs.
                            <NumberField
                                value={record?.statementAmount || 0}
                                options={{
                                    notation: "standard",
                                }}
                            />
                        </Space>
                    )}
                />

                <Table.Column
                    dataIndex="referenceIdSharedWithBank"
                    title="URN"
                />
                <Table.Column
                    dataIndex="month"
                    title="month"
                />
                <Table.Column dataIndex="isActive" title="Status"
                    render={(_, record: ActiveFeeStatements) => (
                        <Space align='baseline' direction="vertical">
                            {
                                record?.isActive && <Tag color="green">Active</Tag>
                            }
                            {
                                record?.isDeleted && <Tag color="red">Deleted</Tag>
                            }
                        </Space>
                    )} />
                <Table.Column<ActiveFeeStatements>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: ActiveFeeStatements) => {
                        return !record?.isDeleted && <Space direction="vertical">
                            {
                                !record?.isActive && <Button href={`/settlement/show/${record.id}?eventType=${record.eventType}`} >
                                    View Settlement Records
                                </Button>
                            }
                            {(record?.eventType === SettlementEventType.KODO_VA_FEE) && (record?.statementAmount > 0) && <FetchStatusForPendingSettlementModel statementId={record.id} eventType={record.eventType} refetchActiveFeeStatementsDetails={refetchActiveFeeStatementsDetails} />}
                        </Space>
                    }}
                />
            </Table>
        </List>
    );
};