
import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, CrudFilters } from "@pankod/refine-core";
import {
    List, Row, Col, DateField, useTable,
    Card,
    Icons,
    Form,
    Input,
    Button,
    FormProps,
    Table
} from "@pankod/refine-antd";


import { OcrMetrics } from "interfaces/ocr-dashboard";
import { DescriptionItem } from "components/text-layout/descriptionItem.text";
import { FileViewer } from "components/FileViewer";
import { getQueryParam, setQueryParam } from "utility/queryParams";
import { CheckAndUpdateOcrMetricsButtonModel } from "./components/check-update-ocr-metrics.model";

export const OcrDashboardList: React.FC<IResourceComponentsProps> = () => {

    let [oprId, setOprId] = useState<string>();
    let [attachmentId, setAttachmentId] = useState<string>();

    let { tableProps: ocrMetrics, searchFormProps } = useTable<OcrMetrics>({
        dataProviderName: "grapghQl",
        resource: 'getOcrMetrics',
        metaData: {
            ...(attachmentId && { attachmentId }),
            ...(oprId && { oprId }),
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            let { attachmentId, oprId } = params;

            if (attachmentId) {
                setAttachmentId(attachmentId)
                setQueryParam('attachmentId', attachmentId)
            }
            if (oprId) {
                setOprId(oprId)
                setQueryParam('oprId', oprId)
            }

            return filters;
        },
    });

    useEffect(() => {
        searchFormProps.form?.setFieldsValue({
            attachmentId,
            oprId
        })
    }, [attachmentId, oprId]);
    useEffect(() => {
        if (getQueryParam("attachmentId")) {
            setAttachmentId(getQueryParam("attachmentId"))
        }
        if (getQueryParam('oprId')) {
            setOprId(getQueryParam("oprId"))
        }
    }, [getQueryParam("oprId"), getQueryParam("attachmentId")]);

    const ocrDataKeys = [
        "VENDOR_NAME",
        "VENDOR_ADDRESS",
        "VENDOR_PHONE",
        "DUE_DATE",
        "TOTAL",
        "INVOICE_RECEIPT_DATE",
        "INVOICE_RECEIPT_ID",
        "VENDOR_GST_NUMBER",
        "RECEIVER_GST_NUMBER",
        "RECEIVER_ADDRESS",
        "TDS",
        "TAX",
        "ACCOUNT_NUMBER",
        "BANK_NAME",
        "BRANCH_NAME",
        "IFSC_CODE",
        "CATEGORY",
        "EMAIL",
        "UPI_ID",
    ]

    return (
        <List title={'OCR Metrics'} headerButtons={({ defaultButtons }) => (
            <CheckAndUpdateOcrMetricsButtonModel />
        )} >
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <Card size="small" title="Filters">
                        <Filter formProps={searchFormProps} oprId={oprId} attachmentId={attachmentId} />
                    </Card>
                </Col>
                <Col lg={24} xs={24}>
                    {
                        ocrMetrics.dataSource?.map((ocrElement) => <Row gutter={[16, 16]}>
                            <Col lg={8} xs={24}>
                                <FileViewer
                                    fileName={ocrElement?.fileName}
                                    fileUrl={ocrElement?.invoiceUrl}
                                    fileType={ocrElement?.mimeType.startsWith('image/') ? "image" : "pdf"} />

                            </Col>
                            <Col lg={16} xs={24}>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="Invoice Created At" content={ocrElement?.invoiceCreatedAt
                                            && <DateField value={ocrElement?.invoiceCreatedAt} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>



                                    <Col span={12}>
                                        <DescriptionItem title="Textract Response Start Time" content={ocrElement?.textractResponse?.startTime
                                            && <DateField value={ocrElement?.textractResponse?.startTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Textract Response End Time" content={ocrElement?.textractResponse?.endTime
                                            && <DateField value={ocrElement?.textractResponse?.endTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="OCR Transformation Start Time" content={ocrElement?.ocrTransformation?.startTime
                                            && <DateField value={ocrElement?.ocrTransformation?.startTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="OCR Transformation End Time" content={ocrElement?.ocrTransformation?.endTime
                                            && <DateField value={ocrElement?.ocrTransformation?.endTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Overall Start Time" content={ocrElement?.overall?.startTime
                                            && <DateField value={ocrElement?.overall?.startTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                    <Col span={12}>
                                        <DescriptionItem title="Overall End Time" content={ocrElement?.overall?.endTime
                                            && <DateField value={ocrElement?.overall?.endTime} format="DD-MM-YYYY HH:mm:ss" />} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <DescriptionItem title="OCR Job ID" content={ocrElement?.ocrJob.jobId} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Card size="small" title="OCR Extracted Data">
                                            <Row>
                                                {
                                                    ocrElement?.ocrJob.ocrData
                                                    && ocrDataKeys.map((ocrData) => <Col span={6}>
                                                        <DescriptionItem title={ocrData.replace(/_/g, ' ')} content={ocrElement?.ocrJob.ocrData[ocrData] || '-'} />
                                                    </Col>)
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                {
                                    ocrElement?.ocrJob?.ocrData && <Row>
                                        <Col span={24}>
                                            <Table title={() => 'Line Items'} dataSource={ocrElement?.ocrJob.ocrData['LINE_ITEMS']} rowKey="id">
                                                <Table.Column dataIndex="ITEM" title="Item" />
                                                <Table.Column dataIndex="PRODUCT_CODE" title="Product Code" />
                                                <Table.Column dataIndex="UNIT_PRICE" title="Unit Price" />
                                                <Table.Column dataIndex="QUANTITY" title="Quantity" />
                                                <Table.Column dataIndex="PRICE" title="Price" />
                                            </Table>
                                        </Col>
                                    </Row>
                                }

                            </Col>
                        </Row>)
                    }
                </Col>
            </Row>
        </List>

    );
};

const Filter: React.FC<{ formProps: FormProps, attachmentId?: string, oprId?: string }> = ({ formProps, oprId, attachmentId }) => {

    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={6}>
                    <Form.Item label="Attachment Id" name="attachmentId">
                        <Input
                            placeholder="attachment Id"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="OPR Id" name="oprId">
                        <Input
                            placeholder="user Id"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item>
                        <br />
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};