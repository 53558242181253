import { Typography, List } from 'antd';
import { IUser } from '../../interfaces';
import { UserAccountStatus, UserCompanyDetails } from '../../interfaces/company';
import { Alert, Col, Row, Space, Tag } from '@pankod/refine-antd';
import { DescriptionItem } from '../text-layout/descriptionItem.text';
const { Title, Text } = Typography;

interface UserDetailsProps {
    user: IUser,
    title: string,
    monthlyLimit: string,
    dailyLimit: string,
    userDetails: UserCompanyDetails | undefined
}

export const UserDetails: React.FC<UserDetailsProps> = ({ user, title, monthlyLimit, dailyLimit, userDetails }) => (
    <div className="item-profile-wrapper mb-3">
        <Title level={5} className="mb-2">{title}:</Title>
        <Row>
            <Col span={6}>
                <DescriptionItem title="Full Name" content={user?.displayName} />
            </Col>
            <Col span={6}>
                <DescriptionItem title="Email Id" status={user.emailVerified ? '#52c41a' : '#ff4d4f'} content={user.emailId} />
            </Col>
            <Col span={6}>
                <DescriptionItem title="Mobile No" status={user.mobileVerified ? '#52c41a' : '#ff4d4f'} content={user.mobileNo} />
            </Col>
            {/* <Col span={6}>
                <DescriptionItem title="KYC Status" status={user.kycStatus === "COMPLETED" ? '#52c41a' : '#ff4d4f'} content={user.kycStatus} />
            </Col> */}
        </Row>
        <Row>
            <Col span={6}>
                <DescriptionItem title="Monthly Limit" content={monthlyLimit || '-'} />
            </Col>
            <Col span={6}>
                <DescriptionItem title="Daily Limit" content={dailyLimit || '-'} />
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <DescriptionItem title="Role" content={<>
                    <Space size={[0, 8]} wrap>
                        {
                            user.rolesToDisplay.length > 0 ? user.rolesToDisplay.map((ele: string) => {
                                return <Tag>{ele}</Tag>
                            }) : <Text>No Role</Text>
                        }
                    </Space>
                </>} />
               
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <DescriptionItem title="User Accounts" content={
                    <Space size={[0, 8]} wrap>
                        {
                            user.userAccounts.length > 0 ? user.userAccounts.map((ele) => {
                                let color;
                                switch (ele.status) {
                                    case UserAccountStatus.ACTIVATED:
                                        color = "green";
                                        break;
                                    case UserAccountStatus.DEACTIVATED:
                                        color = "danger";
                                        break;
                                    case UserAccountStatus.NOT_ACTIVATED_YET:
                                        color = "yellow";
                                        break;
                                    default:
                                        color = "";
                                        break;
                                }
                                return <Tag color={color}>{`${ele.status} : ${ele.id}`}</Tag>;
                            }) : <Text>No User Account</Text>
                        }
                    </Space>
                } />
            </Col>
        </Row>

        {
            userDetails && userDetails?.pendingPaymentCount > 0 && <Alert
                message="Pending Payment"
                description={`User Has ${userDetails?.pendingPaymentCount} Pending requests`}
                type="error"
            />
        }

        {
            userDetails && userDetails?.userCompanyList.length > 1 && <>
                <Alert
                    message="Company Association"
                    description={`User is part of ${userDetails?.userCompanyList.length} Companies`}
                    type="error"
                    className='mb-3 mt-3'
                />
                <List
                    header={'Company List'}
                    bordered
                    dataSource={userDetails?.userCompanyList}
                    renderItem={(item) => (
                        <List.Item>
                            <Text>{item?.company?.brandName}</Text>
                        </List.Item>
                    )}
                />
            </>
        }

    </div >
);