import { gql } from '@apollo/client';

export const loginQueryGQL = gql`
  mutation userLogin(
    $emailId: String
    $passcode: String
    $deviceToken: String
    $panelView: String
    $appName: String
  ) {
    userLogin(
      login: {
        emailId: $emailId
        passcode: $passcode
        deviceToken: $deviceToken
        panelView: $panelView
        appName: $appName
      }
    )
  }
`;

export const refreshTokenGQL = gql`
  mutation refreshToken {
    refreshToken {
      refreshToken
      token
    }
  }
`;


export const otpValidationQueryGQL = gql`
  mutation otpValidation($emailId: String, $otp: Int) {
    otpValidation(validOtp: {emailId: $emailId, otp: $otp})
  }
`;

export const logOutUserOnDeviceGQL = gql`
  mutation logOutUserOnDevice($userDeviceToken: String!) {
    logOutUserOnDevice(userDeviceToken: $userDeviceToken)
  }
`;
