import React, { useEffect, useState } from "react";

import { useModalForm, Modal, Space, Form, Button, Input } from "@pankod/refine-antd";

// import { urlPath } from "../../utility/url";
import { ICompany, InputValidatorRegex } from "../../../../interfaces/company";
import { useOne } from "@pankod/refine-core";

export const BankModel: React.FC<{ refetchCompanyDetail: any, companyDetail: ICompany | undefined }> = ({ companyDetail, refetchCompanyDetail }) => {
    // let url = urlPath()
    const [ifscValue, setIFSCValue] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [ifscError, setIFSCError] = useState<any>("");


    const { refetch: refetchPostsIfsc } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getBankBranchDescriptionByIfsc',
        id: '',
        metaData: {
            ifsc: ifscValue,
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                (data.error) ? setIFSCError("error") : setIFSCError(""); setBankName(data?.data)
            },
        },
    });

    useEffect(() => {
        if (ifscValue !== '' && ifscValue.length > 10) {
            refetchPostsIfsc();
        }
    }, [ifscValue]);

    //Add bank Model
    let { modalProps: bankModel, formProps: bankForm, show: bankShow, close: bankClose } = useModalForm<ICompany>({
        resource: "addBank",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: { company: companyDetail?.id },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    bankModel.title = 'Add Bank Details'
    bankModel.centered = true
    bankModel.width = 400
    bankModel.onCancel = () => {
        bankForm.form?.resetFields();
        bankClose();
    }
    return (
        <Space >
            <Button className="credit-btn" onClick={() => { bankShow() }} type="primary">
                Add Bank
            </Button>

            <Modal {...bankModel}>
                <Form {...bankForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Beneficiary Name is required',
                            required: true,
                        },
                        {
                            message: 'Enter correct Beneficiary Name',
                            pattern: InputValidatorRegex.BENEFICIARY_NAME_REGEX,
                        },
                    ]} label="Bank Name" name="bankName">
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: "Invalid IFSC code",
                            pattern: InputValidatorRegex.ALPHA_NUMERIC_WO_SPACE,
                        },
                        {
                            message: 'IFSC Code is required',
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (ifscError) {
                                    return Promise.reject(
                                        new Error("Invalid IFSC code"),
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]} label="IFSC Code"
                        name="ifsc">
                        <Input status={ifscError} maxLength={11} onChange={(event: any) => {
                            setIFSCValue(event?.target?.value.replace(/[^a-zA-Z0-9]/g, ''))
                            bankForm.form?.setFieldsValue({ ifsc: event?.target?.value.replace(/[^a-zA-Z0-9]/g, '') })
                        }} />
                        <small>{bankName}</small>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Mobile Number is required',
                            required: true,
                            max: 10,
                        },
                        {
                            message: 'Enter correct Mobile Number',
                            pattern: InputValidatorRegex.INDIAN_MOBILE_NO,
                        },
                    ]} label="mobile" name="mobileNo">
                        <Input maxLength={10} onChange={(event: any) => {
                            bankForm.form?.setFieldsValue({ mobileNo: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Beneficiary Name is required',
                            required: true,
                            max: 10,
                        },
                        {
                            message: 'Enter correct Beneficiary Name',
                            pattern: InputValidatorRegex.BENEFICIARY_NAME_REGEX,
                        },
                    ]} label="Beneficiary Name"
                        name="accountName">
                        <Input onChange={(event: any) => {
                            bankForm.form?.setFieldsValue({ accountName: event?.target?.value.replace(/[^a-z A-Z]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Account Number is required',
                            required: true,
                        },
                        {
                            message: 'Enter correct Account Number',
                            pattern: InputValidatorRegex.BANK_ACCOUNT_NUMBER,
                        },
                        // {
                        //     message: "Invalid Account Number",
                        //     pattern: /^[0-9]{1,20}$/,
                        // }
                    ]} label="Account Number"
                        name="accountNumber">
                        <Input onChange={(event: any) => {
                            bankForm.form?.setFieldsValue({ accountNumber: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} />
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};