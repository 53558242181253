import React, { useEffect } from "react";
import {
    Button,
    Col,
    Row, Space, Table, Tag, useTable,
} from '@pankod/refine-antd';
import { ICompany, ICompanyPBI } from "../../../interfaces/company";
import { verificationStatus } from "../../company/list";


interface CompanyOverviewProps {
    totalCompanyCount: any
}

export const CompanyOverview: React.FC<CompanyOverviewProps> = ({ totalCompanyCount }: CompanyOverviewProps) => {
    const { tableProps } = useTable<ICompany>({
        dataProviderName: "grapghQl",
        resource: 'getCompaniesForManagementPortalPaginated',
        syncWithLocation: true,
        metaData: {
            filter: {},
            limit: 5
        },
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ]
    });
    useEffect(() => {
        const totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 0
        totalCompanyCount(totalSize)
    })
    return <Table size="small" {...tableProps}>
        <Table.Column
            dataIndex="registeredName"
            title="Brand Name"
            key="registeredName"
            render={(_, record: ICompany) => (
                <Space align='baseline' direction="vertical">
                    <h4>
                        {record.brandName}
                    </h4>
                    <small>
                        {record.cin}
                    </small>
                </Space>

            )}
        />
        <Table.Column
            dataIndex="companyCustomerId"
            title="Product Bundles"
            render={(_, record: ICompany) => (
                <Space>
                    <Row wrap={true} gutter={[0, 0]} >
                        {
                            record?.nonDeletedPBIsSortedByDate.map((item: ICompanyPBI, index: number) => {
                                return <Col lg={24} xs={24}>
                                    <p><b> {item?.displayName}</b> :-  {item.creditLimit}</p>
                                </Col>
                            })
                        }
                    </Row>
                </Space>
            )}
        />

        <Table.Column
            dataIndex="companyVerification"
            title="Company Verification"
            key="companyVerification"
            render={(value) => {
                let color;
                switch (value) {
                    case verificationStatus.ONBOARDED:
                        color = "green";
                        break;
                    case verificationStatus.REJECTED:
                        color = "red";
                        break;
                    case verificationStatus.PENDING:
                        color = "blue";
                        break;
                    default:
                        color = "";
                        break;
                }
                return <Tag color={color}>{value}</Tag>;
            }}
        />
        <Table.Column<ICompany>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
                <Space>
                    <Button
                        href={`/company/show/${record?.id}`}
                        size="small"
                    >
                        Show
                    </Button>
                </Space>
            )}
        />
    </Table>
}