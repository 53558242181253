
import { gql } from '@apollo/client';

export const getOcrMetricsGQL = gql`
  query getOcrMetrics(
    $oprId: String
    $attachmentId: String
  ) {
    getOcrMetrics(
      input: {
       oprId: $oprId
       attachmentId: $attachmentId
      }
    ) {
        mimeType
        fileName
        invoiceUrl
        invoiceCreatedAt
        textractResponse {
            startTime
            endTime
        }
        ocrTransformation {
            startTime
            endTime
        }
        overall {
            startTime
            endTime
        }
        ocrJob {
          id
          ocrData {
            VENDOR_NAME
            VENDOR_ADDRESS
            VENDOR_PHONE
            DUE_DATE
            TOTAL
            INVOICE_RECEIPT_DATE
            INVOICE_RECEIPT_ID
            VENDOR_GST_NUMBER
            RECEIVER_GST_NUMBER
            RECEIVER_ADDRESS
            TDS
            TAX
            ACCOUNT_NUMBER
            BANK_NAME
            BRANCH_NAME
            IFSC_CODE
            CATEGORY
            EMAIL
            UPI_ID
            LINE_ITEMS {
              ITEM
              PRODUCT_CODE
              QUANTITY
              UNIT_PRICE
              PRICE
            }
          }
          jobId
        }
    }
     
  }
`;
export const checkAndUpdateOcrMetricsGQL = gql`
  mutation checkAndUpdateOcrMetrics(
    $startTime: Date
    $endTime: Date
  ) {
    checkAndUpdateOcrMetrics(
      input: {
        startTime: $startTime
        endTime: $endTime
      }
    ) {
        totalOcrJobs
        ocrMetricsGenerated
    }
     
  }
`;
export const OcrDashboardGQL = {
  getOcrMetrics: getOcrMetricsGQL,
  checkAndUpdateOcrMetrics: checkAndUpdateOcrMetricsGQL,
}