import { IResourceComponentsProps, CrudFilters } from "@pankod/refine-core";
import {
    List,
    Table,
    useTable,
    Space,
    DateField,
    ShowButton,
    TextField,
    Card,
    Row,
    Col,
    Input,
    Icons,
    Form,
    Button,
    DatePicker,
    FormProps,
    Typography,
} from "@pankod/refine-antd";

import '../../style.css';
import { ITransaction } from "../../interfaces";
const { RangePicker } = DatePicker;

const { Text } = Typography;

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<ITransaction>({
        syncWithLocation: true,
        dataProviderName: "grapghQl",
        resource: "getCompanyExpensesForAdmin",
        metaData: {
            type: "query",
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            const { q, merchantName, createdAt } = params;

            filters.push(
                {
                    field: "company.registeredName",
                    operator: "contains",
                    value: q,
                },
                {
                    field: "merchantName",
                    operator: "contains",
                    value: merchantName,
                },
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt ? createdAt[0].toISOString() : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt ? createdAt[1].toISOString() : undefined,
                },
            );
            return filters;
        },
    })

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Transactions (Expenses)" >
                    <Row className="filter-row">
                        <Col span={20} className="mr-40">

                            <Card size="small" title="Filters">
                                <Filter formProps={searchFormProps} />
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column
                            title="Company"
                            render={(_, record: any) => (
                                <Space align='baseline' direction="vertical">
                                    <p>
                                        {record?.company?.registeredName}
                                    </p>

                                </Space>

                            )}
                        />
                        <Table.Column
                            title="User"
                            render={(_, record: ITransaction) => {
                                const { fullName, lastName, firstName, middleName, displayName } = record?.user ?? {};
                                return <Text>
                                    {fullName || displayName || [firstName, middleName, lastName].filter(Boolean).join(' ')}
                                </Text>
                            }}
                        />

                        <Table.Column
                            dataIndex="createdAt"
                            title="Date"
                            render={(value) => <DateField value={value} format="LLL" />}
                        />
                        <Table.Column
                            dataIndex="narration"
                            title="Description"
                            render={(value) => <TextField value={value} />}
                        />
                        <Table.Column
                            dataIndex="transactionAmount"
                            title="Amount"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs {value}
                                    </small>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex="transactionReceipt"
                            title="Receipt"
                            render={(value) => <TextField value={value?.length} />}
                        />
                        <Table.Column
                            dataIndex="transactionCategory"
                            title="Category"
                            render={(value) => value?.replaceAll("_", " ")}
                        />
                        <Table.Column<ITransaction>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => <ShowButton
                                size="small"
                                recordItemId={record?.id}
                            />}
                        />

                    </Table>
                </List>
            </Col>

        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[11, 11]}>
                <Col lg={8}>
                    <Form.Item label="Company Name" name="q">
                        <Input
                            placeholder="Search by Company Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                {/* <Col lg={10}>
                    <Form.Item name="q" className="mt-10">
                        <Checkbox
                        />
                        <label className="ml-10">Show only expenses w/o receipts</label>

                    </Form.Item>
                </Col> */}
                <Col lg={8}>
                    <Form.Item label="Merchant Name" name="merchantName">
                        <Input
                            placeholder="Search by M Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24} className="flex-between">
                    <Form.Item label="Created At" name="createdAt">
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <br />
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    );
};