import React from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
} from "@pankod/refine-antd";


export const SyncDelegatedAccessBankAccountFundTransferForOprModel: React.FC<{}> = ({ }) => {


    const { modalProps: syncDelegatedAccessModel, formProps: syncDelegatedAccessForm, show: syncDelegatedAccessShow } = useModalForm({
        dataProviderName: "grapghQl",
        resource: "syncDelegatedAccessBankAccountFundTransferForOpr",
        action: "create",
        metaData: {},
        redirect: false,
        onMutationSuccess() {

        }
    });
    syncDelegatedAccessModel.title = 'Sync Delegated Access Bank Account Fund Transfer For Opr'
    syncDelegatedAccessModel.centered = true
    syncDelegatedAccessModel.width = 400

    return (
        <Space>
            {
                <Button onClick={() => { syncDelegatedAccessShow() }} type="default">
                    Sync Delegated Access Bank Account Fund Transfer For Opr
                </Button>
            }

            <Modal {...syncDelegatedAccessModel}>

                <Form {...syncDelegatedAccessForm} layout="vertical">
                    <Form.Item
                        label="urn"
                        name={"urn"}
                        rules={[
                            {
                                message: "Please input urn!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={"OPR Id"}
                        name={"oprId"}
                        rules={[
                            {
                                message: "Please input oprId!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};