import React, { useState, useEffect } from "react";
import { List, Typography } from 'antd';

import {
    Button,
    Row,
    Col,
    Radio,
    Checkbox,
    Select,
} from "@pankod/refine-antd";

import { useModalForm, Modal, Form, Input } from "@pankod/refine-antd";

import { CompanyRoles, CardLimit, KycType, InputValidatorRegex, UserGender } from "../../../interfaces/company";
import { IUser } from "../../../interfaces";
import { urlPath } from "../../../utility/url";
import { deleteQueryParam, getQueryParam, setQueryParam } from "utility/queryParams";
const { Title, Text, Link } = Typography;

export const AddEditUserComponent: React.FC<{ userItem?: IUser, refetchUsers: any, refetchCompanyDetail: any, pbiId: string, isAddUser: boolean }> = ({ userItem, isAddUser, pbiId, refetchCompanyDetail, refetchUsers }) => {
    let url = urlPath()

    let [cardStatus, setCardStatus] = useState('');
    let [kyctype, setKycType] = useState(KycType.electronic);


    //Add user Model
    const { modalProps: userModel, formProps: userForm, show: userShow, close: userClose } = useModalForm<any>({
        resource: isAddUser ? "addNewCardholderForEKyc" : "updateCardholderDetails",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            company: url.lastElement,
            pbiId: pbiId,
            id: userItem?.id,
        },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchCompanyDetail(true)
            refetchUsers(true)
            if (!isAddUser) {
                userForm.form?.resetFields()
            }
        }
    });

    useEffect(() => {
        if (getQueryParam("modal") === "add-edit-user" && getQueryParam('userId') === userItem?.id) {
            userShow()
        }
        if (!isAddUser) {
            userForm.form?.setFieldsValue({
                firstName: userItem?.firstName || '',
                lastName: userItem?.lastName || '',
                emailId: userItem?.emailId || '',
                mobileNo: userItem?.mobileNo || '',
                gender: userItem?.gender || '',
                panNo: userItem?.panNo || '',
            })
        }
    });

    userModel.title = `${isAddUser ? "Add new " : "Edit"} User`
    userModel.centered = true
    userModel.width = 600
    userModel.onCancel = () => {
        userForm.form?.resetFields();
        deleteQueryParam('userId')
        deleteQueryParam('modal');
        userClose()
    }


    return (
        <>
            {
                <Button onClick={() => { userShow(); setQueryParam('modal', 'add-edit-user'); setQueryParam('userId', userItem?.id || ''); }} type="default">
                    {isAddUser ? "Add" : "Edit"} User
                </Button>
            }
            <Modal {...userModel}>
                <Form {...userForm} name="userDetails" layout="vertical">
                    {
                        isAddUser && <>
                            <Form.Item label="User Role" name="cardStatus">
                                <Checkbox.Group>
                                    <Checkbox value={CompanyRoles.cardholder} onChange={(e) => { (cardStatus === "") ? setCardStatus(e.target.value) : setCardStatus(''); }}>Will be a Cardholder</Checkbox>
                                    <Checkbox value={CompanyRoles.companyAdmin} onChange={(e) => { }}>Company Admin</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            {(cardStatus === CompanyRoles.cardholder) ? <Row gutter={16}>
                                <Col lg={12}>
                                    <Form.Item label={CardLimit.monthlyLimit} name="monthlyLimit">
                                        <Input type={"number"} />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item label={CardLimit.dailyLimit} name="dailyLimit">
                                        <Input type={"number"} />
                                    </Form.Item>
                                </Col>
                            </Row> : ''}
                            <Form.Item label="KYC Type" name="status" rules={[
                                {
                                    required: true,
                                },
                            ]} initialValue={KycType.electronic}>
                                <Radio.Group>
                                    {/* <Radio onChange={(e) => { setKycType(e.target.value); }} value={KycType.paper}>Paper KYC</Radio> */}
                                    <Radio onChange={(e) => { setKycType(e.target.value); }} value={KycType.electronic}>vKYC</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </>
                    }
                    {
                        (kyctype === KycType.electronic) ? <Row gutter={16}>
                            <Col lg={10}>
                                <Form.Item className="w-100" rules={[
                                    {
                                        message: 'First Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct First Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} label="First Name" name="firstName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={14}>
                                <Form.Item label="Last Name" rules={[
                                    {
                                        message: 'Last Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct Last Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} className="w-100" name="lastName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Mobile Number is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Max 10 digit Mobile number allowed',
                                        max: 10,
                                    },
                                    {
                                        message: 'Enter correct Mobile Number',
                                        pattern: InputValidatorRegex.INDIAN_MOBILE_NO,
                                    },
                                ]} label="Mobile Number" name="mobileNo">
                                    <Input onChange={(event: any) => {
                                        userForm.form?.setFieldsValue({ mobileNo: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Email ID is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct email address',
                                        pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                                    },
                                ]} label="Email ID" name="emailId">
                                    <Input />
                                </Form.Item>
                            </Col>
                            {
                                !isAddUser && <Col lg={12}>
                                    <Form.Item label="Gender" name="gender" >
                                        <Select
                                            options={UserGender}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                !isAddUser && <Col lg={12}>
                                    <Form.Item rules={[
                                        {
                                            message: 'Enter correct PAN number',
                                            required: true,
                                        },
                                        {
                                            message: "PAN number should be CZTPAXXXXB",
                                            pattern: InputValidatorRegex.PAN_NO
                                        },
                                    ]} label="PAN Number" name="panNo">
                                        <Input onChange={(event: any) => {
                                            userForm.form?.setFieldsValue({ panNo: event?.target?.value.toUpperCase() })
                                        }} maxLength={10} />
                                    </Form.Item>
                                </Col>
                            }

                        </Row> : ''
                    }
                </Form>
            </Modal>
        </>
    );
};