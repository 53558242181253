import {
    IResourceComponentsProps, CrudFilters,
} from "@pankod/refine-core";

import {
    Table,
    useTable,
    Space,
    ShowButton,
    DateField,
    Tag,
    Card,
    Row,
    Col,
    Icons,
    FilterDropdownProps,
    FilterDropdown,
    Form,
    Input,
    AutoComplete,
    Button,
    DatePicker,
    Typography,
    FormProps,
    message,
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";

import { ICompany, ICompanyPBI } from "../../interfaces";
import React, { useState, useEffect } from "react";
import { SwitchStatementGenerationComponent } from "./components/switchStatementGeneration.component";
import { getCompanyDetailsbyID, isCreditCompany } from "utility/companyUtils";
const { RangePicker } = DatePicker;

const { Text } = Typography;
const { SearchOutlined } = Icons;

export enum BorrowerCategory {
    FAST_TRACK = 'FAST_TRACK',
    REGULAR = 'REGULAR',
    WAITLIST = 'WAITLIST',
}


export enum verificationStatus {
    PENDING = 'PENDING',
    IN_PROCESS = 'IN_PROCESS',
    ONBOARDED = 'ONBOARDED',
    REJECTED = 'REJECTED',
}

export interface IOptionGroup {
    value: string;
    label: string | React.ReactNode;
}

export interface IOptions {
    label: string | React.ReactNode;
    options: IOptionGroup[];
}

// To be able to customize the option title
const renderTitle = (title: string) => {
    return (
        <Text strong style={{ fontSize: "16px" }}>
            {title}
        </Text>
    );
};

// To be able to customize the option item
const renderItem = (title: string, resource: string, companyId: string) => {
    return {
        value: title,
        label: (
            <a className="d-block" href={`/${resource}/show/${companyId}`}>
                <Text>{title}</Text>
            </a>
        ),
    };
};
export const CompanyList: React.FC<IResourceComponentsProps> = () => {
    // const { Link } = useRouterContext();
    // Deployment Comment
    let [fromDate, setfromDate] = useState();
    let [toDate, settoDate] = useState();
    let [tableRefetch, setTableRefetch] = useState();
    // let [searchKeyword, setSearchKey] = useState();
    let [cin, setCin] = useState();
    let [id, setID] = useState();
    // let [borrowerCategory, setBorrowerCategory] = useState();
    // let [companyVerification, setCompanyVerification] = useState();
    let [brandName, setBrandName] = useState();
    const [value, setValue] = useState<string>("");
    const [options, setOptions] = useState<IOptions[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    const { refetch: refetchPosts } = useList<ICompany>({
        dataProviderName: "grapghQl",
        resource: 'searchUserByEmail',
        metaData: {
            emailId: value,
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const postOptionGroup = data.data.map((item) =>
                    renderItem(`${item?.brandName}`, "company", item?.id),
                );
                if (postOptionGroup.length > 0) {
                    setOptions([
                        {
                            label: renderTitle("Company"),
                            options: postOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    useEffect(() => {
        setOptions([]);
        refetchPosts();
    }, [value]);

    const portalUrl = process.env.REACT_APP_OLDPORTAL

    const { tableProps, searchFormProps, } = useTable<ICompany>({
        dataProviderName: "grapghQl",
        resource: 'getCompaniesForManagementPortalPaginated',
        syncWithLocation: true,
        metaData: {
            filter: {
                fromDate: fromDate,
                toDate: toDate,
                registeredName: brandName,
                cin: cin,
                id: id,
            },
            tableRefetch: tableRefetch,
        },
        initialSorter: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            const { q, cin, id, createdAt, tableRefetch } = params;
            // setSearchKey(q)
            setBrandName(q)
            setTableRefetch(tableRefetch)
            // setBrandName(brandName)
            setCin(cin)
            setID(id)
            // setBorrowerCategory(borrowerCategory)
            // setCompanyVerification(companyVerification)
            setfromDate(createdAt ? createdAt[0].toISOString() : undefined,)
            settoDate(createdAt ? createdAt[1].toISOString() : undefined,)

            return filters;
        },
    });
    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }
    const refetchCompanyList = (message: boolean) => {
        if (searchFormProps.form?.getFieldValue('tableRefetch')) {
            searchFormProps.form?.setFieldsValue({ tableRefetch: null });
        } else {
            searchFormProps.form?.setFieldsValue({ tableRefetch: message });
        }

        searchFormProps.form?.submit();
    };
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        if (newSelectedRowKeys.length == 0) {
            setSelectedRowKeys([]);
        }

        if (newSelectedRowKeys.length == 1) {
            const companyDetail = getCompanyDetailsbyID(tableProps?.dataSource, newSelectedRowKeys[newSelectedRowKeys.length - 1] as string)
            if (!isCreditCompany(companyDetail)) {
                return message.error('Please select credit company')
            }
        }

        let fillterdSelectedRowKeys = newSelectedRowKeys.filter((item) => {
            const companyDetail = getCompanyDetailsbyID(tableProps?.dataSource, item as string)
            return isCreditCompany(companyDetail)
        })
        setSelectedRowKeys(fillterdSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_NONE,
            {
                key: 'credit',
                text: 'Select All Credit Company',
                onSelect: (creditCompanyRowKeys: React.Key[]) => {
                    let fillterdSelectedRowKeys = creditCompanyRowKeys.filter((item) => {
                        const companyDetail = getCompanyDetailsbyID(tableProps?.dataSource, item as string)
                        return isCreditCompany(companyDetail)
                    })
                    setSelectedRowKeys(fillterdSelectedRowKeys);
                },
            },
        ]
    };

    const hasSelected = selectedRowKeys.length > 0;
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <AutoComplete
                    style={{ width: "100%", maxWidth: "550px" }}
                    filterOption={false}
                    options={options}
                    onSearch={(value: string) => setValue(value)}
                >
                    <Input
                        size="large"
                        placeholder="Search User by Email"
                        suffix={<SearchOutlined />}
                    />
                </AutoComplete>
            </Col>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                {
                    hasSelected ? <div style={{ marginBottom: 16 }}>
                        <SwitchStatementGenerationComponent refetchCompanyList={refetchCompanyList} hasSelected={!hasSelected} isButton={true} companyIds={selectedRowKeys} disableStatementGenerationValue={true} />
                        <SwitchStatementGenerationComponent refetchCompanyList={refetchCompanyList} hasSelected={!hasSelected} isButton={true} companyIds={selectedRowKeys} disableStatementGenerationValue={false} />
                        <span style={{ marginLeft: 8 }}>
                            Selected {selectedRowKeys.length} items
                        </span>
                    </div> : <></>
                }
                <Table {...tableProps} rowKey="id" rowSelection={rowSelection}>
                    <Table.Column
                        dataIndex="registeredName"
                        key="registeredName"
                        title="Brand Name"
                        render={(_, record: ICompany) => (
                            <Space align='baseline' direction="vertical">
                                <h4>
                                    {record.brandName}
                                </h4>
                                <small>
                                    {record.cin}
                                </small>
                            </Space>

                        )}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <Input />
                            </FilterDropdown>
                        )}
                    />
                    <Table.Column
                        dataIndex="emailId"
                        title="Email Id"
                        key="emailId"
                        render={(_, record: ICompany) => (
                            <Space align='baseline' direction="vertical">
                                <h4>
                                    {record?.emailId}
                                </h4>
                            </Space>

                        )}
                    // filterDropdown={(props: FilterDropdownProps) => (
                    //     <FilterDropdown {...props}>
                    //         <Input />
                    //     </FilterDropdown>
                    // )}
                    />
                    <Table.Column
                        dataIndex="companyCustomerId"
                        title="Customer Id"
                    // sorter
                    // filterDropdown={(props: FilterDropdownProps) => (
                    //     <FilterDropdown {...props}>
                    //         <Input />
                    //     </FilterDropdown>
                    // )}
                    />
                    <Table.Column
                        dataIndex="companyCustomerId"
                        title="ProductsBundles"
                        key="companyCustomerId"
                        render={(_, record: ICompany) => (
                            <Space>
                                <Row wrap={true} gutter={[0, 0]} >
                                    {
                                        record?.nonDeletedPBIsSortedByDate.map((item: ICompanyPBI, index: number) => {
                                            return <Col lg={24} xs={24}>
                                                <p><b> {item?.displayName}</b> :-  {item.creditLimit}</p>
                                            </Col>
                                        })
                                    }
                                </Row>
                            </Space>
                        )}
                    />
                    <Table.Column
                        dataIndex="borrowerCategory"
                        title="Category"
                        key="borrowerCategory"
                        render={(value) => {
                            let color;
                            switch (value) {
                                case BorrowerCategory.REGULAR:
                                    color = "green";
                                    break;
                                case BorrowerCategory.FAST_TRACK:
                                    color = "blue";
                                    break;
                                case BorrowerCategory.WAITLIST:
                                    color = "yellow";
                                    break;
                                default:
                                    color = "";
                                    break;
                            }
                            return <Tag color={color}>{value}</Tag>;
                        }}
                    // filterDropdown={(props: FilterDropdownProps) => (
                    //     <FilterDropdown {...props}>
                    //         <Radio.Group>
                    //             <Radio value="REGULAR">Regular</Radio>
                    //             <Radio value="FAST_TRACK">Fast Track</Radio>
                    //             <Radio value="WAITLIST">Waitlist</Radio>
                    //         </Radio.Group>
                    //     </FilterDropdown>
                    // )}
                    // sorter
                    />
                    <Table.Column
                        dataIndex="companyVerification"
                        title="Company Verification"
                        key="companyVerification"
                        render={(value) => {
                            let color;
                            switch (value) {
                                case verificationStatus.ONBOARDED:
                                    color = "green";
                                    break;
                                case verificationStatus.REJECTED:
                                    color = "red";
                                    break;
                                case verificationStatus.PENDING:
                                    color = "blue";
                                    break;
                                default:
                                    color = "";
                                    break;
                            }
                            return <Tag color={color}>{value}</Tag>;
                        }}
                    // filterDropdown={(props: FilterDropdownProps) => (
                    //     <FilterDropdown {...props}>
                    //         <Radio.Group>
                    //             <Radio value="ONBOARDED">Onboarded</Radio>
                    //             <Radio value="PENDING">Pending</Radio>
                    //         </Radio.Group>
                    //     </FilterDropdown>
                    // )}
                    // sorter
                    />
                    <Table.Column
                        dataIndex="createdAt"
                        key="createdAt"
                        title="Created At"
                        render={(value) => <DateField value={value} format="LLL" />}
                    // sorter
                    />
                    <Table.Column<ICompany>
                        dataIndex="disableStatementGeneration"
                        key="disableStatementGeneration"
                        title="Statement Generation"
                        render={(_, record) => <SwitchStatementGenerationComponent refetchCompanyList={refetchCompanyList} companyIds={[record?.id]} companyDetails={record} disableStatementGenerationValue={record?.disableStatementGeneration} />}
                    />
                    <Table.Column<ICompany>
                        title="Actions"
                        dataIndex="actions"
                        key="actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    size="small"
                                    recordItemId={record?.id}
                                />
                                <a href={`${portalUrl}/app/employee/${record?.id}`} rel="noreferrer" target='_blank'>Old Portal</a>
                                {/* <EditButton
                                        size="small"
                                        hideText
                                        recordItemId={record?.id}
                                    /> */}
                                {/* <DeleteButton
                                        size="small"
                                        hideText
                                        recordItemId={record.id}
                                    /> */}
                            </Space>
                        )}
                    />
                </Table>
                {
                    (tableProps?.dataSource && tableProps?.pagination) ? <span>
                        Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                    </span> : ''
                }

            </Col>
        </Row>
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={6}>
                    <Form.Item hidden={true} label="Table Refetch field" name="tableRefetch">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Search Brand Name" name="q">
                        <Input
                            placeholder="Brand Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={3} xs={3}>
                    <Form.Item label="Search CIN" name="cin">
                        <Input
                            placeholder="CIN"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={3} xs={3}>
                    <Form.Item label="Search Id" name="id">
                        <Input
                            placeholder="ID"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="Created At" name="createdAt">
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item>
                        <br />
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
