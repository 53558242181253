import { CrudFilters, CrudSorting, DataProvider } from "@pankod/refine-core";
import { GraphQLClient } from "graphql-request";
import * as gql from "gql-query-builder";
import pluralize from "pluralize";
import camelCase from "camelcase";
// import { useNotification } from "@pankod/refine-core";
import { formatDateTime, formatDate } from "../utility/dateFormat"
// import { arrayOfObjectFormat } from "utility/reponseFormat"
import { CompanyRoles, DebitMethod, KycType, MandateAmount } from "../interfaces/company";

import { clients } from '../dataProvider/apollo-graphql';
import {
    getCityByStateGQL, initiateRepaymentAutoDebitAuthorizationEmandateGQL,
    saveRepaymentAutoDebitFixedIntervalRuleGQL, saveRepaymentAutoDebitRuleStatementDueGQL,
    initiateRepaymentAutoDebitAuthorizationGQL, companyBankGQL,
    issueAdditionalProductsBundleNonPrepaidGQL, getCompaniesForManagementPortalPaginatedGQL, renameCompanyGQL,
    changeProductsBundleIssuanceToNewCardTypeGQL, updateSanctionedCreditLimitOfPbiGQL, adduserGQL, sanctionNewCreditLimitForPbiGQL,
    onboardCompanyWithPayLaterProductsBundleGQL, getCompanyListsGQL, assignCompanyAccountManagerGQL,
    getListOfAccountManagersGQL, onboardCompanyWithPrepaidProductsBundleGQL, getProductsBundlesGQL,
    onboardCompanyWithNonPrepaidProductsBundleGQL, addBankGQL, issueAdditionalProductsBundlePrepaidGQL,
    getCompaniesForManagementPortalGQL, getUsersGQL, getCompanyClassificationGQL, getStateQueryGQL,
    deleteUserGQL, pendingPaymentAndAssociatedCompanyListForUserGQL, searchUserByEmailGQL, deactivateUserAccountGQL,
    waitlistedCompaniesForManagementGQL, updateCompanyBorrowerCategoryGQL, getBankBranchDescriptionByIfscGQL, repaymentAutoDebitPaginatedGQL, inviteUserToMyCompanyGQL,
    issuePhysicalFormOfActiveCardGQL, doFinalCardActivationGQL, registerM2pCorporateGQL, setM2pCorporateEnabledAtGQL, getProductFeatureFeeCompanyConfigGQL, addOrUpdateCompanyAddressAndGstNumberGQL, saveProductFeatureFeeCompanyConfigGQL, setupProductFeatureFeeExclusionGQL, getProductFeatureFeeConfigGQL, closeKodoAccountOfCompanyGQL, updateCompanyDetailGQL,
    updateCompanyFeaturesAndSettingsGQL,
} from 'dataProvider/mutations/company.mutation';
import {
    addNewCardholderForPaperKycGQL, getLimitsSpecifiedInCardIssuanceRequestGQL, editCompanyDetailGQL, addNewCardholderForEKycGQL, updateCardholderDetailsGQL, updateCardholderForEKycGQL, updateCardholderForPaperKycGQL, assignFirstCardToUserGQL,
    lockCardBySuperAdminGQL, unlockCardBySuperAdminGQL, blockCardBySuperAdminGQL, updateUserLimitForPbiGQL, issueAdditionalCardToUserFromMgmtPortalGQL,
} from '../dataProvider/mutations/card.mutations';
import {
    getLatestUserEkySessionForSuperAdminGQL,
    createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompanyGQL, addDeliveryAddressGQL,
    generateVcipLinkWithUpdatedDetailsGQL,
    removeUserFromCompanyGQL,
    enableUserForCompanyGQL,
} from '../dataProvider/mutations/user.mutation';
import { companyLeadsForManagementGQL, getOnboardCompanyGQL, promoteCompanyDataToCompanyWithBorrowerCategoryGQL } from 'dataProvider/mutations/eligible.mutation';
import { calculateCompanyPenaltiesForUpcomingStatementGQL, getPenaltyForCompaniesAppearingInUpcomingStatementsGQL } from 'dataProvider/mutations/penalty,mutation';
import { acceptPendingCreditNotificationTransactionGQL, getTransactionsWithPendingStatusGQL, findCandidateParentTransactionGQL, } from 'dataProvider/mutations/refund.mutation';
import { integrationSettlementStatementForKonsolePaginateGQL, integrationSettlementStatementMappingForKonsoleGQL, settleIntegrationPaymentGQL, checkIntegrationSettlementStatusWithBankGQL, getActiveIntegrationsGQL, removeMmtIntegrationSettlementStatementGQL, generateMmtIntegrationSettlementStatementGQL, updateSettlementFinalAmountGQL, getSettlementHistoryGQL, addIntegrationCompanyMappingGQL, findCompanyByIntegrationIdPaginatedGQL, disableOrEnableMmtIntegrationFromInternalPortalGQL } from 'dataProvider/mutations/mmt.mutation';
import { loginQueryGQL, logOutUserOnDeviceGQL } from "./mutations/auth.mutation";
import { dailyNpaReportsSbmGQL, entriesInDailyNpaReportsSbmGQL } from "./mutations/npa.mutation";
import { VASettlementGQL } from "./mutations/va-settlement.gql";
import { SettlementEventType } from "interfaces/mmt";
import { KodoPayGQL } from "./mutations/kodo-pay.mutation";
import { AccountFundingGQL } from "./mutations/account-funding.mutation";
import { createDecentroVirtualAccountForCompanyGQL, createNewRepaymentVaViaInternalPortalGQL, recalculateBalancesOfVirtualAccountsViaInternalPortalGQL } from "./mutations/company.mutation";
import { OcrDashboardGQL } from "./mutations/ocr.mutation";

let GQLQureies: any = {
    ...OcrDashboardGQL,
    ...AccountFundingGQL,
    ...VASettlementGQL,
    ...KodoPayGQL,
    getAllState: getStateQueryGQL,
    companyBank: companyBankGQL,
    createNewRepaymentVaViaInternalPortal: createNewRepaymentVaViaInternalPortalGQL,
    dailyNpaReportsSbm: dailyNpaReportsSbmGQL,
    recalculateBalancesOfVirtualAccountsViaInternalPortal: recalculateBalancesOfVirtualAccountsViaInternalPortalGQL,
    createDecentroVirtualAccountForCompany: createDecentroVirtualAccountForCompanyGQL,
    updateCompanyFeaturesAndSettings: updateCompanyFeaturesAndSettingsGQL,
    entriesInDailyNpaReportsSbm: entriesInDailyNpaReportsSbmGQL,
    saveRepaymentAutoDebitFixedIntervalRule: saveRepaymentAutoDebitFixedIntervalRuleGQL,
    saveRepaymentAutoDebitRuleStatementDue: saveRepaymentAutoDebitRuleStatementDueGQL,
    initiateRepaymentAutoDebitAuthorization: initiateRepaymentAutoDebitAuthorizationGQL,
    initiateRepaymentAutoDebitAuthorizationEmandate: initiateRepaymentAutoDebitAuthorizationEmandateGQL,
    getCityByState: getCityByStateGQL,
    getCompaniesForManagementPortalPaginated: getCompaniesForManagementPortalPaginatedGQL,
    getCompanyLists: getCompanyListsGQL,
    updateSanctionedCreditLimitOfPbi: updateSanctionedCreditLimitOfPbiGQL,
    updateCompanyDetail: updateCompanyDetailGQL,
    sanctionNewCreditLimitForPbi: sanctionNewCreditLimitForPbiGQL,
    promoteCompanyDataToCompanyWithBorrowerCategory: promoteCompanyDataToCompanyWithBorrowerCategoryGQL,
    getCompanyClassification: getCompanyClassificationGQL,
    getCompaniesForManagementPortal: getCompaniesForManagementPortalGQL,
    getUsers: getUsersGQL,
    pendingPaymentAndAssociatedCompanyListForUser: pendingPaymentAndAssociatedCompanyListForUserGQL,
    searchUserByEmail: searchUserByEmailGQL,
    deleteUser: deleteUserGQL,
    deactivateUserAccount: deactivateUserAccountGQL,
    adduser: adduserGQL,
    addOrUpdateCompanyAddressAndGstNumber: addOrUpdateCompanyAddressAndGstNumberGQL,
    getBankBranchDescriptionByIfsc: getBankBranchDescriptionByIfscGQL,
    issuePhysicalFormOfActiveCard: issuePhysicalFormOfActiveCardGQL,
    doFinalCardActivation: doFinalCardActivationGQL,
    registerM2pCorporate: registerM2pCorporateGQL,
    setM2pCorporateEnabledAt: setM2pCorporateEnabledAtGQL,
    repaymentAutoDebitPaginated: repaymentAutoDebitPaginatedGQL,
    addNewCardholderForEKyc: addNewCardholderForEKycGQL,
    updateCardholderForEKyc: updateCardholderForEKycGQL,
    updateCardholderForPaperKyc: updateCardholderForPaperKycGQL,
    assignFirstCardToUser: assignFirstCardToUserGQL,
    issueAdditionalCardToUserFromMgmtPortal: issueAdditionalCardToUserFromMgmtPortalGQL,
    updateCardholderDetails: updateCardholderDetailsGQL,
    addNewCardholderForPaperKyc: addNewCardholderForPaperKycGQL,
    updateUserLimitForPbi: updateUserLimitForPbiGQL,
    lockCardBySuperAdmin: lockCardBySuperAdminGQL,
    unlockCardBySuperAdmin: unlockCardBySuperAdminGQL,
    blockCardBySuperAdmin: blockCardBySuperAdminGQL,
    getLimitsSpecifiedInCardIssuanceRequest: getLimitsSpecifiedInCardIssuanceRequestGQL,
    getLatestUserEkySessionForSuperAdmin: getLatestUserEkySessionForSuperAdminGQL,
    removeUserFromCompany: removeUserFromCompanyGQL,
    enableUserForCompany: enableUserForCompanyGQL,
    generateVcipLinkWithUpdatedDetails: generateVcipLinkWithUpdatedDetailsGQL,
    createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompany: createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompanyGQL,
    addDeliveryAddress: addDeliveryAddressGQL,
    onboardCompanyWithPayLaterProductsBundle: onboardCompanyWithPayLaterProductsBundleGQL,
    renameCompany: renameCompanyGQL,
    issueAdditionalProductsBundleNonPrepaid: issueAdditionalProductsBundleNonPrepaidGQL,
    waitlistedCompaniesForManagement: waitlistedCompaniesForManagementGQL,
    companyLeadsForManagement: companyLeadsForManagementGQL,
    updateCompanyBorrowerCategory: updateCompanyBorrowerCategoryGQL,
    getOnboardCompany: getOnboardCompanyGQL,
    getStateQuery: getStateQueryGQL,
    editCompanyDetail: editCompanyDetailGQL,
    getPenaltyForCompaniesAppearingInUpcomingStatements: getPenaltyForCompaniesAppearingInUpcomingStatementsGQL,
    calculateCompanyPenaltiesForUpcomingStatement: calculateCompanyPenaltiesForUpcomingStatementGQL,
    getTransactionsWithPendingStatus: getTransactionsWithPendingStatusGQL,
    findCandidateParentTransaction: findCandidateParentTransactionGQL,
    addBank: addBankGQL,
    loginQuery: loginQueryGQL,
    logOutUserOnDevice: logOutUserOnDeviceGQL,
    getProductsBundles: getProductsBundlesGQL,
    onboardCompanyWithNonPrepaidProductsBundle: onboardCompanyWithNonPrepaidProductsBundleGQL,
    getListOfAccountManagers: getListOfAccountManagersGQL,
    onboardCompanyWithPrepaidProductsBundle: onboardCompanyWithPrepaidProductsBundleGQL,
    changeProductsBundleIssuanceToNewCardType: changeProductsBundleIssuanceToNewCardTypeGQL,
    assignCompanyAccountManager: assignCompanyAccountManagerGQL,
    issueAdditionalProductsBundlePrepaid: issueAdditionalProductsBundlePrepaidGQL,
    integrationSettlementStatementForKonsolePaginate: integrationSettlementStatementForKonsolePaginateGQL,
    integrationSettlementStatementMappingForKonsole: integrationSettlementStatementMappingForKonsoleGQL,
    settleIntegrationPayment: settleIntegrationPaymentGQL,
    generateMmtIntegrationSettlementStatement: generateMmtIntegrationSettlementStatementGQL,
    removeMmtIntegrationSettlementStatement: removeMmtIntegrationSettlementStatementGQL,
    checkIntegrationSettlementStatusWithBank: checkIntegrationSettlementStatusWithBankGQL,
    updateSettlementFinalAmount: updateSettlementFinalAmountGQL,
    getSettlementHistory: getSettlementHistoryGQL,
    addIntegrationCompanyMapping: addIntegrationCompanyMappingGQL,
    disableOrEnableMmtIntegrationFromInternalPortal: disableOrEnableMmtIntegrationFromInternalPortalGQL,
    findCompanyByIntegrationIdPaginated: findCompanyByIntegrationIdPaginatedGQL,
    getActiveIntegrations: getActiveIntegrationsGQL,
    inviteUserToMyCompany: inviteUserToMyCompanyGQL,
    closeKodoAccountOfCompany: closeKodoAccountOfCompanyGQL,
    getProductFeatureFeeCompanyConfig: getProductFeatureFeeCompanyConfigGQL,
    getProductFeatureFeeConfig: getProductFeatureFeeConfigGQL,
    saveProductFeatureFeeCompanyConfig: saveProductFeatureFeeCompanyConfigGQL,
    setupProductFeatureFeeExclusion: setupProductFeatureFeeExclusionGQL,
    acceptPendingCreditNotificationTransaction: acceptPendingCreditNotificationTransactionGQL
}

export const genereteSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const sortQuery = sort.map((i) => {
            return `${i.field}:${i.order}`;
        });

        return sortQuery.join();
    }

    return [];
};

export const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: any } = {};

    // if (filters) {
    //     filters.map((filter) => {
    //         if (filter.operator !== "or") {
    //             const { field, operator, value } = filter;

    //             if (operator === "eq") {
    //                 queryFilters[`${field}`] = value;
    //             } else {
    //                 queryFilters[`${field}_${operator}`] = value;
    //             }
    //         } else {
    //             const { value } = filter;

    //             const orFilters: any[] = [];
    //             value.map((val) => {
    //                 orFilters.push({
    //                     [`${val.field}_${val.operator}`]: val.value,
    //                 });
    //                 return true
    //             });

    //             queryFilters["_or"] = orFilters;
    //         }
    //         return false
    //     });
    // }

    return queryFilters;
};
const dataProvider = (client: GraphQLClient): DataProvider => {
    return {
        getList: async ({
            resource,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            sort,
            filters,
            metaData,
        }) => {
            // delete metaData?.type
            const { current = 1, pageSize = 10 } = pagination ?? {};
            let sortObj
            if (sort && sort.length > 0) {
                sortObj = {
                    field: sort[0].field,
                    direction: sort[0].order.toUpperCase()
                }
            }

            let variables: any = {
                ...metaData,
                sortBy: sortObj,
                limit: metaData?.limit || pageSize,
                page: metaData?.current || current,
                pagination: {
                    page: metaData?.current || current,
                    limit: metaData?.limit || pageSize,
                },
            }

            if (filters && filters.length > 0) {
                filters.map((ele: any) => {
                    variables.filter[ele.field] = ele.value
                    return ele
                })
            }
            if (variables.fromAmount) {
                variables.fromAmount = parseFloat(variables.fromAmount)
            }
            if (variables.toAmount) {
                variables.toAmount = parseFloat(variables.toAmount)
            }

            if (variables?.filter?.fromAmount && variables.filter.fromAmount !== '') {
                variables.filter.fromAmount = parseFloat(variables.filter.fromAmount)
            } else {
                if (variables?.filter?.fromAmount) {
                    delete variables.filter.fromAmount
                }
            }
            if (variables?.filter?.toAmount && variables.filter.toAmount !== '') {
                variables.filter.toAmount = parseFloat(variables.filter.toAmount)
            } else {
                if (variables?.filter?.toAmount) {
                    delete variables.filter.toAmount
                }
            }
            if (resource === 'getCompanyExpensesForAdmin') {
                variables = {
                    ...variables,
                    filter: {
                        pageNumber: metaData?.current || current,
                        pageSize: metaData?.limit || pageSize,
                    }
                }
            }

            let response: any

            if (resource === 'getOcrMetrics' && !variables?.oprId && !variables?.attachmentId) {
                return {
                    data: [],
                    total: 0,
                };
            }
            if (resource === 'getUsersAdmin') {
                // console.log(resource, variables);
                response = await clients.mutate({
                    mutation: GQLQureies['getUsers'],
                    variables: variables,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });

                const userList = (response?.data?.getUsers?.user) ? response.data.getUsers.user : []
                // console.log(userList, response);

                const adminList = userList.filter((d: any) => d.rolesToDisplay.find((element: any) => element === CompanyRoles.companyAdmin))

                return {
                    data: adminList,
                    total: adminList.length,
                };
            }
            if (!metaData) {
                response = await clients.query({
                    query: GQLQureies[resource],
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            } else {
                response = await clients.mutate({
                    mutation: GQLQureies[resource],
                    variables: variables,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            }
            if (response?.errors?.length > 0) {
                // sessionStorage.setItem('notification', JSON.stringify(response.errors))
                throw Error(
                    response.errors[0].message,
                );
                // HandleError(response)
            }
            if (resource === "getCompanyExpensesForAdmin" && response.data[resource]?.expense) {
                return {
                    data: response.data[resource]?.expense,
                    total: response.data[resource]?.total,
                };
            }
            if (resource === 'companyBank') {
                const companyBank = (response.data['getCompaniesForManagementPortal'] && response.data['getCompaniesForManagementPortal'].company && response.data['getCompaniesForManagementPortal'].company[0] && response.data['getCompaniesForManagementPortal'].company[0]['bank']) ? response.data['getCompaniesForManagementPortal'].company[0].bank : []
                return {
                    data: companyBank,
                    total: companyBank.length,
                };
            } else if (variables.isExport) {
                // response.data[resource].items = arrayOfObjectFormat(response.data[resource]?.items)
                if (response.data[resource]?.items) {
                    let responseData = response.data[resource]?.items.map((ele: any) => {
                        delete ele.__typename
                        if (ele?.company) {
                            ele.company = ele.company?.registeredName
                        }
                        if (ele?.createdAt) {
                            ele.createdAt = formatDate(ele?.createdAt)
                        }
                        if (ele?.startDate) {
                            ele.startDate = formatDate(ele?.startDate)
                        }
                        if (ele?.endDate) {
                            ele.endDate = formatDate(ele?.endDate)
                        }
                        if (ele?.integrationSettlementStatementRequest) {
                            ele.utr = ele?.integrationSettlementStatementRequest?.utr
                            ele.integrationSettlementStatementRequest = ele.integrationSettlementStatementRequest?.id
                        }
                        if (resource === 'integrationSettlementStatementForKonsolePaginate') {
                            if (ele?.gstPercent) {
                                ele.gstAmount = `Rs. ${ele?.gstPercent.toFixed(2)}`
                                delete ele.gstPercent
                            }
                            ele.finalAmountToPay = `Rs. ${ele?.finalAmountToPay.toFixed(2)}`
                            ele.totalInvoiceAmount = `Rs. ${ele?.totalInvoiceAmount.toFixed(2)}`
                            ele.processingFeePercent = `${ele?.processingFeePercent} %`
                        }
                        if (resource === 'entriesInDailyNpaReportsSbm') {

                            ele.report = ele?.report?.id
                            ele.currentAssetClassCode = ele?.sbmFeedback?.assetClassification?.currentAssetClassCode
                            ele.currentSubAssetClassCode = ele?.sbmFeedback?.assetClassification?.currentSubAssetClassCode
                            ele.npaDate = ele?.sbmFeedback?.assetClassification?.npaDate
                            ele.revisedMainAssetClassCode = ele?.sbmFeedback?.assetClassification?.revisedMainAssetClassCode
                            ele.revisedSubAssetClassCode = ele?.sbmFeedback?.assetClassification?.revisedSubAssetClassCode
                            ele.asOnDate = ele?.sbmFeedback?.provision?.asOnDate
                            ele.previousDay = ele?.sbmFeedback?.provision?.previousDay
                            delete ele.sbmFeedback
                        }
                        if (resource === 'integrationSettlementStatementMappingForKonsole') {
                            if (ele?.transaction) {
                                ele.transactionId = ele.transaction?.id
                                ele.company = ele.transaction?.company?.registeredName
                                ele.transactionAmount = `Rs. ${ele.transaction?.transactionAmount}`
                                ele.transactionCategory = ele.transaction?.transactionCategory
                                ele.txnDate = ele.transaction?.txnDate
                                delete ele.transaction
                            }
                        }

                        if (ele?.pbi?.company) {
                            ele.company = ele?.pbi?.company?.registeredName
                            delete ele.pbi
                        }
                        if (ele?.pbi?.company) {
                        ele.company = ele?.pbi?.company?.registeredName
                        delete ele.pbi
                    }
                    if (ele.user) {
                            ele.user = ele.user?.firstName + ' ' + ele.user?.lastName
                        }
                        return ele
                    })
                    if (resource === 'integrationSettlementStatementMappingForKonsole') {
                        responseData = await responseData.sort((a: any, b: any) => new Date(a?.txnDate).getTime() - new Date(b?.txnDate).getTime());
                        responseData = responseData.map((ele: any) => {
                            ele.transactionCategory = ele?.transactionCategory.replaceAll("_", " ");
                            ele.txnDate = ele?.txnDate ? formatDateTime(ele?.txnDate) :'-';
                            // ele.transactionAmount = ele?.transactionAmount ? `Rs. ${ele?.transactionAmount.toFixed(2)}`:"-"
                            return ele
                        })
                    }

                    return {
                        data: responseData,
                        total: responseData.length,
                    }
                } else {
                    return {
                        data: response.data[resource],
                        total: response.data[resource].length,
                    }
                }
            }
            if (resource === 'fundingBankAccountForCompany') {
                return {
                    data: response.data[resource].fundingBankAccountForCompanyOutput,
                    total: response.data[resource]?.totalActiveFundingBankAccountForCompany || 10,
                };
            }
            if (resource === 'findCompanyByIntegrationIdPaginated') {
                response.data[resource].items = response.data[resource].items.map((ele: any) => {
                    ele = ele?.company
                    return ele
                })
                return {
                    data: response.data[resource].items,
                    total: response.data[resource]?.meta?.totalItems || 10,
                };
            }
            if (resource === 'getOcrMetrics') {
                return {
                    data: [response.data[resource]],
                    total: 1,
                };
            }
            if (resource === 'getOnboardCompany') {
                const companyData = (response.data[resource]['company']) ? response.data[resource].company : []
                return {
                    data: companyData,
                    total: companyData.length,
                };
            } else if (response.data[resource].items) {
                return {
                    data: response.data[resource].items,
                    total: response.data[resource]?.meta?.totalItems || 10,
                };
            } else {
                return {
                    data: response.data[resource],
                    total: response.data[resource].length,
                };
            }
        },
        getMany: async ({ resource, ids, metaData }) => {
            const camelResource = camelCase(resource);

            const operation = metaData?.operation ?? camelResource;

            const { query, variables } = gql.query({
                operation,
                variables: {
                    where: {
                        value: { id_in: ids },
                        type: "JSON",
                    },
                },
                fields: metaData?.fields,
            });

            const response: any = await client.request(query, variables);

            return {
                data: response[operation],
            };
        },
        create: async ({ resource, variables, metaData }) => {
            let variable: any = {
                ...metaData,
                ...variables
            }

            if (variable?.companyLimit) {
                variable.companyLimit = parseFloat(variable.companyLimit)
            }
            if (variable?.nonCardTxnCompanyLimit) {
                variable.nonCardTxnCompanyLimit = parseFloat(variable.nonCardTxnCompanyLimit)
            }
            if (variable?.monthlyTransactionLimit) {
                variable.monthlyTransactionLimit = parseFloat(variable.monthlyTransactionLimit)
            }
            if (variable?.perTransactionLimit) {
                variable.perTransactionLimit = parseFloat(variable.perTransactionLimit)
            }
            if (variable?.monthsApprovedFor) {
                variable.monthsApprovedFor = parseFloat(variable.monthsApprovedFor)
            }
            if (variable?.specifiedMandateAmount) {
                variable.specifiedMandateAmount = parseFloat(variable.specifiedMandateAmount)
            }
            if (variable?.creditLimit) {
                variable.creditLimit = parseFloat(variable.creditLimit)
            }
            if (variable?.monthlyLimit) {
                variable.monthlyLimit = parseFloat(variable.monthlyLimit)
            }
            if (variable?.quickPayLimit?.monthlyTransactionLimit) {
                variable.quickPayLimit.monthlyTransactionLimit = parseFloat(variable?.quickPayLimit?.monthlyTransactionLimit)
            }
            if (variable?.quickPayLimit?.perTransactionLimit) {
                variable.quickPayLimit.perTransactionLimit = parseFloat(variable?.quickPayLimit?.perTransactionLimit)
            }
            if (variable?.dailyLimit) {
                variable.dailyLimit = parseFloat(variable.dailyLimit)
            }

            if (resource === 'updateSettlementFinalAmount') {
                variable.finalAmountToPay = parseFloat(variable.finalAmountToPay)
            }
            let response: any
            if (resource === 'addNewCardholderForEKyc' || resource === 'updateCardholderDetails' || resource === 'updateCardholderForEKyc' || resource === 'updateCardholderForPaperKyc') {

                const obj: any = {
                    input: {
                        expectedKycModeForIssuingCard: variable.status || variable.expectedKycModeForIssuingCard,
                        userDetails: {
                            id: variable.id || null,
                            emailId: variable.emailId || null,
                            companyId: variable.company || null,
                            firstName: variable.firstName || null,
                            lastName: variable.lastName || null,
                            mobileNo: variable.mobileNo,
                            panNo: variable.panNo || null,
                        }
                    }
                }
                if (resource === 'updateCardholderDetails') {
                    obj.input.userDetails.gender = variable.gender || null
                }
                if (resource === 'updateCardholderForEKyc') {
                    obj.input.pbiId = variable.pbiId
                    obj.input.userDetails.cardHolderAccess = true
                    obj.input.userDetails.dailyLimit = (variable.dailyLimit) ? parseFloat(variable.dailyLimit) : null
                    obj.input.userDetails.monthlyLimit = (variable.monthlyLimit) ? parseFloat(variable.monthlyLimit) : null
                }
                if (resource === 'updateCardholderForPaperKyc') {
                    obj.input.pbiId = variable.pbiId
                    obj.input.userDetails.cardHolderAccess = true
                    obj.input.userDetails.dailyLimit = (variable.dailyLimit) ? parseFloat(variable.dailyLimit) : null
                    obj.input.userDetails.monthlyLimit = (variable.monthlyLimit) ? parseFloat(variable.monthlyLimit) : null
                    obj.input.userDetails.addressLine1 = variable.addressLine1
                    obj.input.userDetails.addressLine2 = variable.addressLine2
                    obj.input.userDetails.cityId = variable.cityId
                    obj.input.userDetails.gender = variable.gender
                    obj.input.userDetails.dob = (variable.dob) ? variable.dob.toISOString().split('T')[0] : ''
                    obj.input.userDetails.pincode = parseInt(variable.pincode) || null
                    obj.input.userDetails.stateId = variable.stateId
                    obj.input.userDetails.adminAccess = (variable.cardStatus?.includes(CompanyRoles.companyAdmin))
                }
                if (resource === 'addNewCardholderForEKyc') {
                    obj.input.pbiId = variable.pbiId
                    obj.input.userDetails.adminAccess = (variable.cardStatus?.includes(CompanyRoles.companyAdmin))
                    obj.input.userDetails.cardHolderAccess = true
                    obj.input.userDetails.dailyLimit = (variable.dailyLimit) ? parseFloat(variable.dailyLimit) : null
                    obj.input.userDetails.monthlyLimit = (variable.monthlyLimit) ? parseFloat(variable.monthlyLimit) : null
                }
                response = await clients.mutate({
                    mutation: GQLQureies[resource],
                    variables: obj,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            }
            if (resource === 'adduser') {
                if (variable.status === KycType.electronic) {
                    const obj = {
                        input: {
                            expectedKycModeForIssuingCard: variable.status,
                            pbiId: variable.pbiId,
                            userDetails: {
                                adminAccess: (variable.cardStatus?.includes(CompanyRoles.companyAdmin)),
                                cardHolderAccess: true,
                                companyId: variable.company || null,
                                dailyLimit: (variable.dailyLimit) ? parseFloat(variable.dailyLimit) : null,
                                emailId: variable.emailId || null,
                                firstName: variable.firstName || null,
                                lastName: variable.lastName || null,
                                mobileNo: variable.mobileNo || null,
                                monthlyLimit: (variable.monthlyLimit) ? parseFloat(variable.monthlyLimit) : null,
                                panNo: variable.panNo || null,
                            }
                        }
                    }
                    response = await clients.mutate({
                        mutation: GQLQureies['addNewCardholderForEKyc'],
                        variables: obj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                } else {
                    variable.dob = (variable.dob) ? variable.dob.toISOString().split('T')[0] : ''

                    const obj = {
                        input: {
                            expectedKycModeForIssuingCard: variable.status,
                            pbiId: variable.pbiId,
                            userDetails: {
                                addressLine1: variable.addressLine1 || null,
                                addressLine2: variable.addressLine2 || null,
                                cityId: variable.cityId || null,
                                gender: variable.gender || null,
                                dob: variable.dob || '',
                                pincode: parseInt(variable.pincode) || null,
                                stateId: variable.stateId || null,

                                adminAccess: (variable?.cardStatus?.includes(CompanyRoles.companyAdmin)),
                                cardHolderAccess: true,
                                companyId: variable.company || null,
                                dailyLimit: (variable.dailyLimit) ? parseFloat(variable.dailyLimit) : null,
                                emailId: variable.emailId || null,
                                firstName: variable.firstName || '',
                                middleName: variable.middleName || '',
                                lastName: variable.lastName || '',
                                mobileNo: variable.mobileNo || null,
                                monthlyLimit: (variable.monthlyLimit) ? parseFloat(variable.monthlyLimit) : null,
                                panNo: variable.panNo || null,

                            }
                        }
                    }

                    response = await clients.mutate({
                        mutation: GQLQureies['addNewCardholderForPaperKyc'],
                        variables: obj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                }
            } else if (resource === 'saveRepaymentAutoDebitRule') {
                if (variable.frequencyOfFixedIntervalCharge === 'MONTHLY') {
                    const saveRepaymentObj = {
                        pbiId: variable.pbiId
                    }
                    response =  await clients.mutate({
                        mutation: GQLQureies['saveRepaymentAutoDebitRuleStatementDue'],
                        variables: saveRepaymentObj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                } else {
                    const saveRepaymentObj = {
                        pbiId: variable.pbiId,
                        chargeCalculationMethod: 'DUE_BASED_ON_LATEST_TXN',
                        frequencyOfFixedIntervalCharge: variable.frequencyOfFixedIntervalCharge
                    }
                    response = await clients.mutate({
                        mutation: GQLQureies['saveRepaymentAutoDebitFixedIntervalRule'],
                        variables: saveRepaymentObj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                }
            } else if (resource === 'autoDebit') {
                if (variable.autoDebitMethod === DebitMethod.NACH) {
                    const obj: any = {
                        authorizingCompanyAdminEmail: variable.emailId,
                        pbiId: variable.pbiId,
                        companyId: variable.companyId,
                        autoDebitMethod: variable.autoDebitMethod,
                        mandateAmountDeterminant: variable.mandateAmountDeterminant,
                        beneficiaryName: variable.beneficiaryName,
                        accountNumber: variable.accountNumber,
                        accountType: 'current',
                        ifsc: variable.ifsc
                    }
                    if (variable.mandateAmountDeterminant === MandateAmount.SPECIFIED) {
                        obj['specifiedMandateAmount'] = variable.specifiedMandateAmount
                    }
                    response = await clients.mutate({
                        mutation: GQLQureies['initiateRepaymentAutoDebitAuthorization'],
                        variables: obj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                }
                if (variable.autoDebitMethod === 'EMANDATE') {
                    const obj = {
                        authorizingCompanyAdminEmail: variable.emailId,
                        pbiId: variable.pbiId,
                        companyId: variable.companyId,
                        autoDebitMethod: variable.autoDebitMethod,
                        mandateAmountDeterminant: variable.mandateAmountDeterminant,
                        specifiedMandateAmount: variable.specifiedMandateAmount,
                    }
                    response = await clients.mutate({
                        mutation: GQLQureies['initiateRepaymentAutoDebitAuthorizationEmandate'],
                        variables: obj,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                }
            }
            if (resource === 'updateSanctionedCreditLimitOfPbi') {
                if (!variable?.sanctionedCreditLimitId) {
                    response = await clients.mutate({
                        mutation: GQLQureies['sanctionNewCreditLimitForPbi'],
                        variables: {
                            input: {
                                pbiId: variable.PBIid || "",
                                creditLimitSanctionAttributes: {
                                    monthsApprovedFor: variable.monthsApprovedFor,
                                    creditLimit: variable.creditLimit
                                }
                            }
                        },
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                    });
                }
            }
            if (resource === 'registerM2pCorporate') {
                response = await clients.mutate({
                    mutation: GQLQureies[resource],
                    variables: {
                        cardIntegrationId: variable.cardIntegrationId,
                        companyId: variable.companyId,
                        tenantKey: variable.tenantKey,
                        onboardingData: {
                            title: variable.title,
                            firstName: variable.firstName,
                            lastName: variable.lastName,
                            contactNo: variable.contactNo,
                            emailAddress: variable.emailAddress,
                            gender: variable.gender,
                            specialDate: variable?.specialDate,
                            address: variable.address,
                            address2: variable.address2,
                            city: variable.city,
                            state: variable.state,
                            country: variable.country,
                            pincode: variable.pincode,
                            idType: variable.idType,
                            idNumber: variable.idNumber,
                            countryofIssue: variable.country,
                            dependent: "false",
                        }
                    },
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            }
            if (!response) {
                response = await clients.mutate({
                    mutation: GQLQureies[resource],
                    variables: variable,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            }
            if (response?.errors?.length > 0) {
                // sessionStorage.setItem('notification', JSON.stringify(response.errors))
                throw Error(
                    response.errors[0].message,
                );
                // HandleError(response)
            }
            return {
                data: response.data[resource],
                rawData: response.data
            };
        },
        createMany: async ({ resource, variables, metaData }) => {
            const singularResource = pluralize.singular(resource);
            const camelCreateName = camelCase(`create-${singularResource}`);

            const operation = metaData?.operation ?? camelCreateName;

            const response = await Promise.all(
                variables.map(async (param) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            input: {
                                value: { data: param },
                                type: `${camelCreateName}Input`,
                            },
                        },
                        fields: metaData?.fields ?? [
                            {
                                operation: singularResource,
                                fields: ["id"],
                                variables: {},
                            },
                        ],
                    });
                    const result: any = await client.request(query, gqlVariables);

                    return result[operation][singularResource];
                }),
            );
            return {
                data: response,
            };
        },
        update: async ({ resource, id, variables, metaData }) => {
            const singularResource = pluralize.singular(resource);
            const camelUpdateName = camelCase(`update-${singularResource}`);

            const operation = metaData?.operation ?? camelUpdateName;

            const { query, variables: gqlVariables } = gql.mutation({
                operation,
                variables: {
                    input: {
                        value: { where: { id }, data: variables },
                        type: `${camelUpdateName}Input`,
                    },
                },
                fields: metaData?.fields ?? [
                    {
                        operation: singularResource,
                        fields: ["id"],
                        variables: {},
                    },
                ],
            });
            const response: any = await client.request(query, gqlVariables);

            return {
                data: response[operation][singularResource],
            };
        },
        updateMany: async ({ resource, ids, variables, metaData }) => {
            const singularResource = pluralize.singular(resource);
            const camelUpdateName = camelCase(`update-${singularResource}`);

            const operation = metaData?.operation ?? camelUpdateName;

            const response = await Promise.all(
                ids.map(async (id) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            input: {
                                value: { where: { id }, data: variables },
                                type: `${camelUpdateName}Input`,
                            },
                        },
                        fields: metaData?.fields ?? [
                            {
                                operation: singularResource,
                                fields: ["id"],
                                variables: {},
                            },
                        ],
                    });
                    const result: any = await client.request(query, gqlVariables);

                    return result[operation][singularResource];
                }),
            );
            return {
                data: response,
            };
        },

        getOne: async ({ resource, id, metaData, }) => {

            const current = 1, pageSize = 10

            let apiParams: any = {
                ...metaData,
                limit: metaData?.limit || pageSize,
                page: metaData?.current || current,
                pagination: {
                    page: metaData?.current || current,
                    limit: metaData?.limit || pageSize,
                }
            }

            if (resource === 'getFeeStatementsWithinDateRange') {
                const currentDate = new Date()
                const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                apiParams = {
                    ...apiParams,
                    startDate: firstDayOfPreviousMonth,
                    endDate: currentDate
                }
            }
            let response
            if (metaData?.isMutation) {
                response = await clients.mutate({
                    mutation: GQLQureies[resource],
                    variables: apiParams,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            } else {
                response = await clients.query({
                    query: GQLQureies[resource],
                    errorPolicy: 'all',
                    variables: apiParams,
                    fetchPolicy: 'network-only',
                });
            }
            if (response.data === null) {
                return {
                    data: null,
                    error: response?.errors,
                };
            }
            if (!response.data[resource]) {
                return {
                    data: response.data[resource],
                };
            }
            if (response.data[resource] === null) {
                return {
                    data: {},
                };
            }
            if (resource === "getExpenseByIdForAdmin" && response.data[resource]) {
                return {
                    data: response.data[resource]
                };
            }
            if (resource === 'getCompaniesForManagementPortal') {
                return {
                    data: response.data[resource]?.company?.[0]
                };
            }
            if (resource === 'getLatestUserEkySessionForSuperAdmin') {
                return response.data[resource]
            }
            if (resource === 'getLimitsSpecifiedInCardIssuanceRequest') {
                return response.data[resource]
            }
            if (response.data[resource].length) {
                if (resource === 'getCityByState') {
                    let cityList: any = []
                    response.data[resource].map((ele: any) => {
                        const obj = {
                            value: ele.id,
                            label: ele.name,
                        }
                        cityList.push(obj)
                        return ele
                    })
                    return {
                        data: cityList,
                    }
                }
                return {
                    data: response.data[resource],
                    error: false,
                }
            } else if (response.data[resource].company) {
                return {
                    data: response.data[resource].company,
                    error: false,
                }
            } else if (response.data[resource].user) {
                return {
                    data: response.data[resource].user,
                    error: false,
                };
            } else {
                if (response.data[resource].items) {
                    return {
                        data: response.data[resource].items || [],
                        error: false,
                    };
                } else {
                    return {
                        data: response.data[resource] || {},
                    };
                }
            }
        },

        deleteOne: async ({ resource, id, metaData }) => {
            const singularResource = pluralize.singular(resource);
            const camelDeleteName = camelCase(`delete-${singularResource}`);

            const operation = metaData?.operation ?? camelDeleteName;

            const { query, variables } = gql.mutation({
                operation,
                variables: {
                    input: {
                        value: { where: { id } },
                        type: `${camelDeleteName}Input`,
                    },
                },
                fields: metaData?.fields ?? [
                    {
                        operation: singularResource,
                        fields: ["id"],
                        variables: {},
                    },
                ],
            });

            const response: any = await client.request(query, variables);

            return {
                data: response[operation][singularResource],
            };
        },

        deleteMany: async ({ resource, ids, metaData }) => {
            const singularResource = pluralize.singular(resource);
            const camelDeleteName = camelCase(`delete-${singularResource}`);

            const operation = metaData?.operation ?? camelDeleteName;

            const response = await Promise.all(
                ids.map(async (id) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            input: {
                                value: { where: { id } },
                                type: `${camelDeleteName}Input`,
                            },
                        },
                        fields: metaData?.fields ?? [
                            {
                                operation: singularResource,
                                fields: ["id"],
                                variables: {},
                            },
                        ],
                    });
                    const result: any = await client.request(query, gqlVariables);

                    return result[operation][singularResource];
                }),
            );
            return {
                data: response,
            };
        },

        getApiUrl: () => {
            throw Error("Not implemented on refine-graphql data provider.");
        },

        custom: async ({ url, method, headers, metaData }) => {
            let response: any
            if (!metaData) {
                response = await clients.query({
                    query: GQLQureies[url],
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
            } else {
                if (url === 'changeProductsBundleIssuanceToNewCardType') {
                    if (!metaData.pbiId) {
                        return ''
                    }
                }
                if (url === 'acceptPendingCreditNotificationTransaction') {
                    if (!metaData.parentTxnId) {
                        return ''
                    }
                }
                response = await clients.mutate({
                    // query: GQLQureies[url],
                    mutation: GQLQureies[url],
                    variables: metaData,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                });
                if (response?.errors?.length > 0) {
                    sessionStorage.setItem('notification', JSON.stringify(response.errors))
                    // throw Error(
                    //     response.errors[0].message,
                    // );
                    // HandleError(response)
                }
            }

            // const response = await client.request(query, variables);

            return response.data[url]
        },
    };
};
// export const HandleError = (response?: any) => {
//     const { open } = useNotification();

//     open?.({
//         type: "error",
//         message: response.errors[0].message,
//         // description: "I <3 Refine",
//         key: "url-345",
//     });
// }
export default dataProvider;
