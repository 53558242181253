import React, { useEffect } from "react";
import {
     NumberField, Space, Table, Tag, useTable,
} from '@pankod/refine-antd';
import { DebitMethod } from "../../../interfaces/company";
import { IRefund } from "../../../interfaces/refund";

interface BankMandateOverviewProps {
    totalBankMandateCount: any
}

export const BankMandateOverview: React.FC<BankMandateOverviewProps> = ({ totalBankMandateCount }: BankMandateOverviewProps) => {
    let { tableProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'repaymentAutoDebitPaginated',
        metaData: {
            limit: 5,
        }
    });
    useEffect(() => {
        const totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 0
        totalBankMandateCount(totalSize)
    })
    return <Table size="small" {...tableProps} rowKey="id">
        <Table.Column title="Mandate Details" render={(_, record: any) =>
            <Space align='baseline' direction="vertical">
                {record?.rzpInvoiceId}
                <a href={record?.rzpMandateRegUrl} target="_blank" rel="noreferrer"> <Space align='baseline' direction="horizontal" onClick={() => { navigator.clipboard.writeText(record?.rzpMandateRegUrl) }}>
                    <small>{record?.rzpMandateRegUrl}</small>
                </Space> </a>
                <strong>
                    Rs.
                    <NumberField
                        value={record?.mandateMaxAmountPerCharge || 0}
                        options={{
                            notation: "standard",
                        }}
                    />
                </strong>
            </Space>
        } />
        <Table.Column
            title="Company"
            dataIndex="company"
            key="company"
            render={(_, record: any) => (
                <Space align='baseline' direction="vertical">
                    {
                        (record?.company) ? <>
                            <a href={'/company/show/' + record?.company?.id}> {record?.company?.registeredName}</a>
                            {record?.rzpCustomerId}
                        </> : ''
                    }
                </Space>
            )}
        />
        <Table.Column dataIndex="Registration Status" title="Status"
            render={(_, record: any) => {
                let color;
                switch (record?.method) {
                    case DebitMethod.EMANDATE_CAP:
                        color = "geekblue";
                        break;
                    case DebitMethod.NACH:
                        color = "gold";
                        break;
                    default:
                        color = "";
                        break;
                }
                return <Space align='baseline' direction="vertical">
                    <Tag color={color}>{record?.method}</Tag>
                    {record?.status.replaceAll("_", " ")}
                </Space>
            }} />
    </Table>
}