import React, { useState, useEffect, useRef } from "react";
// import { useLogin } from "@pankod/refine-core";
import axios from "axios";

import {
    Row,
    Col,
    AntdLayout,
    Alert,
} from "@pankod/refine-antd";
import "./style.css"
import { kodoLogo } from "../../../style";
import { from, InMemoryCache, createHttpLink, ApolloClient } from '@apollo/client';
import { AppNameForAuthContext } from "../login-password";
import { AppConfig } from "../../../app.config";
import { useLogin } from "@pankod/refine-core";


export interface ILoginForm {
    emailId: string;
    passcode: string;
    gsmNumber: string;
    code: string;
}


const clientId = process.env.REACT_APP_CLIENTID
const API_URL = process.env.REACT_APP_URL;
let windowIn: any = window
const httpLink = createHttpLink(
    {
        uri: `${API_URL}/graphql`,
        credentials: 'same-origin'
    }
);

export const clients = new ApolloClient(
    {
        link: from([httpLink]),
        cache: new InMemoryCache()
    }
);

export const LoginGoogle: React.FC = () => {
    // const [loading, setLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    const GoogleButton = (): JSX.Element => {
        const divRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (
                typeof windowIn === "undefined" ||
                !windowIn?.google ||
                !divRef.current
            ) {
                return;
            }

            try {
                windowIn.google.accounts.id.initialize({
                    ux_mode: "popup",
                    client_id: clientId,
                    callback: async (res: any) => {
                        if (res.credential) {
                            let loginData = {
                                gIdToken: res.credential,
                                appName: AppNameForAuthContext.KODO_KONSOLE,
                            };
                            const API_URL = process.env.REACT_APP_URL;

                            axios.post(`${API_URL}/user/internal-portal-login`, loginData)
                                .then((response: any) => {
                                    sessionStorage.setItem(AppConfig.apiTokenKey, response.data?.loginToken);
                                    sessionStorage.setItem(AppConfig.refreshTokenKey, response.data?.refreshToken);

                                    sessionStorage.setItem('userName', response.data?.user?.username);
                                    sessionStorage.setItem('fullname', response.data?.user?.fullname);
                                    sessionStorage.setItem('emailId', response.data?.user?.email);
                                    sessionStorage.setItem('assignedRole', btoa(JSON.stringify(response.data?.user?.role)));
                                    sessionStorage.setItem('roleName', btoa(JSON.stringify(response.data?.user?.roleName)));
                                    login(response);
                                })
                                .catch((error) => {
                                    setShowError(true);
                                    setErrorMsg('User not Found')
                                    // setLoading(false);
                                });

                        }
                    },
                    auto_select: true,
                });
                windowIn.google.accounts.id.prompt()
                windowIn.google.accounts.id.renderButton(divRef.current, {
                    // theme: "filled_blue",
                    theme: "outline",
                    size: "medium",
                    type: "standard",
                });
            } catch (error) {
                console.log(error);
            }
        }, [clientId, windowIn.google, divRef.current]);

        return <div ref={divRef} />;
    };

    const renderGSMForm = () => (
        <div>
            <div className="invalid-credentials-container">
                {
                    showError &&
                    <Alert type="error"
                        showIcon={false}
                        message="Login Failed"
                        description={errorMsg}
                        closable
                    />
                }
            </div>
            <GoogleButton />
        </div>
    );

    return (
        <AntdLayout
            style={{
                background: `radial-gradient(50% 50% at 50% 50%, #63386A 0%, #310438 100%)`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    {[
                        <h2 style={kodoLogo}>KODO KONSOLE</h2>,
                        renderGSMForm()
                    ].map(it => <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "28px",
                    }}>{it}</div>)}
                </Col>
            </Row>
        </AntdLayout>
    );
};
