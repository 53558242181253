import React from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
    Select,
} from "@pankod/refine-antd";
const { TextArea } = Input;


export const UpdateCircuitBreakerStateModel: React.FC<{}> = ({ }) => {


    const { modalProps: updateCircuitBreakerModel, formProps: updateCircuitBreakerForm, show: updateCircuitBreakerShow } = useModalForm({
        dataProviderName: "grapghQl",
        resource: "updateCircuitBreakerState",
        action: "create",
        metaData: {},
        redirect: false,
        onMutationSuccess() {

        }
    });
    updateCircuitBreakerModel.title = 'Update Circuit Breaker State'
    updateCircuitBreakerModel.centered = true
    updateCircuitBreakerModel.width = 400

    return (
        <Space>
            {
                <Button onClick={() => { updateCircuitBreakerShow() }} type="default">
                    Update Circuit Breaker State
                </Button>
            }

            <Modal {...updateCircuitBreakerModel}>

                <Form {...updateCircuitBreakerForm} layout="vertical">
                    <Form.Item
                        label="OPR Id"
                        name={"oprId"}
                        rules={[
                            {
                                message: "Please input OPR Id!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="New State"
                        name={"newState"}
                        rules={[
                            {
                                message: "Please select New State!",
                                required: true,
                            }
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: "OPEN",
                                    value: "OPEN",
                                },
                                {
                                    label: "CLOSED",
                                    value: "CLOSED",
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: "Please input Last Update Notes!",
                            required: true,

                        }
                    ]}
                        label="Last Update Notes" name="lastUpdateNotes">
                        <TextArea rows={4} placeholder="Last Update Notes" />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};