import { gql } from '@apollo/client';

export const companyLeadsForManagementGQL = gql`
query companyLeadsForManagement(
  $page: Int!,
  $limit: Int!
) {
  companyLeadsForManagement(
    pagination: {page: $page, limit: $limit}
  ) {
    items {
      id
      authorizedCapital
      email
      legalName
      efilingStatus
      incorporationDate
      addressLine1
      addressLine2
      state
      city
      pincode
      gstNo
      serviceTaxNo
      vatTinNo
      phoneNo
      cin
      borrowerCategory
      acceptCreditLimit
      isManualEntry
    },
 
    meta {
      totalItems
    }
  }
}
`;

export const promoteCompanyDataToCompanyWithBorrowerCategoryGQL = gql`
  mutation promoteCompanyDataToCompanyWithBorrowerCategory($companyDataId: String!, $borrowerCategory: BorrowerCategory!) {
    promoteCompanyDataToCompanyWithBorrowerCategory(input: {companyDataId: $companyDataId, borrowerCategory: $borrowerCategory})
  }
`;

export const getOnboardCompanyGQL = gql`
  query  getOnboardCompany($filter: OnboardCompanyFilter) {
    getOnboardCompany(filter: $filter) {
      company {
        id
        cin
        email
        onboardStatus
        legalName
        directors {
          id
          isSelected
          dob
          name
          firstName
          middleName
          lastName
          isEdited
        }
      }
    }
  }
`;