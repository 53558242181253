export interface IAppIntegration {
    id: string;
    totalInvoiceAmount: number,
    finalAmountToPay: number,
    processingFeePercent: number,
    isActive: boolean,
    gstPercent: number,
    paymentStatus: string;
    utr: string;
    startDate: string;
    integrationApp: string;
    endDate: string;
    createdAt: string;
    integrationSettlementStatementRequestId: string;
    integrationSettlementStatementRequest: IIntegrationSettlementStatementRequest;
    integrationId: string;
    isDeleted: false,
    createdById: string;
    updatedAt: string;
    updatedById: string;
    lastUpdateNotes: string;
}
export interface IIntegrationSettlementStatementRequest {
    id: string;
    referenceIdSharedWithBank: string,
    utr: string,
}
export interface ISettlementStatement {
    id: string;
    integrationSettlementStatementId: string,
    transaction: ITransaction,
}
export interface ITransaction {
    id: string;
    transactionAmount: string,
    transactionCategory: string,
    transactionStatus: string,
    txnDate: string,
}

export enum transactionStatus {
    SUCESS = 'SUCESS',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    REPLACED = 'REPLACED',
    WAIVED = 'WAIVED',
}
export enum paymentStatus {
    PROCESSING = 'PROCESSING',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    PAID = 'PAID',
}

export enum transactionType { // FIXME: replace all usages to `LedgerEntryType`
    CREDIT = 'Cr',
    DEBIT = 'Dr',
}

export enum SettlementEventType {
    CARD_TRANSACTION_FEE = 'CARD_TRANSACTION_FEE',
    KODO_VA_FEE = 'KODO_VA_FEE',
    CARD_ISSUANCE_FEE = 'CARD_ISSUANCE_FEE',
    CARD_REPLACEMENT_FEE = 'CARD_REPLACEMENT_FEE',
}
export enum SettlementPartner {
    EASEBUZZ = 'EASEBUZZ', // for eb VA
    M2P = 'M2P', // for cards
    DECENTRO = 'DECENTRO' // for decentro VA
}

export interface ActiveFeeStatements {
    id: string;
    eventType: SettlementEventType;
    statementAmount: number;
    month: string;
    startDate: string;
    endDate: string;
    referenceIdSharedWithBank: string;
    isActive: boolean;
    isDeleted: boolean;
}
export interface SettlementBankAccountsList {
    id: string;
    accountNumber: string;
    accountHolderName: string;
    bankName: string;
    ifsc: string;
    email: string;
    mobileNo: string;
}
export enum FundSettlementStatus {
    SETTLED = 'SETTLED', // Completed successfully
    FAILED = 'FAILED', // Settlement failed
    CREATED = 'CREATED', // Settlement record is created
    INITIATED = 'INITIATED', // Settlement is initiated with 3rd party API call
}