export const setQueryParam = (key: string, value: string): void => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState({}, '', url.toString());
}
export const deleteQueryParam = (key: string): void => {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.replaceState({}, '', url.toString());
}

export const getQueryParam = (key: string): string => {
    const url = new URL(window.location.href);
    return url.searchParams.get(key) || '';
}