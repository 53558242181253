import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import React from "react";

import {
    Show,
    Table,
    Space,
    Button,
    DateField,
    useTable,
    ExportButton
} from "@pankod/refine-antd";

import { urlPath } from "../../utility/url";

export const NpaReportShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    // const { open } = useNotification();
    let pram: any = url.params
    const pageTitle: string = (pram && pram.get('uploadedFileName')) ? `Daily NPA Report - ( ${pram.get('uploadedFileName')} )` : 'Daily NPA Report'
    // Daily NPA Reports

    let { tableProps } = useTable<any>({
        dataProviderName: "grapghQl",
        resource: 'entriesInDailyNpaReportsSbm',
        metaData: {
            reportId: url.lastElement,
        },
        // onSearch: (params: any) => {
        //     const filters: CrudFilters = [];
        //     let { q, createdAt, mcc, fromAmount, toAmount } = params;

        //     toAmount = (toAmount) ? toAmount : 1000000
        //     fromAmount = (fromAmount) ? fromAmount : 0
        //     setSearchKey(q)
        //     setMcc(mcc)
        //     setfromAmount(fromAmount)
        //     settoAmount(toAmount)
        //     setfromDate(createdAt ? createdAt[0].toISOString().split('T')[0] : undefined,)
        //     settoDate(createdAt ? createdAt[1].toISOString().split('T')[0] : undefined,)
        //     return filters;
        // },
    });

    let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<any>({
        dataProviderName: "grapghQl",
        resourceName: 'entriesInDailyNpaReportsSbm',
        metaData: {
            reportId: url.lastElement,
            limit: totalSize,
            isExport: true
        }
    });

    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    return (
        <Show title={pageTitle} isLoading={(tableProps?.dataSource && tableProps?.dataSource?.length) ? false : true} headerButtons={({ defaultButtons }) => (
            <>
                <ExportButton
                    onClick={triggerExport}
                    loading={exportLoading}
                />
                {/* {defaultButtons} */}
                {/* <RefreshButton resource="getCompaniesForManagementPortal" dataProviderName="grapghQl"/> */}

            </>
        )}>

            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex='company'
                    title="Company"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.registeredName || value?.brandName}
                            </h4>
                            <p>
                                {value?.companyCustomerId || '-'}
                            </p>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex="cardIssuingDate"
                    title="Card Issuing Date"
                    key="cardIssuingDate"
                    render={(value) => value ? <DateField value={value} format="DD-MM-YYYY" /> : <></>}
                />


                <Table.Column
                    dataIndex='sbmFeedback'
                    title="Provision Previous Day"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            {
                                (value?.provision?.previousDay) ? <h4>
                                    Rs. {value?.provision?.previousDay}
                                </h4> : <h4>-</h4>
                            }
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="DPD"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.dpd}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="Current Asset Class"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.assetClassification?.currentAssetClassCode}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="Sub Asset Class"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.assetClassification?.currentSubAssetClassCode}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="NPA Date"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.assetClassification?.npaDate}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="Revised Main Asset Class"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.assetClassification?.revisedMainAssetClassCode}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='sbmFeedback'
                    title="Revised Sub Asset Class"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <h4>
                                {value?.assetClassification?.revisedSubAssetClassCode}
                            </h4>
                        </Space>
                    }
                />
                <Table.Column<any>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (

                        <Space>
                            {
                                <Button type="default" href={`/report-show/${url.lastElement}?company=${record.company.id}`} >
                                    Show Details
                                </Button>
                            }
                        </Space>
                    )}
                />
            </Table>
            {
                (tableProps?.dataSource && tableProps?.pagination) ? <span>
                    Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                </span> : ''
            }
        </Show>
    );
};
