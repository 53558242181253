import { gql } from '@apollo/client';

export const syncDelegatedAccessBankAccountFundTransferForOprGQL = gql`
mutation syncDelegatedAccessBankAccountFundTransferForOpr(
  $urn: String!
  $oprId: String!
) {
  syncDelegatedAccessBankAccountFundTransferForOpr(
    urn: $urn
    oprId: $oprId
  ) 
}
`;

export const syncTransparentAccessBankAccountFundTransferForOprGQL = gql`
mutation syncTransparentAccessBankAccountFundTransferForOpr(
  $uniqueRequestNo: String! 
  $oprId: String!
  $fixMissingAuth: Boolean
  $markFailedIfURNMissingAtIntegratorEnd: Boolean
) {
  syncTransparentAccessBankAccountFundTransferForOpr(
    fixMissingAuth: $fixMissingAuth
    oprId: $oprId
    uniqueRequestNo: $uniqueRequestNo
    markFailedIfURNMissingAtIntegratorEnd: $markFailedIfURNMissingAtIntegratorEnd
  ) 
}
`;

export const updateCircuitBreakerStateGQL = gql`
mutation updateCircuitBreakerState(
  $newState:  CircuitBreakerStateOption!
  $oprId: String!
  $lastUpdateNotes: String!
) {
  updateCircuitBreakerState(
    input:{
      oprId: $oprId
      newState:  $newState
      lastUpdateNotes: $lastUpdateNotes
    }
  ) 
}
`;

export const getCompanyExpensesForAdminGQL = gql`
  query getCompanyExpensesForAdmin($filter:ExpenseFiltersForAdmin) {
    getCompanyExpensesForAdmin(filter:$filter)
    {
      page
      total
      expense{
        id
        txnDate
        createdAt
        user{
          id
          fullName
          displayName
          middleName
          firstName
          lastName
        }
        company{
          id
          registeredName
        }
        merchantName
        transactionCategory
        narration
        amount
        transactionAmount
        pbiLedgerEntryType
        transactionReceipt{
          id
        }
        transactionReceiptCount
        expenseCategory{
          id
        }
        companyWideTagMappings{
          id
          tag{
            id
            name

          }
        }
      }
    }
  }
`;

export const getExpenseByIdForAdminGQL = gql`
  query getExpenseByIdForAdmin($id: String) {
    getExpenseByIdForAdmin(id: $id)
    {
    id
    company{
      id
      registeredName
    }
    createdAt
    narration
    amount
    transactionAmount
    pbiLedgerEntryType
    merchantName
    user{
      id
      firstName
      middleName
      lastName
      fullName
      displayName
    }
    transactionCategory
    transactionReceipt{
      receiptImage
      isPdf
      id
      url
    }
    cardholderNotes
    companyWideTagMappings{
     id
     tag{
        id
        name
     }
    }
  }
  }
`;

export const KodoPayGQL = {
  getCompanyExpensesForAdmin: getCompanyExpensesForAdminGQL,
  getExpenseByIdForAdmin: getExpenseByIdForAdminGQL,
  updateCircuitBreakerState: updateCircuitBreakerStateGQL,
  syncTransparentAccessBankAccountFundTransferForOpr: syncTransparentAccessBankAccountFundTransferForOprGQL,
  syncDelegatedAccessBankAccountFundTransferForOpr: syncDelegatedAccessBankAccountFundTransferForOprGQL,
}