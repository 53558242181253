
export const urlPath = (): { url: string, pathName: string, lastElement: string, params: Object } => {

    const url: string = window.location.href.replaceAll('%3F', '?').replaceAll('%3D', '=')
    const urlSplit: string[] = url.split("?")
    const pathName: string[] = window.location.pathname.replaceAll('%3F', '?').replaceAll('%3D', '=').split("?")[0].split("/")
    const params: any = (urlSplit[1]) ? new URLSearchParams(urlSplit[1]) : null

    const id: string = pathName.pop() || ''
    return {
        url: window.location.href,
        pathName: window.location.pathname,
        lastElement: id,
        params
    }
};
