import { Card, Col, Row } from "@pankod/refine-antd"
import { DescriptionItem } from "components/text-layout/descriptionItem.text"
import { ICompany } from "interfaces"
import { PricingItem, ProductFeatureFeeConfig, ProductFeatureFeeCompanyConfig } from "interfaces/company"
import { EditCompanyFeeModel } from "./models/editCompanyFee.model"
import { useOne } from "@pankod/refine-core"
import { useEffect, useState } from "react"

interface FeeDetailsProps {
    cardTitle: string
    refetchCompanyDetail: any
    companyId: string | undefined
    companyDetail: ICompany | undefined
}

export const FeeDetailsComponent: React.FC<FeeDetailsProps> = ({ cardTitle, refetchCompanyDetail, companyId, companyDetail }) => {
    let [refreshCompanyFeeData, setRefreshCompanyFeeData] = useState(false);


    //get ProductFeatureFeeConfig details graphql
    let { data: productFeatureData, isLoading: productFeatureLoading } = useOne<ProductFeatureFeeConfig[]>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeConfig',
        id: '',
        metaData: {
            pricingItem: PricingItem.KODO_VA_TXN_EXCLUDE_LV,
        },
    });
    //get company FeeConfig details graphql
    let { data: companyProductFeatureFeeData, isLoading: companyProductFeatureFeeLoading, refetch: refetchCompanyProductFeatureData } = useOne<ProductFeatureFeeCompanyConfig>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeCompanyConfig',
        id: '',
        metaData: {
            input: {
                companyId: companyId,
                pricingItem: PricingItem.KODO_VA_TXN_EXCLUDE_LV,
            }
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshCompanyFeeData(false);
            },
        },
    });
    const refetchCompanyFeeDetails = (message: boolean) => {
        setRefreshCompanyFeeData(message)
    };
    useEffect(() => {
        if (refreshCompanyFeeData) {
            refetchCompanyProductFeatureData();
        }
    }, [refreshCompanyFeeData]);
    return (
        <Card size="small" title={cardTitle} extra={[
            !companyProductFeatureFeeLoading && <EditCompanyFeeModel
                companyDetail={companyDetail}
                companyId={companyId}
                productFeature={productFeatureData?.data[0] || {} as ProductFeatureFeeConfig}
                companyProductFeature={(companyProductFeatureFeeData?.data) ? companyProductFeatureFeeData?.data : {} as ProductFeatureFeeCompanyConfig}
                refetchCompanyFeeDetails={refetchCompanyFeeDetails}
                refetchCompanyDetail={refetchCompanyDetail}
            />
        ]} className="h-100">
            {
                (!companyProductFeatureFeeLoading && companyProductFeatureFeeData?.data === null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Fee (%)" content={
                                `${productFeatureData?.data[0]?.priceListing?.chargeCalc.seedValue}%` || '-'
                            } />
                        </Col>
                        <Col lg={24}>
                            <DescriptionItem title="Maximum Fee Charged" content={
                                `₹${productFeatureData?.data[0].priceListing?.chargeCalc.upperCap} (on Transaction > ₹2000)` || '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {
                (!companyProductFeatureFeeLoading && companyProductFeatureFeeData?.data !== null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Fee (%)" content={
                                (companyProductFeatureFeeData?.data &&
                                    !companyProductFeatureFeeData?.data?.productFeatureFeeExclusion) ?
                                    `${companyProductFeatureFeeData?.data?.priceListing?.chargeCalc.seedValue}%` :
                                    '-'
                            } />
                        </Col>
                        <Col lg={24}>
                            <DescriptionItem title="Maximum Fee Charged" content={
                                (companyProductFeatureFeeData?.data &&
                                    !companyProductFeatureFeeData?.data?.productFeatureFeeExclusion) ?
                                    `₹${companyProductFeatureFeeData?.data?.priceListing?.chargeCalc.upperCap} (on Transaction> ₹2000)` : '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {
                companyProductFeatureFeeLoading && <Col lg={24}>
                    Loading
                </Col>
            }

        </Card>
    )
}