
import { gql } from '@apollo/client';

export const dailyNpaReportsSbmGQL = gql`
  query dailyNpaReportsSbm(
    $pagination: PaginationOptions!
  ) {
    dailyNpaReportsSbm(
      pagination: $pagination
    ) {
        items{
            id
            reportDate
            uploadedToSftpAt
            uploadedFileName
        },
        meta {
            totalItems
        }
    }
     
  }
`;
export const entriesInDailyNpaReportsSbmGQL = gql`
  query entriesInDailyNpaReportsSbm(
    $reportId: String!,
    $pagination: PaginationOptions!
  ) {
    entriesInDailyNpaReportsSbm(
      reportId: $reportId,
      pagination: $pagination
    ) {
        items{
            company {
                brandName
                nameOnCard
                registeredName
                id
                companyCustomerId
                __typename @skip(if: true)
            }
            report {
                id
                reportDate
                uploadedToSftpAt
                uploadedFileName
                __typename @skip(if: true)
            }
            cardIssuingDate
            dpd
            sbmFeedback {
                assetClassification {
                    currentAssetClassCode
                    currentSubAssetClassCode
                    revisedMainAssetClassCode
                    revisedSubAssetClassCode
                    npaDate
                    __typename @skip(if: true)
                }
                dpd
                provision {
                    previousDay
                    asOnDate
                    __typename @skip(if: true)
                }
                __typename @skip(if: true)
            }
            __typename @skip(if: true)
        },
        meta {
            totalItems
        }
    }
     
  }
`;
