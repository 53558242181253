import React from "react";

import { useModalForm, Modal, Space, Form, message } from "@pankod/refine-antd";

export const BlockCardModel: React.FC<{ refetchUsers: any, userCard: any }> = ({ userCard, refetchUsers }) => {

    let { modalProps: BlockCardConfirmModel, formProps: BlockCardConfirmForm, show: BlockCardConfirmShow } = useModalForm<any>({
        resource: `blockCardBySuperAdmin`,
        dataProviderName: "grapghQl",
        action: "create",
        successNotification: false,
        metaData: { cardId: userCard?.id },
        redirect: false,
        onMutationSuccess(data) {
            if (data?.data === false){
                message.error('Unable to Block Card');
            }else {
                refetchUsers(true)
            }
        }
    });
    BlockCardConfirmModel.title = "Block Card"
    BlockCardConfirmModel.centered = true
    BlockCardConfirmModel.width = 400

    return (
        <Space >
            <Space onClick={() => { BlockCardConfirmShow() }}>
                Block Card
            </Space>

            <Modal {...BlockCardConfirmModel}>
                <h4>Are you sure you want to Block the card? </h4>
                <Form {...BlockCardConfirmForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
        </Space>
    );
};