import React from "react";

import { useModalForm, Modal, Space, Form, Button, Input } from "@pankod/refine-antd";

import { ICompany } from "../../../../interfaces/company";
const { TextArea } = Input;

interface reactivateUserProp {
    refetchCompanyDetail: any,
    refetchInactiveUserList: any,
    userAccountId: string,
    companyId: string,
    status: string
    reActivateAccount: boolean
}
export const ReactivateUserModel: React.FC<reactivateUserProp> = ({ reActivateAccount, companyId, refetchCompanyDetail, refetchInactiveUserList, userAccountId, status }) => {

    //Add Reactive User Model
    let { modalProps: reactiveUserModel, formProps: reactiveUserForm, show: reactiveUserShow, close: reactiveUserClose } = useModalForm<ICompany>({
        dataProviderName: reActivateAccount ? "grapghQl" : 'default',
        resource: reActivateAccount ? "enableUserForCompany" : "user-account/internal-portal/activate-deactive-user-account",
        action: "create",
        metaData: {
            companyId: companyId,
            userAccountId: userAccountId,
            status: status,
        },
        redirect: false,
        onMutationSuccess() {
            refetchInactiveUserList(true)
            refetchCompanyDetail(true)
            reactiveUserClose();
        }
    });
    reactiveUserModel.title = 'Reactive User'
    reactiveUserModel.centered = true
    reactiveUserModel.width = 400
    reactiveUserModel.okText = 'Re-activate'
    reactiveUserModel.onCancel = () => {
        reactiveUserForm.form?.resetFields();
        reactiveUserClose();
    }
    return (
        <Space >
            <Button type="link" onClick={() => { reactiveUserShow() }} >
                Re-Activate
            </Button>

            <Modal {...reactiveUserModel}>
                <h4>Are you sure you want to re-activate the account</h4>
                <Form {...reactiveUserForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="Re Activate" name="notes">
                        <TextArea rows={4} placeholder={"Reasons To Reactivate"} />
                    </Form.Item>
                    {
                        !reActivateAccount && <Form.Item rules={[
                            {
                                message: 'Password is required',
                                required: true,
                            }
                        ]} label="Password" name="passcode">
                            <Input.Password placeholder="input password" />
                        </Form.Item>
                    }
                </Form>
            </Modal>

        </Space>
    );
};