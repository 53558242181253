import { AppConfig } from "../app.config";
import jwt_decode from "jwt-decode";

export const getAccessToken = (): string  => {
    return sessionStorage.getItem(AppConfig.apiTokenKey) || '';
};
export const removeAccessToken = (): void => {
    sessionStorage.removeItem(AppConfig.apiTokenKey);
};
export const isTokenExpiringSoon = (data: { minute: number }): boolean => {
    const token: string = getAccessToken();
    if (!token) {
        return true;
    }
    const decodedToken: any = jwt_decode(token);
    if (!decodedToken.acc) {
        return true;
    }
    if (typeof decodedToken.exp === 'number') {
        return (decodedToken.exp * 1000 - Date.now()) < data.minute * 60 * 1000;
    } else {
        return false;
    }
};
export const getTokenExpireTime = (): number => {
    const token: string = getAccessToken();
    if (!token) {
        return 0;
    }
    const decodedToken: any = jwt_decode(token);
    if (typeof decodedToken.exp === 'number') {
        return decodedToken.exp * 1000
    }
    return 0;
};
export const isTokenExpired = (token: string = getAccessToken()): boolean => {
    if (!token) {
        return true;
    }
    const decodedToken: any = jwt_decode(token);
    if (!decodedToken.acc) {
        return true;
    }
    if (typeof decodedToken.exp === 'number') {
        return Date.now() > decodedToken.exp * 1000;
    } else {
        return false;
    }
};