import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { ITransaction, TransactionCategory, TransactionsReceipt } from "../../interfaces/transaction";
import React, { useState } from 'react';

import '../../style.css';
import {
    Space,
    Card,
    Row,
    Col,
    Show,
    Typography,
    Table,
    ImageField,
    FileField,
} from "@pankod/refine-antd";
import { urlPath } from "utility/url";
import { TxnReversalModel } from "./components/txn-reversal.modal";
import { Role } from "utility/role";
const { Title, Text } = Typography;

export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    let role = Role()

    const { data: transactionData, refetch: refetchTransactionDetail } = useOne<ITransaction>({
        dataProviderName: "grapghQl",
        resource: "getExpenseByIdForAdmin",
        id: "",
        metaData: {
            id: url.lastElement,
        },
    });

    const transactionDetail = transactionData?.data;
    const { fullName, lastName, firstName, middleName, displayName } = transactionDetail?.user ?? {};


    return (
        <Show headerButtons={({ defaultButtons }) => (
            <>
                {/* {defaultButtons} */}
                {
                    role.isAdmin
                    && transactionDetail
                    && <TxnReversalModel txnId={transactionDetail?.id} />
                }
            </>
        )}>
            <Row gutter={[11, 11]}>
                <Col md={14} lg={14}>
                    <Card>
                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>ID</Title>
                            <Text>{transactionDetail?.id || '-'}</Text>
                        </Space>

                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Company Name</Title>
                            <Text>{transactionDetail?.company?.registeredName || '-'}</Text>
                        </Space>
                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Cardholder's Name</Title>
                            <Text>
                                {fullName || displayName || [firstName, middleName, lastName].filter(Boolean).join(' ')}
                            </Text>
                        </Space>

                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Date</Title>
                            <Text>
                                {transactionDetail?.createdAt || '-'}
                            </Text>
                        </Space>
                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Merchant Name</Title>
                            <Text>
                                {transactionDetail?.merchantName || transactionDetail?.narration || '-'}
                            </Text>
                        </Space>
                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Transaction Amount</Title>
                            <Text>
                                {transactionDetail?.transactionAmount || "-"}
                            </Text>
                        </Space>

                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Reciepts</Title>
                            <Text>
                                {transactionDetail?.transactionReceipt?.length || "-"}
                            </Text>
                        </Space>

                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Category</Title>
                            <Text>
                                {transactionDetail?.transactionCategory || "-"}
                            </Text>
                        </Space>

                        <Space direction="horizontal" size="middle" className="card-row ">
                            <Title level={5}>Notes</Title>
                            <Text>
                                {transactionDetail?.cardholderNotes || "-"}
                            </Text>
                        </Space>
                    </Card>
                </Col>
                <Col md={10} lg={10}>
                    <Table
                        title={() => 'Attach Reciepts'}
                        rowKey="id"
                        dataSource={transactionDetail?.transactionReceipt}
                    >
                        <Table.Column
                            title="Image Name"
                            render={(_, record: TransactionsReceipt) => (
                                <Space align="baseline" direction="vertical">
                                    <p>{record?.receiptImage}</p>
                                </Space>
                            )}
                        />
                        <Table.Column
                            title="File"
                            render={(_, record: TransactionsReceipt) =>
                                !record.isPdf ? (
                                    <ImageField
                                        value={record.url}
                                        title={record.receiptImage}
                                        width={100}
                                    />
                                ) : (
                                    <FileField src={record.url} title={"Download PDF"} />
                                )
                            }
                        />
                    </Table>

                </Col>
            </Row>
        </Show>
    );
};