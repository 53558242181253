export const Role = () => {

    const role: string = window.atob(sessionStorage.getItem("assignedRole") || '') || '[]';
    const token: string = sessionStorage.getItem("konsole-token") || '';
    const roleArr: string[] = JSON.parse(role)

    let isUserSuperAdmin: any = roleArr.filter((d: any) => d === 1)
    isUserSuperAdmin = (isUserSuperAdmin.length) ? true : false

    let isSBMUser: any = roleArr.filter((d: any) => d === 8)
    isSBMUser = (isSBMUser.length) ? true : false

    let isAdmin: any = roleArr.some((d: any) => d === 2)
    let isBACKEND_SCRIPT_RUNNER: any = roleArr.some((d: any) => d === 7)
    let isCREDIT_ANALYST: any = roleArr.some((d: any) => d === 5)
    let isACCOUNT_MANAGER: any = roleArr.some((d: any) => d === 4)

    return {
        roleArr: roleArr,
        isLoggedIn: (token) ? true : false,
        isSBMUser,
        isBACKEND_SCRIPT_RUNNER,
        isAdmin,
        isCREDIT_ANALYST,
        isAccountManager: isACCOUNT_MANAGER,
        isUserSuperAdmin
    }
};
