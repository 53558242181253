import React, { useState } from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Checkbox,
} from "@pankod/refine-antd";

import type { CheckboxChangeEvent } from 'antd/es/checkbox';
export const TxnReversalModel: React.FC<{ txnId: string }> = ({ txnId }) => {
    const [checked, setChecked] = useState(true);


    const { modalProps: txnReversalModel, formProps: txnReversalForm, show: txnReversalShow } = useModalForm({
        resource: "api/v2/pvt/txn-reversal",
        action: "create",
        metaData: {
            transactionIds: [txnId]
        },
        redirect: false,
        onMutationSuccess(data) {
            if (Object.keys(data.data?.failed).length > 0) {
                throw Error(
                    data.data?.failed[Object.keys(data.data?.failed)[0]],
                );
            }
        }
    });
    txnReversalModel.title = 'TXN Reversal'
    txnReversalModel.centered = true
    txnReversalModel.width = 400

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };
    return (
        <Space>
            {
                <Button onClick={() => { txnReversalShow() }} type="default">
                    Reverse Card TXN
                </Button>
            }

            <Modal {...txnReversalModel}>

                <Form {...txnReversalForm} layout="vertical">
                    <Form.Item>
                        <Checkbox name="sendNotifications" checked={checked} onChange={onChange}>
                            Send Notifications
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};
