import axios from 'axios';
import { AppConfig } from "../app.config";
import { GraphQlErrors } from "./apollo-graphql";
import { HttpError, useNotification } from '@pankod/refine-core';

// Create an instance of Axios with your custom configuration
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // You can modify the request config here, such as adding headers or tokens
        // For example, adding an authorization header with a token
        const accessTokenRaw = sessionStorage.getItem(AppConfig.apiTokenKey);
        if (accessTokenRaw) {
            config.headers.Authorization = `Bearer ${accessTokenRaw}`;
        }

        return config;
    },
    (error) => {
        // Handle any request errors here
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response: any) => {
        if (response?.errors?.length) {
            if (response.errors.some((it: any) => [GraphQlErrors.ACCESSED_WITH_REVOKED_TOKEN, GraphQlErrors.UNAUTHORIZED].includes(it.message))) {
                sessionStorage.clear();
                window.location.reload()
            }
            HandleError(response)
        }
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

export const HandleError = (response?: any) => {
    const { open } = useNotification();

    open?.({
        type: "error",
        message: response.errors[0].message,
        // description: "I <3 Refine",
        key: "url-345",
    });
}
export default axiosInstance;
