import { IResourceComponentsProps } from "@pankod/refine-core";
import {
    List,
    Row,
    Col,
} from "@pankod/refine-antd";
import { SyncTransparentAccessBankAccountModel } from "./components/sync-txn-access-bank-account.modal";
import { UpdateCircuitBreakerStateModel } from "./components/update-circuit-breaker-state.modal";
import { SyncDelegatedAccessBankAccountFundTransferForOprModel } from "./components/sync-delegated-access-bank-account-fund-transfer-for-opr.modal";

export const KodoPayList: React.FC<IResourceComponentsProps> = () => {

    return (
        <List title={'Kodo Pay'} pageHeaderProps={{
            extra: (
                <></>
            )
        }}>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <SyncTransparentAccessBankAccountModel />
                </Col>
                <Col lg={24} xs={24}>
                    <UpdateCircuitBreakerStateModel />
                </Col>
                <Col lg={24} xs={24}>
                    <SyncDelegatedAccessBankAccountFundTransferForOprModel />
                </Col>
            </Row>
        </List>

    );
};
