import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import React from "react";

import {
    Table,
    useTable,
    ExportButton,
    NumberField,
    Tag,
    PageHeader,
    Row,
    Col,
    Card,
} from "@pankod/refine-antd";

import { IAppIntegration, ISettlementStatement } from "../../interfaces/mmt";
import { urlPath } from "../../utility/url"
import { formatDateTime } from "../../utility/dateFormat";


export const SettlementShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()

    const pageTitle: string = 'Integration Settlement Statement Mapping'

    let { tableProps } = useTable<IAppIntegration>({
        dataProviderName: "grapghQl",
        resource: 'integrationSettlementStatementMappingForKonsole',
        metaData: {
            settlementId: url.lastElement,
        },
    });
    let { tableProps: settlementProps } = useTable<IAppIntegration>({
        dataProviderName: "grapghQl",
        resource: 'getSettlementHistory',
        metaData: {
            settlementId: url.lastElement,
        },
    });

    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    let totalSize = (tableProps?.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IAppIntegration>({
        dataProviderName: "grapghQl",
        resourceName: 'integrationSettlementStatementMappingForKonsole',
        metaData: {
            limit: totalSize,
            settlementId: url.lastElement,
            isExport: true
        }
    });

    //get settlementHistory

    // let { queryResult } = useShow<any>({
    //     dataProviderName: "grapghQl",
    //     resource: 'getSettlementHistory',
    //     id: '',
    //     metaData: { settlementId: url.lastElement, },
    // });
    // const { data, isLoading, isError } = queryResult;
    // const settlementHistory = data?.data

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={pageTitle}
                // extra={[
                //     <ExportButton
                //         onClick={triggerExport}
                //         loading={exportLoading}
                //     />
                // ]}
            />

            <Row gutter={16}>
                {
                    (settlementProps?.dataSource && settlementProps?.dataSource?.length > 0) && <Col lg={24} md={24} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} >
                        <Card
                            size={'small'}
                            title={'Settlement Update History'}
                            style={{
                                textTransform: 'capitalize',
                                borderRadius: "0.8rem",
                            }}
                        >
                            <Table {...settlementProps} rowKey="id">
                                <Table.Column
                                    title="Total Invoice Amount"
                                    render={(_, record: any) => (
                                        <>
                                            Rs. <NumberField
                                                value={record?.totalInvoiceAmount || 0}
                                                options={{
                                                    notation: "standard",
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <Table.Column
                                    title="Final Amount To Pay"
                                    render={(_, record: any) => (
                                        <>
                                            Rs. <NumberField
                                                value={record?.finalAmountToPay || 0}
                                                options={{
                                                    notation: "standard",
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                <Table.Column
                                    title="Last Update Notes"
                                    render={(_, record: any) => (
                                        <>
                                            {record?.lastUpdateNotes}
                                        </>
                                    )}
                                />
                                <Table.Column
                                    title="Updated At"
                                    render={(_, record: any) => (
                                        <>
                                            {formatDateTime(record?.updatedAt)}
                                        </>
                                    )}
                                />
                            </Table>

                        </Card>
                    </Col>
                }
                
                <Col lg={24} md={24} style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} >
                    <Card
                        size={'small'}
                        title={'integration Settlement Statement Mapping'}
                        style={{
                            textTransform: 'capitalize',
                            borderRadius: "0.8rem",
                        }}
                        extra={[
                            <ExportButton
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        ]}
                    >
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                title="Company"
                                render={(_, record: any) => (
                                    <>
                                        {record?.transaction?.company?.registeredName || '-'}
                                    </>
                                )}
                            />
                            <Table.Column
                                title="Transaction Id"
                                render={(_, record: any) => (
                                    <>
                                        {record?.transaction?.id || '-'}
                                    </>
                                )}
                            />
                            <Table.Column
                                title="Amount"
                                render={(_, record: any) => (
                                    <>
                                        {
                                            (record?.transaction?.transactionAmount) && <p>
                                                Rs.
                                                <NumberField
                                                    value={record?.transaction?.transactionAmount || 0}
                                                    options={{
                                                        notation: "standard",
                                                    }}
                                                />
                                            </p>
                                        }
                                    </>

                                )}
                            />
                            <Table.Column
                                title="Date"
                                render={(_, record: ISettlementStatement) => (
                                    formatDateTime(record?.transaction?.txnDate)
                                    // <DateField value={record?.transaction?.txnDate} format="LLL" />
                                )}
                            />

                            <Table.Column
                                title="Category"
                                render={(_, record: ISettlementStatement) => (
                                    <>
                                        {
                                            {
                                                "MMT_TRANSACTION": <Tag color="green">{record?.transaction?.transactionCategory.replaceAll("_", " ")}</Tag>,
                                                "MMT_TRANSACTION_REVERSAL": <Tag color="red">{record?.transaction?.transactionCategory.replaceAll("_", " ")}</Tag>,
                                            }[record?.transaction?.transactionCategory]
                                        }
                                    </>
                                )}
                            />
                        </Table>
                        {
                            (tableProps?.dataSource && tableProps?.pagination) ? <span>
                                Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                            </span> : ''
                        }
                    </Card>
                </Col>
            </Row>

           
        </>
    );
};