import React, { useEffect, useState } from "react";

import {
    Space,
    Button,
    useModalForm,
    Modal,
    Form,
} from "@pankod/refine-antd";
import { ICompany, ICompanyPBI } from "../../../interfaces";
import { ProductBundle } from "../../../interfaces/company";
import { IssueProductBundleToCompanyModel } from "./models/issueProductBundleToCompany.model";
import { OnboardCompanyModel } from "./models/onboardCompany.model";
import { verificationStatus } from "../list";

export const OnboardProductBundleComponent: React.FC<{ refetchCompanyDetail: any, accountMangerSelectProps: any, companyDetail: ICompany | undefined, features: any, onboardedProductBundles: string[], productBundles: ICompanyPBI[] | undefined }> = ({ accountMangerSelectProps, companyDetail, features, onboardedProductBundles, productBundles, refetchCompanyDetail }) => {
    const [pbisPending, setpbisPending] = useState({ state: false, pbiId: '', cardType: '' });

    useEffect(() => {

    });

    // //Check User Kyc
    // let { modalProps: LockCardConfirmModel, formProps: LockCardConfirmForm, show: LockCardConfirmShow } = useModalForm<any>({
    //     resource: `${userCard?.isLocked ? "unlockCardBySuperAdmin" : "lockCardBySuperAdmin"}`,
    //     dataProviderName: "grapghQl",
    //     action: "create",
    //     metaData: { cardId: userCard?.id },
    //     redirect: false,
    //     onMutationSuccess(data, variables, context) {
    //         window.location.reload()
    //     }
    // });
    // showDrawerProps.title = 'Card Details'


    // switch model
    const { modalProps: switchPbModel, formProps: switchPbForm, show: switchPbShow, close: switchPbClose } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "changeProductsBundleIssuanceToNewCardType",
        action: "create",
        metaData: { companyId: companyDetail?.id, pbiId: pbisPending.pbiId, cardType: pbisPending.cardType },
        redirect: false,
        onMutationSuccess() {
            // window.location.reload()
            refetchCompanyDetail(true)
        }
    });
    switchPbModel.title = ''
    switchPbModel.centered = true
    switchPbModel.okText = 'Continue'
    switchPbModel.width = 400
    switchPbModel.onCancel = () => {
        switchPbForm.form?.resetFields();
        switchPbClose();
    }

    const pendingPB: string[] | undefined = companyDetail?.pbisPendingActivation?.map((pbActivation: ICompanyPBI): string => {
        return pbActivation?.productsBundle?.id
    })
    return (
        <Space>
            {
                companyDetail?.pbisPendingActivation.map((pbPanding: ICompanyPBI) => {
                    return (pbPanding?.productsBundle?.productEnabled?.cards) ?
                        <>
                            <OnboardCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={pbPanding.productsBundle.id} companyDetail={companyDetail} productsBundleItem={pbPanding} />
                            <Button className="credit-btn" onClick={() => { setpbisPending({ state: true, pbiId: pbPanding.id, cardType: "PREPAID" }); switchPbShow() }} type="default">
                                Switch to Prepaid
                            </Button>
                        </> : <>
                            <OnboardCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={pbPanding.productsBundle.id} companyDetail={companyDetail} productsBundleItem={pbPanding} />
                            <Button className="credit-btn" onClick={() => { setpbisPending({ state: true, pbiId: pbPanding.id, cardType: "CREDIT" }); switchPbShow() }} type="default">
                                Switch to Credit
                            </Button>
                        </>
                    // (pbPanding?.productsBundle?.productEnabled?.prepaidCards) && 
                })
            }
            {

                (pendingPB && companyDetail && pendingPB?.length === 0) && productBundles?.map((ele: ICompanyPBI) => {

                    if (!onboardedProductBundles.includes(ele.id)) {
                        if (ele?.displayName === ProductBundle.pbKodoDailyCredit && !features.isPBKodoDailyCredit) {
                            return <></>
                        }
                        if (ele?.displayName === ProductBundle.pbKodoPayLater && !features.isPBKodoPayLater) {
                            return <></>
                        }
                        if (ele?.displayName === ProductBundle.pbKodoCredit && features.isPBKodoCredit) {
                            return <>
                                {(companyDetail?.companyVerification === verificationStatus.ONBOARDED) && <IssueProductBundleToCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={ele.id} companyDetail={companyDetail} productsBundleItem={ele} />}
                                {companyDetail?.companyVerification === verificationStatus.PENDING && <OnboardCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={ele.id} companyDetail={companyDetail} productsBundleItem={ele} />}
                            </>
                        }
                        if (ele?.productEnabled?.prepaidCards && features.isPBKodoPrepaid) {
                            return <>
                                {(companyDetail?.companyVerification === verificationStatus.ONBOARDED) && <IssueProductBundleToCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={ele.id} companyDetail={companyDetail} productsBundleItem={ele} />}
                                {companyDetail?.companyVerification === verificationStatus.PENDING && <OnboardCompanyModel refetchCompanyDetail={refetchCompanyDetail} accountMangerSelectProps={accountMangerSelectProps} productsBundleId={ele.id} companyDetail={companyDetail} productsBundleItem={ele} />}
                            </>
                        }
                    }
                })
            }
            <Modal {...switchPbModel}>
                <h4>Are you sure you want to Switch the product Bundle to  <strong> {pbisPending.cardType}</strong> ?</h4>
                <Form {...switchPbForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
        </Space>
    );
};