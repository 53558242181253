import React, { useEffect } from "react";

import {
    useModalForm, Modal, Space, Form,
    useDrawerForm,
    Row,
    Col, useModal,
    DateField,
    Button,
} from "@pankod/refine-antd";
import { Divider, Drawer, Tabs, Typography } from 'antd';

import { urlPath } from "../../utility/url";
import { EllipsisOutlined } from '@ant-design/icons';
import { DescriptionItem } from "../text-layout/descriptionItem.text";
const { Title, Text, Link } = Typography;

export const CardDetailsModel: React.FC<{ refetchUsers: any, userCard: any }> = ({ refetchUsers, userCard }) => {
    let url = urlPath()

    useEffect(() => {

    });

    // auto Debit responce model
    const { modalProps: cardDetailModel, close: cardDetailClose, show: cardDetailShow } = useModal();

    cardDetailModel.title = 'Card Details'
    cardDetailModel.centered = true
    cardDetailModel.footer = [
        <Button key="submit" onClick={() => { cardDetailClose()}} type="default">
            Close
        </Button>
    ]

    return (
        <Space>
            <Space onClick={() => { cardDetailShow() }} direction="horizontal"><EllipsisOutlined key="ellipsis" /> View Details</Space>

            <Modal {...cardDetailModel} >
                <Row>
                    <Col span={12}>
                        <DescriptionItem title="Kit Number" content={userCard?.kit?.kitNo} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Virtual Card issue date" content={userCard?.issueDateForVirtualForm} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Physical Card requested?" content={userCard?.physicalFormRequestedAt ? 'Yes' : 'No'} />
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Physical Card issue date" content={userCard?.issueDateForPhysicalForm ? <DateField value={userCard?.issueDateForPhysicalForm} format="LLL" /> : <></>} />
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <DescriptionItem title="M2P activation date" content={userCard?.cardActivatedByIntegratorAt ? <DateField value={userCard?.cardActivatedByIntegratorAt} format="LLL" /> : <></>} />

                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="M2P activation date" content={userCard?.cardActivatedInternallyAt ? <DateField value={userCard?.cardActivatedInternallyAt} format="LLL" /> : <></>} />
                    </Col>
                </Row>
            </Modal>
        </Space>
    );
};