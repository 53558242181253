import React from "react";

import { useModalForm, Modal, Space, Form, Button, Input } from "@pankod/refine-antd";

const { TextArea } = Input;

interface reactivateUserProp {
    refetchOnboaredCompany: any,
    mappingId: string,
}
export const RemoveCompanyMappingModel: React.FC<reactivateUserProp> = ({ refetchOnboaredCompany, mappingId }) => {

    let { modalProps: removeCompanyMappingModel, formProps: removeCompanyMappingForm, show: removeCompanyMappingShow, close: removeCompanyMappingClose } = useModalForm({
        resource: "disableOrEnableMmtIntegrationFromInternalPortal",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            id: mappingId,
            status: false
        },
        redirect: false,
        onMutationSuccess() {
            refetchOnboaredCompany(true)
            removeCompanyMappingClose();
        }
    });
    removeCompanyMappingModel.title = 'Remove Company Mapping'
    removeCompanyMappingModel.centered = true
    removeCompanyMappingModel.width = 500
    removeCompanyMappingModel.onCancel = () => {
        removeCompanyMappingForm.form?.resetFields();
        removeCompanyMappingClose();
    }
    return (
        <Space >
            <Button type="primary" danger={true} onClick={() => { removeCompanyMappingShow() }} >
                Remove Company Mapping
            </Button>

            <Modal {...removeCompanyMappingModel}>
                <h4>Are you sure you want to remove company mapping</h4>
                <Form {...removeCompanyMappingForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="Reasons to remove" name="lastUpdateNotes">
                        <TextArea rows={4} placeholder={"Reasons to remove"} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Password is required',
                            required: true,
                        }
                    ]} label="Password" name="passcode">
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};