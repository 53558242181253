import React from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
    Checkbox,
} from "@pankod/refine-antd";


export const SyncTransparentAccessBankAccountModel: React.FC<{}> = ({ }) => {


    const { modalProps: syncTransparentAccessModel, formProps: syncTransparentAccessForm, show: syncTransparentAccessShow } = useModalForm({
        dataProviderName: "grapghQl",
        resource: "syncTransparentAccessBankAccountFundTransferForOpr",
        action: "create",
        metaData: {
            fixMissingAuth: false,
            markFailedIfURNMissingAtIntegratorEnd: false,
        },
        redirect: false,
        onMutationSuccess() {

        }
    });
    syncTransparentAccessModel.title = 'Sync Txn Access Bank Account Fund Transfer For Opr'
    syncTransparentAccessModel.centered = true
    syncTransparentAccessModel.width = 400

    return (
        <Space>
            {
                <Button onClick={() => { syncTransparentAccessShow() }} type="default">
                    Sync Txn Access Bank Account Fund Transfer For Opr
                </Button>
            }

            <Modal {...syncTransparentAccessModel}>

                <Form {...syncTransparentAccessForm} layout="vertical">
                    <Form.Item
                        label="Unique Request No"
                        name={"uniqueRequestNo"}
                        rules={[
                            {
                                message: "Please input EB Unique Request No!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="OPR Id"
                        name={"oprId"}
                        rules={[
                            {
                                message: "Please input OPR Id!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Fix Missing Auth" name="fixMissingAuth" valuePropName="checked">
                        <Checkbox>Fix Missing Auth</Checkbox>
                    </Form.Item>
                    <Form.Item label="Mark Failed If URN Missing At Integrator End" name="markFailedIfURNMissingAtIntegratorEnd" valuePropName="checked">
                        <Checkbox>Mark Failed If URN Missing At Integrator End</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};