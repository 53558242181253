import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Form,
    Select,
    useSelect,
    FormProps,
} from "@pankod/refine-antd";
import { Typography } from 'antd';
import { DebitMethod, ICompany, InputValidatorRegex, MandateAmount } from "../../../interfaces/company";
import "../company.css";
import { useOne } from "@pankod/refine-core";
interface ICategory {
    id: number;
    title: string;
}
const { Title } = Typography;


export const AutoDebitModel: React.FC<{ formProps: FormProps, companyDetails: ICompany | undefined, companyId: any }> = ({ formProps, companyDetails, companyId }) => {
    let [autoDebitMethod, setAutoDebitMethod] = useState();
    // let [emailId, setEmailId] = useState();
    let [mandateAmountDeterminant, setMandateAmountDeterminant] = useState(MandateAmount.SPECIFIED);

    const { selectProps: companyBankSelectProps } = useSelect<ICategory>({
        dataProviderName: "grapghQl",
        resource: 'companyBank',
        optionLabel: "accountName",
        optionValue: "id",
        metaData: { filter: { id: companyId } },
    });

    const mandateAmountDeterminantList = [{
        label: MandateAmount.SAME_AS_COMPANY_LIMIT,
        value: "SAME_AS_COMPANY_LIMIT"
    }, {
        label: MandateAmount.MAX_ALLOWED_BY_AUTO_DEBIT_METHOD,
        value: "MAX_ALLOWED_BY_AUTO_DEBIT_METHOD"
    }, {
        label: MandateAmount.SPECIFIED,
        value: "SPECIFIED"
    }]

    const debitMethodList = [{
        label: DebitMethod.NACH,
        value: "NACH"
    }, {
        label: DebitMethod.EMANDATE,
        value: "EMANDATE"
    }]
    const { selectProps: adminSelectProps } = useSelect<ICategory>({
        dataProviderName: "grapghQl",
        resource: 'getUsersAdmin',
        optionLabel: "emailId",
        metaData: { filter: { company: companyId } },
        optionValue: "emailId"
    });

    const [ifscValue, setIFSCValue] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [ifscError, setIFSCError] = useState<any>("");

    const { refetch: refetchPosts, data: ifscData } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getBankBranchDescriptionByIfsc',
        id: '',
        metaData: {
            ifsc: ifscValue,
        }
    });

    useEffect(() => {
        setBankName(ifscData?.data)
        setIFSCError(ifscData?.data ? 'success' : "error")
        formProps.form?.validateFields(['ifsc'])
    }, [ifscData]);

    useEffect(() => {
        if (ifscValue !== '' && ifscValue.length > 10) {
            refetchPosts();
        }
    }, [ifscValue]);

    return (
        <Row>
            {
                (autoDebitMethod === DebitMethod.NACH && companyDetails?.bank.length === 0) ? <Col lg={24} md={24}>
                    <Title className="red" level={5}>Add Bank Account To Use NACH</Title>
                </Col> : ''
            }

            <Col lg={24} md={24}>
                <Form {...formProps} layout="vertical">
                    <Row gutter={12}>
                        <Col lg={6} md={6}>
                            <Form.Item rules={[
                                {
                                    required: true,
                                }
                            ]}
                                label="Select Admin Email" name="emailId">
                                <Select {...adminSelectProps} />
                            </Form.Item>
                        </Col>

                        <Col lg={12} md={12}>
                            {
                                <Form.Item rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                                    label="Select Debit Method" name="autoDebitMethod">
                                    <Select options={debitMethodList} onChange={(event: any) => {
                                        setAutoDebitMethod(event)
                                    }}
                                    />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col lg={12} md={12}>
                            {
                                <Form.Item rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                    label="Mandate Amount Determinant" name="mandateAmountDeterminant">
                                    <Select options={mandateAmountDeterminantList}
                                        onChange={(event: any) => {
                                            event = event.replaceAll("_", " ")
                                            setMandateAmountDeterminant(event)
                                            if (event === MandateAmount.SAME_AS_COMPANY_LIMIT || event === MandateAmount.SPECIFIED) {
                                                formProps.form?.setFieldsValue({ specifiedMandateAmount: companyDetails?.productsBundleIssuance?.creditLimit })
                                            }
                                            if (event === MandateAmount.MAX_ALLOWED_BY_AUTO_DEBIT_METHOD) {
                                                formProps.form?.setFieldsValue({ specifiedMandateAmount: 10000000 })
                                            }
                                        }}
                                    />
                                </Form.Item>
                            }

                        </Col>
                        <Col lg={12} md={12}>
                            {
                                <Form.Item rules={[
                                    {
                                        // message: 'Mandate Amount should be greater then 500',
                                        // min: 4,
                                        required: formProps.form?.getFieldValue('mandateAmountDeterminant') === MandateAmount.SPECIFIED,
                                    }
                                ]}
                                    initialValue={companyDetails?.productsBundleIssuance?.creditLimit}
                                    label="Mandate Amount" name="specifiedMandateAmount">
                                    <Input disabled={(mandateAmountDeterminant !== MandateAmount.SPECIFIED) ? true : false} type={'number'} onChange={(event: any) => {

                                    }} />
                                </Form.Item>
                            }

                        </Col>
                        <Col lg={24} md={24}>
                            {
                                (autoDebitMethod === DebitMethod.NACH) ? <Form.Item
                                    label="Select Bank Account" name="bankDetails">
                                    <Select
                                        onChange={(event: any) => {
                                            companyDetails?.bank.map((ele: any) => {
                                                if (ele.id === event) {
                                                    setIFSCValue(ele.ifsc)
                                                    formProps.form?.setFieldsValue({ beneficiaryName: ele.accountName })
                                                    formProps.form?.setFieldsValue({ accountNumber: ele.accountNumber })
                                                    formProps.form?.setFieldsValue({ ifsc: ele.ifsc })
                                                }
                                                return false
                                            })
                                        }}
                                        {...companyBankSelectProps} />
                                </Form.Item> : ''
                            }
                        </Col>
                    </Row>


                    {
                        (autoDebitMethod === DebitMethod.NACH) ? <Row gutter={12}>

                            <Col lg={24} md={24}>
                                <h5>or Add bank Details</h5>

                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Beneficiary Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct Beneficiary Name',
                                        pattern: InputValidatorRegex.BENEFICIARY_NAME_REGEX,
                                    },
                                ]} label="Beneficiary Name" name="beneficiaryName">
                                    <Input onChange={(event: any) => {
                                        formProps.form?.setFieldsValue({ accountName: event?.target?.value.replace(/[^a-z A-Z]/g, '') })
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Item rules={[
                                    {
                                        message: "Account Number required",
                                        required: true,
                                    },
                                    {
                                        message: "Invalid Account Number",
                                        pattern: InputValidatorRegex.BANK_ACCOUNT_NUMBER,
                                    }
                                ]} label="Account Number"
                                    name="accountNumber">
                                    <Input onChange={(event: any) => {
                                        formProps.form?.setFieldsValue({ accountNumber: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Item status={ifscError} rules={[
                                    {
                                        message: "Invalid format for IFSC",
                                        pattern: InputValidatorRegex.IFSC,
                                    },
                                    {
                                        message: 'IFSC Code is required',
                                        required: true,
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (!ifscData?.data) {
                                                return Promise.reject(
                                                    new Error("Invalid format for IFSC"),
                                                );
                                            }else 
                                            return Promise.resolve();
                                        },
                                    },
                                ]} label="IFSC Code"
                                    name="ifsc">
                                    <Input maxLength={11} onChange={(event: any) => {
                                        setIFSCValue(event?.target?.value.replace(/[^a-zA-Z0-9]/g, ''))
                                        formProps.form?.setFieldsValue({ ifsc: event?.target?.value.replace(/[^a-zA-Z0-9]/g, '') })
                                    }} />
                                </Form.Item>
                                <small>{bankName}</small>
                            </Col>
                        </Row> : ''
                    }
                </Form>
            </Col>
        </Row>
    );
};