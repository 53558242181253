import React, { useEffect, useState } from "react";
import {
    Input,
    Form,
    Select,
    FormProps,
    DatePicker,
} from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { removeObjectsById } from "utility/companyOnboard";

export const OnboardCompanyModel: React.FC<{ onboardedCompany: any, formProps: FormProps }> = ({ onboardedCompany, formProps }) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [comapanyName, setComapanyName] = useState<string>();
    let [brandName, setBrandName] = useState<string>();

    let { data: companyList, refetch: refetchCompanyList, isLoading } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCompaniesForManagementPortalPaginated',
        metaData: {
            filter: {
                registeredName: brandName,
            }
        },
        id: '',
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                // if (data?.data && onboardedCompany && companyList) {
                //     companyList.data = removeObjectsById(onboardedCompany, data?.data)
                // }
            },
        },
    });
    useEffect(() => {
        if (searchTerm !== '') {
            refetchCompanyList();
        }
    }, [searchTerm])


    const handleSearch = (searchTerm: string) => {
        if (searchTerm) {
            setBrandName(searchTerm)
            setSearchTerm(searchTerm)
        } 
    };

    const handleChange = (searchTerm: string) => {
        setComapanyName(searchTerm);
    };
    const dateFormat = 'YYYY-MM-DD';

    return (
        <>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    rules={[
                        {
                            required: true
                        },
                    ]} label="Select a Company" name="companyId">
                    <Select
                        loading={isLoading}
                        showSearch
                        value={comapanyName}
                        placeholder="Select a Company"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={handleChange}
                        notFoundContent={null}
                        options={(companyList?.data || []).map((d: any) => ({
                            value: d.id,
                            label: d.brandName,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true
                        },
                    ]} label="Mapped Company Id From MMT" name="mappedCompanyId">
                    <Input />
                </Form.Item>
                <Form.Item
                    hidden={true}
                    rules={[
                        {
                            required: true
                        },
                    ]} label="Onboarding Date" name="onboardingDate">
                
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true
                        },
                    ]} label="Onboarding Date" name="tempDateStore">
                    <DatePicker format={dateFormat} onChange={(event: any) => {
                        formProps.form?.setFieldsValue({ onboardingDate: event?.toISOString().split('T')[0] })
                    }} />
                </Form.Item>

            </Form>
        </>
    );
};