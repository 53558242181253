import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import {
    List,
    Table,
    Space,
    Row,
    Col,
    useTable,
    DateField,
    ExportButton,
    NumberField
} from "@pankod/refine-antd";
import {
    LinkOutlined,
} from "@ant-design/icons";
import { IRefund } from "../../interfaces";

export const AutoDebitList: React.FC<IResourceComponentsProps> = () => {
    let { tableProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'repaymentAutoDebitPaginated',
        metaData: {}
    });
    let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IRefund>({
        dataProviderName: "grapghQl",
        resourceName: 'repaymentAutoDebitPaginated',
        metaData: {
            limit: totalSize,
            isExport: true
        }
    });
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List title={'Bank Mandate Registration'} pageHeaderProps={{
                    extra: (
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ),
                }}>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column title="Rzp Registration Id & Mandate Registration URL" render={(_, record: any) =>
                            <Space align='baseline' direction="vertical">
                                {record?.rzpInvoiceId}
                                <a href={record?.rzpMandateRegUrl} target="_blank" rel="noreferrer"> <Space align='baseline' direction="horizontal" onClick={() => { navigator.clipboard.writeText(record?.rzpMandateRegUrl) }}>
                                    <small>{record?.rzpMandateRegUrl}</small>
                                </Space> <LinkOutlined /></a>
                            </Space>
                        } />
                        <Table.Column
                            title='Mandate Amount (max per txn)'
                            dataIndex="mandateMaxAmountPerCharge"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex="createdAt"
                            title="Created On"
                            key="createdAt"
                            render={(value) => <DateField value={value} format="LLL" />}
                        />
                        <Table.Column
                            title="Company"
                            dataIndex="company"
                            key="company"
                            render={(_, record: any) => (
                                <Space align='baseline' direction="vertical">
                                    {
                                        (record?.company) ? <>
                                            <a href={'/company/show/' + record?.company?.id}><LinkOutlined /> {record?.company?.registeredName}</a>
                                            {record?.rzpCustomerId}
                                        </> : ''
                                    }
                                </Space>
                            )}
                        />
                        <Table.Column title="Registration Mode" dataIndex="method" />
                        <Table.Column dataIndex="Registration Status" title="Status"
                            render={(_, record: any) => (
                                record?.status.replaceAll("_", " ")
                            )} />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
