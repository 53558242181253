import React from "react";
import {
    Form,
    Select,
    Button,
    Row,
    Col,
} from "@pankod/refine-antd";
import { useModalForm, Modal } from "@pankod/refine-antd";
import { FrequencyList, ICompany, ICompanyPBI, ProductBundle } from "interfaces/company";
import { verificationStatus } from "../list";


export const CreateRepaymentRuleModel: React.FC<{ refetchCompanyDetail: any, isForEdit: boolean, autodeLinkShow: boolean, company: ICompany, pbiDisplayName: string, pbiId: string, productBundle: ICompanyPBI[] }> = ({ refetchCompanyDetail, autodeLinkShow, pbiDisplayName, company, pbiId, productBundle, isForEdit }) => {

    productBundle = productBundle?.map((ele: ICompanyPBI): ICompanyPBI => {
        return {
            ...ele,
            value: ele?.id,
            label: ele?.displayName
        }
    })

    let frequencyList = [{
        label: FrequencyList.MONTHLY,
        value: "MONTHLY"
    }]
    if (pbiDisplayName === ProductBundle.pbKodoCredit) {
        frequencyList.push({
            label: FrequencyList.WEEKLY,
            value: "WEEKLY"
        }, {
            label: FrequencyList.DAILY,
            value: "DAILY"
        })
    }

    //kodo Payment Model
    const { modalProps: repaymentAutoDebitRulModel, formProps: repaymentAutoDebitRulForm, close: repaymentAutoDebitRuleClose, show: repaymentAutoDebitRuleShow } = useModalForm<any>({
        resource: "saveRepaymentAutoDebitRule",
        dataProviderName: "grapghQl",
        redirect: false,
        action: "create",
        metaData: {
        },
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    repaymentAutoDebitRulModel.title = `${isForEdit ? "Edit " : 'Create '} Repayment Bank Mandate Rule`
    repaymentAutoDebitRulModel.centered = true
    repaymentAutoDebitRulModel.width = 600
    repaymentAutoDebitRulModel.onCancel = () => {
        repaymentAutoDebitRulForm.form?.resetFields();
        repaymentAutoDebitRuleClose();
    }

    const closedSelectDropdown = () => {
        // Unsets Background Scrolling to use when SideDrawer/Modal is closed
        document.body.style.overflow = 'unset';
    }
    const openSelectDropdown = () => {
        // Disables Background Scrolling whilst the SideDrawer/Modal is open
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <>
            {
                (autodeLinkShow && company?.companyVerification === verificationStatus.ONBOARDED) && <Button onClick={() => {
                    openSelectDropdown();
                    repaymentAutoDebitRuleShow()
                }} type="default">
                    {isForEdit ? "Edit " : 'Create '}
                    Bank Mandate Rule</Button>
            }
            <Modal {...repaymentAutoDebitRulModel}>
                <Row>

                    <Col lg={24} md={24}>
                        <div className="outerOverlay" onClick={closedSelectDropdown}>
                        </div>
                        <Form {...repaymentAutoDebitRulForm} layout="vertical">
                            <Row gutter={12}>

                                <Col lg={12} md={12}>
                                    {
                                        <Form.Item initialValue={pbiId} rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                            label="Select Product Bundle" name="pbiId">
                                            <Select disabled onClick={openSelectDropdown} options={productBundle} />
                                        </Form.Item>
                                    }
                                </Col>
                                <Col lg={12} md={12}>
                                    <Form.Item rules={[
                                        {
                                            required: true,
                                        }
                                    ]}
                                        label="Select Frequency" name="frequencyOfFixedIntervalCharge">
                                        <Select options={frequencyList} onClick={openSelectDropdown} onChange={(event: any) => { }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};