import React from "react";
import {
    Form,
    useModalForm,
    Space,
    Button,
    Modal,
    Select,
} from "@pankod/refine-antd";
import { SettlementEventType } from "interfaces/mmt";


export const FetchStatusForPendingSettlementModel: React.FC<{ refetchActiveFeeStatementsDetails: any, eventType: string, statementId?: string }> = ({ refetchActiveFeeStatementsDetails, statementId, eventType }) => {

    let { modalProps: FetchStatusForPendingSettlementModel, formProps: FetchStatusForPendingSettlementForm, show: FetchStatusForPendingSettlementShow } = useModalForm({
        resource: statementId ? "fetchSettlementStatusOfStatement" : "fetchStatusForPendingSettlementRecords",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            statementId: statementId,
            eventType: eventType
        },
        redirect: false,
        onMutationSuccess() {
            refetchActiveFeeStatementsDetails(true)
        }
    });
    FetchStatusForPendingSettlementModel.title = 'Fetch Settlement Status'
    FetchStatusForPendingSettlementModel.centered = true
    FetchStatusForPendingSettlementModel.okText = 'Fetch Status'
    FetchStatusForPendingSettlementModel.width = 500



    return (
        <Space >
            <Button className="credit-btn" onClick={() => { FetchStatusForPendingSettlementShow() }} type="default">
                {statementId ? "Fetch Settlement Status" : "Fetch Status For Pending Settlement Records"}
            </Button>

            <Modal {...FetchStatusForPendingSettlementModel}>
                {statementId ? "Fetch Settlement Status" : "Fetch Status For Pending Settlement Records"}
                <Form {...FetchStatusForPendingSettlementForm} layout="vertical">
                    {
                        (statementId && !eventType) && <Form.Item
                            label="Event Type"
                            name="eventType"
                            rules={[
                                {
                                    message: 'Event Type is required',
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: SettlementEventType.KODO_VA_FEE,
                                        value: SettlementEventType.KODO_VA_FEE,
                                    },
                                    {
                                        label: SettlementEventType.CARD_ISSUANCE_FEE,
                                        value: SettlementEventType.CARD_ISSUANCE_FEE,
                                    },
                                    {
                                        label: SettlementEventType.CARD_TRANSACTION_FEE,
                                        value: SettlementEventType.CARD_TRANSACTION_FEE,
                                    },
                                    {
                                        label: SettlementEventType.CARD_REPLACEMENT_FEE,
                                        value: SettlementEventType.CARD_REPLACEMENT_FEE,
                                    },
                                ]}
                            />
                        </Form.Item>
                    }

                </Form>
            </Modal>
        </Space>
    );
};