import React from "react";
import {
    Modal, Space,
    Button,
    Form,
    useModalForm
} from "@pankod/refine-antd";

export const CreateDecentroAccountModel: React.FC<{ refetchCompanyDetail: any, companyId: string }> = ({ refetchCompanyDetail, companyId }) => {

    const { modalProps: createDecentroAccountModel, formProps: createDecentroAccountForm, show: createDecentroAccountShow, close: createDecentroAccountClose } = useModalForm({
        resource: "createDecentroVirtualAccountForCompany",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });

    createDecentroAccountModel.title = 'Create Decentro VA'
    createDecentroAccountModel.confirmLoading = true
    createDecentroAccountModel.centered = true
    createDecentroAccountModel.width = 500


    return (
        <Space >
            <Button  onClick={() => { createDecentroAccountShow() }}  >
                Create Decentro VA
            </Button>
            
            <Modal {...createDecentroAccountModel}>
                <Form {...createDecentroAccountForm} layout="vertical">
                    <>Register Company to Decentro VA</>
                </Form>
            </Modal>
        </Space>
    );
};