import { CSSProperties } from "react";

export const kodoLogo: CSSProperties = {
    position: "relative",
    fontFamily: 'Poiret One',
    color:'white',
    fontSize:'2rem',
    margin:'0 auto',
    fontWeight:'600',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
};

export const kodoLogoSmall: CSSProperties = {
    position: "relative",
    fontFamily: 'Poiret One',
    color:'white',
    fontSize:'1.4rem',
    margin:'0 auto',
    fontWeight:'600',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
};