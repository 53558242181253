import React from "react";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import {
    List,
    Table,
    Space,
    useTable,
    ExportButton,
    ShowButton,
} from "@pankod/refine-antd";

import { IAppIntegration } from "../../interfaces/mmt";
import { formatDate } from "../../utility/dateFormat";

export const AppIntegrationList: React.FC<IResourceComponentsProps> = () => {

    let { tableProps } = useTable<IAppIntegration>({
        dataProviderName: "grapghQl",
        resource: 'getActiveIntegrations',
        metaData: {}
    });
    let totalSize = (tableProps?.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IAppIntegration>({
        dataProviderName: "grapghQl",
        resourceName: 'getActiveIntegrations',
        metaData: {
            limit: totalSize,
            isExport: true
        }
    });
    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    return (
        <List pageHeaderProps={{
            extra: (
                <ExportButton
                    onClick={triggerExport}
                    loading={exportLoading}
                />
            ),
        }}>
            <Table {...tableProps} rowKey="id">
                <Table.Column title="Integration App" dataIndex="integrationApp" />
                <Table.Column title="name" dataIndex="name" />
                <Table.Column
                    dataIndex="createdAt"
                    title="Created At"
                    render={(value) =>  formatDate(value) }
                />
                <Table.Column
                    dataIndex="processingFeePercent"
                    title="Processing Fee Percent"
                />
                <Table.Column
                    dataIndex="gstPercent"
                    title="GST Percent"
                />
                <Table.Column<IAppIntegration>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId={`${record.id}?appIntegration=${record.integrationApp}`}
                            />
                        </Space>
                    )}
                />
            </Table>
            {
                (tableProps?.dataSource && tableProps?.pagination) ? <span>
                    Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                </span> : ''
            }
        </List>
    );
};