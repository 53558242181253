import React from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Typography,
} from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;

export const CloseVaFundingAccountButtonModel: React.FC<{ refetchCompanyDetail: any, fundingBankAccountValidationRequestId: string, companyId: string, bankAccountId: string }> = ({ refetchCompanyDetail, fundingBankAccountValidationRequestId, companyId, bankAccountId }) => {

    const { modalProps: closeVaFundingAccountModel, formProps: closeVaFundingAccountForm, show: closeVaFundingAccountShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "ensureKodoBankAccountForCompany",
        action: "create",
        metaData: {
            removeFundingBankAccount: true,
            fundingBankAccountValidationRequestId: fundingBankAccountValidationRequestId,
            ownerCompanyId: companyId,
            bankAccountId,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    closeVaFundingAccountModel.title = 'Remove Account'
    closeVaFundingAccountModel.confirmLoading = true
    closeVaFundingAccountModel.centered = true
    closeVaFundingAccountModel.okText = 'Remove'
    closeVaFundingAccountModel.width = 500

    return (
        <Space >
            <Button danger={true} onClick={() => { closeVaFundingAccountShow() }} icon={<DeleteOutlined />} > Remove Account</Button>

            <Modal {...closeVaFundingAccountModel}>
                <Title level={3}>If you remove it, the customer won’t be able to add funds using this account. Are you sure you want to proceed with removal?</Title>
                <Form {...closeVaFundingAccountForm} layout="vertical">
                    <></>
                </Form>
            </Modal>

        </Space>
    );
};