import React, { useEffect, useState } from "react";

import { useModalForm, Modal, Space, Form, Row, Col, Switch, Input, Typography } from "@pankod/refine-antd";

import { EditOutlined } from '@ant-design/icons';
const { Title } = Typography;

export const EditCardLimitModel: React.FC<{ refetchUsers: any, userCard: any, userId: string }> = ({ userCard, refetchUsers, userId }) => {
    let [monthlyLimitCheckBox, setMonthlyLimitCheckBox] = useState(userCard?.monthlyLimit ? true : false);
    let [dailyLimitCheckBox, setDailyLimitCheckBox] = useState(userCard?.dailyLimit ? true : false);

    useEffect(() => {

    });

    //Check User Kyc
    let { modalProps: cardLimitModel, formProps: cardLimitForm, show: cardLimitShow, close: cardLimitClose } = useModalForm<any>({
        resource: "updateUserLimitForPbi",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            cardId: userCard?.id,
            pbiId: userCard?.pbiId,
            userId: userId,
        },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchUsers(true)
        }
    });
    cardLimitModel.title = 'Edit card Limit'
    cardLimitModel.centered = true
    cardLimitModel.width = 400
    cardLimitModel.onCancel = () => {
        cardLimitForm.form?.resetFields();
        cardLimitClose();
    }


    return (
        <>
            <Space direction="horizontal" onClick={() => { cardLimitShow() }}>
                <EditOutlined key="edit" /> Edit Card Limit
            </Space>

            <Modal {...cardLimitModel}>
                <Form {...cardLimitForm} initialValues={{
                    monthlyLimit: userCard?.monthlyLimit,
                    dailyLimit: userCard?.dailyLimit,
                }} layout="vertical">
                    <Row>
                        <Col lg={24}>
                            <Space className="mb-3" align="center" direction="horizontal" >
                                <Title level={5}>Monthly Limit</Title>
                                <Switch checked={monthlyLimitCheckBox} onChange={(event: any) => {
                                    setMonthlyLimitCheckBox(event)
                                    cardLimitForm.form?.setFieldsValue({ monthlyLimit: monthlyLimitCheckBox ? userCard?.monthlyLimit :null })
                                }} />
                            </Space>
                            {
                                monthlyLimitCheckBox && <Form.Item name="monthlyLimit">
                                    <Input placeholder="Monthly Limit in numbers" onChange={(event: any) => {
                                        cardLimitForm.form?.setFieldsValue({ monthlyLimit: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} />
                                </Form.Item>
                            }
                        </Col>
                        <Col lg={24}>
                            <Space className="mb-3" align="center" direction="horizontal" >
                                <Title level={5}>Daily Limit</Title>
                                <Switch checked={dailyLimitCheckBox} onChange={(event: any) => {
                                    setDailyLimitCheckBox(event)
                                    cardLimitForm.form?.setFieldsValue({ dailyLimit: dailyLimitCheckBox ? userCard?.dailyLimit : null })
                                }} />
                            </Space>
                            {
                                dailyLimitCheckBox && <Form.Item  label="" name="dailyLimit">
                                    <Input placeholder="Daily Limit in numbers" onChange={(event: any) => {
                                        cardLimitForm.form?.setFieldsValue({ dailyLimit: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};