import {  IResourceComponentsProps, useOne } from "@pankod/refine-core";
import React from 'react';
import ReactJson from 'react-json-view';

import '../../style.css';
import {
    Row,
    Col,
} from "@pankod/refine-antd";
import { urlPath } from "../../utility/url";

export const ReportDetailsShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    let pram: any = url.params

    const companyId: string = (pram && pram.get('company')) ? pram.get('company') : ''

    // Fix the page and limit in url
    let { data: tableData } = useOne<any>({
        id: '',
        dataProviderName: "grapghQl",
        resource: 'entriesInDailyNpaReportsSbm',
        metaData: {
            reportId: url.lastElement,
            pagination: {
                "page": 1,
                "limit": 1000
            }
        },
    });
    let arrayValue = tableData?.data || []
    let record = arrayValue?.filter((ele: any) => {
        return ele.company.id === companyId 
    })


    return (
        <Row>
            <Col span={24} className="card-container">
                <ReactJson src={record} theme="monokai" />
            </Col>
        </Row>
    );
};