import React, { useEffect } from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
} from "@pankod/refine-antd";

import { ICompany } from "../../interfaces";

export const RenameCompanyNameModel: React.FC<{ refreshAccountManager: any, companyDetail: ICompany | undefined }> = ({ companyDetail, refreshAccountManager }) => {


    useEffect(() => {
        renameCompanyForm.form?.setFieldsValue({
            newCompanyName: companyDetail?.registeredName,
            nameOnCard: companyDetail?.nameOnCard,
        })
    });

    //account manager Edit Model
    const { modalProps: renameCompanyModel, formProps: renameCompanyForm, show: renameCompanyShow } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "renameCompany",
        action: "create",
        metaData: { companyId: companyDetail?.id },
        redirect: false,
        onMutationSuccess() {
            refreshAccountManager(true)
        }
    });
    renameCompanyModel.title = 'Rename Company'
    renameCompanyModel.centered = true
    renameCompanyModel.width = 400

    return (
        <Space>
            {
                (companyDetail) && <Button onClick={() => { renameCompanyShow() }} type="link">
                    Rename Company
                </Button>
            }

            <Modal {...renameCompanyModel}>

                <Form {...renameCompanyForm} layout="vertical">
                    <Form.Item
                        label="Company Registered Name"
                        name={"newCompanyName"}
                        rules={[
                            {
                                message: "Please input Company Registered Name!",
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Name On Card"
                        name={"nameOnCard"}
                        rules={[
                            {
                                message: "Please input Name On Card!",
                                required: true,
                            },
                            {
                                message: "Name On Card must be less then 20 characters!",
                                max: 19,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};