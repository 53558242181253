import React, { useState } from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Typography,
    Checkbox,
} from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

export const UpdateCompanyBalanceButtonModel: React.FC<{ refetchCompanyDetail: any, companyId: string }> = ({ refetchCompanyDetail, companyId }) => {
    const [checked, setChecked] = useState(false);

    const { modalProps: updateCompanyBalanceModel, formProps: updateCompanyBalanceForm, show: updateCompanyBalanceShow } = useModalForm<any>({
        resource: "api/v2/calculateAndUpdateCompaniesBalances",
        action: "create",
        metaData: {
            updateAtCardLevelToo: checked,
            companyIds: [companyId],
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    updateCompanyBalanceModel.title = 'Update Company Balance'

    updateCompanyBalanceModel.centered = true
    updateCompanyBalanceModel.width = 500
    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };
    return (
        <Space >
            <Button onClick={() => { updateCompanyBalanceShow() }} >Recalculate PBI Balance</Button>

            <Modal {...updateCompanyBalanceModel}>
                <Form {...updateCompanyBalanceForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Update At Card Level Too is required',
                            required: true,
                        }
                    ]} >
                        <Checkbox name="updateAtCardLevelToo" checked={checked} onChange={onChange}>
                            Update At Card Level Too
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};