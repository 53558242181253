import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import {
    List,
    Table,
    Space,
    Row,
    Col,
    useTable,
    ExportButton,
    NumberField
} from "@pankod/refine-antd";

import { IRefund } from "../../interfaces";

export const PenaltyList: React.FC<IResourceComponentsProps> = () => {
    let { tableProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'getPenaltyForCompaniesAppearingInUpcomingStatements',
        metaData: {}
    });
    let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IRefund>({
        dataProviderName: "grapghQl",
        resourceName: 'getPenaltyForCompaniesAppearingInUpcomingStatements',
        metaData: {
            limit: totalSize,
        }
    });

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List title={'Penalty'} pageHeaderProps={{
                    extra: (
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ),
                }}>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column dataIndex="companyName" title="Company" />
                        <Table.Column dataIndex="pbiDisplayName" title="Product Bundle" />

                        <Table.Column
                            dataIndex='dueAmount'
                            title="Due Amount"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />

                        {/* <Table.Column
                            dataIndex='amount'
                            title="Amount Paid"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs. {value}
                                    </small>
                                </Space>
                            }
                        /> */}
                        <Table.Column
                            dataIndex='outstandingAmount'
                            title="Outstading Amount"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex='penaltyAmount'
                            title="Penalty Amount"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex='gst'
                            title="GST"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex='totalPenalty'
                            title="Total Penalty"
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <small>
                                        Rs.
                                        <NumberField
                                            value={value || 0}
                                            options={{
                                                notation: "standard",
                                            }}
                                        />
                                    </small>
                                </Space>
                            }
                        />

                        {/* <Table.Column<IRefund>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <ShowButton
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        /> */}
                    </Table>
                </List>
            </Col>
        </Row>
    );
};