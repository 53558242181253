import { IResourceComponentsProps } from "@pankod/refine-core";
import {
    List,
    Table,
    Space,
    useTable,
    DateField,
    ShowButton
} from "@pankod/refine-antd";

import { IRefund } from "../../interfaces";

export const NpaReportList: React.FC<IResourceComponentsProps> = () => {
    let { tableProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'dailyNpaReportsSbm',
        metaData: {}
    });
    // let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    // const { triggerExport, isLoading: exportLoading } = useExport<IRefund>({
    //     dataProviderName: "grapghQl",
    //     resourceName: 'dailyNpaReportsSbm',
    //     metaData: {
    //         limit: totalSize,
    //     }
    // });
    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    return (
        <List title={'NPA Report'} pageHeaderProps={{
            // extra: (
            //     <ExportButton
            //         onClick={triggerExport}
            //         loading={exportLoading}
            //     />
            // ),
        }}>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="reportDate"
                    title="Date"
                    key="reportDate"
                    render={(value) => value ? <DateField value={value} format="DD-MM-YYYY" /> : <></>}
                />
                <Table.Column
                    dataIndex="uploadedToSftpAt"
                    title="Uploaded To Sftp"
                    key="uploadedToSftpAt"
                    render={(value) => value ? <DateField value={value} format="LLL" /> : <></>}
                />

                <Table.Column dataIndex="uploadedFileName" title="Uploaded File Name" />
                <Table.Column<any>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId={`${record.id}?uploadedFileName=${record?.uploadedFileName}`}
                            />
                        </Space>
                    )}
                />
            </Table>
            {
                (tableProps?.dataSource && tableProps?.pagination) ? <span>
                    Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                </span> : ''
            }
        </List>
    );
};

//uploadedToSftpAt=${record?.uploadedToSftpAt}&