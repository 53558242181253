import { IResourceComponentsProps, useExport, useOne } from "@pankod/refine-core";
import React, { useEffect, useState } from "react";

import {
    Show,
    Table,
    Space,
    Button,
    NumberField,
    useTable,
    Form,
    ExportButton,
    Tag,
    Card,
    Row, Col,
    Input,
} from "@pankod/refine-antd";
import { useModalForm, Modal } from "@pankod/refine-antd";

import { IAppIntegration, paymentStatus } from "../../interfaces/mmt";
import { urlPath } from "../../utility/url"
import { formatDateTime, formatDate } from "utility/dateFormat"
import { OnboardCompanyModel } from "./components/onboard-company.model"
import { ICompany } from "interfaces";
import { RemoveCompanyMappingModel } from "./components/remove-company-mapping.model";
const { TextArea } = Input;

export const AppIntegrationShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    let pram: any = url.params

    const pageTitle: string = (pram && pram.get('appIntegration')) ? pram.get('appIntegration') : 'App integrations Show'

    let [settlementId, setSettlementId] = useState('');
    let [processSettlementAmount, setProcessSettlementAmount] = useState(0);
    let [selectedPaymentStatus, setPaymentStatus] = useState('');
    let [checkSettlement, setCheckSettlement] = useState('');
    let [currentSettlementAmount, setCurrentSettlementAmount] = useState(0);
    let [refetchOnboaredCompanyData, setRefetchOnboaredCompanyData] = useState(false);

    let { data: onboardCompany, isLoading: onboardCompanyLoading, refetch: refetchOnboardCompanyData } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'findCompanyByIntegrationIdPaginated',
        id: '',
        metaData: {
            mmtIntegrationId: url.lastElement,
            pagination: {
                limit: 10,
                page: 1
            }
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefetchOnboaredCompanyData(false);
            },
        },
    });

    let { tableProps } = useTable<IAppIntegration>({
        dataProviderName: "grapghQl",
        resource: 'integrationSettlementStatementForKonsolePaginate',
        metaData: {
            filter: {
                paymentStatus: selectedPaymentStatus,
                integrationId: url.lastElement,
            },
            pagination: {
                limit: 10,
                page: 1
            }
        },
    });

    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    let totalSize = (tableProps?.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IAppIntegration>({
        dataProviderName: "grapghQl",
        resourceName: 'integrationSettlementStatementForKonsolePaginate',
        metaData: {
            filter: {
                paymentStatus: selectedPaymentStatus,
                integrationId: url.lastElement,
            },
            pagination: {
                limit: totalSize,
                page: 1
            },
            isExport: true
        }
    });

    const { refetch: refetchCheckSettlement } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'checkIntegrationSettlementStatusWithBank',
        id: '',
        metaData: {
            isMutation: true
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                window.location.reload()
            },
        },
    });

    useEffect(() => {
        if (checkSettlement) {
            refetchCheckSettlement();
        }
    }, [checkSettlement]);

    const { modalProps: generateSettlementModel, formProps: generateSettlementForm, show: generateSettlementShow, close: generateSettlementClose } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "generateMmtIntegrationSettlementStatement",
        action: "create",
        metaData: {},
        redirect: false,
        onMutationSuccess(data, variables, context) {
            window.location.reload()
        }
    });
    generateSettlementModel.title = ''
    generateSettlementModel.centered = true
    generateSettlementModel.okText = 'Continue'
    generateSettlementModel.width = 400
    generateSettlementModel.onCancel = () => {
        generateSettlementForm.form?.resetFields();
        generateSettlementClose();
    }


    const { modalProps: deleteSettlementModel, formProps: deleteSettlementForm, show: deleteSettlementShow, close: deleteSettlementClose } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "removeMmtIntegrationSettlementStatement",
        action: "create",
        metaData: { settlementId: settlementId },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            window.location.reload()
        }
    });

    deleteSettlementModel.title = ''
    deleteSettlementModel.centered = true
    deleteSettlementModel.okText = 'Continue'
    deleteSettlementModel.okType = 'danger'
    deleteSettlementModel.width = 400
    deleteSettlementModel.onCancel = () => {
        deleteSettlementForm.form?.resetFields();
        deleteSettlementClose();
    }

    const { modalProps: processSettlementModel, formProps: processSettlementForm, show: processSettlementShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "settleIntegrationPayment",
        action: "create",
        metaData: { settlementId: settlementId },
        redirect: false,
        onMutationError() {
            // window.location.reload()
        },
        onMutationSuccess() {
            window.location.reload()
        }
    });

    processSettlementModel.title = ''
    processSettlementModel.centered = true
    processSettlementModel.okText = 'Continue'
    processSettlementModel.onCancel = () => {
        window.location.reload()
    }
    processSettlementModel.width = 400

    const { modalProps: editSettlementModel, formProps: editSettlementForm, show: editSettlementShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "updateSettlementFinalAmount",
        action: "create",
        metaData: {
            id: settlementId,
        },
        redirect: false,
        onMutationError() {
            // window.location.reload()
        },
        onMutationSuccess() {
            window.location.reload()
        }
    });

    editSettlementModel.title = ''
    editSettlementModel.centered = true
    editSettlementModel.okText = 'Continue'
    editSettlementModel.width = 400

    const { modalProps: companyOnboardingModel, formProps: companyOnboardingForm, show: companyOnboardingShow, close: companyOnboardingClose } = useModalForm<IAppIntegration>({
        resource: "addIntegrationCompanyMapping",
        dataProviderName: "grapghQl",
        redirect: false,
        action: "create",
        metaData: {
            integrationId: url.lastElement,
        },
        onMutationSuccess(data: any) {
            window.location.reload()
        }
    });
    companyOnboardingModel.title = 'Onboard new company'
    companyOnboardingModel.centered = true
    companyOnboardingModel.width = 400
    companyOnboardingModel.onCancel = () => {
        companyOnboardingForm.form?.resetFields();
        companyOnboardingClose();
    }

    const refetchOnboaredCompany = (message: boolean) => {
        setRefetchOnboaredCompanyData(message);
    };

    useEffect(() => {
        if (refetchOnboaredCompanyData) {
            refetchOnboardCompanyData();
        }
    }, [refetchOnboaredCompanyData]);
    
    return (
        <Show title={pageTitle} breadcrumb={false} headerButtons={({ defaultButtons }) => (
            <>
                {/* <Select
                    defaultValue={''}
                    style={{ width: 120 }}
                    onChange={(event: string) => { setPaymentStatus(event) }}
                    options={[
                        { value: '', label: 'Payment Status' },
                        { value: paymentStatus.PAID, label: paymentStatus.PAID },
                        { value: paymentStatus.PROCESSING, label: paymentStatus.PROCESSING },
                        { value: paymentStatus.PENDING, label: paymentStatus.PENDING },
                        { value: paymentStatus.FAILED, label: paymentStatus.FAILED },
                    ]}
                /> */}
                {/* {defaultButtons} */}
                <ExportButton
                    onClick={triggerExport}
                    loading={exportLoading}
                />
                <Button onClick={() => {
                    companyOnboardingShow()
                }} type="default">Onboard new company</Button>
                <Button
                    onClick={() => {
                        generateSettlementShow()
                    }}
                >
                    Generate Settlement Statement
                </Button>
            </>
        )}>

            <Table {...tableProps} rowKey="id">
                <Table.Column
                    title='UTR'
                    dataIndex="utr"
                    render={(_, record: any) => (
                        <Space size={'small'}>
                            {record?.integrationSettlementStatementRequest?.utr || '-'}
                        </Space>
                    )}
                />
                <Table.Column
                    dataIndex="createdAt"
                    title="Dates"
                    render={(_, record: any) => (
                        <>
                            {
                                (record?.createdAt) && <p>
                                    <strong className="white-space-nowrap mb-0">Created At</strong>
                                    <br />
                                    <small>
                                        {formatDate(record?.createdAt)}
                                    </small>
                                </p>
                            }
                            {
                                (record?.startDate) && <p>
                                    <strong className="white-space-nowrap mb-0">Start Date</strong>
                                    <br />
                                    <small>
                                        {formatDate(record?.startDate)}
                                        {/* {formatDateTime(record?.startDate)} */}
                                        {/* {record?.startDate} */}
                                    </small>
                                </p>
                            }
                            {
                                (record?.endDate) && <p>
                                    <strong className="white-space-nowrap mb-0">End Date</strong>
                                    <br />
                                    <small>
                                        {/* {record?.endDate} */}
                                        {formatDate(record?.endDate)}
                                    </small>
                                </p>
                            }
                        </>
                    )}
                />

                <Table.Column
                    title="Percent Breakup"
                    render={(_, record: any) => (
                        <>
                            {
                                (record?.totalInvoiceAmount > 0) && <p>
                                    <strong className="white-space-nowrap mb-0">Invoice Amount</strong>
                                    <br />
                                    Rs.
                                    <NumberField
                                        value={record?.totalInvoiceAmount || 0}
                                        options={{
                                            notation: "standard",
                                        }}
                                    />
                                </p>
                            }
                            {
                                (record?.processingFeePercent > 0) && <p>
                                    <strong className="white-space-nowrap mb-0">Processing Fee</strong>
                                    <br />

                                    <NumberField
                                        value={record?.processingFeePercent || 0}
                                        options={{
                                            notation: "standard",
                                        }}
                                    />%
                                </p>
                            }
                            {
                                (record?.gstPercent > 0) && <p>
                                    <strong className="white-space-nowrap mb-0">GST</strong>
                                    <br />
                                    Rs.
                                    <NumberField
                                        value={record?.gstPercent || 0}
                                        options={{
                                            notation: "standard",
                                        }}
                                    />
                                </p>
                            }
                        </>

                    )}
                />
                <Table.Column
                    title='Final Amount'
                    dataIndex="finalAmountToPay"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <strong className="white-space-nowrap mb-0">
                                Rs.
                                <NumberField
                                    value={value || 0}
                                    options={{
                                        notation: "standard",
                                    }}
                                />
                            </strong>
                        </Space>
                    }
                />

                <Table.Column
                    title='Notes'
                    dataIndex="lastUpdateNotes"
                    render={(value) =>
                        <Space size={'small'}>
                            {value || '-'}
                        </Space>
                    }
                />
                <Table.Column dataIndex="paymentStatus" title="Status"
                    render={(_, record: IAppIntegration) => (
                        <Space align='baseline' direction="vertical">
                            {
                                {
                                    "FAILED": <Tag color="red">{record?.paymentStatus}</Tag>,
                                    "PAID": <Tag color="green">{record?.paymentStatus}</Tag>,
                                    "PROCESSING": <Tag color="cyan">{record?.paymentStatus}</Tag>,
                                    "PENDING": <Tag color="geekblue">{record?.paymentStatus}</Tag>,
                                }[record?.paymentStatus]
                            }
                        </Space>
                    )} />
                <Table.Column<IAppIntegration>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space align='baseline' direction="vertical">
                            {
                                (record?.paymentStatus === paymentStatus.PENDING) && <Button onClick={() => {
                                    setSettlementId(record.id)
                                    setProcessSettlementAmount(record.finalAmountToPay)
                                    processSettlementShow()
                                }} type="dashed">
                                    Process Settlement
                                </Button>
                            }
                            {
                                (record?.paymentStatus === paymentStatus.PENDING) && <Button onClick={() => {
                                    setSettlementId(record.id)
                                    setCurrentSettlementAmount(record.finalAmountToPay)
                                    editSettlementShow()
                                }} type="dashed">
                                    Edit Settlement Amount
                                </Button>
                            }
                            {
                                (record?.paymentStatus === paymentStatus.PROCESSING) && <Button onClick={() => {
                                    setCheckSettlement('process')
                                }} type="dashed">
                                    Check Settlement
                                </Button>
                            }
                            {
                                (record?.paymentStatus === paymentStatus.PENDING || record?.paymentStatus === paymentStatus.FAILED) && <Button onClick={() => {
                                    setSettlementId(record.id)
                                    deleteSettlementShow()
                                }} danger={true} type="dashed" >
                                    Delete Settlement
                                </Button>
                            }
                            {
                                <Button type="primary" href={`/settlement/${record.id}`} >
                                    Show Details
                                </Button>
                            }

                        </Space>
                    )}
                />
            </Table>
            {
                (tableProps?.dataSource && tableProps?.pagination) ? <span>
                    Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                </span> : ''
            }
            <Card className="mt-4"
                size='small' title='Mapped Company'
            >
                <Table loading={onboardCompanyLoading} dataSource={onboardCompany?.data} rowKey="id">
                    <Table.Column
                        title='Company Name'
                        dataIndex="utr"
                        render={(_, record: any) => (
                            <Space size={'small'}>
                                {record?.company?.registeredName || '-'}
                            </Space>
                        )}
                    />
                    <Table.Column
                        title='Brand Name'
                        dataIndex="utr"
                        render={(_, record: any) => (
                            <Space size={'small'}>
                                {record?.company?.brandName || '-'}
                            </Space>
                        )}
                    />
                    <Table.Column
                        title='Action'
                        dataIndex="utr"
                        render={(_, record: any) => (
                            <RemoveCompanyMappingModel refetchOnboaredCompany={refetchOnboaredCompany} mappingId={record?.id} />
                        )}
                    />

                </Table>
            </Card>
            <Modal {...generateSettlementModel}>
                <h4>Are you sure you want to Generate New Settlement Statement?</h4>
                <Form {...generateSettlementForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
            <Modal {...deleteSettlementModel}>
                <h4>Are you sure you want to Delete this Settlement?</h4>
                <Form {...deleteSettlementForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            required: true,

                        }
                    ]}
                        label="Last Update Notes" name="lastUpdateNotes">
                        <TextArea rows={4} placeholder="Last Update Notes" onChange={(event: any) => {
                            (event?.target?.value.trim()) ?
                                deleteSettlementForm.form?.setFieldsValue({ lastUpdateNotes: event?.target?.value }) : deleteSettlementForm.form?.setFieldsValue({ lastUpdateNotes: event?.target?.value.trim() })

                        }} />
                        {/* <Input type={'text'}/> */}
                    </Form.Item>
                </Form>
            </Modal>
            <Modal {...processSettlementModel}>
                <h4>Are you sure you want to process Rs. {<NumberField
                    value={processSettlementAmount || 0}
                    options={{
                        notation: "standard",
                    }}
                />} this Settlement?</h4>
                <Form {...processSettlementForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
            <Modal {...companyOnboardingModel}>
                <OnboardCompanyModel onboardedCompany={onboardCompany?.data} formProps={companyOnboardingForm} />
            </Modal>
            <Modal {...editSettlementModel}>
                <>
                    <h4>Current Settlement Final Amount <br /> Rs. <NumberField
                        value={currentSettlementAmount || 0}
                        options={{
                            notation: "standard",
                        }}
                    />
                    </h4>
                    <Form {...editSettlementForm} layout="vertical">
                        <Form.Item rules={[
                            {
                                required: true,
                            }
                        ]}
                            label="Final Amount To Pay" name="finalAmountToPay">
                            <Input type={'number'} />
                        </Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                            }
                        ]}
                            label="Last Update Notes" name="lastUpdateNotes">
                            <TextArea rows={4} placeholder="Last Update Notes" onChange={(event: any) => {
                                (event?.target?.value.trim()) ?
                                    editSettlementForm.form?.setFieldsValue({ lastUpdateNotes: event?.target?.value }) : editSettlementForm.form?.setFieldsValue({ lastUpdateNotes: event?.target?.value.trim() })

                            }} />
                            {/* <Input type={'text'}/> */}
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </Show>
    );
};
