import React, { useState, useEffect } from "react";
import { Card, List, Typography } from 'antd';

import {
    useSelect, Input, Select,
    Button, Checkbox, Col, Row, Space,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import { DatePicker } from "@pankod/refine-antd";
import { useModalForm, Modal, Form } from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { IProductsBundle, IUser } from "../../../interfaces";
import { IState, InputValidatorRegex, KycStatus } from "../../../interfaces/company";
import { useOne } from "@pankod/refine-core";
const { Title, Text } = Typography;

export const InitiateKycComponent: React.FC<{
    refetchUsers: any,
    userItem: IUser,
    productBundle: IProductsBundle,
    cardIntegrationId: string,
    limitsSpecifiedInCardData: any,
    isPayLaterCandidate: boolean,
    kycDetails: any,
    pbiId: string
}> = ({ limitsSpecifiedInCardData, userItem, cardIntegrationId, kycDetails, isPayLaterCandidate, productBundle, pbiId, refetchUsers }) => {

    let [refetchKYCData, setRefetchKYCData] = useState(false);
    const [checked, setChecked] = useState(true);
    const [requiredPermanentAddress, setRequiredPermanentAddress] = useState(false);
    const [requiredCurrentAddress, setRequiredCurrentAddress] = useState(false);
    let [currentStateSelectValue, setCurrentStateSelectValue] = useState(0);
    let [cureentCityList, setCureentCityList] = useState<IState[]>();

    let [permanentStateSelectValue, setPermanentStateSelectValue] = useState(0);
    let [permanentCityList, setPermanentCityList] = useState<IState[]>();

    //get state list
    const { selectProps: stateSelectProps } = useSelect<IState>({
        dataProviderName: "grapghQl",
        resource: 'getAllState',
        optionLabel: "name",
        optionValue: "id"
    });
    stateSelectProps.showSearch = false

    // current Address
    //get city list
    const { refetch: fetchSateData } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCityByState',
        id: '',
        metaData: { stateId: currentStateSelectValue },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                setCureentCityList(data.data)
            }
        },
    });
    let currentStateSwitch = (id: any) => {
        setCurrentStateSelectValue(id)
    }
    useEffect(() => {
        if (currentStateSelectValue) {
            fetchSateData();
        }
    }, [currentStateSelectValue]);

    // permanent Address
    //get city list
    const { refetch: fetchPermanentSateData } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCityByState',
        id: '',
        metaData: { stateId: permanentStateSelectValue },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                setPermanentCityList(data.data)
            }
        },
    });
    let permanentStateSwitch = (id: any) => {
        setPermanentStateSelectValue(id)
    }
    useEffect(() => {
        if (permanentStateSelectValue) {
            fetchPermanentSateData();
        }
    }, [permanentStateSelectValue]);

    useEffect(() => {
        if (refetchKYCData) {
            // refetchLatestUserEkySession();
        }
    }, [refetchKYCData]);


    const { modalProps: initiateKYCModel, close: initiateKYCClose, submit: initiateKYCSubmit, formProps: initiateKYCForm, show: initiateKYCShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "createAndSendEKycLinkBySuperAdminToUserOfAlreadyOnboardedCompany",
        action: "create",
        metaData: { userId: userItem.id, pbiId, cardIntegrationId, shouldIssueCard: checked },
        redirect: false,
        onMutationSuccess() {
            refetchUsers(true)
        }
    });
    initiateKYCModel.title = `${kycDetails?.status === null ? 're' : ''}Send VKYC link to user`
    initiateKYCModel.centered = true
    initiateKYCModel.width = 600
    initiateKYCModel.onCancel = () => {
        initiateKYCForm.form?.resetFields();
        initiateKYCClose();
    }

    const { modalProps: failedReKYCModel, close: failedReKYCClose, submit: failedReKYCSubmit, formProps: failedReKYCForm, show: failedReKYCShow } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "generateVcipLinkWithUpdatedDetails",
        action: "create",
        metaData: { userId: userItem.id },
        redirect: false,
        onMutationSuccess() {
            refetchUsers(true)
        }
    });
    failedReKYCModel.title = `Resend vKYC link to user`
    failedReKYCModel.centered = true
    failedReKYCModel.width = 600
    failedReKYCModel.onCancel = () => {
        failedReKYCForm.form?.resetFields();
        failedReKYCClose();
    }

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    let handlePermanentAddressValueChange = (value: string) => {
        if (failedReKYCForm.form?.getFieldValue("permanentAddress") || failedReKYCForm.form?.getFieldValue("permanentState") || failedReKYCForm.form?.getFieldValue("permanentCity") || failedReKYCForm.form?.getFieldValue("permanentPinCode")) {
            setRequiredPermanentAddress(true)
        }else {
            setRequiredPermanentAddress(false)
        }
    }
    let handleCurrentAddressValueChange = (value: string) => {
        if (failedReKYCForm.form?.getFieldValue("currentAddress") || failedReKYCForm.form?.getFieldValue("currentState") || failedReKYCForm.form?.getFieldValue("currentCity") || failedReKYCForm.form?.getFieldValue("currentPinCode")) {
            setRequiredCurrentAddress(true)
        }else {
            setRequiredCurrentAddress(false)
        }
    }
    return (
        <>
            <Space direction="horizontal">
                {
                    kycDetails?.status === KycStatus.FAILED ? <Button onClick={() => { failedReKYCShow(); setRefetchKYCData(true) }} type="default">
                        Resend KYC For {productBundle.displayName}
                    </Button> : <Button onClick={() => { initiateKYCShow(); setRefetchKYCData(true) }} type="default">
                        {!kycDetails && `Initiate KYC For ${productBundle.displayName}`}
                        {(kycDetails?.status === null || kycDetails?.status === KycStatus.NOT_SUBMITTED || kycDetails?.status === KycStatus.SUBMITTED) && `Resend KYC For ${productBundle.displayName}`}
                    </Button>
                }
            </Space>
            <Modal {...failedReKYCModel}>
                <Text>Limits can be set from Manage Card page after KYC</Text>
                <List >
                    <Row gutter={10}>
                        <Col lg={12} md={12}>
                            <List.Item >
                                <Title level={5}>
                                    Monthly Limit:
                                    <Text> {limitsSpecifiedInCardData?.monthlyLimit || '-'}</Text>
                                </Title>
                            </List.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <List.Item>
                                <Title level={5}>
                                    Daily Limit:
                                    <Text> {limitsSpecifiedInCardData?.dailyLimit || '-'}</Text>
                                </Title>
                            </List.Item>
                        </Col>
                    </Row>
                </List>
                <Form {...failedReKYCForm} layout="vertical">
                    <Row gutter={16}>
                        <Col lg={12}>
                            <Form.Item rules={[
                                {
                                    message: "Invalid First Name code",
                                    pattern: InputValidatorRegex.ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES,
                                },
                            ]} label="First Name" name="firstName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item rules={[
                                {
                                    message: "Invalid First Name code",
                                    pattern: InputValidatorRegex.ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES,
                                },
                            ]} label="Middle Name" name="middleName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item rules={[
                                {
                                    message: "Invalid Last Name code",
                                    pattern: InputValidatorRegex.ALPHABETS_WITHOUT_CONSECUTIVE_DOUBLE_SPACES,
                                },
                            ]} label="Last Name" name="lastName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <Form.Item hidden={true} label="DOB" name="dateOfBirth">
                            </Form.Item>
                            <Form.Item label="DOB" name="dob" getValueProps={(value) => ({
                                value: value ? dayjs(value) : "",
                            })}>
                                <DatePicker onChange={(event: any) => {
                                    failedReKYCForm.form?.setFieldsValue({ dateOfBirth: event?.toISOString().split('T')[0] })
                                }} />
                            </Form.Item>
                        </Col>
                        {/* obj.input.userDetails.dob = (variable.dob) ? variable.dob.toISOString().split('T')[0] : '' */}

                    </Row>
                    {/* Current Address */}
                    <Col lg={24} md={24} className="p-0">
                        <Card size="small" className="mb-3 ml--1" title='Current Address'>
                            <Row gutter={16}>
                                <Col lg={12}>
                                    <Form.Item rules={[
                                        {
                                            message: "Address required",
                                            required: requiredCurrentAddress,
                                        },
                                        {
                                            message: "Invalid Address code",
                                            pattern: InputValidatorRegex.ADDRESS,
                                        },
                                    ]} label="Address" name="currentAddress">
                                        <Input onChange={(e: any) => {
                                            handleCurrentAddressValueChange(e.target.value)
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        label="State"
                                        name="currentState"
                                        rules={[
                                            {
                                                message: "State required",
                                                required: requiredCurrentAddress,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder='Enter Current State'
                                            onChange={(e: any) => {
                                                let currentState = stateSelectProps.options?.find(it => it.value === e);
                                                failedReKYCForm.form?.setFieldsValue({ currentState: currentState?.label });
                                                failedReKYCForm.form?.setFieldsValue({ currentCity: '' });
                                                setCurrentStateSelectValue(e);
                                                currentStateSwitch(e)
                                                handleCurrentAddressValueChange(e)
                                            }}
                                            {...stateSelectProps} />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        label="City"
                                        name="currentCity"
                                        rules={[
                                            {
                                                message: "City required",
                                                required: requiredCurrentAddress,
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder='Enter current City'
                                            onChange={(e: any) => {
                                                let currentCity = cureentCityList?.find((it: any) => it.value === e);
                                                failedReKYCForm.form?.setFieldsValue({ currentCity: currentCity?.label });
                                                handleCurrentAddressValueChange(e)
                                            }}
                                            options={cureentCityList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item rules={[
                                        {
                                            message: "Pincode required",
                                            required: requiredCurrentAddress,
                                        },
                                        {
                                            message: "Invalid Pincode code",
                                            pattern: InputValidatorRegex.PINCODE,
                                        },
                                    ]} label="Pincode" name="currentPinCode">
                                        <Input onChange={(event: any) => {
                                            failedReKYCForm.form?.setFieldsValue({ currentPinCode: event?.target?.value.replace(/[^0-9]/g, '') })
                                            handleCurrentAddressValueChange(event)
                                        }} maxLength={6} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                    {/* Permanent Address */}
                    <Col lg={24} md={24} className="p-0">
                        <Card size="small" title='Permanent Address'>
                            <Row gutter={16}>
                                <Col lg={12}>
                                    <Form.Item rules={[
                                        {
                                            message: "Address required",
                                            required: requiredPermanentAddress,
                                        },
                                        {
                                            message: "Invalid Address code",
                                            pattern: InputValidatorRegex.ADDRESS,
                                        },

                                    ]} label="Address" name="permanentAddress">
                                        <Input onChange={(e: any) => {
                                            handlePermanentAddressValueChange(e.target.value)
                                        }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        label="State"
                                        name="permanentState"
                                        rules={[
                                            {
                                                message: "State required",
                                                required: requiredPermanentAddress,
                                            }
                                        ]}
                                    >
                                        <Select
                                            placeholder='Enter Cardholder State'
                                            onChange={(e: any) => {
                                                let permanentState = stateSelectProps.options?.find(it => it.value === e);
                                                failedReKYCForm.form?.setFieldsValue({ permanentState: permanentState?.label });
                                                failedReKYCForm.form?.setFieldsValue({ permanentCity: '' });
                                                setPermanentStateSelectValue(e);
                                                permanentStateSwitch(e)
                                                handlePermanentAddressValueChange(e)
                                            }}
                                            {...stateSelectProps} />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        label="City"
                                        name="permanentCity"
                                        rules={[
                                            {
                                                message: "City required",
                                                required: requiredPermanentAddress,
                                            }
                                        ]}
                                    >

                                        <Select
                                            placeholder='Enter permanent City'
                                            onChange={(e: any) => {
                                                let permanentCity = permanentCityList?.find((it: any) => it.value === e);
                                                failedReKYCForm.form?.setFieldsValue({ permanentCity: permanentCity?.label });
                                                handlePermanentAddressValueChange(e)
                                            }}
                                            options={permanentCityList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item rules={[
                                        {
                                            message: "Pincode required",
                                            required: requiredPermanentAddress,
                                        },
                                        {
                                            message: "Invalid Pincode code",
                                            pattern: InputValidatorRegex.PINCODE,
                                        },
                                    ]} label="Pincode" name="permanentPinCode">
                                        <Input onChange={(event: any) => {
                                            failedReKYCForm.form?.setFieldsValue({ permanentPinCode: event?.target?.value.replace(/[^0-9]/g, '') })
                                            handlePermanentAddressValueChange(event?.target?.value)
                                        }} maxLength={6} />
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Form>
            </Modal>
            <Modal {...initiateKYCModel}>
                <Text>Limits can be set from Manage Card page after KYC</Text>

                <List >
                    <Row gutter={10}>
                        <Col lg={12} md={12}>
                            <List.Item >
                                <Title level={5}>
                                    Monthly Limit:
                                    <Text> {limitsSpecifiedInCardData?.monthlyLimit || '-'}</Text>
                                </Title>
                            </List.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <List.Item>
                                <Title level={5}>
                                    Daily Limit:
                                    <Text> {limitsSpecifiedInCardData?.dailyLimit || '-'}</Text>
                                </Title>
                            </List.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <List.Item>
                                <Title level={5}>
                                    PAN:
                                    <Text italic={true}> {userItem?.panNo || '-'}</Text>
                                </Title>
                            </List.Item>
                        </Col>
                    </Row>
                </List>
                <Form {...initiateKYCForm} layout="vertical">
                    <Form.Item >
                        <Checkbox checked={checked} onChange={onChange}>
                            Should Issue Card
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};