import React, { useEffect, useState } from "react";

import { useModalForm, Modal, Checkbox,Space, Form, Button } from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { urlPath } from "../../utility/url";

export const ConvertToPhysicalCardModel: React.FC<{ refetchUsers: any, userCard: any, hasDeliveryAddress: boolean, cardholderUserId: string, pbiId: string }> = ({ userCard, refetchUsers, hasDeliveryAddress, cardholderUserId, pbiId }) => {
    let url = urlPath()
    const [checked, setChecked] = useState(false);

    useEffect(() => {

    });

    //Check User Kyc
    let { modalProps: convertToPhysicalConfirmModel, formProps: convertToPhysicalConfirmForm, show: convertToPhysicalConfirmShow, close: convertToPhysicalConfirmClose } = useModalForm<any>({
        resource: `issuePhysicalFormOfActiveCard`,
        dataProviderName: "grapghQl",
        action: "create",
        metaData: { cardId: userCard?.id, cardholderUserId, pbiId },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchUsers(true)
        }
    });
    convertToPhysicalConfirmModel.title = 'Convert To Physical Card'
    convertToPhysicalConfirmModel.centered = true
    convertToPhysicalConfirmModel.width = 400
    convertToPhysicalConfirmModel.onCancel = () => {
        convertToPhysicalConfirmForm.form?.resetFields();
        convertToPhysicalConfirmClose();
    }

    //hide submit button if no delivery address
    convertToPhysicalConfirmModel.footer = hasDeliveryAddress ? convertToPhysicalConfirmModel.footer : null

    //Check User Kyc
    let { modalProps: activateCardConfirmModel, formProps: activateCardConfirmForm, show: activateCardConfirmShow, close: activateCardConfirmClose } = useModalForm<any>({
        resource: `doFinalCardActivation`,
        dataProviderName: "grapghQl",
        action: "create",
        metaData: { cardId: userCard?.id, cardholderUserId, pbiId, issuePhysicalCard: checked },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchUsers(true)
        }
    });
    activateCardConfirmModel.title = `Activate Card + ${checked ? "Request Physical +" : ""} Notify User`
    activateCardConfirmModel.centered = true
    activateCardConfirmModel.okText = 'Activate Card'
    activateCardConfirmModel.width = 400
    activateCardConfirmModel.onCancel = () => {
        activateCardConfirmForm.form?.resetFields();
        activateCardConfirmClose();
    }

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    return (
        <Space >
            {
                (!userCard.cardActivatedInternallyAt) && <Button onClick={() => { activateCardConfirmShow() }} type="primary">
                    Activate Card + {`${checked ? "Request Physical +" : ""}`} Notify User
                </Button>
            }
            {
                (userCard.cardActivatedInternallyAt && !userCard.issueDateForPhysicalForm) && <Button onClick={() => { convertToPhysicalConfirmShow() }} type="primary">
                    Convert To Physical Card
                </Button>
            }
            <Modal {...convertToPhysicalConfirmModel}>
                {
                    hasDeliveryAddress ? <p>Are you sure you want to convert to physical card?</p> : <p>Are you sure you want to convert to physical card? Please add delivery address first.</p>
                }
                <Form {...convertToPhysicalConfirmForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
            <Modal {...activateCardConfirmModel}>
                <h4>Are you sure you want to convert to physical card? </h4>
                <Form {...activateCardConfirmForm} layout="vertical">
                    <Form.Item >
                        <Checkbox checked={checked} onChange={onChange}>
                            Also dispatch physical card
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};