import { useOne, IResourceComponentsProps } from "@pankod/refine-core";
import React, { useState, useEffect } from "react";
import { List, Typography } from 'antd';
import { useNavigate } from "react-router-dom";

import {
    Show, Card, Row, Input,
    Col, Table, Space, Button,
    DateField, NumberField,
    useSelect, useModal,
} from "@pankod/refine-antd";
import { useModalForm, Modal, Form } from "@pankod/refine-antd";

import "./company.css";

import { ICompany, ICompanyPBI, IPBIMappings, IUser, IUserAddress } from "../../interfaces/company";
import { urlPath } from "../../utility/url";
import { AutoDebitModel } from "./components/autodebit.model";
import { FeatureFlags } from "../../contexts/feature-flags";

import {
    CopyOutlined
} from "@ant-design/icons";
import { AddEditUserComponent } from "./components/addEditUser.component";
import { ManageUserComponent } from "./components/manageUser.component";
import { getGenerateBankMandateLinkShow, getLqProductBundleId, getNameonCard } from "../../utility/companyUtils";
import { ProductsBundlesTable } from "./components/tables/productsBundles.table";
import { AccountManagersModel } from "../../components/model/accountManagers.model";
import { DescriptionItem } from "../../components/text-layout/descriptionItem.text";
import { OnboardProductBundleComponent } from "./components/onboardProductBundle.component";
import { BankModel } from "./components/models/bank.model";
import { RenameCompanyNameModel } from "components/model/renameCompanyName.model";
import { FeeDetailsComponent } from "./components/fee-details.component";
import { InactiveUserModel } from "./components/models/inactiveUser.model";
import { verificationStatus } from "./list";
import { UpdateCompanyAddressModel } from "pages/company/components/models/updateCompanyAddress.model";
import { Role } from "utility/role";
import { CardFeeDetailsComponent } from "./components/card-fee-details.component";
import { VaFundingAccountsTable } from "./components/tables/va-funding-accounts.table";
import { UpdateCompanyDetailsModel } from "./components/models/updateCompanyDetails.model";
import { ConfigureCustomWorkflowModel } from "./components/models/configure-custom-workflow.model";
import { CreateNewRepaymentVaButtonModel } from "./components/models/create-new-repayment-va.model";
import { CancelAutoDebitAuthButtonModel } from "./components/models/cancel-auto-debit-auth.model";
import { CreateDecentroAccountModel } from "./components/models/create-decentro-account.model";
import { RecalculateBalancesOfVirtualAccountsModel } from "./components/models/recalculate-va-balance.model";

const { Title, Text } = Typography;

export const CompanyShow: React.FC<IResourceComponentsProps> = () => {

    let url = urlPath()
    let role = Role()
    const navigate = useNavigate();
    const { features }: any = React.useContext(FeatureFlags);


    // states
    let [refreshCompanyData, setRefreshCompanyData] = useState(false);
    let [refetchUsersList, setRefetchUsersList] = useState(false);
    const [passcode, setPasscode] = useState();

    let [rzpMethod, setRzpMethod] = useState("");
    let [rzpMandateMaxAmountPerCharge, setRzpMandateMaxAmountPerCharge] = useState("");
    let [rzpLastUpdateNotes, setRzpLastUpdateNotes] = useState();
    // let [rzpFailureReason, setRzpFailureReason] = useState();
    let [rzpInvoiceId, setRzpInvoiceId] = useState("");
    let [rzpCustomerId, setRzpCustomerId] = useState("");
    let [rzpOrderId, setRzpOrderId] = useState("");
    let [rzpMandateRegUrl, setRzpMandateRegUrl] = useState("");
    // let [rzpTokenId, setRzpTokenId] = useState();
    // let [lastEventId, setLastEventId] = useState();
    let [textCopy, setTextCopy] = useState("");

    let [addressLine1, setAddressLine1] = useState("");
    let [email, setEmail] = useState("");
    let [panNo, setPanNo] = useState("");
    let [phoneNo, setPhoneNo] = useState("");
    let [addressLine2, setAddressLine2] = useState("");
    let [city, setCity] = useState("");
    let [state, setState] = useState("");
    let [pincode, setPincode] = useState("");

    const refetchCompanyDetails = (message: boolean) => {
        setRefreshCompanyData(message);
    };
    const refetchUsers = (message: boolean) => {
        setRefetchUsersList(message);
    };
    //get company details graphql
    let { data: companyData, isLoading: companyLoading, refetch: refetchCompanyData } = useOne<ICompany>({
        dataProviderName: "grapghQl",
        resource: 'getCompaniesForManagementPortal',
        id: '',
        metaData: { filter: { id: url.lastElement } },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshCompanyData(false);
            },
        },
    });

    useEffect(() => {
        if (companyData?.data === null) {
            navigate('/company', { replace: true });
        }
    }, [companyData]);

    const companyDetail: ICompany | undefined = companyData?.data

    useEffect(() => {
        if (companyDetail) {
            const { addressLine1, addressLine2, city, state, pincode } = companyDetail.companyData ?? {};
            setAddressLine1(addressLine1)
            setAddressLine2(addressLine2)
            setCity(city)
            setState(state)
            setPincode(pincode)

            const { emailId, panNo, phoneNo } = companyDetail ?? {};
            setEmail(emailId)
            setPanNo(panNo)
            setPhoneNo(phoneNo)
        }
    }, [companyDetail]);

    const companyTitle: string | undefined = (companyDetail?.registeredName) ? companyDetail?.registeredName + getNameonCard(companyDetail) : ''
    const isGenerateBankMandateLinkShow: boolean = companyDetail ? getGenerateBankMandateLinkShow(companyDetail) : false

    const nonDeletedPBIsSortedByDate = companyDetail?.nonDeletedPBIsSortedByDate.filter((item: ICompanyPBI) => item !== null) || [] as ICompanyPBI[]
    const onboardedProductBundles: string[] = companyDetail?.nonDeletedPBIsSortedByDate.map((ele: ICompanyPBI) => {
        return ele?.productsBundle.id
    }).filter((id: string | undefined) => id !== null && id !== undefined) || []

    //get accountmanager list
    const { selectProps: accountMangerSelectProps } = useSelect<ICompany>({
        dataProviderName: "grapghQl",
        resource: 'getListOfAccountManagers',
        optionLabel: "displayName",
        optionValue: "id"
    });

    //get Product list
    let { data: productBundlesList } = useOne<ICompanyPBI[]>({
        dataProviderName: "grapghQl",
        resource: 'getProductsBundles',
        id: ''
    });

    const productBundles: ICompanyPBI[] | undefined = productBundlesList?.data

    //get user list
    let { data: userLst, isLoading: userLoading, refetch: refetchUserList } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getUsers',
        id: '',
        metaData: { type: 'query', filter: { company: url.lastElement } },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefetchUsersList(false);
            },
        },
    });


    const closedSelectDropdown = () => {
        // Unsets Background Scrolling to use when SideDrawer/Modal is closed
        document.body.style.overflow = 'unset';
    }
    const openSelectDropdown = () => {
        // Disables Background Scrolling whilst the SideDrawer/Modal is open
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
    }

    useEffect(() => {
        if (refreshCompanyData) {
            refetchCompanyData();
        }
    }, [refreshCompanyData]);
    useEffect(() => {
        if (refetchUsersList) {
            refetchUserList();
        }
    }, [refetchUsersList]);

    // models

    //Auto Debit Model
    const { modalProps: autoDebiModel, formProps: autoDebitForm, close: autoDebitClose, submit: autoDebitSubmit, show: autoDebitShow } = useModalForm<ICompany>({
        resource: "autoDebit",
        dataProviderName: "grapghQl",
        redirect: false,
        action: "create",
        metaData: {
            companyId: url.lastElement
        },
        onMutationSuccess(data: any, variables, context) {
            // window.location.reload()
            refetchCompanyData()
            closedSelectDropdown()
            setRzpMethod(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.method)
            setRzpMandateMaxAmountPerCharge(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.mandateMaxAmountPerCharge)
            setRzpLastUpdateNotes(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.lastUpdateNotes)
            // setRzpFailureReason(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.failureReason)
            setRzpInvoiceId(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.rzpInvoiceId)
            setRzpCustomerId(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.rzpCustomerId)
            setRzpOrderId(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.rzpOrderId)
            setRzpMandateRegUrl(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.rzpMandateRegUrl)
            // setRzpTokenId(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.rzpTokenId)
            // setLastEventId(data?.rawData?.initiateRepaymentAutoDebitAuthorization?.lastEventId)
            autoDebitResShow()

        }
    });
    autoDebiModel.title = 'Generate Auto Debit Link'
    autoDebiModel.centered = true
    autoDebiModel.onCancel = () => {
        closedSelectDropdown();
        autoDebitClose();
    }
    // autoDebiModel.onOk = ()=>{
    //     closedSelectDropdown();
    //     autoDebitSubmit();
    // }
    // autoDebiModel.width = 400

    // auto Debit responce model
    const { modalProps: autoDebitResModel, close: autoDebitResClose, show: autoDebitResShow } = useModal();

    autoDebitResModel.title = ''
    autoDebitResModel.centered = true
    autoDebitResModel.okText = 'ok'
    autoDebitResModel.cancelText = 'close'
    autoDebitResModel.okButtonProps = {
        disabled: true,
    }
    autoDebitResModel.onCancel = () => {
        closedSelectDropdown();
        autoDebitResClose();
        // window.location.reload()
    }
    autoDebitResModel.width = 600
    autoDebitResModel.footer = [
        <Button key="submit" onClick={() => { autoDebitResClose(); refetchCompanyDetails(true) }} type="default">
            Close
        </Button>
    ]

    return (
        <Show isLoading={companyLoading || refreshCompanyData || refetchUsersList} title={companyTitle} headerButtons={({ defaultButtons }) => (
            <>
                {
                    companyDetail && <ConfigureCustomWorkflowModel
                        refetchCompanyDetail={refetchCompanyDetails}
                        companyId={companyDetail.id}
                        companyDetail={companyDetail} />
                }
                {
                    (features.isGenerateAutoDebit && isGenerateBankMandateLinkShow) && <Button disabled={!companyDetail?.nonDeletedPBIsSortedByDate.find((ele) => ele?.repaymentAutoDebitRule)} onClick={() => {
                        openSelectDropdown();
                        autoDebitShow()
                    }} type="default">Bank Mandate Registration</Button>
                }
            </>
        )}>
            <Row onClick={closedSelectDropdown} className="company-wrapper" gutter={[11, 11]}>
                <Col lg={6} md={6}>
                    <Card size="small" className="h-100">
                        <Row>
                            <Col lg={12}>
                                <DescriptionItem title="Category" content={(companyDetail?.borrowerCategory) ? companyDetail?.borrowerCategory : '-'} />
                            </Col>
                            <Col lg={12}>
                                <DescriptionItem title="Company Verification" content={companyDetail?.companyVerification} />
                            </Col>
                            <Col lg={24}>
                                {
                                    (companyDetail && !companyLoading) && <RenameCompanyNameModel refreshAccountManager={refetchCompanyDetails} companyDetail={companyDetail} />
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={3} md={3}>
                    <Card size="small" className="h-100">
                        <DescriptionItem title="Account Manager" content={
                            <Space direction="vertical">
                                {(companyDetail?.primaryAccountManagerUser) ? <Text>{companyDetail?.primaryAccountManagerUser?.manager?.firstName + ' ' + companyDetail?.primaryAccountManagerUser?.manager?.lastName}</Text> : '-'}
                                <AccountManagersModel refreshAccountManager={refetchCompanyDetails} accountMangerSelectProps={accountMangerSelectProps} companyDetail={companyDetail} />
                            </Space>
                        } />
                    </Card>
                </Col>

                {(companyDetail?.id && companyDetail?.companyVerification === verificationStatus.ONBOARDED) && <Col lg={4} md={4}>
                    <FeeDetailsComponent cardTitle={'KODO Pay Fee'} refetchCompanyDetail={refetchCompanyDetails} companyDetail={companyDetail} companyId={companyDetail?.id} />
                </Col>}
                {(productBundles && getLqProductBundleId(productBundles) && companyDetail?.id && companyDetail?.companyVerification === verificationStatus.ONBOARDED) && <Col lg={4} md={4}>
                    <CardFeeDetailsComponent lqProductBundleId={getLqProductBundleId(productBundles)} cardTitle={'Card Fee'} refetchCompanyDetail={refetchCompanyDetails} companyDetail={companyDetail} companyId={companyDetail?.id} />
                </Col>}
                {
                    (companyDetail?.repaymentAutoDebitAuth?.rzpMandateRegUrl) && <Col lg={5} md={12}>
                        <Card size="small" className="h-100">
                            <Row gutter={4}>
                                <Col lg={24}>
                                    <DescriptionItem title="Previous Bank mandates" content={
                                        <Space direction="horizontal">
                                            Rs.<NumberField
                                                value={companyDetail?.repaymentAutoDebitAuth?.mandateMaxAmountPerCharge || 0}
                                                options={{
                                                    notation: "standard",
                                                }}
                                            />
                                            <a rel="noreferrer" target="_blank" href={companyDetail?.repaymentAutoDebitAuth?.rzpMandateRegUrl || ''}>
                                                {companyDetail?.repaymentAutoDebitAuth?.rzpInvoiceId}
                                            </a>
                                        </Space>
                                    } />
                                    {/* <Text>{(record?.repaymentAutoDebitAuth) ? record?.borrowerCategory : '-'}</Text> */}
                                    {(role.isCREDIT_ANALYST) && companyDetail && <CancelAutoDebitAuthButtonModel refetchCompanyDetail={refetchCompanyDetails} companyId={companyDetail.id} />}
                                </Col>

                            </Row>

                        </Card>
                    </Col>
                }
                <Col lg={7} md={12}>
                    <Card size="small" className="h-100">
                        <DescriptionItem
                            className="item-profile-wrapper"
                            title={`Company Address`}
                            content={`${[[addressLine1, addressLine2, city, state].filter(Boolean).join(', ')].concat([pincode].filter(Boolean)).join(' - ')} \n GST Number :- ${companyDetail?.gstNo || '-'}`}
                        />
                        {
                            (companyDetail && !companyLoading) && <UpdateCompanyAddressModel refetchCompanyDetail={refetchCompanyDetails} companyDetail={companyDetail} />
                        }
                    </Card>
                </Col>
                <Col lg={7} md={12}>
                    <Card size="small" className="h-100">
                        <DescriptionItem
                            className="item-profile-wrapper"
                            title={`Company Details`}
                            content={<Space direction="vertical">
                                <Text>Pan Number :- {panNo || '-'}</Text>
                                <Text>Email :- {email || '-'}</Text>
                                <Text>Phone Number :- {phoneNo || '-'}</Text>
                            </Space>
                            }
                        />
                        {
                            (companyDetail && !companyLoading) && <UpdateCompanyDetailsModel refetchCompanyDetail={refetchCompanyDetails} companyDetail={companyDetail} />
                        }
                    </Card>
                </Col>
                <Col lg={24} md={24}>
                    <Space direction="horizontal">
                        {companyDetail && <RecalculateBalancesOfVirtualAccountsModel refetchCompanyDetail={refetchCompanyDetails} companyId={companyDetail.id} />}
                        {companyDetail && <CreateNewRepaymentVaButtonModel refetchCompanyDetail={refetchCompanyDetails} companyId={companyDetail.id} />}
                        {companyDetail && <CreateDecentroAccountModel refetchCompanyDetail={refetchCompanyDetails} companyId={companyDetail.id} />}
                    </Space>
                </Col>
                <Col style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} lg={24} md={24}>
                    {
                        productBundles
                        && productBundles.length
                        && <ProductsBundlesTable nonDeletedPBIsSortedByDate={nonDeletedPBIsSortedByDate} refetchUsers={refetchUsers} companyDetail={companyDetail} productBundlesList={productBundles} refetchCompanyDetail={refetchCompanyDetails} />
                    }
                    <OnboardProductBundleComponent refetchCompanyDetail={refetchCompanyDetails} accountMangerSelectProps={accountMangerSelectProps} companyDetail={companyDetail} features={features} onboardedProductBundles={onboardedProductBundles} productBundles={productBundles} />
                </Col>
                <Col style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} lg={24} md={24}>
                    <Title level={5}>Directors</Title>
                    <Table pagination={false} loading={userLoading} dataSource={companyDetail?.companyData?.directors} rowKey="id">
                        <Table.Column
                            title="Full Name"
                            render={(_, record: IUser) =>
                                <Space align='baseline' direction="vertical">
                                    <p>
                                        {(record?.firstName) ? record?.firstName : ""}  {(record?.middleName) ? record?.middleName : ""}  {(record?.lastName) ? record?.lastName : ""}
                                    </p>
                                </Space>
                            }
                        />
                        <Table.Column dataIndex="dob" render={(value) => (value) ? <DateField value={value} format="LLL" /> : '-'} title="Date Of birth" />
                    </Table>
                </Col>
                <Col style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} lg={24} md={24}>
                    <List >
                        <List.Item>
                            <Title level={5}>Users</Title>
                            {
                                (role.isCREDIT_ANALYST || role.isAccountManager) && companyDetail && <InactiveUserModel refetchCompanyDetail={refetchCompanyDetails} refetchUsers={refetchUsers} companyId={companyDetail?.id} />
                            }
                        </List.Item>
                    </List>
                    <Table pagination={false} loading={userLoading} dataSource={userLst?.data} rowKey="id">
                        <Table.Column
                            dataIndex="displayName" title="Full Name" render={(_, record: IUser) =>
                                <DescriptionItem title={record?.displayName} content={record?.emailId} />
                            } />
                        <Table.Column dataIndex="m2pCustomerId" title="M2P Customer Id" />
                        <Table.Column
                            title="Product Bundles"
                            render={(_, record: IUser) =>
                                <Space align='baseline' direction="vertical">
                                    {
                                        record?.pbiMappings.map((item: IPBIMappings, index: number) => {
                                            return <li key={index}>{item.pbi.productsBundle.description}</li>
                                        })
                                    }
                                </Space>
                            }
                        />
                        {/* <Table.Column
                            title="Alerts"
                            render={(_, record: IUser) =>
                                <Space align='baseline' direction="vertical">
                                    {
                                        (record?.kycStatus === 'PENDING') && <Tag color="cyan">{record?.kycStatus}</Tag>
                                    }
                                    {
                                        (record?.kycStatus === 'SUBMITTED') && <Tag color="blue">{record?.kycStatus}</Tag>
                                    }
                                    {
                                        (!record?.kycStatus) && <Tag color="red">NOT STARTED</Tag>
                                    }
                                </Space>
                            }
                        /> */}
                        <Table.Column
                            title="Roles To Display"
                            render={(_, record: IUser) =>
                                <Space align='baseline' direction="vertical">
                                    {
                                        record?.rolesToDisplay.map((item: string, index: number) => {
                                            return <li key={index}>{item}</li>
                                        })
                                    }
                                </Space>
                            }
                        />

                        <Table.Column
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record: IUser) => (
                                <Space className="flex-nowrap" wrap>
                                    {
                                        (features.isEditUser) && <AddEditUserComponent refetchUsers={refetchUsers} refetchCompanyDetail={refetchCompanyDetails} userItem={record} pbiId={''} isAddUser={false} />
                                    }
                                    {/* <InitiateKycComponent isPayLaterCandidate={companyDetail?.productsBundleIssuance?.sharedPayLaterBeneficiaryId && companyDetail?.companyVerification === 'ONBOARDED'} productsBundleIssuances={companyDetail?.nonDeletedPBIsSortedByDate} userItem={record} cardIntegrations={companyDetail?.productsBundleIssuance?.productsBundle.cardIntegrations ? companyDetail?.productsBundleIssuance?.productsBundle.cardIntegrations : []} /> */}
                                    {
                                        !refetchUsersList && <ManageUserComponent refetchCompanyDetail={refetchCompanyDetails} refetchUsers={refetchUsers} isPayLaterCandidate={companyDetail?.productsBundleIssuance?.sharedPayLaterBeneficiaryId && companyDetail?.companyVerification === verificationStatus.ONBOARDED}
                                            productsBundleIssuances={nonDeletedPBIsSortedByDate}
                                            userItem={record}
                                            fetchUserDetails={true}
                                            companyId={companyDetail?.id}
                                        />
                                    }

                                    {/* <Space >
                                        <Button danger={true} onClick={() => { setIsDeleteActive(false); setreEnable(record?.id); setIsLoading(true); setUserAccountId(record?.userAccounts[0].id); deleteUserShow() }} icon={<DeleteOutlined />} />
                                    </Space> */}
                                </Space>
                            )}
                        />
                    </Table>
                </Col>
                <Col style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} lg={24} md={24}>
                    {companyDetail && <VaFundingAccountsTable companyDetail={companyDetail} />}
                </Col>
                <Col style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} lg={24} md={24}>
                    <List >
                        <List.Item>
                            <Title level={5}>Bank Details</Title>
                            <BankModel refetchCompanyDetail={refetchCompanyDetails} companyDetail={companyDetail} />
                        </List.Item>
                    </List>
                    <Table dataSource={companyDetail?.bank} rowKey="id">
                        <Table.Column title="Account Name" dataIndex="accountName" />
                        <Table.Column title="Account Number" dataIndex="accountNumber" />
                        <Table.Column title="Mobile No" dataIndex="mobileNo" />
                        <Table.Column title="IFSC" dataIndex="ifsc" />
                        <Table.Column title="Bank Name" dataIndex="bankName" />
                    </Table>
                </Col>
            </Row>

            <Modal {...autoDebiModel}>
                <AutoDebitModel formProps={autoDebitForm} companyId={url.lastElement} companyDetails={companyDetail} />
            </Modal>
            <Modal {...autoDebitResModel}>
                <h4>{rzpLastUpdateNotes}</h4>
                <Card
                    style={{
                        textTransform: 'capitalize',
                        borderRadius: "0.8rem",
                    }}
                >
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Title className="mb-0" level={5}>Razorpay Customer Id</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpCustomerId); navigator.clipboard.writeText(rzpCustomerId) }}><CopyOutlined /> {rzpCustomerId}</Text>
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Title className="mb-0" level={5}>Razorpay Invoice Id</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpInvoiceId); navigator.clipboard.writeText(rzpInvoiceId) }}><CopyOutlined /> {rzpInvoiceId}</Text>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Title className="mb-0" level={5}>Razorpay Mandate Reg Url</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpMandateRegUrl); navigator.clipboard.writeText(rzpMandateRegUrl) }}><CopyOutlined /> {rzpMandateRegUrl}</Text>
                            {/* <a rel="noreferrer" target="_blank" href={rzpMandateRegUrl}>Mandate Url</a> */}
                        </Col>
                        <Col lg={12} className="mb-3">
                            <Title className="mb-0" level={5}>Razorpay Order Id</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpOrderId); navigator.clipboard.writeText(rzpOrderId) }}><CopyOutlined /> {rzpOrderId}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Title className="mb-0" level={5}>Razorpay mandate Max Amount</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpMandateMaxAmountPerCharge); navigator.clipboard.writeText(rzpMandateMaxAmountPerCharge) }}><CopyOutlined /> {rzpMandateMaxAmountPerCharge}</Text>
                        </Col>
                        <Col lg={12}>
                            <Title className="mb-0" level={5}>Method</Title>
                            <Text className=" pointer" onClick={() => { setTextCopy(rzpMethod); navigator.clipboard.writeText(rzpMethod) }}><CopyOutlined /> {rzpMethod}</Text>
                        </Col>
                    </Row>
                </Card>
                <div className="p-2">
                    {
                        (textCopy !== '') ? <Text> Text Copied <Title className="mt-0 text-lowercase" level={5}>{textCopy}</Title></Text> : ''
                    }
                </div>
            </Modal>
        </Show>

    );
};
