import React from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Typography,
    Input
} from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;
const { TextArea } = Input;

export const CloseBundleButtonModel: React.FC<{ refetchCompanyDetail: any, pbiId: string, companyId: string }> = ({ refetchCompanyDetail, pbiId, companyId }) => {

    const { modalProps: deleteUserModel, formProps: deleteUserForm, show: deleteUserShow } = useModalForm<any>({
        resource: "credit-product-bundle-issuance/deactivate-via-internal-portal",
        action: "create",
        metaData: { pbiId: pbiId, companyId },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    deleteUserModel.title = 'Close bundle'
    deleteUserModel.confirmLoading = true
    deleteUserModel.centered = true
    deleteUserModel.okText = 'Continue'
    deleteUserModel.width = 500

    return (
        <Space >
            <Button danger={true} onClick={() => { deleteUserShow() }} icon={<DeleteOutlined />} > Close Bundle</Button>

            <Modal {...deleteUserModel}>
                <Title level={3}>Are you sure you want to Close this Bundle?</Title>
                <Form {...deleteUserForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="Close Bundle" name="notes">
                        <TextArea rows={4} placeholder={"Reasons To Close Bundle"} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Password is required',
                            required: true,
                        }
                    ]} label="Password" name="passcode">
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                </Form>
            </Modal>

        </Space>
    );
};