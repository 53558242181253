import React from "react";
import {
    Modal, Space,
    Button,
    Form,
    useModalForm,
} from "@pankod/refine-antd";

export const RecalculateBalancesOfVirtualAccountsModel: React.FC<{ refetchCompanyDetail: any, companyId: string }> = ({ refetchCompanyDetail, companyId }) => {

    const { modalProps: recalculateBalancesOfVirtualAccountsModel, formProps: recalculateBalancesOfVirtualAccountsForm, show: recalculateBalancesOfVirtualAccountsShow, close: recalculateBalancesOfVirtualAccountsClose } = useModalForm({
        resource: "recalculateBalancesOfVirtualAccountsViaInternalPortal",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });

    recalculateBalancesOfVirtualAccountsModel.title = 'Recalculate VA Balance'
    recalculateBalancesOfVirtualAccountsModel.confirmLoading = true
    recalculateBalancesOfVirtualAccountsModel.centered = true
    recalculateBalancesOfVirtualAccountsModel.width = 500

    return (
        <Space >
            <Button onClick={() => { recalculateBalancesOfVirtualAccountsShow() }}  >
                Recalculate VA Balance
            </Button>

            <Modal {...recalculateBalancesOfVirtualAccountsModel}>
                <Form {...recalculateBalancesOfVirtualAccountsForm} layout="vertical">
                    <Form.Item >
                        <></>
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};