import React from "react";
import {
    Form,
    useModalForm,
    Space,
    Button,
    Modal,
} from "@pankod/refine-antd";
import { Typography } from 'antd';
import { SettlementEventType } from "interfaces/mmt";

const { Text } = Typography;

export const InitiateFundSettlementModel: React.FC<{ refetchActiveFeeStatementsDetails: any, statementId: string, settlementAccountId: string, eventType: string }> = ({ refetchActiveFeeStatementsDetails, statementId, settlementAccountId, eventType }) => {

    let { modalProps: initiateFundSettlementModel, formProps: initiateFundSettlementForm, show: initiateFundSettlementShow } = useModalForm({
        resource: "initiateFundSettlement",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            eventType: eventType,
            statementId: statementId,
            ...(settlementAccountId && { settlementAccountId: settlementAccountId }),
        },
        redirect: false,
        onMutationSuccess() {
            refetchActiveFeeStatementsDetails(true)
        }
    });
    initiateFundSettlementModel.title = 'Initiate Fund Settlement'
    initiateFundSettlementModel.centered = true
    initiateFundSettlementModel.okText = 'Initiate Fund Settlement'
    initiateFundSettlementModel.width = 500


    return (
        <Space >
            <Button className="credit-btn" onClick={() => { initiateFundSettlementShow() }} type="default">
                Initiate Fund Settlement
            </Button>

            <Modal {...initiateFundSettlementModel}>
                <Text>Are you sure you want Initiate Fund Settlement?</Text>

                <Form {...initiateFundSettlementForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
        </Space>
    );
};