import { verificationStatus } from "pages/company/list"
import { ICompany, UserAccount } from "../../interfaces"
import { ICompanyPBI, IState, ProductBundle, UserAccountStatus } from "../../interfaces/company"
import { SettlementBankAccountsList } from "interfaces/mmt"


export const getNameonCard = (company: ICompany): string => {
    return (company?.nameOnCard) ? `( ${company?.nameOnCard} )` : ''
}

export const getActiveBankStatement = (bankAccountList: SettlementBankAccountsList[]): string => {
    const bankAccount = bankAccountList.pop()
    return bankAccount?.id || ''
}

export const getStateNameById = (stateId: number, stateList: any): string => {    
    const state = stateList.find((state: IState) => state.value === stateId);
    return state ? state.label : '';
}

export const getGenerateBankMandateLinkShow = (company: ICompany): boolean => {
    // company[0].nonDeletedPBIsSortedByDate.map((item: any, index: number) => {
    //     if (item?.productsBundle?.id === pbKodoCredit) {
    //         setAutodeLinkShow(true)
    //     }
    // })
    //  && record?.companyVerification === 'ONBOARDED'
    return company?.companyVerification === 'ONBOARDED'
}
export const getUserAccount = (userAccounts: UserAccount[]): string => {
    const itemWithActivatedStatus = userAccounts.find(item => item.status === UserAccountStatus.ACTIVATED || item.status === UserAccountStatus.NOT_ACTIVATED_YET);
    return itemWithActivatedStatus?.id || '';
}
export const getLqProductBundleId = (productBundles: ICompanyPBI[]): string => {
    return productBundles.find(item => item.displayName === ProductBundle.KODO_LIVQUIK_PREPAID)?.id || '';
}
export const getUserAccountStatus = (userAccounts: UserAccount[]): string => {
    const itemWithActivatedStatus = userAccounts.find(item => item.status === UserAccountStatus.ACTIVATED || item.status === UserAccountStatus.NOT_ACTIVATED_YET);
    return itemWithActivatedStatus?.status || '';
}
export const isCreditCompany = (company: ICompany): boolean => {
    return company?.companyVerification === verificationStatus.ONBOARDED && company.nonDeletedPBIsSortedByDate.some((pbi) => pbi.companyProductEnabled.cards === true || pbi.companyProductEnabled.loans === true);
}
export const getCompanyDetailsbyID = (companyList: readonly ICompany[] | undefined, companyId: string): ICompany => {
    const companyFilter = companyList?.filter((company) => company.id === companyId) || []
    return companyFilter.length > 0 ? companyFilter[0] : {} as ICompany;
}