import React, { useEffect, useState } from "react";
import {
    Input,
    Form,
    useModalForm,
    Space,
    Button,
    Modal,
} from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { InputValidatorRegex } from "interfaces/company";

export const AddBankAccountModel: React.FC<{}> = ({ }) => {

    const [ifscValue, setIFSCValue] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");
    const [ifscError, setIFSCError] = useState<any>("");

    let { modalProps: addBankAccountModel, formProps: addBankAccountForm, show: addBankAccountShow } = useModalForm({
        resource: "addnewSettlementbankAccDetails",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {},
        redirect: false,
        onMutationSuccess() {
            // refetchCompanyDetail(true)
        }
    });
    addBankAccountModel.title = 'Add Bank Details'
    addBankAccountModel.centered = true
    addBankAccountModel.width = 500


    const { refetch: refetchPostsIfsc } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getBankBranchDescriptionByIfsc',
        id: '',
        metaData: {
            ifsc: ifscValue,
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                (data.error) ? setIFSCError("error") : setIFSCError(""); setBankName(data?.data)
                

            },
        },
    });

    useEffect(() => {
        if (ifscValue !== '' && ifscValue.length > 10) {
            refetchPostsIfsc();
        }
    }, [ifscValue]);

    return (
        <Space >
            <Button className="credit-btn" onClick={() => { addBankAccountShow() }} type="default">
                Add Settlement Bank Account
            </Button>

            <Modal {...addBankAccountModel}>
                <Form {...addBankAccountForm} layout="vertical">
                   
                    <Form.Item rules={[
                        {
                            message: "Invalid IFSC code",
                            pattern: InputValidatorRegex.ALPHA_NUMERIC_WO_SPACE,
                        },
                        {
                            message: 'IFSC Code is required',
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (ifscError) {
                                    return Promise.reject(
                                        new Error("Invalid IFSC code"),
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]} label="IFSC Code"
                        extra={bankName}
                        name="ifsc">
                        <Input status={ifscError} maxLength={11} onChange={(event: any) => {
                            setIFSCValue(event?.target?.value.replace(/[^a-zA-Z0-9]/g, ''))
                            addBankAccountForm.form?.setFieldsValue({ ifsc: event?.target?.value.replace(/[^a-zA-Z0-9]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Bank Name is required',
                            required: true,
                        },
                        {
                            message: 'Enter correct Bank Name',
                            pattern: InputValidatorRegex.BENEFICIARY_NAME_REGEX,
                        },
                    ]} label="Bank Name" name="bankName">
                        <Input />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Account Holder Name is required',
                            required: true,
                        },
                        {
                            message: 'Enter correct Account Holder Name',
                            pattern: InputValidatorRegex.BENEFICIARY_NAME_REGEX,
                        },
                    ]} label="Account Holder Name"
                        name="accountHolderName">
                        <Input onChange={(event: any) => {
                            addBankAccountForm.form?.setFieldsValue({ accountHolderName: event?.target?.value.replace(/[^a-z A-Z]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Account Number is required',
                            required: true,
                        },
                        {
                            message: 'Enter correct Account Number',
                            pattern: InputValidatorRegex.BANK_ACCOUNT_NUMBER,
                        },
                        // {
                        //     message: "Invalid Account Number",
                        //     pattern: /^[0-9]{1,20}$/,
                        // }
                    ]} label="Account Number"
                        name="accountNumber">
                        <Input onChange={(event: any) => {
                            addBankAccountForm.form?.setFieldsValue({ accountNumber: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Maximum of 10 letter are allowed Mobile Number',
                            max: 10,
                        },
                        {
                            message: 'Enter correct Mobile Number',
                            pattern: InputValidatorRegex.INDIAN_MOBILE_NO,
                        },
                    ]} label="mobile" name="mobileNo">
                        <Input maxLength={10} onChange={(event: any) => {
                            addBankAccountForm.form?.setFieldsValue({ mobileNo: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Enter Correct Email',
                            pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                        }
                    ]} label="email" name="email">
                        <Input  />
                    </Form.Item>
                </Form>
            </Modal>
        </Space>
    );
};