import React, { useState } from "react";
import {
    Modal, Space,
    Button,
    Form,
    useModalForm,
    Checkbox,
    Typography,
} from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
const { Text } = Typography;

export const CreateNewRepaymentVaButtonModel: React.FC<{ refetchCompanyDetail: any, companyId: string }> = ({ refetchCompanyDetail, companyId }) => {
    const [checked, setChecked] = useState(true);

    const { modalProps: createNewRepaymentVaButtonModel, formProps: createNewRepaymentVaButtonForm, show: createNewRepaymentVaButtonShow, close: createNewRepaymentVaButtonClose } = useModalForm({
        resource: "createNewRepaymentVaViaInternalPortal",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId,
            sendEmailNotificationToCompanyAdmin: checked,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });

    createNewRepaymentVaButtonModel.title = 'Create new repayment va'
    createNewRepaymentVaButtonModel.confirmLoading = true
    createNewRepaymentVaButtonModel.centered = true
    createNewRepaymentVaButtonModel.width = 500
    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    return (
        <Space >
            <Button onClick={() => { createNewRepaymentVaButtonShow() }}  >
                Create New Repayment VA
            </Button>

            <Modal {...createNewRepaymentVaButtonModel}>
                <Text>Company Admin will get a Email Notification</Text>
                <Form {...createNewRepaymentVaButtonForm} layout="vertical">
                    <></>
                    {/* <Form.Item >
                        <Checkbox checked={checked} onChange={onChange}>
                            Send Email Notification To Company Admin
                        </Checkbox>
                    </Form.Item> */}
                </Form>
            </Modal>
        </Space>
    );
};