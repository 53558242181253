import React, { useEffect, useState } from "react";
import { useOne } from "@pankod/refine-core";
import {
    useModalForm, Modal, Space, Form,
    Row,
    Col,
    Button,
    Input,
    Radio,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import {
    DatePicker,
    Checkbox,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import { ICompanyPBI, IUser } from "../../../../interfaces";
import { KycType, IState, InputValidatorRegex } from "../../../../interfaces/company";
import { urlPath } from "../../../../utility/url";
enum CompanyRoles {
    companyAdmin = 'Company Admin',
    accountant = 'Accountant',
    cardholder = 'Cardholder',
}
export const AssignBundleModel: React.FC<{ refetchUsers: any, refetchCompanyDetail: any, userItem: IUser, pbiId: string, cardPbi: ICompanyPBI }> = ({ userItem, cardPbi, pbiId, refetchCompanyDetail, refetchUsers }) => {
    let url = urlPath()
    let [kyctype, setKycType] = useState(userItem.expectedKycModeForIssuingCard || KycType.electronic);
    let [userState, setUserState] = useState(0);
    let [cityList, setCityList] = useState();
    //get state list
    const { selectProps: stateSelectProps } = useSelect<IState>({
        dataProviderName: "grapghQl",
        resource: 'getAllState',
        optionLabel: "name",
        optionValue: "id"
    });
    stateSelectProps.showSearch = false
    const { refetch: processRefunds } = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getCityByState',
        id: '',
        metaData: { stateId: userState },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                setCityList(data.data)
            },
            onError: (error) => {
                // console.log('search error', error);
            },
            onSettled: (data, error) => {
                // console.log('search error', data, error);
            },
        },
    });
    let paymentSwitch = (id: any) => {
        setUserState(id)
    }
    useEffect(() => {
        if (userState) {
            processRefunds();
        }
    }, [userState]);
    //Add user Model
    const { modalProps: assignBundleModel, formProps: assignBundleForm, show: assignBundleShow, close: assignBundleClose } = useModalForm<any>({
        resource: userItem.expectedKycModeForIssuingCard === "PAPER" ? "updateCardholderForPaperKyc" : "updateCardholderForEKyc",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            company: url.lastElement,
            pbiId: pbiId,
            id: userItem.id,
        },
        redirect: false,
        onMutationSuccess() {
            // refetchCompanyDetail(true)
            refetchUsers(true)
        }
    });

    assignBundleModel.title = `Assign ${cardPbi.productsBundle.displayName}`
    assignBundleModel.centered = true
    assignBundleModel.width = 800
    assignBundleModel.onCancel = () => {
        assignBundleForm.form?.resetFields();
        assignBundleClose();
    }

    return (
        <Space>
            <Button className="credit-btn" type="primary" onClick={() => {
                assignBundleShow();
            }}>
                Assign {cardPbi.productsBundle.displayName}
            </Button>

            <Modal {...assignBundleModel}>
                <h4>Are you sure you want Add {cardPbi.productsBundle.displayName} product Bundle?</h4>

                <Form {...assignBundleForm} initialValues={{
                    id: userItem.id,
                    firstName: userItem.firstName,
                    lastName: userItem.lastName,
                    mobileNo: userItem.mobileNo,
                    emailId: userItem.emailId,
                    panNo: userItem.panNo,
                    cardHolderAccess: (userItem.rolesToDisplay?.includes(CompanyRoles.companyAdmin)),
                    dailyLimit: userItem.dailyLimit,
                    monthlyLimit: userItem.monthlyLimit,
                    expectedKycModeForIssuingCard: userItem.expectedKycModeForIssuingCard || KycType.electronic,
                }} layout="vertical">
                    <Form.Item label="KYC Type" name="expectedKycModeForIssuingCard" rules={[
                        {
                            required: true,
                        },
                    ]} initialValue={KycType.electronic}>
                        <Radio.Group>
                            <Radio onChange={(e) => { setKycType(e.target.value); }} value={KycType.paper}>Paper KYC</Radio>
                            <Radio onChange={(e) => { setKycType(e.target.value); }} value={KycType.electronic}>vKYC</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        (kyctype === KycType.electronic) && <Row gutter={16}>
                            <Col lg={10}>
                                <Form.Item className="w-100" rules={[
                                    {
                                        message: 'First Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct First Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} label="First Name" name="firstName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={14}>
                                <Form.Item label="Last Name" rules={[
                                    {
                                        message: 'Last Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct Last Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} className="w-100" name="lastName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Mobile Number is required',
                                        required: true,
                                        max: 10,
                                    },
                                    {
                                        message: 'Enter correct Mobile Number',
                                        pattern: InputValidatorRegex.INDIAN_MOBILE_NO,
                                    },
                                ]} label="Mobile Number" name="mobileNo">
                                    <Input onChange={(event: any) => {
                                        assignBundleForm.form?.setFieldsValue({ mobileNo: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Email ID is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct email address',
                                        pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                                    },
                                ]} label="Email ID" name="emailId">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={24}>
                                <Form.Item rules={[
                                    {
                                        message: 'Enter correct PAN number',
                                        required: true,
                                        max: 10,
                                    },
                                    {
                                        message: "PAN number should be CZTPAXXXXB",
                                        pattern: InputValidatorRegex.PAN_NO
                                    },
                                ]} label="PAN Number" name="panNo">
                                    <Input onChange={(event: any) => {
                                        assignBundleForm.form?.setFieldsValue({ panNo: event?.target?.value.toUpperCase() })
                                    }} maxLength={10} />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    {
                        (kyctype === KycType.paper) && <Row gutter={16}>
                            <Col lg={10}>
                                <Form.Item className="w-100" rules={[
                                    {
                                        message: 'First Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct First Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} label="First Name" name="firstName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={7}>
                                <Form.Item className="w-100" rules={[
                                    {
                                        message: 'Middle Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct Middle Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} label="Middle Name" name="middleName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={7}>
                                <Form.Item label="Last Name" rules={[
                                    {
                                        message: 'Last Name is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct Last Name',
                                        pattern: InputValidatorRegex.PERSON_NAME,
                                    },
                                ]} className="w-100" name="lastName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item rules={[
                                    {
                                        message: 'DOB is required',
                                        required: true,
                                    }]} label="DOB" name="dob" getValueProps={(value) => ({
                                        value: value ? dayjs(value) : "",
                                    })}>
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item label="Gender" name="gender">
                                    <Select
                                        defaultValue=""
                                        options={[
                                            {
                                                value: 'MALE',
                                                label: 'Male',
                                            },
                                            {
                                                value: 'FEMALE',
                                                label: 'Female',
                                            },
                                            {
                                                value: '',
                                                label: "Don't Know",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={7}>
                                <Form.Item rules={[
                                    {
                                        message: 'Mobile Number is required',
                                        required: true,
                                        max: 10,
                                    },
                                    {
                                        message: 'Enter correct Mobile Number',
                                        pattern: InputValidatorRegex.INDIAN_MOBILE_NO,
                                    },
                                ]} label="Mobile Number" name="mobileNo">
                                    <Input onChange={(event: any) => {
                                        assignBundleForm.form?.setFieldsValue({ mobileNo: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col lg={7}>
                                <Form.Item rules={[
                                    {
                                        message: 'Email ID is required',
                                        required: true,
                                    },
                                    {
                                        message: 'Enter correct email address',
                                        pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                                    },
                                ]} label="Email ID" name="emailId">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'PAN number is required',
                                        required: true,
                                        max: 10,
                                    },
                                    {
                                        message: "PAN number should be CZTPAXXXXB",
                                        pattern: InputValidatorRegex.PAN_NO
                                    },
                                ]} label="PAN Number" name="panNo">
                                    <Input onChange={(event: any) => {
                                        assignBundleForm.form?.setFieldsValue({ panNo: event?.target?.value.toUpperCase() })
                                    }} maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: 'Address is required',
                                        required: true,
                                    },
                                    {
                                        message: "Enter correct Address",
                                        pattern: InputValidatorRegex.ADDRESS
                                    },
                                ]} label="Address" name="addressLine1">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12}>
                                <Form.Item rules={[
                                    {
                                        message: "Enter correct Address",
                                        pattern: InputValidatorRegex.ADDRESS
                                    },
                                ]} label="Address Line 2" name="addressLine2">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    label="State"
                                    name="stateId"
                                    rules={[
                                        {
                                            message: 'State is required',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder='Enter Cardholder State'
                                        onChange={(e: any) => { assignBundleForm.form?.setFieldsValue({ cityId: '' }); setUserState(e); paymentSwitch(e) }}
                                        {...stateSelectProps} />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item
                                    label="City"
                                    name="cityId"
                                    rules={[
                                        {
                                            message: 'City is required',
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder='Enter Cardholder City'
                                        options={cityList}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg={6}>
                                <Form.Item rules={[
                                    {
                                        message: 'Pincode is required',
                                        required: true,
                                        max: 6
                                    },
                                    {
                                        message: "Enter Correct Pincode",
                                        pattern: InputValidatorRegex.PINCODE
                                    },
                                ]} label="Pincode" name="pincode">
                                    <Input onChange={(event: any) => {
                                        assignBundleForm.form?.setFieldsValue({ pincode: event?.target?.value.replace(/[^0-9]/g, '') })
                                    }} maxLength={6} />
                                </Form.Item>
                            </Col>
                        </Row>
                    }

                </Form>
            </Modal>
        </Space>
    );
};