import React, { useEffect, useState } from "react";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
    Row,
    Col,
    DatePicker,
    Card,
    Typography
} from "@pankod/refine-antd";
import { ICompany } from "interfaces";
import { PricingChargeCalculationMethod, PricingChargeTrigger, PricingItem, ProductFeatureFeeCompanyConfig, ProductFeatureFeeConfig } from "interfaces/company";
import dayjs from "dayjs";
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

interface EditCompanyCardFeeModelProps {
    productFeature: ProductFeatureFeeConfig;
    companyProductFeature: ProductFeatureFeeCompanyConfig;
    refetchCompanyDetail: any;
    refetchCompanyCardFeeDetails: any;
    title: string;
    companyId: string | undefined;
    companyDetail: ICompany | undefined
}

export const EditCompanyCardFeeModel: React.FC<EditCompanyCardFeeModelProps> = ({ title, productFeature, companyProductFeature, companyId, refetchCompanyCardFeeDetails, refetchCompanyDetail, companyDetail }) => {

    const [editFeeConfig, setEditFeeConfig] = useState<boolean>(false);

    //account manager Edit Model
    const { modalProps: EditCompanyCardFeeModel, formProps: editCompanyFeeForm, show: editCompanyFeeShow, close: editCompanyFeeClose } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "saveProductFeatureFeeCompanyConfig",
        action: "create",
        metaData: {
            companyId: companyId,
            isActive: true,
            pbiId: null
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true);
            refetchCompanyCardFeeDetails(true);
        }
    });
    EditCompanyCardFeeModel.title = `Set up ${title} Fees`;
    EditCompanyCardFeeModel.centered = true;
    EditCompanyCardFeeModel.width = 800;

    EditCompanyCardFeeModel.onCancel = () => {
        setEditFeeConfig(false)
        editCompanyFeeClose()
        editCompanyFeeForm.form?.resetFields()
    }
    // EditCompanyCardFeeModel.onOk = () => {
    //     setEditFeeConfig(false)
    // }
    editCompanyFeeForm.form?.setFieldsValue({
        isActive: true,
        pbiId: null,
        priority: 1,
        productFeatureFeeConfigId: productFeature.id,
        priceListing: {
            chargeCalcMethod: companyProductFeature?.priceListing?.chargeCalcMethod || productFeature?.priceListing?.chargeCalcMethod || PricingChargeCalculationMethod.FIXED_AMOUNT,
            chargeTrigger: companyProductFeature?.priceListing?.chargeTrigger || productFeature?.priceListing?.chargeTrigger || PricingChargeTrigger.PER_ACTION,
            gstOnFeePercentage: companyProductFeature?.priceListing?.gstOnFeePercentage || productFeature?.priceListing?.gstOnFeePercentage,
            chargeCalc: {
                seedValue: (companyProductFeature?.priceListing && companyProductFeature?.priceListing?.chargeCalc.seedValue !== null) ? companyProductFeature?.priceListing?.chargeCalc.seedValue : productFeature?.priceListing?.chargeCalc.seedValue || 0,
                upperCap: (companyProductFeature?.priceListing && companyProductFeature?.priceListing?.chargeCalc.upperCap !== null) ? companyProductFeature?.priceListing?.chargeCalc.upperCap : productFeature?.priceListing?.chargeCalc.upperCap || 0,
                multiple: companyProductFeature?.priceListing?.chargeCalc.multiple || productFeature?.priceListing?.chargeCalc.multiple || 0,
            }
        },
        applicableDate: (companyProductFeature?.applicableFromDate && companyProductFeature?.applicableTillDate) && [dayjs(companyProductFeature?.applicableFromDate), dayjs(companyProductFeature?.applicableTillDate)],
        applicableFromDate: companyProductFeature?.applicableFromDate && dayjs(companyProductFeature?.applicableFromDate),
        applicableTillDate: companyProductFeature?.applicableTillDate && dayjs(companyProductFeature?.applicableTillDate),
    })



    return (
        <Space>
            <Button onClick={() => { editCompanyFeeShow(); }} type="link">
                Edit {title || ''}
            </Button>
            <Modal {...EditCompanyCardFeeModel}>
                <Card bordered={editFeeConfig} size="small" className="h-100">
                    {
                        editFeeConfig && <>
                            <Title level={4}>Change Fee Amount</Title>
                            <Text className="mb-3">Update Kodo Pay fee is only applicable for the selected duration Afterwords, it will revert to the default Kodo Pay fee structure.</Text>
                        </>
                    }

                    <Form disabled={!editFeeConfig} {...editCompanyFeeForm} layout="vertical">
                        <Form.Item hidden name={["productFeatureFeeConfigId"]} > </Form.Item>
                        <Form.Item hidden name={["priceListing", "chargeCalcMethod"]} > </Form.Item>
                        <Form.Item hidden name={["priceListing", "chargeTrigger"]} > </Form.Item>
                        <Form.Item hidden name={["priceListing", "gstOnFeePercentage"]} > </Form.Item>
                        <Form.Item hidden name={["priceListing", "chargeCalc", "multiple"]} > </Form.Item>
                        <Form.Item hidden name={["priceListing", "chargeCalc", "upperCap"]} > </Form.Item>
                        <Form.Item hidden name={["priority"]} > </Form.Item>
                        <Form.Item hidden name={["deactivationDate"]} > </Form.Item>
                        <Form.Item hidden name={["deactivationReason"]} > </Form.Item>
                        <Row gutter={[11, 11]}>
                            <Col span={12}>
                                <Form.Item
                                    className="mb-3"
                                    label="Fee value"
                                    name={["priceListing", "chargeCalc", "seedValue"]}
                                    rules={[
                                        {
                                            message: "Please input Fee!",
                                            required: true,
                                        }
                                    ]}
                                    help={'Fee is changed on the overall transaction amount.'}
                                >
                                    <Input type="number" onChange={(event: any) => {
                                        editCompanyFeeForm.form?.setFieldsValue({
                                            priceListing: {
                                                chargeCalc: {
                                                    seedValue: parseFloat(event?.target?.value)
                                                }
                                            }
                                        })
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={editFeeConfig ? "Select Duration for Revised Fee" : "Duration for Revised Fee"}
                                    help={!editFeeConfig && 'Updated Kodo Pay fee is only applicable for the selected duration. Afterward, it will revert to default Kodo Pay fee structure.'}
                                    name={"applicableDate"}
                                    // getValueProps={(value) => ({
                                    //     value: value ? dayjs(value) : "",
                                    // })}
                                    rules={[
                                        {
                                            message: "Please input Applicable From and Till Date!",
                                            required: true,
                                        },
                                    ]}
                                >
                                    <RangePicker onChange={(event: any) => {
                                        const selectedStartDate = event ? new Date(event[0]) : null;
                                        if (selectedStartDate) {
                                            selectedStartDate.setHours(0, 0, 0, 0); // Set the time to 12:00 AM
                                            editCompanyFeeForm.form?.setFieldsValue({ applicableFromDate: selectedStartDate.toISOString() });
                                        }
                                        const selectedEndDate = event ? new Date(event[1]) : null;
                                        if (selectedEndDate) {
                                            selectedEndDate.setHours(23, 59, 59, 999); // Set the time to 11:59:59.999 PM
                                            editCompanyFeeForm.form?.setFieldsValue({ applicableTillDate: selectedEndDate.toISOString() });
                                        }
                                    }} />
                                </Form.Item>
                                <Form.Item name={"applicableFromDate"} hidden={true} > </Form.Item>
                                <Form.Item name={"applicableTillDate"} hidden={true} > </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                {
                    !editFeeConfig && <Button type="link" onClick={() => { setEditFeeConfig(!editFeeConfig) }} className="mt-3">
                        Change Fee Amount
                    </Button>
                }
            </Modal>
        </Space>
    );
};
