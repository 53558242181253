import { useEffect } from 'react';
import { AppConfig } from './app.config';
import { getAccessToken, isTokenExpiringSoon } from './utility/token.service';
import { clients } from './dataProvider/apollo-graphql';
import { refreshTokenGQL } from './dataProvider/mutations/auth.mutation';


const userIdleExpiryTimeInMs = 1000 * 60 * AppConfig.userIdleExpiryTimeInMins;

// Get New Token
const Timer = (): void => {
    const getTimeRemaining = () => {

        //refresh token 2 min before expiry
        if (isTokenExpiringSoon({ minute: 6 }) && getAccessToken()) {
            getNewToken()
        }
    };

    useEffect(() => {
        if (getAccessToken()) {
            let interval = setInterval(() => getTimeRemaining(), 1000);
            return () => clearInterval(interval);
        }
    }, []);

};


// for user inactiveness
export const MonitorUserIdleness = (): void => {
    resetTimerOnUserActivity();

    const checkForInactivity = () => {
        if (!sessionStorage.getItem(AppConfig.lastTimeTriggered)) {
            return;
        }
        const lastTimeTriggered = new Date(JSON.parse(sessionStorage.getItem(AppConfig.lastTimeTriggered) || '')).valueOf();
        const diff = new Date().valueOf() - lastTimeTriggered;
        if (lastTimeTriggered && diff > userIdleExpiryTimeInMs) {
            logout();
        }
    };

    useEffect(() => {
        if (getAccessToken()) {
            const interval = setInterval(() => checkForInactivity(), 300);
            return () => clearInterval(interval);
        }
    }, []);
};


function resetTimerOnUserActivity() {
    const resetTimer = () => {
        const lastTimeTriggered = new Date();

        sessionStorage.setItem(
            AppConfig.lastTimeTriggered,
            getAccessToken() ? JSON.stringify(lastTimeTriggered) : JSON.stringify(null)
        );
    }

    window.onload = resetTimer;

    // DOM Events for which app will be inactivated
    document.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    document.onkeyup = resetTimer;
    document.onkeydown = resetTimer;
}


async function getNewToken() {
    const response = await clients.mutate({
        mutation: refreshTokenGQL,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
    });
    if (response?.data?.refreshToken?.token) {
        sessionStorage.setItem(AppConfig.apiTokenKey, response.data.refreshToken.token);
    }
    if (response?.data?.refreshToken?.refreshToken) {
        sessionStorage.setItem(AppConfig.refreshTokenKey, response.data.refreshToken.refreshToken);
    }

}


function logout() {
    sessionStorage.clear()
    window.location.reload();
}

export default Timer;