import React from "react";
import { createRoot } from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { FeatureFlagsProvider } from "./contexts/feature-flags";


const client = new ApolloClient({
  uri: process.env.REACT_APP_URL,
  // uri: environment.graphqlUrl,
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <FeatureFlagsProvider>
        <App />
      </FeatureFlagsProvider>
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
