import { gql } from '@apollo/client';

export const getActiveFeeStatementsGQL = gql`
query {
  getActiveFeeStatements {
        id
        eventType
        statementAmount
        month
        startDate
        endDate
        referenceIdSharedWithBank
        isActive
    }
}
`;
export const getFeeStatementsWithinDateRangeGQL = gql`
query getFeeStatementsWithinDateRange(
    $startDate: String!
    $endDate: String!
  ){
  getFeeStatementsWithinDateRange(
    input:{
      startDate: $startDate,
      endDate: $endDate,
    }
  ) {
        id
        eventType
        statementAmount
        month
        startDate
        endDate
        referenceIdSharedWithBank
        isActive
        isDeleted
    }
}
`;

export const viewListOfSettlementBankAccountsGQL = gql`
  query  viewListOfSettlementBankAccounts {
    viewListOfSettlementBankAccounts {
      id
      accountNumber
      accountHolderName
      bankName
      ifsc
      email
      mobileNo
    }
  }
`;

export const deleteFeeStatementGQL = gql`
  mutation  deleteFeeStatement(
    $statementId: String!
    $notes: String!
  ) {
    deleteFeeStatement(
       input:{
          statementId:$statementId,
          notes:$notes,
       }
    ) {
      success
    }
  }
`;

export const addnewSettlementbankAccDetailsGQL = gql`
  mutation addnewSettlementbankAccDetails(
    $accountNumber: String!
    $accountHolderName: String!
    $bankName: String!
    $ifsc: String!
    $email: String
    $mobileNo: String
  ) {
    addnewSettlementbankAccDetails (
      input:{
        accountNumber: $accountNumber
        accountHolderName: $accountHolderName
        bankName: $bankName
        ifsc: $ifsc
        email: $email
        mobileNo: $mobileNo
      }
    ){
        success
        settlementAccountId
    }
  }
`;

export const fetchSettlementStatusOfStatementGQL = gql`
  mutation fetchSettlementStatusOfStatement(
    $statementId: ID!
    $eventType: String!
  ) {
    fetchSettlementStatusOfStatement (
      input:{
        statementId: $statementId
        eventType: $eventType
      }
    ){
        success
    }
  }
`;
export const generateStatementGQL = gql`
  mutation generateStatement(
    $eventType: SettlementEventType!
    $startDate: String
    $endDate: String
    $settlementPartner: SettlementPartner!
  ) {
    generateStatement (
      input:{
        eventType: $eventType
        startDate: $startDate
        endDate: $endDate
        settlementPartner: $settlementPartner
      }
    ){
        success
        statementId
    }
  }
`;

export const fetchStatusForPendingSettlementRecordsGQL = gql`
  mutation 
   fetchStatusForPendingSettlementRecords {
    fetchStatusForPendingSettlementRecords 
  }
`;

export const initiateFundSettlementGQL = gql`
  mutation initiateFundSettlement(
    $eventType: String!
    $statementId: ID!
    $settlementAccountId: ID
  ) {
    initiateFundSettlement (
      input:{
        eventType: $eventType
        statementId: $statementId
        settlementAccountId: $settlementAccountId
      }
    ){
        success
    }
  }
`;


export const getSettlementsOfFeeStatementGQL = gql`
  query getSettlementsOfFeeStatement($statementId:ID!) {
    getSettlementsOfFeeStatement (input:{
        statementId: $statementId
      }
    ){
      companyName
      companyId
      FeeChargeEventDate
      kodoTransferId
      urn
      amountWithoutTax
      taxAmount
      fee
      gstOnFee
      totalAmount
      settlementId
      feeChargeEventId
      settlementStatus
      integration
    }
  }
`;

export const VASettlementGQL = {
  getActiveFeeStatements: getActiveFeeStatementsGQL,
  getFeeStatementsWithinDateRange: getFeeStatementsWithinDateRangeGQL,
  viewListOfSettlementBankAccounts: viewListOfSettlementBankAccountsGQL,
  deleteFeeStatement: deleteFeeStatementGQL,
  getSettlementsOfFeeStatement: getSettlementsOfFeeStatementGQL,
  addnewSettlementbankAccDetails: addnewSettlementbankAccDetailsGQL,
  fetchStatusForPendingSettlementRecords: fetchStatusForPendingSettlementRecordsGQL,
  generateStatement: generateStatementGQL,
  fetchSettlementStatusOfStatement: fetchSettlementStatusOfStatementGQL,
  initiateFundSettlement: initiateFundSettlementGQL,
}
