import React, { useRef, useState } from "react";
import {
    useModalForm,
    Modal,
    Space,
    Form,
    Button,
    Divider,
    Row,
    Col,
    Switch,
    Icons,
    notificationProvider,
    Table
} from "@pankod/refine-antd";
import { ICompany } from "interfaces";
import NestsxCrud from "dataProvider/nestjs-crud";
import { API_URL } from "App";


interface ConfigureCustomWorkflowModelProps {
    refetchCompanyDetail: (shouldRefetch: boolean) => void;
    companyId: string;
    companyDetail: ICompany;
}

interface PreviewData {
    header?: string[] | null;
    validatedRows?: (({ [key: string]: string | null })[] | null)[] | null;
}

const masterTypeMap = new Map()
    .set('isDesignationRequiredForApprovalWorkflow', 'DESIGNATION')
    .set('isDepartmentRequiredForApprovalWorkflow', 'DEPARTMENT')
    // .set('isSubDepartmentRequiredForApprovalWorkflow', '')
    .set('isEmployeeGradeRequiredForApprovalWorkflow', 'EMPLOYEE_GRADE')
    // .set('isEmployeeCategoryRequiredForApprovalWorkflow', '')
    .set('isLocationRequiredForApprovalWorkflow', 'LOCATION')
    .set('isProjectRequiredForApprovalWorkflow', 'PROJECT')
    // .set('isReportingManagerRequiredForApprovalWorkflow', '');


const getDefaultCheckboxState = (companyDetail: ICompany) => {
    const featureFlags = companyDetail.featureFlags;
    const companySettings = companyDetail.companySettings?.settings;
    return {
        dynamicWorkflow: featureFlags?.dynamicWorkflow,

        isDepartmentRequiredForApprovalWorkflow: (companySettings?.isDepartmentRequiredForApprovalWorkflow) || false,
        isSubDepartmentRequiredForApprovalWorkflow: (companySettings?.isSubDepartmentRequiredForApprovalWorkflow) || false,
        isDesignationRequiredForApprovalWorkflow: (companySettings?.isDesignationRequiredForApprovalWorkflow) || false,
        isEmployeeGradeRequiredForApprovalWorkflow: (companySettings?.isEmployeeGradeRequiredForApprovalWorkflow) || false,
        isEmployeeCategoryRequiredForApprovalWorkflow: (companySettings?.isEmployeeCategoryRequiredForApprovalWorkflow) || false,
        isLocationRequiredForApprovalWorkflow: (companySettings?.isLocationRequiredForApprovalWorkflow) || false,
        isProjectRequiredForApprovalWorkflow: (companySettings?.isProjectRequiredForApprovalWorkflow) || false,
        isReportingManagerRequiredForApprovalWorkflow: (companySettings?.isReportingManagerRequiredForApprovalWorkflow) || false,
    };
}




export const ConfigureCustomWorkflowModel: React.FC<ConfigureCustomWorkflowModelProps> = ({
    refetchCompanyDetail,
    companyId,
    companyDetail
}) => {
    const [isPreviewMasterModalVisible, setIsPreviewMasterModalVisible] = useState(false);
    const [uploadedMasterDataFile, setUploadedMasterDataFile] = useState<{
        file: File | null,
        label: string | null,
        type: string | null,
        previewData?: PreviewData | null,
    }>({
        type: null,
        label: null,
        file: null,
        previewData: null,
    });


    const defaultState = getDefaultCheckboxState(companyDetail);
    const [checkboxState, setCheckboxState] = useState(defaultState);

    const isEveryActionEnabled = Object.values(defaultState).every(it => it === true);


    const {
        modalProps: configureCustomWorkflowModalProps,
        formProps: configureCustomWorkflowFormProps,
        show: showConfigureCustomWorkflowModal,
        close: closeConfigureCustomWorkflowModal,
    } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "updateCompanyFeaturesAndSettings",
        action: "create",
        metaData: {
            input: {
                companyId,
                ...(checkboxState.dynamicWorkflow) && {
                    companyFeatureFlags: {
                        dynamicWorkflow: checkboxState.dynamicWorkflow,
                    }
                },
                companySettings: {
                    ...(checkboxState.isDepartmentRequiredForApprovalWorkflow) && { isDepartmentRequiredForApprovalWorkflow: checkboxState.isDepartmentRequiredForApprovalWorkflow },
                    ...(checkboxState.isSubDepartmentRequiredForApprovalWorkflow) && { isSubDepartmentRequiredForApprovalWorkflow: checkboxState.isSubDepartmentRequiredForApprovalWorkflow },
                    ...(checkboxState.isDesignationRequiredForApprovalWorkflow) && { isDesignationRequiredForApprovalWorkflow: checkboxState.isDesignationRequiredForApprovalWorkflow },
                    ...(checkboxState.isEmployeeGradeRequiredForApprovalWorkflow) && { isEmployeeGradeRequiredForApprovalWorkflow: checkboxState.isEmployeeGradeRequiredForApprovalWorkflow },
                    ...(checkboxState.isEmployeeCategoryRequiredForApprovalWorkflow) && { isEmployeeCategoryRequiredForApprovalWorkflow: checkboxState.isEmployeeCategoryRequiredForApprovalWorkflow },
                    ...(checkboxState.isLocationRequiredForApprovalWorkflow) && { isLocationRequiredForApprovalWorkflow: checkboxState.isLocationRequiredForApprovalWorkflow },
                    ...(checkboxState.isProjectRequiredForApprovalWorkflow) && { isProjectRequiredForApprovalWorkflow: checkboxState.isProjectRequiredForApprovalWorkflow },
                    ...(checkboxState.isReportingManagerRequiredForApprovalWorkflow) && { isReportingManagerRequiredForApprovalWorkflow: checkboxState.isReportingManagerRequiredForApprovalWorkflow },
                },
            }
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true);
        },
    });
    configureCustomWorkflowModalProps.afterClose = () => {
        setCheckboxState(defaultState);
    };
    configureCustomWorkflowModalProps.title = 'Configure Customised Workflow';
    configureCustomWorkflowModalProps.centered = true;
    configureCustomWorkflowModalProps.width = 500;
    if (isEveryActionEnabled) {
        configureCustomWorkflowModalProps.footer = null;
    }


    const previewMasterData = async (file, type: string, label: string) => {
        const response = await callMasterDataApi({ file, type, label, showPreview: true });
        if (response) {
            setUploadedMasterDataFile({ file, type, label, previewData: response });
            closeConfigureCustomWorkflowModal();
            setIsPreviewMasterModalVisible(true);
        }
    }

    const uploadMasterData = async (file, type: string, label: string) => {
        const response = await callMasterDataApi({ file, type, label, showPreview: false });
        if (response) {
            notificationProvider.open({
                message: `Master data sucessfully updated for '${label}'`,
                type: 'success',
                description: `Successfully uploaded data`,
            });
        }
    }

    const callMasterDataApi = async ({ file, type, label, showPreview = true }) => {
        const showErrorToast = (err) => {
            notificationProvider.open({
                message: err?.message || err,
                type: 'error',
                description: `Failed to upload master data for '${label}'`,
            });
        };
        try {
            const dataProvider = NestsxCrud('') as any;
            if (dataProvider) {
                const formData = new FormData();
                formData.append('master', file);
                let url = `${API_URL}/approval-workflow/upload/internal`;
                if (showPreview) {
                    url = `${url}/${type}/master/preview`;
                } else {
                    url = `${url}/${companyDetail.id}/${type}/master`;
                }
                const data = await dataProvider.custom({
                    url,
                    method: 'post',
                    payload: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (data?.data) {
                    return data.data;
                } else {
                    showErrorToast('Something went wrong');
                }
            }
        } catch (err: any) {
            showErrorToast(err);
        }
    }


    const handleCheckboxChange = (name: string, checked: boolean) => {
        setCheckboxState(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    }

    const handleIconClick = (fileInputRef): void => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event, name, label) => {
        const resetFileData = () => setUploadedMasterDataFile({ file: null, label: null, type: null, previewData: null });

        const file = event.target.files[0] as File;
        if (file) {
            if (file.type === "text/csv") {
                setUploadedMasterDataFile({ file, type: masterTypeMap.get(name), label });
                previewMasterData(file, masterTypeMap.get(name), label);
            } else resetFileData();
        } else {
            resetFileData();
        }
    }


    const ToggleItem = ({ label, name }: { label: string, name: string }) => {
        const fileInputRef = useRef<HTMLInputElement>(null);
        const showToggleSwitch = !defaultState[name];
        const showUploadMasterBtn = defaultState[name];
        return (
            ((showToggleSwitch) || (showUploadMasterBtn && masterTypeMap.has(name)))
            &&
            (
                <Row justify="space-between" align="middle" style={{ paddingTop: 10 }}>
                    <Col style={{ textWrap: 'wrap' }}>{label}</Col>

                    {
                        showToggleSwitch
                        && (
                            <Col>
                                <Switch
                                    id={name}
                                    key={name}
                                    checked={checkboxState[name as keyof typeof checkboxState]}
                                    onChange={(checked) => {
                                        handleCheckboxChange(name, checked)
                                    }}
                                />
                            </Col>
                        )
                    }

                    {
                        showUploadMasterBtn && masterTypeMap.has(name)
                        && (
                            <Col>
                                <Icons.UploadOutlined
                                    onClick={(event) => { handleIconClick(fileInputRef) }}
                                    style={{ cursor: 'pointer' }}
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".csv"
                                    onChange={(event) => { handleFileChange(event, name, label) }}
                                />
                            </Col>
                        )
                    }

                </Row>
            )
        );
    };


    const PreviewMasterDataModalComponent = ({
        isPreviewMasterModalVisible,
        setIsPreviewMasterModalVisible,
        uploadMasterData,
        uploadedMasterDataFile,
    }: {
        isPreviewMasterModalVisible: boolean,
        setIsPreviewMasterModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
        uploadMasterData: (file: any, type: string, label: string) => Promise<void>,
        uploadedMasterDataFile: {
            type: string | null,
            label: string | null,
            file: File | null,
            previewData?: PreviewData | null,
        }
    }) => {
        const handleOk = async () => {
            await uploadMasterData(uploadedMasterDataFile.file, uploadedMasterDataFile.type as string, uploadedMasterDataFile.label as string)
            setIsPreviewMasterModalVisible(false);
        };

        const handleCancel = () => {
            setIsPreviewMasterModalVisible(false);
        };

        const PreviewTable = ({ data }) => {
            if (!data) {
                return <div>No data available</div>;
            }
            const { header = [], validatedRows } = (data || {}) as PreviewData;
            const columns = (header as string[]).map((col, idx) => ({
                title: col,
                dataIndex: `cell-${idx}`,
                key: col,
            }));

            const dataSource = (validatedRows || []).map((row: any, idx) => {

                return {
                    key: `${idx}`,
                    [`cell-${0}`]: Object.values(row[0] || {})?.[0],
                    [`cell-${1}`]: Object.values(row[1] || {})?.[0],
                    [`cell-${2}`]: Object.values(row[2] || {})?.[0],
                    [`cell-${3}`]: Object.values(row[3] || {})?.[0],
                    // ...(row?.length ? row.map((r, i) => {
                    //     return {
                    //         [`cell-${i}`]:  Object.values(r)[0]
                    //     }
                    // }) : {})
                }
            });
            return (
                <Table columns={columns} dataSource={dataSource} pagination={{ pageSize: 10 }} />
            );
        };

        const [form] = Form.useForm();

        return (
            <div>
                <Modal
                    title="Preview"
                    visible={isPreviewMasterModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Upload"
                    cancelText="Cancel"
                >
                    <Form form={form} layout="vertical">
                        <PreviewTable data={uploadedMasterDataFile.previewData} />
                    </Form>
                </Modal>
            </div>
        );
    };



    return (
        <Space>
            <Button type="default" onClick={() => { showConfigureCustomWorkflowModal(); }}>
                {companyDetail.featureFlags?.dynamicWorkflow ? 'Configure' : 'Enable'} Customised Workflow
            </Button>

            <Modal {...configureCustomWorkflowModalProps} >
                <Form {...configureCustomWorkflowFormProps} layout="vertical">
                    <Form.Item>

                        {
                            (!companyDetail.featureFlags?.dynamicWorkflow)
                            && (
                                <ToggleItem
                                    label={`Enable Customised Workflow for ${companyDetail.registeredName}`}
                                    name="dynamicWorkflow"
                                />
                            )
                        }

                        {
                            (!companyDetail.featureFlags?.dynamicWorkflow)
                            && (
                                <Divider orientation="center" style={{ paddingTop: 10 }}>Configure Fields</Divider>
                            )
                        }

                        <ToggleItem label="Designation" name="isDesignationRequiredForApprovalWorkflow" />
                        <ToggleItem label="Department" name="isDepartmentRequiredForApprovalWorkflow" />
                        <ToggleItem label="Sub-Department" name="isSubDepartmentRequiredForApprovalWorkflow" />
                        <ToggleItem label="Employee Grade" name="isEmployeeGradeRequiredForApprovalWorkflow" />
                        <ToggleItem label="Employee Category" name="isEmployeeCategoryRequiredForApprovalWorkflow" />
                        <ToggleItem label="Location" name="isLocationRequiredForApprovalWorkflow" />
                        <ToggleItem label="Project" name="isProjectRequiredForApprovalWorkflow" />
                        <ToggleItem label="Reporting Manager" name="isReportingManagerRequiredForApprovalWorkflow" />
                    </Form.Item>
                </Form>
            </Modal>

            <PreviewMasterDataModalComponent
                isPreviewMasterModalVisible={isPreviewMasterModalVisible}
                setIsPreviewMasterModalVisible={setIsPreviewMasterModalVisible}
                uploadMasterData={uploadMasterData}
                uploadedMasterDataFile={uploadedMasterDataFile}
            />


        </Space>
    );
};
