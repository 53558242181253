import { verificationStatus } from "pages/company/list";
import { CardIntegration } from "../interfaces/company";

export enum CardFundsSource {
    ALLOCATED_TO_CARD = 'ALLOCATED_TO_CARD',
    SHARED_WITHIN_PBI = 'SHARED_WITHIN_PBI',
}

export const isPayLaterCandidate = (company: any): boolean => {
    return company && company.productsBundleIssuance && company.productsBundleIssuance.sharedPayLaterBeneficiaryId && (company.companyVerification === verificationStatus.ONBOARDED);
}
export const removeDuplicateObjectsById = (array1: any, array2: any) => {
    const combinedArray = array1.concat(array2);
    const uniqueObjects: any = {};

    for (const obj of combinedArray) {
        uniqueObjects[obj.id] = obj;
    }

    return Object.values(uniqueObjects);

}
export const removeObjectsById = (array1: any, array2: any) => {
    const idSet = new Set(array1.map((obj: any) => obj.id));
    return array2.filter((obj: any) => !idSet.has(obj.id));
}
export const showOnboardOrAddPB = (pbId: any, productsBundleIssuances: any): boolean => {
    if (productsBundleIssuances && productsBundleIssuances.length) {
        return !productsBundleIssuances.find((it: any) => it.productsBundle.id === pbId);
    } else {
        return true;
    }
}
export const filterForActiveCardIntegrations = (cardIntegrations: CardIntegration[]): CardIntegration[] => {
    cardIntegrations = cardIntegrations.filter(item => item !== null);
    cardIntegrations = cardIntegrations?.filter(
        (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );

    return cardIntegrations?.filter(ci => {
        return ci.availableForNewCardIssuance === true
    }) || [];
}
export const getCardType = (fundsSource: CardFundsSource): string => {
    let cardType: string = '';
    if (fundsSource === CardFundsSource.ALLOCATED_TO_CARD) {
        cardType = 'Top-up';
    } else if (fundsSource === CardFundsSource.SHARED_WITHIN_PBI) {
        cardType = 'Limit-based';
    }
    return cardType;
}