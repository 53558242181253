import { Card, Col, Row } from "@pankod/refine-antd"
import { DescriptionItem } from "components/text-layout/descriptionItem.text"
import { ICompany } from "interfaces"
import { PricingItem, ProductFeatureFeeConfig, ProductFeatureFeeCompanyConfig } from "interfaces/company"
import { useOne } from "@pankod/refine-core"
import { useEffect, useState } from "react"
import { EditCompanyCardFeeModel } from "./models/editCompanyCardFee.model"

interface CardFeeDetailsProps {
    cardTitle: string
    lqProductBundleId: string
    refetchCompanyDetail: any
    companyId: string | undefined
    companyDetail: ICompany | undefined
}

export const CardFeeDetailsComponent: React.FC<CardFeeDetailsProps> = ({ cardTitle, lqProductBundleId, refetchCompanyDetail, companyId, companyDetail }) => {
    let [refreshCompanyFeeData, setRefreshCompanyFeeData] = useState(false);
    let [refreshCompanyFeeReplacementData, setRefreshCompanyFeeReplacementData] = useState(false);


    //get ProductFeatureFeeConfig details graphql
    let { data: productFeatureReplacementData, isLoading: productFeatureReplacementLoading } = useOne<ProductFeatureFeeConfig[]>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeConfig',
        id: '',
        metaData: {
            pricingItem: PricingItem.PHYSICAL_CARD_REPLACEMENT,
        },
    });
    //get company FeeConfig details graphql
    let { data: companyProductFeatureFeeReplacementData, isLoading: companyProductFeatureFeeReplacementLoading, refetch: refetchCompanyProductFeatureFeeReplacementData } = useOne<ProductFeatureFeeCompanyConfig>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeCompanyConfig',
        id: '',
        metaData: {
            input: {
                companyId: companyId,
                pricingItem: PricingItem.PHYSICAL_CARD_REPLACEMENT,
                productsBundleId: lqProductBundleId,
            }
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshCompanyFeeReplacementData(false);
            },
        },
    });
    
    //get ProductFeatureFeeConfig details graphql
    let { data: productFeatureData, isLoading: productFeatureLoading } = useOne<ProductFeatureFeeConfig[]>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeConfig',
        id: '',
        metaData: {
            pricingItem: PricingItem.PHYSICAL_CARD_ISSUANCE,
        },
    });
    //get company FeeConfig details graphql
    let { data: companyProductFeatureFeeData, isLoading: companyProductFeatureFeeLoading, refetch: refetchCompanyProductFeatureData } = useOne<ProductFeatureFeeCompanyConfig>({
        dataProviderName: "grapghQl",
        resource: 'getProductFeatureFeeCompanyConfig',
        id: '',
        metaData: {
            input: {
                companyId: companyId,
                pricingItem: PricingItem.PHYSICAL_CARD_ISSUANCE,
                productsBundleId: lqProductBundleId,
            }
        },
        queryOptions: {
            enabled: true,
            onSuccess: () => {
                setRefreshCompanyFeeData(false);
            },
        },
    });
    const refetchCompanyCardFeeReplacementDetails = (message: boolean) => {
        setRefreshCompanyFeeReplacementData(message)
    };
    useEffect(() => {
        if (refreshCompanyFeeReplacementData) {
            refetchCompanyProductFeatureFeeReplacementData();
        }
    }, [refreshCompanyFeeReplacementData]);

    const refetchCompanyCardFeeDetails = (message: boolean) => {
        setRefreshCompanyFeeData(message)
    };
    useEffect(() => {
        if (refreshCompanyFeeData) {
            refetchCompanyProductFeatureData();
        }
    }, [refreshCompanyFeeData]);
    return (
        <Card size="small" title={cardTitle} className="h-100">

            {/* CARD ISSUANCE */}
            {
                (!companyProductFeatureFeeLoading && companyProductFeatureFeeData?.data === null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Issuance Fee (Rs.)" content={
                                `Rs. ${productFeatureData?.data[0]?.priceListing?.chargeCalc.seedValue}` || '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {
                (!companyProductFeatureFeeLoading && companyProductFeatureFeeData?.data !== null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Issuance Fee (Rs.)" content={
                                (companyProductFeatureFeeData?.data &&
                                    !companyProductFeatureFeeData?.data?.productFeatureFeeExclusion) ?
                                    `Rs. ${companyProductFeatureFeeData?.data?.priceListing?.chargeCalc.seedValue}` :
                                    '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {/* CARD REPLACEMENT */}
            {
                (!companyProductFeatureFeeReplacementLoading && companyProductFeatureFeeReplacementData?.data === null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Replacement Fee (Rs.)" content={
                                `Rs. ${productFeatureReplacementData?.data[0]?.priceListing?.chargeCalc.seedValue}` || '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {
                (!companyProductFeatureFeeReplacementLoading && companyProductFeatureFeeReplacementData?.data !== null) && (
                    <Row>
                        <Col lg={24}>
                            <DescriptionItem title="Replacement Fee (Rs.)" content={
                                (companyProductFeatureFeeReplacementData?.data &&
                                    !companyProductFeatureFeeReplacementData?.data?.productFeatureFeeExclusion) ?
                                    `Rs. ${companyProductFeatureFeeReplacementData?.data?.priceListing?.chargeCalc.seedValue}` :
                                    '-'
                            } />
                        </Col>
                    </Row>
                )
            }
            {
                (!companyProductFeatureFeeLoading) && (
                    <EditCompanyCardFeeModel
                        companyDetail={companyDetail}
                        companyId={companyId}
                        title={"Card Issuance"}
                        productFeature={productFeatureData?.data[0] || {} as ProductFeatureFeeConfig}
                        companyProductFeature={(companyProductFeatureFeeData?.data) ? companyProductFeatureFeeData?.data : {} as ProductFeatureFeeCompanyConfig}
                        refetchCompanyCardFeeDetails={refetchCompanyCardFeeDetails}
                        refetchCompanyDetail={refetchCompanyDetail}
                    />
                )
            }
            {
                (!companyProductFeatureFeeReplacementLoading) && (
                    <EditCompanyCardFeeModel
                        companyDetail={companyDetail}
                        companyId={companyId}
                        title={"Card Replacement"}
                        productFeature={productFeatureReplacementData?.data[0] || {} as ProductFeatureFeeConfig}
                        companyProductFeature={(companyProductFeatureFeeReplacementData?.data) ? companyProductFeatureFeeReplacementData?.data : {} as ProductFeatureFeeCompanyConfig}
                        refetchCompanyCardFeeDetails={refetchCompanyCardFeeReplacementDetails}
                        refetchCompanyDetail={refetchCompanyDetail}
                    />
                )
            }
            {
                companyProductFeatureFeeLoading && <Col lg={24}>
                    Loading Card Fee
                </Col>
            }
        </Card>
    )
}