import React, { useEffect, useState } from "react";

import { useModalForm, Modal, Checkbox, Space, Form, Button, Input } from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { urlPath } from "../../utility/url";
import { ICompanyPBI, IPBIMappings, IUser } from "../../interfaces/company";

export const IssueVirtualCardModel: React.FC<{ refetchUsers: any, cardPbi: ICompanyPBI, cardIntegrationId: string, pbiId: string, user: IUser }> = ({ refetchUsers, cardIntegrationId, pbiId, user, cardPbi }) => {
    let url = urlPath()
    const [checked, setChecked] = useState(false);
    const userPbiMapping: IPBIMappings | undefined = user.pbiMappings.find(it => it.pbiId === cardPbi.id)
    useEffect(() => {

    });

    //Check User Kyc
    let { modalProps: issueVirtualConfirmModel, formProps: issueVirtualConfirmForm, show: issueVirtualConfirmShow, close: issueVirtualConfirmClose } = useModalForm<any>({
        resource: cardPbi?.cards.length > 0 ? `issueAdditionalCardToUserFromMgmtPortal` : `assignFirstCardToUser`,
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            alsoIssuePhysicalForm: checked,
            cardIntegrationId,
            pbiId,
            pbiUserMappingId: userPbiMapping?.id,
            userId: user.id,
            fundsSource: "SHARED_WITHIN_PBI",
        },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchUsers(true)
        }
    });
    issueVirtualConfirmModel.title = 'Assign First Card To User'
    issueVirtualConfirmModel.centered = true
    issueVirtualConfirmModel.width = 400
    issueVirtualConfirmModel.onCancel = () => {
        issueVirtualConfirmForm.form?.resetFields();
        issueVirtualConfirmClose();
    }
    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };
    return (
        <Space className="mb-3">
            {
                cardPbi?.cards.length > 0 ? <Button onClick={() => { issueVirtualConfirmShow() }} type="primary">
                    Issue Additional Cards
                </Button> :<Button onClick={() => { issueVirtualConfirmShow() }} type="primary">
                    Issue Virtual Card
                </Button>
            }
           
            <Modal {...issueVirtualConfirmModel}>
                <h4>Are you sure you want to Issue Virtual Card? </h4>
                <Form {...issueVirtualConfirmForm} layout="vertical">
                    {
                        cardPbi?.cards.length > 0 && <>
                            <Form.Item label="Monthly Limit" name="monthlyLimit">
                                <Input onChange={(event: any) => {
                                    issueVirtualConfirmForm.form?.setFieldsValue({ monthlyLimit: event?.target?.value.replace(/[^0-9]/g, '') })
                                }} />
                            </Form.Item>
                            <Form.Item label="Daily Limit" name="dailyLimit">
                                <Input onChange={(event: any) => {
                                    issueVirtualConfirmForm.form?.setFieldsValue({ dailyLimit: event?.target?.value.replace(/[^0-9]/g, '') })
                                }} />
                            </Form.Item>
                        </>
                    }

                    {/* <Form.Item >
                        <Checkbox checked={checked} onChange={onChange}>
                            Issue Physical Form
                        </Checkbox>
                    </Form.Item> */}
                </Form>
            </Modal>
        </Space>
    );
};