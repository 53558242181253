import React, { useEffect, useState } from "react";

import {
    Space,
    Button,
    useModalForm,
    Modal,
    Form,
    Switch,
    Input
} from "@pankod/refine-antd";

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ICompany } from "interfaces";
import { isCreditCompany } from "utility/companyUtils";
const { TextArea } = Input;

export const SwitchStatementGenerationComponent: React.FC<{ companyDetails?: ICompany, refetchCompanyList: any, hasSelected?: boolean, isButton?: boolean, disableStatementGenerationValue?: boolean, companyIds: React.Key[] }> = ({ companyDetails, refetchCompanyList, hasSelected, isButton, disableStatementGenerationValue, companyIds }) => {

    useEffect(() => {

    });
    const isCredit = isCreditCompany(companyDetails as ICompany)

    // switch model
    const { modalProps: switchStatementGenerationModel, formProps: switchStatementGenerationForm, show: switchStatementGenerationShow } = useModalForm<any>({
        resource: `api/v2/${disableStatementGenerationValue ? "enableStatementGeneration" : "disableStatementGeneration"}`,
        action: "create",
        metaData: {
            companyIds: companyIds
        },
        redirect: false,
        onMutationSuccess() {
            // window.location.reload()
            refetchCompanyList(true)
        }
    });
    switchStatementGenerationModel.title = ''
    switchStatementGenerationModel.centered = true
    switchStatementGenerationModel.okText = 'Continue'
    switchStatementGenerationModel.width = 400

    return (
        <Space>
            {
                isButton ? <Button
                    type="primary"
                    danger={!disableStatementGenerationValue}
                    onClick={() => {
                        switchStatementGenerationShow()
                    }} >{disableStatementGenerationValue ? "Enable" : "Disabled"} Statement Generation</Button> :
                    isCredit &&
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={!disableStatementGenerationValue}
                        disabled={true}
                        onClick={() => {
                            switchStatementGenerationShow()
                        }}
                    />
            }

            <Modal {...switchStatementGenerationModel}>
                <h4>Are you assure you want to <strong> {disableStatementGenerationValue ? "Enable" : "Disabled"}</strong> Statement Generation?</h4>
                <Form {...switchStatementGenerationForm} layout="vertical">
                    {
                        !disableStatementGenerationValue && <Form.Item rules={[
                            {
                                required: true,
                            }
                        ]}
                            label={`Reasons To ${disableStatementGenerationValue ? "Enable" : "Disable"}`} name="reasonsToDisable">
                            <TextArea rows={4} placeholder={`Reasons To ${disableStatementGenerationValue ? "Enable" : "Disable"}`} />
                        </Form.Item>
                    }
                </Form>
            </Modal>
        </Space>
    );
};