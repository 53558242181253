import React, { useEffect, useState } from "react";
import { useOne } from "@pankod/refine-core";
import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
} from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;


export const UserDeactivationModel: React.FC<{ deactivateAccount: boolean, refetchUsers: any, refetchCompanyDetail: any, userAccountId: string, userStatus: string, companyId: string }> = ({ deactivateAccount, refetchUsers, refetchCompanyDetail, userAccountId, userStatus, companyId }) => {

    const { modalProps: deleteUserModel, formProps: deleteUserForm, show: deleteUserShow, close: deleteUserClose } = useModalForm<any>({
        dataProviderName: deactivateAccount ? "grapghQl" : 'default',
        resource: deactivateAccount ? "removeUserFromCompany" : "user-account/internal-portal/activate-deactive-user-account",
        action: "create",
        metaData: {
            userAccountId: userAccountId,
            companyId: companyId,
            status: userStatus,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
            refetchUsers(true)
            deleteUserClose();
        }
    });
    deleteUserModel.title = ''
    deleteUserModel.confirmLoading = true
    deleteUserModel.centered = true
    deleteUserModel.okText = 'Continue'
    deleteUserModel.width = 400

    return (
        <Space >
            <Button danger={true} onClick={() => { deleteUserShow() }} icon={<DeleteOutlined />} > {deactivateAccount ? "Deactivate" : "Delete"} User</Button>

            <Modal {...deleteUserModel}>
                <h4>Are you sure you want to Delete User?</h4>
                <Form {...deleteUserForm} layout="vertical">
                    <Form.Item rules={[
                        {
                            message: 'Notes is required',
                            required: true,
                        }
                    ]} label="De-Activate" name="notes">
                        <TextArea rows={4} placeholder={"Reasons To De-Activate"} />
                    </Form.Item>
                    {
                        !deactivateAccount && <Form.Item rules={[
                            {
                                message: 'Password is required',
                                required: true,
                            }
                        ]} label="Password" name="passcode">
                            <Input.Password placeholder="input password" />
                        </Form.Item>
                    }
                </Form>
            </Modal>

        </Space>
    );
};
