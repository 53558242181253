import {
  ErrorComponent,
  Icons,
  Layout,
  notificationProvider,
} from "@pankod/refine-antd";
import { AuthProvider, Refine } from "@pankod/refine-core";
import { RefineKbar, RefineKbarProvider } from "@pankod/refine-kbar";
import routerProvider from "@pankod/refine-react-router-v6";
import { GraphQLClient } from "graphql-request";

import "react-toastify/dist/ReactToastify.css";

import "@pankod/refine-antd/dist/styles.min.css";

import dataProviderGQ from "./dataProvider/grapgh-ql";
import dataProvider from "./dataProvider/nestjs-crud";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";

import { CompanyList, CompanyShow } from "./pages/company";
import { AppIntegrationList, AppIntegrationShow } from "./pages/integration";
import { VASettlementList, VASettlementShow } from "./pages/va-settlement";
// import { EligibleList, EligibleEdit, EligibleShow } from "pages/eligible";
// import { TransactionList, TransactionShow } from "pages/transaction";
// import { WaitlistedEdit, WaitlistedList, WaitlistedShow } from "pages/waitlisted";
// import { EligibleEdit, EligibleList, EligibleShow } from "pages/eligible";

import { AutoDebitList } from "./pages/auto-debit/list";
import {
  NpaReportList,
  NpaReportShow,
  ReportDetailsShow,
  UploadNpaReportShow,
} from "./pages/npa-report";
import { PenaltyList } from "./pages/penalty";
import { RefundList, RefundShow } from "./pages/refund";

import { CustomSider } from "./components";

import { LoginGoogle } from "./pages/auth-pages/login-google";
import { LoginOtp } from "./pages/auth-pages/login-password";
import { kodoLogo, kodoLogoSmall } from "./style";

import { KodoPayList } from "pages/kodo-pay";
import { AppConfig } from "./app.config";
import { HomePage } from "./pages/dashboard";
import { SettlementShow } from "./pages/integration/show-settlement";
import Timer, { MonitorUserIdleness } from "./screenTimeOut";
import { TransactionList, TransactionShow } from "pages/transaction";
import { OcrDashboardList } from "pages/ocr-dashboard";

export const API_URL: string = process.env.REACT_APP_URL + "";
const graphAPI_URL: string = `${process.env.REACT_APP_URL}/graphql`;

const client = new GraphQLClient(graphAPI_URL);
const gqlDataProvider = dataProviderGQ(client);
const { Link } = routerProvider;

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
  credentials: "same-origin",
});

export const clients = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
});
export const OffLayoutArea: React.FC = () => {
  return <RefineKbar />;
};
function App() {
  Timer();
  MonitorUserIdleness();
  const authProvider: AuthProvider = {
    login: async ({ emailId, passcode, remember }) => {
      return Promise.resolve();
    },
    logout: () => {
      sessionStorage.removeItem(AppConfig.apiTokenKey);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
      sessionStorage.getItem(AppConfig.apiTokenKey)
        ? Promise.resolve()
        : Promise.reject(),
    getPermissions: () => Promise.resolve(["admin"]),
  };
  return (
    <RefineKbarProvider>
      <Refine
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <LoginOtp />,
              path: "/otp-login",
              layout: true,
            },
            {
              element: <SettlementShow />,
              path: "/settlement/:id",
              layout: true,
            },
            {
              element: <ReportDetailsShow />,
              path: "/report-show/:id",
              layout: true,
            },
            {
              element: <UploadNpaReportShow />,
              path: "/upload-npa",
              layout: true,
            },
          ],
        }}
        dataProvider={{
          default: dataProvider(API_URL),
          grapghQl: gqlDataProvider,
        }}
        authProvider={authProvider}
        LoginPage={LoginGoogle}
        Sider={CustomSider}
        // Header={CustomHeader}

        Title={({ collapsed }) => (
          <Link to="/">
            {collapsed ? (
              <h4 style={kodoLogoSmall}>KODO</h4>
            ) : (
              <h4 style={kodoLogo}>KODO</h4>
            )}
          </Link>
        )}
        resources={[
          {
            name: "company",
            list: CompanyList,
            // create: CompanyCreate,
            // edit: CompanyEdit,
            show: CompanyShow,
            icon: <Icons.HomeOutlined />,
          },
          {
            name: "transactions",
            list: TransactionList,
            // edit: TransactionEdit,
            show: TransactionShow,
            icon: <Icons.PaperClipOutlined />,
          },
          {
            name: "refund",
            list: RefundList,
            show: RefundShow,
            icon: <Icons.MoneyCollectOutlined />,
          },
          {
            name: "penalty",
            list: PenaltyList,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "app-integration",
            list: AppIntegrationList,
            show: AppIntegrationShow,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "settlement",
            list: VASettlementList,
            show: VASettlementShow,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "bank-mandate",
            list: AutoDebitList,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "kodo-pay",
            list: KodoPayList,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "npa-report",
            list: NpaReportList,
            show: NpaReportShow,
            icon: <Icons.PayCircleOutlined />,
          },
          {
            name: "ocr-dashboard",
            list: OcrDashboardList,
            icon: <Icons.BarChartOutlined />,
          },
          // {
          //   name: "waitlisted",
          //   list: WaitlistedList,
          //   edit: WaitlistedEdit,
          //   show: WaitlistedShow,
          //   icon: <Icons.ClockCircleOutlined />,
          // },
          // {
          //   name: "eligible-leads",
          //   list: EligibleList,
          //   edit: EligibleEdit,
          //   show: EligibleShow,
          //   icon: <Icons.SafetyCertificateOutlined />,
          // }
        ]}
        notificationProvider={notificationProvider}
        Layout={Layout}
        DashboardPage={HomePage}
        catchAll={<ErrorComponent />}
        disableTelemetry={true}
        OffLayoutArea={OffLayoutArea}
      />
    </RefineKbarProvider>
  );
}

export default App;
