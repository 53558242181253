import { IResourceComponentsProps, useOne, CrudFilters } from "@pankod/refine-core";
import React, { useState } from "react";

import {
    Show, List,
    Table,
    Space,
    Typography,
    Row,
    Col,
    Button,
    useModal,
    DateField,
    useTable,
    DatePicker, FormProps, Form, Input, Icons, Card
} from "@pankod/refine-antd";
import { useModalForm, Modal } from "@pankod/refine-antd";

import { IRefund } from "../../interfaces";
import { urlPath } from "../../utility/url";
const { RangePicker } = DatePicker;

const { Title, Text } = Typography;
let buttonSwith: any = {}

export const RefundShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    // const { open } = useNotification();

    let [processButton, setprocessButton] = useState({ state: false, id: '' });
    let [refundTxn, setRefundTxn] = useState({ value: [] });
    let [searchKeyword, setSearchKey] = useState();
    let [mcc, setMcc] = useState();
    let [fromDate, setfromDate] = useState();
    let [toDate, settoDate] = useState();
    let [fromAmount, setfromAmount] = useState();
    let [toAmount, settoAmount] = useState();


    const { modalProps: refundDetailsModel, show: refundDetailsShow, close: refundDetailsClose } = useModal();
    refundDetailsModel.title =  'Previous Refund Details'

    const { modalProps: processRefundModel, formProps: processRefundForm, show: processRefundShow, close: processRefundClose } = useModalForm<any>({
        dataProviderName: "grapghQl",
        resource: "acceptPendingCreditNotificationTransaction",
        action: "create",
        metaData: { txnId: url.lastElement, parentTxnId: processButton.id },
        redirect: false,
        // onMutationSuccess(data, variables, context) {
        //     window.location.reload()
        // }
    });
    processRefundModel.title = ''
    processRefundModel.centered = true
    // console.log(processRefundModel.okButtonProps)
    processRefundModel.okText = 'Continue'

    processRefundModel.width = 400
    processRefundModel.onCancel = () => {
        processRefundForm.form?.resetFields();
        processRefundClose();
    }

    let { data, isLoading } = useOne<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'getTransactionsWithPendingStatus',
        id: '',
        metaData: { filter: { id: url.lastElement }, limit: 1, page: 1 },
    });
    let txnDetail: any
    if (data) {
        txnDetail = data
        txnDetail = txnDetail.data[0]
    }

    let { tableProps, searchFormProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'findCandidateParentTransaction',
        metaData: {
            transactionId: url.lastElement,
            searchKeyword: searchKeyword,
            mcc: mcc,
            fromDate: fromDate,
            toDate: toDate,
            fromAmount: fromAmount,
            toAmount: toAmount,
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            let { q, createdAt, mcc, fromAmount, toAmount } = params;

            toAmount = (toAmount) ? toAmount : 1000000
            fromAmount = (fromAmount) ? fromAmount : 0
            setSearchKey(q)
            setMcc(mcc)
            setfromAmount(fromAmount)
            settoAmount(toAmount)
            setfromDate(createdAt ? createdAt[0].toISOString().split('T')[0] : undefined,)
            settoDate(createdAt ? createdAt[1].toISOString().split('T')[0] : undefined,)
            return filters;
        },
    });

    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }

    return (
        <Show isLoading={isLoading} headerButtons={({ defaultButtons }) => (
            <>
                {/* {defaultButtons} */}
                {/* <RefreshButton resource="getCompaniesForManagementPortal" dataProviderName="grapghQl"/> */}

            </>
        )}>
            <Card title="Transaction Details">
                <Row gutter={[16, 16]}>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Transection Id</Title>
                        <Text >{url.lastElement}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Company</Title>
                        <Text >{(txnDetail?.company?.registeredName) ? txnDetail?.company?.registeredName : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Merchant Id</Title>
                        <Text >{(txnDetail?.merchantId) ? txnDetail?.merchantId : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Merchant Name</Title>
                        <Text >{(txnDetail?.merchantName) ? txnDetail?.merchantName : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>MCC</Title>
                        <Text  >{(txnDetail?.mcc) ? txnDetail?.mcc : '-'}</Text>
                    </Col>

                    <Col lg={8} xs={8}>
                        <Title level={5}>Amount</Title>
                        <Text >{(txnDetail?.transactionAmount) ? txnDetail?.transactionAmount : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Txn RefNo</Title>
                        <Text >{(txnDetail?.txnRefNo) ? txnDetail?.txnRefNo : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>Created At</Title>
                        <Text >{(txnDetail?.createdAt) ? <DateField value={txnDetail?.createdAt} format="LLL" /> : '-'}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>M2P Customer Id</Title>
                        <Text >{txnDetail?.user?.m2pCustomerId}</Text>
                    </Col>
                    <Col lg={8} xs={8}>
                        <Title level={5}>User</Title>
                        <Text >{txnDetail?.user?.firstName} {txnDetail?.user?.lastName} ({txnDetail?.user?.emailId})</Text>
                    </Col>
                </Row>
            </Card>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <Card title="Filters">
                        <Filter formProps={searchFormProps} />
                    </Card>
                </Col>
                <Col lg={24} xs={24}>
                    <List title={'User Txn'} breadcrumb={false}>
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                dataIndex="createdAt"
                                title="Date"
                                key="createdAt"
                                render={(value) => <DateField value={value} format="LLL" />}
                            />

                            <Table.Column
                                dataIndex='transactionAmount'
                                title="Txn Amount"
                                render={(value) =>
                                    <Space align='baseline' direction="vertical">
                                        <h4>
                                            Rs. {value}
                                        </h4>
                                    </Space>
                                }
                            />
                            <Table.Column
                                dataIndex='fee'
                                title="fee"
                                render={(value) =>
                                    <Space align='baseline' direction="vertical">
                                        <h4>
                                            Rs. {value}
                                        </h4>
                                    </Space>
                                }
                            />
                            <Table.Column
                                dataIndex='merchantName'
                                title="Merchant Name"
                            />
                            <Table.Column
                                dataIndex='merchantId'
                                title="Merchant Id"
                            />
                            <Table.Column
                                dataIndex='mcc'
                                title="MCC"
                            />
                            <Table.Column
                                dataIndex='totalRefundAmount'
                                title="Net Amount Refunded till"
                                render={(_, record: any) =>
                                    <Space align='baseline' direction="horizontal">
                                        
                                        {record?.totalRefundAmount} {(record?.refundTransactions?.length > 0) ? <Button onClick={() => {
                                            setRefundTxn({ value: record?.refundTransactions });
                                            refundDetailsShow()
                                        }}>{record?.refundTransactions?.length} </Button> :''}
                                        
                                    </Space>
                                }
                            />
                            <Table.Column
                                dataIndex='transactionCategory'
                                title="Transaction Category"
                                render={(value) =>
                                    <Space align='baseline' direction="vertical">
                                        <h5>
                                            {(value) ? value.replaceAll('_', ' ') : '-'}
                                        </h5>
                                    </Space>
                                }
                            />

                            <Table.Column<IRefund>
                                title="Actions"
                                dataIndex="actions"
                                render={(_, record) => (
                                    <Space>
                                        <Button onClick={() => {
                                            buttonSwith[record.id] = true
                                            // processRefund(record.id)
                                            setprocessButton({ state: true, id: record.id })
                                            processRefundShow()
                                        }} type="dashed">
                                            Proces Refund
                                        </Button>
                                    </Space>
                                )}
                            />
                        </Table>
                        {
                            (tableProps?.dataSource && tableProps?.pagination) ? <span>
                                Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                            </span> : ''
                        }
                    </List>
                </Col>
            </Row>
            <Modal {...processRefundModel}>
                <h4>Are you sure you want to process refund for this transaction?</h4>
                <Form {...processRefundForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
            <Modal onOk={refundDetailsClose} {...refundDetailsModel}>

                <div className="navigation">
                    <ol>
                        {
                            refundTxn.value.map((link: any) =>
                                <li key={link.id}>{link.merchantName} :- {link.transactionAmount}</li>
                            )
                        }
                    </ol>
                </div>
            </Modal>
        </Show>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item label="Search" name="q">
                        <Input
                            placeholder="Merchant Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="MCC" name="mcc">
                        <Input
                            placeholder="mcc"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="From Amount" name="fromAmount">
                        <Input
                            placeholder="From Amount"
                            type={'number'}
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="To Amount" name="toAmount">
                        <Input
                            placeholder="To Amount"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                {/* <Col lg={6} xs={24}>
                    <Form.Item label="Company Status" name="companyVerification">
                        <Select
                            allowClear
                            options={[
                                {
                                    label: "onboarded",
                                    value: "ONBOARDED",
                                },
                                {
                                    label: "Pending",
                                    value: "PENDING",
                                },
                            ]}
                            placeholder="Company Status"
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="Borrower Category" name="borrowerCategory">
                        <Select
                            allowClear
                            options={[
                                {
                                    label: "Rregular",
                                    value: "REGULAR",
                                },
                                {
                                    label: "Fast Track",
                                    value: "FAST_TRACK",
                                },
                                {
                                    label: "Waitlist",
                                    value: "WAITLIST",
                                },
                            ]}
                            placeholder="Borrower Category"
                        />
                    </Form.Item>
                </Col> */}
                <Col lg={6} xs={24}>
                    <Form.Item label="Created At" name="createdAt">
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item>
                        <br />
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};