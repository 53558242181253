import React from "react";
import {
    Modal, Space,
    Button,
    useModal,
    Table,
    Typography,
    Form,
    useModalForm
} from "@pankod/refine-antd";
import { DeleteOutlined } from '@ant-design/icons';
import { ICompanyPBI } from "interfaces";
import { CloseBundleButtonModel } from "./closeBundleButton.model";
import { useNavigate } from "react-router-dom";
const { Text, Title } = Typography;

export const ProductDeactivateModel: React.FC<{ refetchCompanyDetail: any, productBundleList: ICompanyPBI[], companyId: string }> = ({ refetchCompanyDetail, productBundleList, companyId }) => {

    const navigate = useNavigate();
    const { modalProps: productDeactivateModel, show: productDeactivateShow, close: productDeactivateClose } = useModal();

    productDeactivateModel.title = 'Product Bundles'
    productDeactivateModel.confirmLoading = true
    productDeactivateModel.centered = true
    productDeactivateModel.footer = [
        <Button onClick={() => { productDeactivateClose(); closeCompanyShow() }} danger={true} type="primary">
            Close Company account
        </Button>,
        <Button key="submit" onClick={() => { productDeactivateClose() }} type="default">
            Close
        </Button>
    ]
    productDeactivateModel.width = 500

    const { modalProps: closeCompanyModel, formProps: closeCompanyForm, show: closeCompanyShow, close: closeCompanyClose } = useModalForm({
        resource: "closeKodoAccountOfCompany",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            companyId,
            ignoreDueAmount: false,
            ignoreMultiplePbi: false
        },
        redirect: false,
        onMutationSuccess() {
            // refetchCompanyDetail(true)
            navigate('/company', { replace: true });
        }
    });

    closeCompanyModel.title = 'Close Company'
    closeCompanyModel.confirmLoading = true
    closeCompanyModel.centered = true
    closeCompanyModel.width = 500


    return (
        <Space >
            <Button danger={true} onClick={() => { productDeactivateShow() }} icon={<DeleteOutlined />} >
                Close Product bundle
            </Button>
            <Modal {...productDeactivateModel}>
                <Text>Select product bundles you want to close</Text>
                <Table dataSource={productBundleList} pagination={false} rowKey="id">
                    <Table.Column
                        title="Name"
                        render={(_, record: ICompanyPBI) =>
                            <Space align='baseline' direction="vertical">
                                <h4 style={{ margin: 0 }}>
                                    {record?.displayName}
                                </h4>
                                <p style={{ margin: 0 }}>
                                    {record?.productsBundle?.description}
                                </p>
                            </Space>
                        }
                    />

                    <Table.Column
                        title="Actions"
                        dataIndex="actions"
                        render={(_, element: ICompanyPBI) => (
                            <CloseBundleButtonModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyId} pbiId={element?.id} />
                        )}
                    />
                </Table>
            </Modal>
            <Modal {...closeCompanyModel}>
                <Title level={3}>In case there's any due amount +ve or -ve 100. Do you still wish to continue with account closure?</Title>
                <Form {...closeCompanyForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
        </Space>
    );
};