import { IResourceComponentsProps } from "@pankod/refine-core";
import React, { useState } from "react";

import {
    Form,
    Button,
    message,
    Upload,
    Row,
    Col,
    Show,
} from "@pankod/refine-antd";

import { UploadOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import '../../style.css';
import { Role } from "../../utility/role"



const API_URL = process.env.REACT_APP_URL;

export const UploadNpaReportShow: React.FC<IResourceComponentsProps> = () => {
    let role = Role()
    const pageTitle: string = 'Upload NPA report'


    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file: any) => {
            formData.set('file', file as RcFile);
            // formData.set('transaction', record?.id);
        });
        setUploading(true);
        const accessTokenRaw = sessionStorage.getItem('konsole-token');
        fetch(API_URL + '/npa/sbm-sync/manually-prepared', {
            method: 'POST',
            headers: {
                authorization: accessTokenRaw ? `Bearer ${accessTokenRaw}` : "",
            },
            body: formData,
        })
            // .then(res => '')

            .then((res) => {
                // console.log(res)
                setFileList([]);
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const props: UploadProps = {
        onPreview: async (file: UploadFile) => {
            let src = file.url as string;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj as RcFile);
                    reader.onload = () => resolve(reader.result as string);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        },
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            console.log(file.type)
            const isCSVorTXT = file.type === 'text/csv' || file.type === 'text/plain';
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (isCSVorTXT && isLt2M) {
                setFileList([file]);
                return isCSVorTXT && isLt2M;
            }
            else {
                if (!isCSVorTXT) {
                    message.error('File should be .csv / .txt!');
                    return false;
                }
                else if (!isLt2M) {
                    message.error('File is greater than 5MB in size')
                }
            }
        },
        fileList,
    };
    return (
        <Show title={pageTitle} headerButtons={({ defaultButtons }) => (
            <>
            </>
        )}>
            {
                (role.isBACKEND_SCRIPT_RUNNER || role.isCREDIT_ANALYST) ? <Row gutter={16}>
                    <Col span={24}>
                        <div className="reciept-container ">
                            <div>
                                <h1 className="reciept-heading">Attach Report</h1>
                            </div>

                            <div>
                                <Form>
                                    <Form.Item>
                                        <Upload {...props}
                                            // accept="text/csv"
                                            listType="text"
                                            multiple={false}
                                        >
                                            <Button icon={<UploadOutlined />}>Choose a File</Button>

                                        </Upload>
                                        <Button
                                            type="primary"
                                            onClick={handleUpload}
                                            disabled={fileList.length === 0}
                                            loading={uploading}
                                            style={{ marginTop: 16 }}
                                        >
                                            {uploading ? 'Uploading' : 'Start Upload'}
                                        </Button>
                                        {/* <SaveButton
                                            // onClick={saveImage}
                                            style={{ margin: 20 }} >Save</SaveButton> */}
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row> : <h4>You are not authorized</h4>
            }



        </Show>
    );
};