import React, { useState } from "react";
import { IResourceComponentsProps, CrudFilters, useExport } from "@pankod/refine-core";
import {
    List, Table, Space, ShowButton, Row, Col, DateField, ExportButton, useTable,
    Card,
    Icons,
    Form,
    Input,
    Button,
    DatePicker,
    FormProps
} from "@pankod/refine-antd";


import { IRefund } from "../../interfaces/refund";
const { RangePicker } = DatePicker;

export const RefundList: React.FC<IResourceComponentsProps> = () => {


    let [fromDate, setFromDate] = useState();
    let [toDate, setToDate] = useState();
    let [fromAmount, setFromAmount] = useState();
    let [toAmount, setToAmount] = useState();
    let [companyId, setCompanyId] = useState();
    let [merchantId, setMerchantId] = useState();
    let [userId, setUserId] = useState();
    // let [id, setID] = useState();

    let { tableProps, searchFormProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'getTransactionsWithPendingStatus',
        metaData: {
            filter: {
                fromDate: fromDate,
                toDate: toDate,
                fromAmount: fromAmount,
                toAmount: toAmount,
                userId: userId,
                // id: id,
                companyId: companyId,
                merchantId: merchantId
            }
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            let { fromAmount, toAmount, companyId, merchantId, userId, createdAt } = params;

            toAmount = toAmount || 1000000
            fromAmount = fromAmount || 0
            setFromAmount(fromAmount)
            setToAmount(toAmount)
            setMerchantId(merchantId)
            setCompanyId(companyId)
            setUserId(userId)
            // setID(id)
            setFromDate(createdAt ? createdAt[0].toISOString().split('T')[0] : undefined,)
            setToDate(createdAt ? createdAt[1].toISOString().split('T')[0] : undefined,)

            return filters;
        },
    });
    let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    const { triggerExport, isLoading: exportLoading } = useExport<IRefund>({
        dataProviderName: "grapghQl",
        resourceName: 'getTransactionsWithPendingStatus',
        metaData: {
            filter: {
                fromDate: fromDate,
                toDate: toDate,
                fromAmount: fromAmount,
                toAmount: toAmount,
                userId: userId,
                // id: id,
                companyId: companyId,
                merchantId: merchantId
            },
            limit: totalSize,
            isExport: true
        }
    });
    let totalPage
    if (tableProps?.pagination) {
        let totalItem: number = tableProps?.pagination.total || 0
        let pageSize: number = tableProps?.pagination.pageSize || 0
        totalPage = Math.ceil(totalItem / pageSize)
    }
    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List pageHeaderProps={{
                    extra: (
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ),
                }}>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column
                            dataIndex="createdAt"
                            title="Date"
                            sorter
                            key="createdAt"
                            render={(value) => <DateField value={value} format="LLL" />}
                        />

                        <Table.Column dataIndex="company" title="Company"
                            render={(_, record: any) => (
                                <Space align='baseline' direction="vertical">
                                    <h5>
                                        {record.company.registeredName}
                                    </h5>
                                </Space>
                            )}

                        />

                        <Table.Column
                            title="User"
                            render={(_, record: any) => (
                                <Space align='baseline' direction="vertical">
                                    <p>
                                        {record.user.firstName} {record.user['lastName']}
                                    </p>
                                    <h4>
                                        {record.user.emailId}
                                    </h4>
                                </Space>
                            )}
                        />
                        <Table.Column
                            title="Category"
                            render={(_, record: any) => (
                                <Space align='baseline' direction="vertical">
                                    <p>
                                        {record?.transactionCategory}
                                    </p>
                                    <h4>
                                        {record?.transactionStatus}
                                    </h4>
                                </Space>
                            )}
                        />

                        <Table.Column
                            dataIndex='transactionAmount'
                            title="Txn Amount"
                            sorter
                            render={(value) =>
                                <Space align='baseline' direction="vertical">
                                    <h5>
                                        Rs. {value}
                                    </h5>
                                </Space>
                            }
                        />
                        <Table.Column
                            dataIndex='merchantName'
                            title="Merchant Name"
                        />
                        <Table.Column
                            dataIndex='merchantId'
                            title="Merchant Id"
                        />

                        <Table.Column<IRefund>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <ShowButton
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                    {
                        (tableProps?.dataSource && tableProps?.pagination) ? <span>
                            Showing {tableProps?.pagination?.current} - {totalPage} of <strong>{tableProps?.pagination.total}</strong> Records
                        </span> : ''
                    }
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={6}>
                    <Form.Item label="Search merchant" name="merchantId">
                        <Input
                            placeholder="merchant Id"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="Search user Id" name="userId">
                        <Input
                            placeholder="user Id"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="From Amount" name="fromAmount">
                        <Input type={'number'}
                            placeholder="From Amount"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                    <Form.Item label="To Amount" name="toAmount">
                        <Input type={'number'}
                            placeholder="To Amount"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={6}>
                    <Form.Item label="Search company Id" name="companyId">
                        <Input
                            placeholder="company Id"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label="Created At" name="createdAt">
                        <RangePicker />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item>
                        <br />
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};