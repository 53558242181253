import { Typography } from 'antd';
const { Title } = Typography;

interface DescriptionItemProps {
    title: string;
    status?: string;
    className?: string;
    content: React.ReactNode;
}

export const DescriptionItem = ({ title, content, status, className }: DescriptionItemProps) => (
    <div className={className || "item-profile-wrapper mb-3"}>
        <Title level={5} className="item-profile-p-label mb-0">{title ? `${title} :` : '-'}</Title>
        <div style={{ color: status }}>
            {content}
        </div>
    </div >
);