import React, { useState, useEffect } from "react";
import { Typography, Tag, Collapse } from 'antd';
import { Divider, Drawer, Space } from 'antd';
import { List } from 'antd';

import { useOne } from "@pankod/refine-core";
import {
    Button, Card, Col, Create, DateField, Form, Row,
    useDrawerForm,
} from "@pankod/refine-antd";

import { ICompanyPBI, IUser, IUserAddress } from "../../../interfaces";
import { ICard, KycStatus, UserCompanyDetails } from "../../../interfaces/company";
import { getCardType } from "../../../utility/companyOnboard";
import { InitiateKycComponent } from "./InitiateKyc.component";
import { LockCardModel } from "../../../components/model/lockCard.model";
import { CardDetailsModel } from "../../../components/model/cardDetails.model";
import { DescriptionItem } from "../../../components/text-layout/descriptionItem.text";
import { EditCardLimitModel } from "../../../components/model/editCardLimit.model";
import { UserDetails } from "../../../components/userDetails";
import { AssignBundleModel } from "./models/assignBundle.model";
import { ConvertToPhysicalCardModel } from "../../../components/model/convertToPhysicalCard.model";
import { IssueVirtualCardModel } from "../../../components/model/IssueVirtualCard.model";
import { BlockCardModel } from "components/model/blockCard.model";
import { deleteQueryParam, getQueryParam, setQueryParam } from "utility/queryParams";
import { AddEditUserAddressModel } from "./models/addUserAddress.model";
import { UserDeactivationModel } from "./models/userDeactivation.model";
import { getUserAccount, getUserAccountStatus } from "utility/companyUtils";
import { Role } from "utility/role";
const { Panel } = Collapse;

const { Title, Text } = Typography;

interface IManageUserComponentProps {
    refetchCompanyDetail: any
    refetchUsers: any
    userItem: IUser
    isPayLaterCandidate: boolean
    productsBundleIssuances: ICompanyPBI[] | undefined
    companyId: string | undefined
    fetchUserDetails: boolean
}

export const ManageUserComponent: React.FC<IManageUserComponentProps> = (props) => {

    const { userItem, isPayLaterCandidate, productsBundleIssuances, refetchCompanyDetail, refetchUsers, companyId, fetchUserDetails } = props;

    let [refetchKYCData, setRefetchKYCData] = useState(false);
    let [refetchUserData, setRefetchUserData] = useState(false);
    let [pbiId, setPbiId] = useState<string>('');

    let role = Role()
    // Manage User Drawer
    const {
        drawerProps: userDrawerProps,
        show: userDrawerShow,
        close: userDrawerClose,
    } = useDrawerForm<any>({
        action: "create",
    });
    // showDrawerProps.width = 700
    userDrawerProps.title = `${userItem.fullName} Details`
    userDrawerProps.onClose = () => {
        deleteQueryParam('userId');
        deleteQueryParam('modal');
        userDrawerClose()
    }

    // Add User Address Drawer
    const {
        formProps: addUserAddressFormProps,
        drawerProps: addUserAddressDrawerProps,
        show: addUserAddressDrawerShow,
        saveButtonProps: addUserAddressSaveButtonProps,
    } = useDrawerForm<any>({
        action: "create",
    });

    // addUserAddressDrawerProps.width = 700
    addUserAddressDrawerProps.title = `Add new address`

    let cardIntegrationObj: any = {}
    productsBundleIssuances?.map((ele: ICompanyPBI): void => {
        ele?.productsBundle?.cardIntegrations.map((cardEle: any) => {
            if (!cardIntegrationObj[cardEle.id] && cardEle.availableForNewCardIssuance) {
                cardIntegrationObj[cardEle.id] = {
                    ...cardEle,
                    productsBundleIssuances: []
                }
            }
            if (cardIntegrationObj[cardEle.id]) {
                let productsBundleIssuanceObj = {
                    id: ele.id,
                    productsBundle: {
                        description: ele.productsBundle.description,
                        displayName: ele?.displayName || ele.productsBundle.displayName,
                        id: ele.productsBundle.id,
                    },
                    cards: userItem?.card.filter((userCard: any) => {
                        return ele.id === userCard.pbiId
                    })
                }
                cardIntegrationObj[cardEle.id].productsBundleIssuances.push(productsBundleIssuanceObj)
            }
        })
    })

    // getLatestUserEkySessionForSuperAdmin
    let { refetch: refetchLatestUserEkySession, data: latestUserEkySessionData }: any = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getLatestUserEkySessionForSuperAdmin',
        metaData: {
            userId: userItem.id
        },
        id: '',
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                setRefetchKYCData(false)
            },
        },
    });

    if (Object.keys(cardIntegrationObj).length > 0) {
        latestUserEkySessionData?.userKycDataArr.map((kycData: any) => {
            if (cardIntegrationObj?.[kycData.cardIntegration?.id]) {
                cardIntegrationObj[kycData.cardIntegration?.id].kycDetails = kycData
            }
        })
    }

    // get pendingPaymentAndAssociatedCompanyListForUser
    let { refetch: refetchUserDetails, data: userDetails } = useOne<UserCompanyDetails>({
        dataProviderName: "grapghQl",
        resource: 'pendingPaymentAndAssociatedCompanyListForUser',
        metaData: {
            userAccountId: getUserAccount(userItem.userAccounts),
            companyId: companyId
        },
        id: '',
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                setRefetchUserData(false)
            },
        },
    });
    // getLimitsSpecifiedInCardIssuanceRequest
    let { refetch: refetchLimitsSpecifiedInCard, data: limitsSpecifiedInCardData }: any = useOne<any>({
        dataProviderName: "grapghQl",
        resource: 'getLimitsSpecifiedInCardIssuanceRequest',
        metaData: {
            input: {
                pbiId: pbiId,
                userId: userItem.id,
            }
        },
        id: '',
        queryOptions: {
            enabled: false,
            onSuccess: (data: any) => {
                setRefetchKYCData(false)
            },
        },
    });

    useEffect(() => {
        if (getQueryParam("modal") === "manage-user" && getQueryParam('userId') === userItem.id && !latestUserEkySessionData) {
            refetchLatestUserEkySession()
            refetchUserDetails()
            refetchLimitsSpecifiedInCard()
            userDrawerShow()
        }
    });
    useEffect(() => {
        if (refetchKYCData) {
            setQueryParam('modal', 'manage-user')
            setQueryParam('userId', userItem.id)
            refetchLatestUserEkySession()
            // refetchCheckUserKyc()
            refetchUserDetails()
            refetchLimitsSpecifiedInCard()
        }
        if (userItem?.pbiMappings.length > 0) {
            setPbiId(userItem.pbiMappings[0].pbiId)
        }
    });

    return (
        <>
            {
                (!isPayLaterCandidate) && <Button onClick={() => { setRefetchUserData(true); setRefetchKYCData(true); userDrawerShow() }} type="primary">
                    Manage User
                    {/* Manage {(userItem?.card.length) ? "Card" : "KYC"} */}
                </Button>
            }
            <Drawer {...userDrawerProps} width={720} >
                {/* user details */}
                <UserDetails user={userItem} title="User Profile" userDetails={userDetails?.data} monthlyLimit={limitsSpecifiedInCardData?.monthlyLimit} dailyLimit={limitsSpecifiedInCardData?.dailyLimit} />
                {
                    (role.isCREDIT_ANALYST || role.isAccountManager) &&
                    (companyId && (getUserAccount(userItem.userAccounts) && userDetails?.data.pendingPaymentCount === 0) ?
                        <UserDeactivationModel deactivateAccount={userDetails?.data.userCompanyList.length > 1 ? true : false} companyId={companyId} userStatus={getUserAccountStatus(userItem.userAccounts)} userAccountId={getUserAccount(userItem.userAccounts)} refetchCompanyDetail={refetchCompanyDetail} refetchUsers={refetchUsers} /> :
                        <Text strong type="danger">Unable to delete user from konsole for now</Text>)
                }
                <Divider />
                <div className="d-flex">
                    <Title level={5}>Card Issuers</Title>
                </div>
                {
                    Object.keys(cardIntegrationObj)?.map((key: string, index: number) => {
                        return <Collapse defaultActiveKey={['1']} className="mb-3">
                            <Panel header={`${cardIntegrationObj[key]?.description}`} key={index + 1}>
                                <div>
                                    {
                                        cardIntegrationObj[key]?.kycDetails &&
                                        <Space>
                                            <Title level={5}>KYC Status : </Title>
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.COMPLETED) &&
                                                <Tag color="green" className="mb-2">{`KYC ${cardIntegrationObj[key]?.kycDetails?.status}`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.PENDING) &&
                                                <Tag color="cyan" className="mb-2">{`KYC ${cardIntegrationObj[key]?.kycDetails?.status}`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.NOT_SUBMITTED) &&
                                                <Tag color="cyan" className="mb-2">{`KYC NOT SUBMITTED`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.SUBMITTED) &&
                                                <Tag color="blue" className="mb-2">{`KYC SUBMITTED`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.SUBMITTED_AND_VERIFIED) &&
                                                <Tag color="green" className="mb-2">{`KYC SUBMITTED AND VERIFIED`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.FAILED) &&
                                                <Tag color="danger" className="mb-2">{`KYC ${cardIntegrationObj[key]?.kycDetails?.status}`}</Tag>
                                            }
                                            {
                                                (cardIntegrationObj[key]?.kycDetails?.status === KycStatus.VERIFIED) &&
                                                <Tag color="green" className="mb-2">{`KYC ${cardIntegrationObj[key]?.kycDetails?.status}`}</Tag>
                                            }
                                            {
                                                (!cardIntegrationObj[key]?.kycDetails?.status) &&
                                                <Tag color="purple" className="mb-2">{`KYC Process Link Sent`}</Tag>
                                            }
                                        </Space>
                                    }
                                    <Title level={5}>Product Bundles</Title>

                                </div>
                                {
                                    cardIntegrationObj[key].productsBundleIssuances?.map((cardPbi: ICompanyPBI, index: number) => {
                                        return userItem.pbiMappings.find(it => it.pbiId === cardPbi.id) ? <Collapse defaultActiveKey={['1']} className="mb-3">
                                            <Panel header={`${cardPbi.productsBundle.description}`} key={index + 1}>
                                                <Space className="mb-2 mr-2">
                                                    <InitiateKycComponent refetchUsers={refetchUsers} limitsSpecifiedInCardData={limitsSpecifiedInCardData} kycDetails={cardIntegrationObj[key]?.kycDetails} isPayLaterCandidate={isPayLaterCandidate} productBundle={cardPbi.productsBundle} pbiId={cardPbi?.id} userItem={userItem} cardIntegrationId={cardIntegrationObj[key]?.id} />
                                                </Space>
                                                {(cardIntegrationObj[key]?.kycDetails?.status === KycStatus.COMPLETED) && <IssueVirtualCardModel cardPbi={cardPbi} user={userItem} refetchUsers={refetchUsers} pbiId={cardPbi?.id} cardIntegrationId={cardIntegrationObj[key]?.id} />}
                                                {
                                                    cardPbi?.cards.map((userCard: ICard) => {

                                                        return <Card size="small"
                                                            style={{ borderColor: userCard.isBlocked ? "red" : "#d9d9d9" }}
                                                            className="mb-3"
                                                            actions={[
                                                                <LockCardModel refetchUsers={refetchUsers} userCard={userCard} />,
                                                                <EditCardLimitModel refetchUsers={refetchUsers} userId={userItem?.id} userCard={userCard} />,
                                                                !userCard.isBlocked && <BlockCardModel refetchUsers={refetchUsers} userCard={userCard} />,
                                                                <CardDetailsModel refetchUsers={refetchUsers} userCard={userCard} />,
                                                            ]}
                                                        >
                                                            <Row>
                                                                <Col>
                                                                    {
                                                                        userCard.isBlocked && <Tag color="red">Blocked</Tag>
                                                                    }
                                                                    {
                                                                        userCard.isLocked && <Tag color="red">Locked</Tag>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col span={8}>
                                                                    <DescriptionItem title="Kit Number" content={userCard?.kit?.kitNo} />
                                                                </Col>
                                                                <Col span={5}>
                                                                    <DescriptionItem title="Card N/W" content={userCard?.kit?.cardIntegration?.cardNetwork} />
                                                                </Col>
                                                                <Col span={5}>
                                                                    <DescriptionItem title="Balance Type" content={getCardType(userCard?.fundsSource)} />
                                                                </Col>
                                                                <Col span={6}>
                                                                    <DescriptionItem title="Card Label" content={userCard?.label} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col span={8}>
                                                                    <DescriptionItem title="When Activated" content={userCard?.cardActivatedInternallyAt ? <DateField value={userCard?.cardActivatedInternallyAt} format="LLL" /> : '-'} />
                                                                </Col>
                                                                <Col span={5}>
                                                                    <DescriptionItem title="Card No" content={`**** **** **** ${userCard?.endNo}`} />
                                                                </Col>
                                                                <Col span={5}>
                                                                    <DescriptionItem title="Monthly Limit" content={userCard?.monthlyLimit || '-'} />
                                                                </Col>
                                                                <Col span={6}>
                                                                    <DescriptionItem title="Daily Limit" content={userCard?.dailyLimit || '-'} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col span={8}>
                                                                    <ConvertToPhysicalCardModel hasDeliveryAddress={userItem.deliveryAddress.length > 0 ? true : false} cardholderUserId={userItem?.id} pbiId={userCard?.pbiId} refetchUsers={refetchUsers} userCard={userCard} />
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    })
                                                }
                                                {/* {
                                                    userItem?.card.length === 0 && <><br /><Text>No Cards Issued yet</Text></>
                                                } */}
                                            </Panel>
                                        </Collapse> : <List.Item >
                                            {
                                                userItem && <AssignBundleModel refetchUsers={refetchUsers} refetchCompanyDetail={refetchCompanyDetail} userItem={userItem} pbiId={cardPbi.id} cardPbi={cardPbi} />
                                            }
                                        </List.Item>
                                    })
                                }
                            </Panel>
                        </Collapse>
                    })
                }
                <Divider />
                {
                    // (latestUserEkySessionData?.userEKycSessionRecord !== null) && <>
                    //     <Title level={5}>SBM KYC Summary</Title>
                    //     <Row>
                    //         <Col span={6}>
                    //             <DescriptionItem title="Status" status={latestUserEkySessionData?.userEKycSessionRecord?.succeeded ? '#52c41a' : '#ff4d4f'} content={latestUserEkySessionData?.userEKycSessionRecord?.succeeded ? 'SUBMITTED' : 'FAILED'} />
                    //         </Col>
                    //         <Col span={8}>
                    //             <DescriptionItem title="e-Kyc link sent to user at" content={latestUserEkySessionData?.userEKycSessionRecord?.notificationSentToUserAt ? <DateField value={latestUserEkySessionData?.userEKycSessionRecord?.notificationSentToUserAt} format="LLL" /> : '-'} />
                    //         </Col>
                    //         <Col span={12}>
                    //             <DescriptionItem title="e-Kyc completed on" content={latestUserEkySessionData?.userEKycSessionRecord?.registrationLastSucceededAt ? <DateField value={latestUserEkySessionData?.userEKycSessionRecord?.registrationLastSucceededAt} format="LLL" /> : '-'} />
                    //         </Col>
                    //         <Col span={6}>
                    //             <DescriptionItem title="Status last updated on" content={latestUserEkySessionData?.userEKycSessionRecord?.registrationLastSucceededAt ? <DateField value={latestUserEkySessionData?.userEKycSessionRecord?.registrationLastSucceededAt || null} format="LLL" /> : '-'} />
                    //         </Col>
                    //     </Row>
                    //     <Divider />
                    // </>
                }


                <Row>
                    <Col span={24}>
                        <List
                            header={<div className="d-flex align-items-center justify-content-between">
                                <DescriptionItem
                                    title="Delivery Address"
                                    content="(not used for kyc)"
                                />
                                {
                                    userItem.deliveryAddress.length === 0 && <AddEditUserAddressModel refetchUsers={refetchUsers} refetchCompanyDetail={refetchCompanyDetail} userItem={userItem} />
                                }
                            </div>}
                            bordered
                            dataSource={userItem.deliveryAddress}
                            renderItem={(address: IUserAddress) => (
                                <List.Item>
                                    <DescriptionItem
                                        title={`Address`}
                                        content={`${address.addressLine1 ? `${address.addressLine1}, ` : ''}${address.addressLine2 ? `${address.addressLine2}, ` : ''}${address.cityName ? `${address.cityName}, ` : ''}${address.stateName ? `${address.stateName} - ` : ''}${address.pincode ? `${address.pincode}` : ''}`}
                                    />
                                    <AddEditUserAddressModel refetchUsers={refetchUsers} refetchCompanyDetail={refetchCompanyDetail} userItem={userItem} addressItem={address} />
                                </List.Item>
                            )}
                            locale={{ emptyText: 'No Delivery Address' }}
                        />
                    </Col>
                    <Divider />

                </Row>
            </Drawer>

        </>
    );
};