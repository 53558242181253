import React from "react";
import {
    Button,
    DateField,
    NumberField, Space, Table, useTable,
} from '@pankod/refine-antd';
import { IRefund } from "../../../interfaces/refund";

import { DescriptionItem } from "../../../components/text-layout/descriptionItem.text";

interface RefundOverviewProps { }

export const RefundOverview: React.FC<RefundOverviewProps> = ({ }: RefundOverviewProps) => {
    let { tableProps } = useTable<IRefund>({
        dataProviderName: "grapghQl",
        resource: 'getTransactionsWithPendingStatus',
        metaData: {
            filter: {},
            limit: 5,
        }
    });
    let totalSize = (tableProps && tableProps.pagination && tableProps.pagination.total) ? tableProps.pagination.total : 100
    return <Table size="small" {...tableProps} rowKey="id">
        <Table.Column
            dataIndex="createdAt"
            title="Date"
            sorter
            key="createdAt"
            render={(value) => <DateField value={value} format="LL" />}
        />

        <Table.Column
            title="User"
            render={(_, record: any) => (
                <Space align='baseline' direction="vertical">
                    <DescriptionItem title={`${record.user.firstName} ${record.user['lastName'] || '-'}`} content={record.user.emailId} />
                    <h5>
                        {record.company.registeredName}
                    </h5>
                </Space>
            )}
        />
        <Table.Column
            title="Category"
            render={(_, record: any) => (
                <Space align='baseline' direction="vertical">
                    <DescriptionItem title={record?.transactionCategory.replaceAll("_", " ") || ''} content={record?.transactionStatus} />
                    <h5>
                        Rs. <NumberField
                            value={record?.transactionAmount || 0}
                            options={{
                                notation: "standard",
                            }}
                        />
                    </h5>
                </Space>
            )}
        />
        <Table.Column
            title="Category"
            render={(_, record: any) => (
                <Space align='baseline' direction="vertical">
                    <DescriptionItem title={record?.merchantName} content={record?.merchantId} />
                </Space>
            )}
        />

        <Table.Column<IRefund>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
                <Space>
                    <Button
                        href={`/refund/show/${record?.id}`}
                        size="small"
                    >
                        Show
                    </Button>
                </Space>
            )}
        />
    </Table>
}