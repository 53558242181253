import { DateField, NumberField, Show, Space, Table, Tag, useTable } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { FundSettlementStatus } from "interfaces/mmt";
import { urlPath } from "utility/url";
import { SettlementEventType } from "../../interfaces/mmt";


export const VASettlementShow: React.FC<IResourceComponentsProps> = () => {
    let url = urlPath()
    const param: any = url.params

    const eventType: string = (param && param.get('eventType')) ? param.get('eventType') : ''

    let { tableProps } = useTable({
        dataProviderName: "grapghQl",
        resource: 'getSettlementsOfFeeStatement',
        metaData: {
            statementId: url.lastElement,
            isMutation: true
        }
    });
    return (
        <Show breadcrumb={false} headerButtons={({ defaultButtons }) => (<> </>)}>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="companyName" title="Company" />
                <Table.Column dataIndex="urn" title="URN" />
                {
                    eventType === SettlementEventType.KODO_VA_FEE && <Table.Column dataIndex="integration" title="Integration" />
                }
                <Table.Column
                    dataIndex="FeeChargeEventDate"
                    title="Date"
                    key="FeeChargeEventDate"
                    render={(value) => <DateField value={value} format="LLL" />}
                />

                <Table.Column
                    dataIndex='totalAmount'
                    title="Total Amount"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <small>
                                Rs.
                                <NumberField
                                    value={value || 0}
                                    options={{
                                        notation: "standard",
                                    }}
                                />
                            </small>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='fee'
                    title="Fee"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <small>
                                Rs.
                                <NumberField
                                    value={value || 0}
                                    options={{
                                        notation: "standard",
                                    }}
                                />
                            </small>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='gstOnFee'
                    title="GST On Fee"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <small>
                                Rs.
                                <NumberField
                                    value={value || 0}
                                    options={{
                                        notation: "standard",
                                    }}
                                />
                            </small>
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex='taxAmount'
                    title="Tax Amount"
                    render={(value) =>
                        <Space align='baseline' direction="vertical">
                            <small>
                                Rs.
                                <NumberField
                                    value={value || 0}
                                    options={{
                                        notation: "standard",
                                    }}
                                />
                            </small>
                        </Space>
                    }
                />
                <Table.Column dataIndex="settlementStatus" title="Settlement Status"
                    render={(value) => {
                        let color;
                        switch (value) {
                            case FundSettlementStatus.CREATED:
                                color = "blue";
                                break;
                            case FundSettlementStatus.FAILED:
                                color = "red";
                                break;
                            case FundSettlementStatus.INITIATED:
                                color = "cyan";
                                break;
                            case FundSettlementStatus.SETTLED:
                                color = "green";
                                break;
                            default:
                                color = "";
                                break;
                        }
                        return <Tag color={color}>{value}</Tag>;
                    }} />
            </Table>
        </Show>
    )

}