import React, { useEffect } from "react";

import { useModalForm, Modal, Space, Form } from "@pankod/refine-antd";

import { urlPath } from "../../utility/url";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

export const LockCardModel: React.FC<{ refetchUsers: any, userCard: any }> = ({ userCard, refetchUsers }) => {
    let url = urlPath()

    useEffect(() => {

    });

    //Check User Kyc
    let { modalProps: LockCardConfirmModel, formProps: LockCardConfirmForm, show: LockCardConfirmShow, close: LockCardConfirmClose } = useModalForm<any>({
        resource: `${userCard?.isLocked ? "unlockCardBySuperAdmin" : "lockCardBySuperAdmin"}`,
        dataProviderName: "grapghQl",
        action: "create",
        metaData: { cardId: userCard?.id },
        redirect: false,
        onMutationSuccess(data, variables, context) {
            refetchUsers(true)
        }
    });
    LockCardConfirmModel.title = userCard?.isLocked ? "Unlock Card" : "Lock Card"
    LockCardConfirmModel.centered = true
    LockCardConfirmModel.width = 400
    LockCardConfirmModel.onCancel = () => {
        LockCardConfirmForm.form?.resetFields();
        LockCardConfirmClose();
    }


    return (
        <Space >
            <Space onClick={() => { LockCardConfirmShow() }}>
                {userCard?.isLocked ? <Space><UnlockOutlined key="lock" /> Unlock Card</Space> : <Space direction="horizontal"><LockOutlined key="lock" /> Lock Card </Space>}
            </Space>

            <Modal {...LockCardConfirmModel}>
                <h4>Are you sure you want to {userCard?.isLocked ? "unlock" : "lock"}  the card? </h4>
                <Form {...LockCardConfirmForm} layout="vertical">
                    <></>
                </Form>
            </Modal>
        </Space>
    );
};