import React, { useEffect, useState } from "react";
import { List, Typography } from 'antd';

import {
    Table, Space,
    NumberField,
    Button,
    useModalForm,
    Modal,
    Form,
    Input,
    Select,
    Row, Col,
    DatePicker,
    Divider,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import { CardIntegration, ICompany, ICompanyPBI, InputValidatorRegex, UserTitle, UserGender, IdType, DirectorsData } from "../../../../interfaces/company";
import { AddEditUserComponent } from "../addEditUser.component";
import { filterForActiveCardIntegrations } from "../../../../utility/companyOnboard";
import { CreateRepaymentRuleModel } from "../repayment-rule.model";
import { verificationStatus } from "pages/company/list";
import { ProductDeactivateModel } from "../models/productDeactivate.model";
import { UpdateCompanyBalanceButtonModel } from "../models/update-company-balance.model";
const { Title } = Typography;

export const ProductsBundlesTable: React.FC<{ refetchCompanyDetail: any, refetchUsers: any, productBundlesList: ICompanyPBI[], companyDetail: ICompany | undefined, nonDeletedPBIsSortedByDate: ICompanyPBI[] }> = ({ nonDeletedPBIsSortedByDate, refetchCompanyDetail, refetchUsers, productBundlesList, companyDetail }) => {

    const [creditLimit, setCreditLimit] = useState(0);
    const [sanctionedCreditLimitId, setsanctionedCreditLimitID] = useState('');
    const [tenantKey, setTenantKey] = useState('');
    const [PBIid, setPBIid] = useState('');
    const [sanctionedCreditMonthsApproved, setSanctionedCreditMonthsApproved] = useState(0);
    const [selectedDirector, setSelectedDirector] = useState<DirectorsData>();
    const [selectedDirectorId, setSelectedDirectorId] = useState();

    useEffect(() => {
        if (companyDetail?.companyData?.directors && companyDetail?.companyData?.directors.length > 0) {
            setSelectedDirector(companyDetail?.companyData?.directors.find((director: any) => director.id === selectedDirectorId))
            if (selectedDirector) {
                RegisterM2pCorporateForm.form?.setFieldsValue({ firstName: selectedDirector?.firstName })
                RegisterM2pCorporateForm.form?.setFieldsValue({ lastName: selectedDirector?.lastName })
            }
        }

    });
    let cardIntegrationList: CardIntegration[] = productBundlesList?.map((pbi: ICompanyPBI): CardIntegration => {
        return pbi.cardIntegration
    })
    cardIntegrationList = filterForActiveCardIntegrations(cardIntegrationList)

    //kodo Credit Edit Model (wip)
    const { modalProps: kodoCreditEditModel, formProps: kodoCreditEditForm, show: kodoCreditEditShow, close: kodoCreditEditClose } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "updateSanctionedCreditLimitOfPbi",
        action: "create",
        metaData: { sanctionedCreditLimitId: sanctionedCreditLimitId, PBIid: PBIid },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    kodoCreditEditModel.title = 'Edit Kodo Credit'
    kodoCreditEditModel.centered = true
    kodoCreditEditModel.width = 400
    kodoCreditEditModel.onCancel = () => {
        kodoCreditEditForm.form?.resetFields();
        kodoCreditEditClose();
    }

    const { modalProps: RegisterM2pCorporateModel, formProps: RegisterM2pCorporateForm, show: RegisterM2pCorporateShow, close: RegisterM2pCorporateClose } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "registerM2pCorporate",
        action: "create",
        metaData: { companyId: companyDetail?.id, tenantKey: tenantKey },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    RegisterM2pCorporateModel.title = 'Register Corporate to M2P'
    RegisterM2pCorporateModel.centered = true
    RegisterM2pCorporateModel.width = 800
    RegisterM2pCorporateModel.onCancel = () => {
        RegisterM2pCorporateForm.form?.resetFields();
        RegisterM2pCorporateClose();
    }


    const { modalProps: EnableM2pCorporateModel, formProps: EnableM2pCorporateForm, show: EnableM2pCorporateShow, close: EnableM2pCorporateClose } = useModalForm<ICompany>({
        dataProviderName: "grapghQl",
        resource: "setM2pCorporateEnabledAt",
        action: "create",
        metaData: {
            companyId: companyDetail?.id,
        },
        redirect: false,
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    EnableM2pCorporateModel.title = 'Enable Corporate to M2P'
    EnableM2pCorporateModel.centered = true
    EnableM2pCorporateModel.width = 600
    EnableM2pCorporateModel.onCancel = () => {
        EnableM2pCorporateForm.form?.resetFields();
        EnableM2pCorporateClose();
    }

    return (
        <>
            <List >
                <List.Item>
                    <Space align="center">
                        <Title level={5}>Products Bundles</Title>
                        <Button onClick={() => { setTenantKey('LQKODO'); RegisterM2pCorporateShow() }} className="mr-3" type="default">
                            Register Corporate
                        </Button>
                        <Button onClick={() => { EnableM2pCorporateShow() }} type="default">
                            Enable Corporate
                        </Button>
                        {companyDetail && <UpdateCompanyBalanceButtonModel refetchCompanyDetail={refetchCompanyDetail} companyId={companyDetail.id} />}
                    </Space>
                    {
                        (companyDetail && nonDeletedPBIsSortedByDate) && <ProductDeactivateModel refetchCompanyDetail={refetchCompanyDetail} productBundleList={nonDeletedPBIsSortedByDate} companyId={companyDetail.id} />
                    }
                </List.Item>
            </List>


            <Table dataSource={nonDeletedPBIsSortedByDate} pagination={false} rowKey="id">
                <Table.Column
                    title="Name"
                    render={(_, record: ICompanyPBI) =>
                        <Space align='baseline' direction="vertical">
                            <h4 style={{ margin: 0 }}>
                                {record?.displayName}
                            </h4>
                            <p style={{ margin: 0 }}>
                                {record?.productsBundle?.description}
                            </p>
                        </Space>
                    }
                />
                <Table.Column
                    title="Available Limit"
                    render={(_, record: ICompanyPBI) =>
                        <Space align='baseline'>
                            Rs.<NumberField
                                value={record?.availableLimit || 0}
                                options={{
                                    notation: "standard",
                                }}
                            />
                        </Space>
                    }
                />
                <Table.Column
                    dataIndex="creditLimit"
                    title="Credit Limit"
                    render={(_, record: ICompanyPBI) =>
                        <>
                            {
                                (record?.creditLimit) ? <Space align='baseline'>
                                    Rs.<NumberField
                                        value={record?.creditLimit || 0}
                                        options={{
                                            notation: "standard",
                                        }}
                                    />
                                </Space> : '-'
                            }
                        </>
                    }
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, element: ICompanyPBI) => (
                        <Space>
                            <AddEditUserComponent refetchUsers={refetchUsers} refetchCompanyDetail={refetchCompanyDetail} pbiId={element?.id} isAddUser={true} />
                            {
                                (companyDetail?.companyVerification === verificationStatus.ONBOARDED && element?.productsBundle?.productEnabled?.cards) ? <Button onClick={() => { setCreditLimit(element.creditLimit); setPBIid(element.id); setSanctionedCreditMonthsApproved(element?.sanctionedCreditLimit?.monthsApprovedFor); setsanctionedCreditLimitID(element?.sanctionedCreditLimit?.id); kodoCreditEditShow(element?.productsBundle.id) }} type="link">
                                    Edit Credit Limit
                                </Button> : ''
                            }
                            {
                                (companyDetail?.companyVerification === verificationStatus.ONBOARDED && element?.productsBundle?.productEnabled?.cards) ?
                                    (!element?.repaymentAutoDebitRule) ? <CreateRepaymentRuleModel refetchCompanyDetail={refetchCompanyDetail} productBundle={nonDeletedPBIsSortedByDate} pbiDisplayName={element?.displayName} pbiId={element?.id} autodeLinkShow={true} isForEdit={false} company={companyDetail} />
                                        : <CreateRepaymentRuleModel refetchCompanyDetail={refetchCompanyDetail} productBundle={nonDeletedPBIsSortedByDate} pbiId={element?.id} pbiDisplayName={element?.displayName} autodeLinkShow={true} isForEdit={true} company={companyDetail} /> : <></>
                            }
                        </Space>
                    )}
                />
            </Table>
            <Modal {...kodoCreditEditModel}>
                <Form {...kodoCreditEditForm} layout="vertical">
                    <Form.Item
                        initialValue={creditLimit}
                        rules={[
                            {
                                message: 'Credit Limit is required',
                                required: true,
                            },
                            {
                                message: 'Only Numaric value allowed',
                                pattern: InputValidatorRegex.MOBILE_NO,
                            },
                        ]} label="Credit Limit" name="creditLimit">
                        <Input onChange={(event: any) => {
                            kodoCreditEditForm.form?.setFieldsValue({ creditLimit: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} maxLength={9} />
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            message: 'Review After Months is required',
                            required: true,
                        },
                        {
                            message: 'Only Numaric value allowed',
                            pattern: InputValidatorRegex.MOBILE_NO,
                        },
                    ]}
                        initialValue={sanctionedCreditMonthsApproved} label="Review After Months" name="monthsApprovedFor">
                        <Input onChange={(event: any) => {
                            kodoCreditEditForm.form?.setFieldsValue({ monthsApprovedFor: event?.target?.value.replace(/[^0-9]/g, '') })
                        }} maxLength={9} />
                    </Form.Item>

                </Form>
            </Modal>
            <Modal {...EnableM2pCorporateModel}>
                <Form {...EnableM2pCorporateForm} layout="vertical">
                    <Form.Item
                        rules={[
                            {
                                message: 'Kit Number is required',
                                required: true,
                            }
                        ]} label="Kit Number" name="kitNo">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal {...RegisterM2pCorporateModel}>
                <h4>Are you sure you want to Register <b>{companyDetail?.brandName}</b> to M2P? </h4>
                <Form {...RegisterM2pCorporateForm} layout="vertical">
                    <Row gutter={11}>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Card Integration is required',
                                    required: true,
                                }
                            ]}
                                label="Card Integration" name="cardIntegrationId">
                                <Select
                                    fieldNames={{ value: "id", label: 'description' }}
                                    options={cardIntegrationList}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item 
                                label="Select Added Director" name="directorId">
                                <Select
                                    onChange={(event: any) => {
                                        setSelectedDirectorId(event)
                                    }}
                                    fieldNames={{ value: "id", label: 'firstName' }}
                                    options={companyDetail?.companyData?.directors}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24}>
                            <Divider>Or</Divider>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director Title is required',
                                    required: true,
                                }
                            ]}
                                label="Director Title" name="title">
                                <Select
                                    options={UserTitle}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director Gender is required',
                                    required: true,
                                }
                            ]}
                                label="Director Gender" name="gender">
                                <Select
                                    options={UserGender}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director First Name is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Last Name',
                                    pattern: InputValidatorRegex.PERSON_NAME,
                                },
                            ]}
                                label="Director First Name" name="firstName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director Last Name is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Last Name',
                                    pattern: InputValidatorRegex.PERSON_NAME,
                                },
                            ]}
                                label="Director Last Name" name="lastName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director Contact No is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Contact No',
                                    pattern: InputValidatorRegex.MOBILE_NO,
                                },
                            ]}
                                label="Director Contact No" name="contactNo">
                                <Input maxLength={10} onChange={(event: any) => {
                                    RegisterM2pCorporateForm.form?.setFieldsValue({ contactNo: event?.target?.value.replace(/[^0-9]/g, '') })
                                }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director Email id is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Email id',
                                    pattern: InputValidatorRegex.EMAIL_CASE_INSENSITIVE,
                                },
                            ]}
                                label="Director Email id" name="emailAddress">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Director DOB is required',
                                    required: true,
                                }]} label="Director DOB" name="specialDate" getValueProps={(value) => ({
                                    value: value ? dayjs(value) : "",
                                })}>
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24}>
                            <Divider>Address</Divider>
                        </Col>

                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Address is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Address',
                                    pattern: InputValidatorRegex.ADDRESS,
                                },
                            ]}
                                label="Address" name="address">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Address Line 2 is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Address',
                                    pattern: InputValidatorRegex.ADDRESS,
                                },
                            ]}
                                label="Address Line 2" name="address2">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'City is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct City',
                                    pattern: InputValidatorRegex.ADDRESS,
                                },
                            ]}
                                label="City" name="city">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'State is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct State',
                                    pattern: InputValidatorRegex.ADDRESS,
                                },
                            ]}
                                label="State" name="state">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item initialValue={'India'} rules={[
                                {
                                    message: 'Country is required',
                                    required: true,
                                }
                            ]}
                                label="Country" name="country">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'Pincode is required',
                                    required: true,
                                },
                                {
                                    message: 'Enter correct Pincode',
                                    pattern: InputValidatorRegex.PINCODE,
                                },
                            ]}
                                label="Pincode" name="pincode">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24}>
                            <Divider>ID</Divider>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item initialValue={'GST'} rules={[
                                {
                                    message: 'Id Type is required',
                                    required: true,
                                }
                            ]}
                                label="Id Type" name="idType">
                                <Select
                                    options={IdType}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12}>
                            <Form.Item rules={[
                                {
                                    message: 'GST number is required',
                                    required: true,
                                }
                            ]}
                                label="GST number of the Corporate" name="idNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
