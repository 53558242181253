import React from "react";
export const FeatureFlags = React.createContext({});
const konsoleFeatures = {
    isGenerateAutoDebit: true,
    isPBKodoDailyCredit: false,
    isPBKodoPayLater: false,
    isPBKodoCredit: true,
    isPBKodoPrepaid: true,
    isEditCreditLimitForCreditCard: false,
    isEditCreditLimitForKodoPayCredit: true,
    isOnboardKodoTranscorpPrepaid: true,
    isAddUserKodoCredit: true,
    isAddUserKodoPrepaid: true,
    isEditUser: true,
    isAddUserKodoTranscorpPrepaid: true,
}
export const FeatureFlagsProvider: React.FC<any> = ({ children }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [features, setFeatures] = React.useState({});
    React.useEffect(() => {
        setFeatures(konsoleFeatures);
        setIsLoading(false);
        // getting features from api for future purpose
        // (async () => {
        //     try {
        //         const data = await fetchFeatures();
        //         if (data.features) {
        //             setFeatures(data.features);
        //         }
        //     } catch (err) {
        //         console.log(err);
        //     } finally {
        //         setIsLoading(false);
        //     }
        // })();
    }, []);
    return (
        <FeatureFlags.Provider value={{ features }}>
            {isLoading ? "Loading..." : children}
        </FeatureFlags.Provider>
    );
};
