import { gql } from '@apollo/client';
export const findCompanyByIntegrationIdPaginatedGQL = gql`
query findCompanyByIntegrationIdPaginated($mmtIntegrationId: String!, $pagination: PaginationOptions!) {
  findCompanyByIntegrationIdPaginated(
    input: {
      mmtIntegrationId: $mmtIntegrationId,
      pagination: $pagination
    } 
  ) {
    items {
      id
      company {
        id
        registeredName
        emailId
        brandName
      }
    }
  }
}
`
export const integrationSettlementStatementForKonsolePaginateGQL = gql`
  query integrationSettlementStatementForKonsolePaginate($filter: SettlementFilter!, $pagination: PaginationOptions!) {
    integrationSettlementStatementForKonsolePaginate(filter:$filter,pagination:$pagination){
      items {
        id
        totalInvoiceAmount
        finalAmountToPay
        processingFeePercent
        startDate
        endDate
        gstPercent
        lastUpdateNotes
        paymentStatus
        utr
        integrationSettlementStatementRequest {
          id,
          utr,
          referenceIdSharedWithBank
        }
      }
      meta {
        totalItems
      }
    }
  }
`;
export const integrationSettlementStatementMappingForKonsoleGQL = gql`
  query integrationSettlementStatementMappingForKonsole($settlementId: String!, $pagination: PaginationOptions!) {
    integrationSettlementStatementMappingForKonsole(settlementId:$settlementId,pagination:$pagination){
      items {
        id
        transaction {
          id,
          transactionAmount,
          transactionCategory,
          txnDate
          company {
            id
            registeredName
            emailId
            brandName
          }
        }
        integrationSettlementStatementId
      }
      meta {
        totalItems
      }
    }
  }
`;
export const getActiveIntegrationsGQL = gql`
  query  getActiveIntegrations($pagination: PaginationOptions!){
    getActiveIntegrations (pagination:$pagination){
      items {
        id
        name
        integrationApp
        createdAt
        processingFeePercent
        gstPercent
      }
      meta {
        totalItems
      }
    }
  }
`;
export const settleIntegrationPaymentGQL = gql`
  mutation  settleIntegrationPayment($settlementId:String!) {
    settleIntegrationPayment(settlementId:$settlementId)
  }
`;
export const generateMmtIntegrationSettlementStatementGQL = gql`
  mutation  generateMmtIntegrationSettlementStatement {
    generateMmtIntegrationSettlementStatement{
        id
    }
  }
`;
export const removeMmtIntegrationSettlementStatementGQL = gql`
  mutation  removeMmtIntegrationSettlementStatement($settlementId:String!,$lastUpdateNotes: String!) {
    removeMmtIntegrationSettlementStatement(
      settlementId:$settlementId,
      lastUpdateNotes:$lastUpdateNotes
    ){
        id
        totalInvoiceAmount
        finalAmountToPay
    }
  }
`;
export const checkIntegrationSettlementStatusWithBankGQL = gql`
  mutation  checkIntegrationSettlementStatusWithBank {
    checkIntegrationSettlementStatusWithBank
  }
`;

export const updateSettlementFinalAmountGQL = gql`
  mutation updateSettlementFinalAmount($id:ID!, $finalAmountToPay: Float!, $lastUpdateNotes: String!) {
    updateSettlementFinalAmount (
      input:{
        id: $id,
        finalAmountToPay: $finalAmountToPay,
        lastUpdateNotes: $lastUpdateNotes
      }
    ){
        id
        totalInvoiceAmount
        finalAmountToPay
    }
  }
`;

export const disableOrEnableMmtIntegrationFromInternalPortalGQL = gql`
  mutation disableOrEnableMmtIntegrationFromInternalPortal($id: String!, $passcode: String!, $lastUpdateNotes: String!, $status: Boolean!) {
    disableOrEnableMmtIntegrationFromInternalPortal (
      input:{
        id: $id,
        passcode: $passcode,
        lastUpdateNotes: $lastUpdateNotes,
        status: $status
      }
    ){
        id
        isActive
    }
  }
`;
export const addIntegrationCompanyMappingGQL = gql`
  mutation addIntegrationCompanyMapping($companyId:String!, $mappedCompanyId: String!, $onboardingDate: String!,$integrationId:String!) {
    addIntegrationCompanyMapping (
      input:{
        companyId: $companyId,
        mappedCompanyId: $mappedCompanyId,
        onboardingDate: $onboardingDate,
        integrationId: $integrationId
      }
    )
  }
`;

export const getSettlementHistoryGQL = gql`
  query getSettlementHistory($settlementId:String!) {
    getSettlementHistory (
      settlementId: $settlementId,
    ){
        id
        totalInvoiceAmount
        finalAmountToPay
        processingFeePercent
        startDate
        endDate
        gstPercent
        paymentStatus
        lastUpdateNotes
        utr
        updatedAt
    }
  }
`;
