import { AxiosInstance } from "axios";
import {
    QueryFilter,
    QueryFilterArr,
    QuerySort,
    QuerySortArr,
    QuerySortOperator,
    RequestQueryBuilder,
    CondOperator,
    // ComparisonOperator,
} from "@nestjsx/crud-request";
import axiosInstance from './axios-instance';
import {
    DataProvider,
    CrudFilters as RefineCrudFilter,
    CrudSorting,
} from "@pankod/refine-core";
import { AppConfig } from "../app.config";

type SortBy = QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>;
type CrudFilters =
    | QueryFilter
    | QueryFilterArr
    | Array<QueryFilter | QueryFilterArr>;

const generateSort = (sort?: CrudSorting): SortBy | undefined => {
    if (sort && sort.length > 0) {
        const multipleSort: SortBy = [];
        sort.map(({ field, order }) => {
            if (field && order) {
                multipleSort.push({
                    field: field,
                    order: order.toUpperCase() as QuerySortOperator,
                });
            }
            return true
        });
        return multipleSort;
    }

    return;
};

const generateFilter = (
    filters?: RefineCrudFilter,
): { crudFilters: CrudFilters; orFilters: CrudFilters } => {
    const crudFilters: CrudFilters = [];
    const orFilters: CrudFilters = [];
    return { crudFilters, orFilters };
};
const accessTokenRaw = sessionStorage.getItem(AppConfig.apiTokenKey);
let config = {
    headers: {
        authorization: accessTokenRaw ? `Bearer ${accessTokenRaw}` : "",
    }
}


const NestsxCrud = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
    getList: async ({
        resource,
        hasPagination = true,
        pagination = { current: 1, pageSize: 10 },
        filters,
        sort,
    }) => {
        const url = `${apiUrl}/${resource}`;

        const { current = 1, pageSize = 10 } = pagination ?? {};

        const { crudFilters, orFilters } = generateFilter(filters);

        const query = RequestQueryBuilder.create()
            .setFilter(crudFilters)
            .setOr(orFilters);

        if (hasPagination) {
            query
                .setLimit(pageSize)
                .setPage(current)
                .setOffset((current - 1) * pageSize);
        }

        const sortBy = generateSort(sort);
        if (sortBy) {
            query.sortBy(sortBy);
        }

        const { data } = await httpClient.get(`${url}?${query.query()}`, config);
        return {
            data: data.data,
            total: data.total,
        };
    },

    getMany: async ({ resource, ids }) => {
        const url = `${apiUrl}/${resource}`;

        const query = RequestQueryBuilder.create()
            .setFilter({
                field: "id",
                operator: CondOperator.IN,
                value: ids,
            })
            .query();

        const { data } = await httpClient.get(`${url}?${query}`, config);

        return {
            data,
        };
    },

    create: async ({ resource, variables, metaData }) => {
        const url = `${apiUrl}/${resource}`;

        const { data } = await httpClient.post(url, { ...variables, ...metaData }, config);

        return {
            data,
        };
    },

    update: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.patch(url, variables, config);

        return {
            data,
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                    config
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables }, config);

        return {
            data,
        };
    },

    getOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.get(url, config);

        return {
            data,
        };
    },

    deleteOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url, config);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`, config
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        const { crudFilters } = generateFilter(filters);
        const requestQueryBuilder = RequestQueryBuilder.create().setFilter(crudFilters);

        const sortBy = generateSort(sort);
        if (sortBy) {
            requestQueryBuilder.sortBy(sortBy);
        }

        let requestUrl = `${url}`;
        const parsedQuery = requestQueryBuilder.query();
        if (parsedQuery) {
            requestUrl = `${requestUrl}?${parsedQuery}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${new URLSearchParams(query as Record<string, string>)}`;
        }

        let axiosResponse;

        const config = {
            headers: {
                ...httpClient.defaults.headers.common,
                ...headers,
            },
        };

        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](requestUrl, payload, config);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(requestUrl, config);
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl, config);
                break;
        }

        const { data } = axiosResponse;

        return { data };
    },
});

export default NestsxCrud;
