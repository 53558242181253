import React, { useState, useRef, useEffect } from 'react';
import { Image } from 'antd';
import { usePdf } from '@mikecousins/react-pdf';
import { Pagination, Typography } from '@pankod/refine-antd';
const { Text } = Typography;

interface FileViewerProp {
    fileName: string,
    fileUrl: string,
    fileType: 'image' | 'pdf'
}
export const FileViewer: React.FC<FileViewerProp> = ({ fileName, fileUrl, fileType }) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const canvasRef = useRef(null);

    const { pdfDocument } = usePdf({
        file: fileUrl,
        page,
        canvasRef,
    });
    useEffect(() => {
        setPageSize(pdfDocument?._pdfInfo.numPages * 10)
    }, [pdfDocument?._pdfInfo.numPages])

    // Simple check for file type
    const isImage = fileType === 'image';

    return (
        <div>
            <Text>{fileName}</Text>
            <br />
            {isImage ? (
                // Displaying an image
                <Image
                    height='500px'
                    style={{ width: '100%', objectFit: 'contain' }}
                    src={fileUrl}
                    fallback="/placeholder.svg"
                />
            ) : (
                <div className='pdf-display'>
                    {!pdfDocument && <span>Loading...</span>}
                    <canvas style={{ width: '100%', height: '500px' }} ref={canvasRef} />
                    {Boolean(pdfDocument && pdfDocument.numPages) && (
                        <Pagination onChange={(page: number, pageSize: number) => {
                            setPage(page)
                        }}
                            defaultCurrent={1} total={pageSize} />
                    )}
                </div>
            )}

        </div>
    );
};
