import React from "react";

import { Modal, Space, Button, useModal, Table, Typography } from "@pankod/refine-antd";

import { useOne } from "@pankod/refine-core";
import { ReactivateUserModel } from "./reactiveUser.model";
const { Text } = Typography;

interface inactiveUserProps {
    refetchCompanyDetail: any,
    refetchUsers: any,
    companyId: string,
}

export const InactiveUserModel: React.FC<inactiveUserProps> = ({ refetchCompanyDetail, refetchUsers, companyId }) => {


    //inactive List
    let { data: inactiveUserListData, isLoading: inactiveUserListLoading, refetch: refetchInactiveUserListData } = useOne<any>({
        resource: `user-account/internal-portal/get-all-deactived-user-for-company?companyId=${companyId}`,
        id: '',
        queryOptions: {
            enabled: true,
        },
    });

    // auto Debit responce model
    const { modalProps: inactiveUserModel, close: inactiveUserClose, show: inactiveUserShow } = useModal();

    inactiveUserModel.title = 'Inactive Users'
    inactiveUserModel.centered = true
    inactiveUserModel.width = 1000
    inactiveUserModel.footer = [
        <Button loading={inactiveUserListLoading} onClick={() => { refetchInactiveUserListData() }} type="dashed">
            Refresh List
        </Button>,
        <Button key="submit" onClick={() => { inactiveUserClose() }} type="default">
            Close
        </Button>
    ]

    const refetchInactiveUserList = (message: boolean) => {
        refetchUsers(message);
        if (message === true) {
            refetchInactiveUserListData();
        }
    };
    return (
        <Space >
            <Button key="submit" onClick={() => { inactiveUserShow() }} type="default">
                Inactive users
            </Button>
            <Modal {...inactiveUserModel}>
                {
                    <Table dataSource={inactiveUserListData?.data} rowKey="id" loading={inactiveUserListLoading}>
                        <Table.Column title="User Name" render={(_, record: any) =>
                            <Space align='baseline' direction="horizontal">
                                {`${record?.user.firstName || ''} ${record?.user.middleName || ''} ${record?.user?.lastName || ''}`}
                            </Space>
                        } />
                        <Table.Column title="Email" render={(_, record: any) =>
                            <Space align='baseline' direction="horizontal">
                                {`${record?.user.emailId || ''}`}
                            </Space>
                        } />
                        <Table.Column title="Mobile No" render={(_, record: any) =>
                            <Space align='baseline' direction="horizontal">
                                {`${record?.user.mobileNo || ''}`}
                            </Space>
                        } />
                        <Table.Column title="Role" render={(_, record: any) =>
                            <Space align='baseline' direction="horizontal">
                                {`${record?.user.userType || ''}`}
                            </Space>
                        } />
                        <Table.Column title="Action" render={(_, record: any) =>
                            <ReactivateUserModel
                                companyId={companyId}
                                reActivateAccount={record.companyMappingList.some((item: any) => item.companyId === companyId && item.status === "DISABLED")}
                                refetchCompanyDetail={refetchCompanyDetail}
                                refetchInactiveUserList={refetchInactiveUserList} userAccountId={record.id} status={record.status} />
                        } />
                    </Table>
                }

            </Modal>
        </Space>
    );
};