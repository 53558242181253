import React, { useState } from "react";
import {
    Form,
    useModalForm,
    Space,
    Button,
    Modal,
    Select,
    DatePicker,
    Input,
    message,
} from "@pankod/refine-antd";
import { SettlementEventType, SettlementPartner } from "interfaces/mmt";

const { RangePicker } = DatePicker;

export const GenerateSettlementModel: React.FC<{ refetchActiveFeeStatementsDetails: any }> = ({ refetchActiveFeeStatementsDetails }) => {
    const [eventType, setEventType] = useState<string>("");

    let { modalProps: generateSettlementModel, formProps: generateSettlementForm, show: generateSettlementShow } = useModalForm({
        resource: "generateStatement",
        dataProviderName: "grapghQl",
        action: "create",
        metaData: {
            // eventType: SettlementEventType.KODO_VA_FEE
        },
        redirect: false,
        onMutationSuccess(data) {
            if (data.data.success === false){
                message.error("Please try again sometime")
            }
            refetchActiveFeeStatementsDetails(true)
        }
    });
    generateSettlementModel.title = 'Generate Settlement'
    generateSettlementModel.centered = true
    generateSettlementModel.okText = 'Generate Settlement'
    generateSettlementModel.width = 500



    return (
        <Space >
            <Button className="credit-btn" onClick={() => { generateSettlementShow() }} type="default">
                Generate Statement
            </Button>

            <Modal {...generateSettlementModel}>
                <Form {...generateSettlementForm} layout="vertical">
                    <Form.Item hidden={true} label="Start Date" name="startDate">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden={true} label="End Date" name="endDate">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Select Range for the Settlement" name="createdAt">
                        <RangePicker showTime onChange={(event: any) => {
                            generateSettlementForm.form?.setFieldsValue({ startDate: event[0].toISOString() })
                            generateSettlementForm.form?.setFieldsValue({ endDate: event[1].toISOString() })
                        }} />
                    </Form.Item>
                    <Form.Item
                        label="Event Type"
                        name="eventType"
                        rules={[
                            {
                                message:'Event Type is required',
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            onChange={(event: any) => {
                                setEventType(event)
                            }}
                            options={[
                                {
                                    label: SettlementEventType.KODO_VA_FEE,
                                    value: SettlementEventType.KODO_VA_FEE,
                                },
                                {
                                    label: SettlementEventType.CARD_ISSUANCE_FEE,
                                    value: SettlementEventType.CARD_ISSUANCE_FEE,
                                },
                                {
                                    label: SettlementEventType.CARD_TRANSACTION_FEE,
                                    value: SettlementEventType.CARD_TRANSACTION_FEE,
                                },
                                {
                                    label: SettlementEventType.CARD_REPLACEMENT_FEE,
                                    value: SettlementEventType.CARD_REPLACEMENT_FEE,
                                },
                            ]}
                        />
                    </Form.Item>
                    {
                        eventType === SettlementEventType.KODO_VA_FEE
                        ? <Form.Item
                            label="Settlement Partner"
                            name="settlementPartner"
                            rules={[
                                {
                                    message: 'Settlement Partner is required',
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: SettlementPartner.DECENTRO,
                                        value: SettlementPartner.DECENTRO,
                                    },
                                    {
                                        label: SettlementPartner.EASEBUZZ,
                                        value: SettlementPartner.EASEBUZZ,
                                    },
                                ]}
                            />
                        </Form.Item>:
                            <Form.Item
                                label="Settlement Partner"
                                name="settlementPartner"
                                rules={[
                                    {
                                        message: 'Settlement Partner is required',
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: SettlementPartner.M2P,
                                            value: SettlementPartner.M2P,
                                        },
                                    ]}
                                />
                            </Form.Item>
                    }


                </Form>
            </Modal>
        </Space>
    );
};