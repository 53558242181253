import React, { useEffect } from "react";

import {
    useModalForm, Modal, Space, Form,
    Button,
    Input,
    Select,
} from "@pankod/refine-antd";
import { ICompany, ICompanyPBI, InputValidatorRegex } from "../../../../interfaces/company";
import { verificationStatus } from "pages/company/list";

export const IssueProductBundleToCompanyModel: React.FC<{ refetchCompanyDetail: any, accountMangerSelectProps: any, productsBundleId: string, companyDetail: ICompany, productsBundleItem: ICompanyPBI }> = ({ productsBundleItem, productsBundleId, accountMangerSelectProps, companyDetail, refetchCompanyDetail }) => {

    useEffect(() => {

    });

    //kodo Payment Model
    const { modalProps: issueModel, formProps: issueForm, show: issueShow, close: issueClose } = useModalForm({
        resource: `${productsBundleItem?.productEnabled?.prepaidCards ? "issueAdditionalProductsBundlePrepaid" : "issueAdditionalProductsBundleNonPrepaid"}`,
        dataProviderName: "grapghQl",
        redirect: false,
        action: "create",
        metaData: {
            productsBundleId: productsBundleId,
            companyId: companyDetail.id
        },
        onMutationSuccess() {
            refetchCompanyDetail(true)
        }
    });
    issueModel.title = `Issue ${productsBundleItem?.displayName}`
    issueModel.centered = true
    issueModel.width = 400
    issueModel.onCancel = () => {
        issueForm.form?.resetFields();
        issueClose();
    }

    return (
        <Space>
            <Button className="credit-btn" type="default" onClick={() => { refetchCompanyDetail(false); issueShow() }}>
                Issue {productsBundleItem?.displayName}
            </Button>

            <Modal {...issueModel}>
                <Form {...issueForm} layout="vertical">

                    <Form.Item label="Name On Card"
                        initialValue={companyDetail?.brandName}
                        rules={[
                            {
                                message: 'Name On Card is required',
                                required: true,
                            }
                        ]} name="nameOnCard">
                        <Input />
                    </Form.Item>
                    {
                        productsBundleItem?.productEnabled?.cards && <Form.Item rules={[
                            {
                                message: 'Kodo Credit Limit is required',
                                required: true,
                            },
                            {
                                message: 'Enter Correct Amount',
                                pattern: InputValidatorRegex.AMOUNT_WT_DCIMAL,
                            },
                            {
                                max: 12,
                            },
                        ]}
                            label="Kodo Credit Limit" name="companyLimit">
                            <Input onChange={(event: any) => {
                                issueForm.form?.setFieldsValue({ companyLimit: event?.target?.value.replace(/[^0-9.]/g, '') })
                            }} />
                        </Form.Item>
                    }
                    {
                        productsBundleItem?.productEnabled?.cards && <Form.Item rules={[
                            {
                                message: 'Kodo Pay On Credit Limit is required',
                                required: true,
                            },
                            {
                                message: 'Enter Correct Amount',
                                pattern: InputValidatorRegex.AMOUNT_WT_DCIMAL,
                            },
                            {
                                max: 12,
                            },
                        ]}
                            label="Kodo Pay On Credit Limit" name="nonCardTxnCompanyLimit">
                            <Input onChange={(event: any) => {
                                issueForm.form?.setFieldsValue({ nonCardTxnCompanyLimit: event?.target?.value.replace(/[^0-9.]/g, '') })
                            }} />
                        </Form.Item>
                    }
                    {
                        productsBundleItem?.productEnabled?.cards && <Form.Item rules={[
                            {
                                message: 'Review After Months is required',
                                required: true,
                            },
                        ]}
                            initialValue={12}
                            label="Review After Months" name="monthsApprovedFor">
                            <Input type={'number'} maxLength={2} />
                        </Form.Item>
                    }
                    {
                        (companyDetail?.companyVerification === verificationStatus.PENDING) && <Form.Item
                            label="Account Manager"
                            name={"accountManagerUserId"}
                            rules={[
                                {
                                    message: 'Account Manager is required',
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...accountMangerSelectProps} />
                        </Form.Item>
                    }

                </Form>
            </Modal>
        </Space>
    );
};